import { Button, Space, Table, message } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload'
import axios from 'axios'
import React from 'react'
import { PdfFileDto } from '../../generated/backend'
import UploadBlockButton from '../UI/Uploads/UploadBlockButton/UploadBlockButton'
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import { pdfApi } from '@rbiconcept/pandid/dist';

type PdfFilesTableProps = {}

const PdfFilesTable: React.FC<PdfFilesTableProps> = () => {
	const [fileList, setFileList] = React.useState<RcFile[]>([])
	const [pdfFiles, setPdfFiles] = React.useState<PdfFileDto[]>([])

	const fetchPdfFiles = async () => {
		const response = await pdfApi.apiPdfDrawingFileGet();

		setPdfFiles(response.data)
	}

	React.useEffect(() => {
		fetchPdfFiles()
	}, [])

	const uploadProps: UploadProps = {
		name: 'file',
		multiple: true,
		beforeUpload: file => {
			if (file.type !== 'application/pdf') {
				message.error('Файл должен быть в формате PDF')
				return false
			}

			setFileList([])
			return true
		},
		accept: 'application/pdf',
		fileList: fileList,
		onChange: ({ file, fileList }: { file: UploadFile<RcFile>; fileList: RcFile[] }) => {
			if (file.type !== 'application/pdf') return
			if (file.status === 'done') {
				const updatedFileList = fileList.filter(item => item.uid !== file.uid)
				setFileList(updatedFileList)

				message.success(`${file.name} успешно загружен`)
			} else if (file.status === 'error') {
				setFileList([])

				message.error(`${file.name} не удалось загрузить`)
			} else {
				setFileList(fileList)
			}
		},
		progress: {
			size: 'small',
			format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
		},
		customRequest: async info => {
			const { onSuccess, file, onProgress, onError } = info

			if (typeof file === 'string') return

			const formData = new FormData()

			if ('name' in file) {
				formData.append('file', file, file.name)
			}

			const config = {
				onUploadProgress: event => {
					onProgress({ percent: (event.loaded / event.total) * 100 })
				},
			}

			axios
				.post('/api/pdfDrawingFile', formData, config)
				.then(() => {
					onSuccess('Ok')
					fetchPdfFiles()
				})
				.catch(err => {
					onError(err)
				})
		},
	}

/*	const splitPdfFile = (id: number) => {
		pdfApi.apiV1PdfSplitPost(id, 100)
	}*/

	const deletePdfFile = async (id: number) => {
		try {
			const { data } = await pdfApi.apiPdfDrawingFileDelete(id);
			if (data) {
				message.success('Файл успешно удален')
				fetchPdfFiles()
			}
		} catch (error) {
			message.error('Не удалось удалить файл')
		}
	}

	const columns: ColumnsType<PdfFileDto> = [
		{
			title: 'Имя',
			dataIndex: 'filename',
			key: 'filename',
		},
		{
			title: 'Действия',
			dataIndex: 'actions',
			key: 'actions',
			width: '10%',
			render: (_, record) => {
				return (
					<Space size={0}>
{/*						<Button
							onClick={() => splitPdfFile(record.id)}
							type='link'
							icon={<DownloadOutlined rev={undefined} />}
						/>*/}
						<Button
							onClick={() => deletePdfFile(record.id)}
							type='link'
							icon={<DeleteOutlined rev={undefined} />}
						/>
					</Space>
				)
			},
		},
	]

	return (
		<>
			<Table
				size='small'
				columns={columns}
				dataSource={pdfFiles?.map(pdfFile => ({ ...pdfFile, key: pdfFile.id }))}
				style={{ marginBottom: '12px' }}
			/>
			<UploadBlockButton uploadProps={uploadProps} title='Загрузить (PDF)' />
		</>
	)
}

export default PdfFilesTable
