import { Button, Space } from 'antd'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	fetchLopaEntries,
	importLopaEntries,
} from '../../../redux/lopaEntries/lopaEntries.actions'
import { useAppDispatch } from '../../../redux/store'
import LopaTable from '../LopaTable/LopaTable'
import styles from './LopaItem.module.css'

type LopaItemProps = {}

const LopaItem: React.FC<LopaItemProps> = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const onClosePage = () => {
		navigate('/lopa')
	}

	const importFromHazop = async () => {
		dispatch(importLopaEntries(id))
	}

	const fetchLopaEntry = async () => {
		dispatch(fetchLopaEntries(id))
	}

	React.useEffect(() => {
		fetchLopaEntry()
	}, [id])

	return (
		<div className={styles.container}>
			<Button onClick={importFromHazop} style={{ marginBottom: '12px' }}>
				Импорт из HAZOP
			</Button>
			<LopaTable />
			<Space style={{ marginTop: '12px' }}>
				<Button onClick={onClosePage}>Назад</Button>
			</Space>
		</div>
	)
}

export default LopaItem
