import { Button, Form, Modal, Select, Space, message } from 'antd'
import React from 'react'
import { settingsApi } from '../../api/apis'
import { SettingOutlined } from '@ant-design/icons'
import { useAppDispatch } from '../../redux/store'
import { fetchMatrix } from '../../redux/riskMatrix/riskMatrix.actions'
import { useModalActions } from '../../hooks/useModalActions'

type SettingsModalProps = {}

const options = [
	{ value: 5, label: '5' },
	{ value: 7, label: '7' },
]

export const SettingsModal: React.FC<SettingsModalProps> = ({}) => {
	const dispatch = useAppDispatch()
	const [matrixHeight, setMatrixHeight] = React.useState<number>(null)
	const [isOpen, onOpenModal, onCloseModal] = useModalActions()

	const sendSettings = async (height: number) => {
		try {
			await settingsApi.apiV1SettingsMatrixHeightPost(`${height}`)
			dispatch(fetchMatrix({ isInitial: false }))
			message.success('Сохранено!')
			onCloseModal()
		} catch (error) {
			message.error('Не удалось сохранить настройки')
		}
	}

	React.useEffect(() => {
		const fetchMatrixHeight = async () => {
			try {
				const response = await settingsApi.apiV1SettingsMatrixHeightGet()
				setMatrixHeight(response.data)
			} catch (error) {
				message.error('Не удалось загрузить настройки')
			}
		}

		fetchMatrixHeight()
	}, [])

	return (
		<>
			<Button
				name='settings'
				onClick={onOpenModal}
				icon={<SettingOutlined rev={undefined} />}
			>
				Настройки
			</Button>
			<Modal
				centered={true}
				footer={false}
				open={isOpen}
				onCancel={onCloseModal}
				title={'Настройки матрицы рисков'}
				destroyOnClose
			>
				<Form layout='vertical'>
					<Form.Item label={'Количество строк:'}>
						<Select
							size='large'
							value={matrixHeight}
							options={options}
							onSelect={value => setMatrixHeight(value)}
						/>
					</Form.Item>
				</Form>
				<Space>
					<Button name='save' type={'primary'} onClick={() => sendSettings(matrixHeight)}>
						Сохранить
					</Button>
					<Button onClick={onCloseModal}>Закрыть</Button>
				</Space>
			</Modal>
		</>
	)
}
