import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import styles from './Loader.module.css';

type LoaderProps = {
    isGeneral?: boolean;
};
export const Loader: React.FC<LoaderProps> = ({ isGeneral }) => {
    return (
        <div
            style={{ height: isGeneral ? '100vh' : '100%', width: isGeneral ? '100vw' : '100%' }}
            className={styles.container}
        >
            <Spin size={'large'} indicator={<LoadingOutlined style={{ fontSize: 50 }} spin rev={undefined} />} />
        </div>
    );
};
