import { Select, SelectProps } from 'antd'
import React from 'react'
import { useDebounceSearch } from '../../../../hooks/useDebounceValue'
import LoaderWithLogo from '../../Loaders/LoaderWithLogo/LoaderWithLogo'
import NotFoundContent from './NotFoundContent'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { AssetSystemDto, EquipmentDto } from '../../../../generated/backend'

interface DebounceSelectProps<ValueType = any> extends SelectProps<ValueType | ValueType[]> {
	fetchOptions: (search: string) => Promise<EquipmentDto[] | AssetSystemDto[]>
	setEntity: (entity: ValueType) => void
	onOpenModal?: () => void
	entity: 'equipments' | 'systems'
	delay?: number
}

const entityForNotFound = {
	equipments: 'оборудование',
	systems: 'систему',
}

const DebounceSelect: React.FC<DebounceSelectProps> = ({
	fetchOptions,
	setEntity,
	onOpenModal,
	searchValue,
	entity,
	delay = 800,
	...props
}) => {
	const isFetching = useDebounceSearch(fetchOptions, setEntity, searchValue, delay)
	const { isLoading } = useTypedSelector(state => state[entity])

	return (
		<Select
			{...props}
			defaultActiveFirstOption={false}
			showSearch={true}
			filterOption={false}
			notFoundContent={
				isFetching || isLoading || false ? (
					<LoaderWithLogo />
				) : (
					<NotFoundContent
						entity={entityForNotFound[entity]}
						searchValue={searchValue}
						onOpenModal={onOpenModal}
					/>
				)
			}
		/>
	)
}

export default DebounceSelect
