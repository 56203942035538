import { createAsyncThunk } from '@reduxjs/toolkit'
import {
	causesApi,
	consequencesApi,
	hazopEntriesApi,
	recommendationsApi,
	safeguardsApi,
} from '../../api/apis'
import {
	CauseDtoRequest,
	ConsequenceDtoRequest,
	ConsequenceDtoRequestRiskTypeEnum,
	HazopEntryDtoRequest,
	RecommendationDtoRequest,
	RecommendationDtoRequestCriticalityEnum,
	SafeguardDtoRequest,
	SafeguardDtoRequestSafeguardTypeEnum,
} from '../../generated/backend'

export const fetchHazopEntries = createAsyncThunk(
	'hazopEntries/fetch-hazop-entries',
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await hazopEntriesApi.apiV1HazopEntryByNodeGet(id)

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const fetchHazopEntryById = createAsyncThunk(
	'hazopEntries/fetch-hazop-entry-by-id',
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await hazopEntriesApi.apiV1HazopEntryIdGet(id)

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const addMatrixValue = createAsyncThunk(
	'hazopEntries/add-matrix-value',
	async (hazopEntry: HazopEntryDtoRequest, { rejectWithValue }) => {
		try {
			const response = await hazopEntriesApi.apiV1HazopEntryPut(hazopEntry)

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const addEmptyRow = createAsyncThunk(
	'hazopEntries/add-empty-row',
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await hazopEntriesApi.apiV1HazopEntryCreateEmptyPost(id)

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const addRow = createAsyncThunk(
	'hazopEntries/add-row',
	async (hazopEntry: HazopEntryDtoRequest, { rejectWithValue }) => {
		try {
			// const { data } = await hazopEntriesApi.apiV1HazopEntryCreateEmptyPost(hazopEntry.nodeId)
			// delete data.node
			// delete data.lopaRiskTotalDtos
			// delete data.causes
			// delete data.consequences
			// delete data.recommendations
			// delete data.safeguards

			const response = await hazopEntriesApi.apiV1HazopEntryPost({
				...hazopEntry,
			})

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const removeRow = createAsyncThunk(
	'hazopEntries/remove-row',
	async (id: string, { rejectWithValue }) => {
		try {
			await hazopEntriesApi.apiV1HazopEntryDelete(id)

			return id
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const addCause = createAsyncThunk(
	'hazopEntries/add-cause',
	async (
		{
			id,
			name,
			causeProbability,
		}: { id: string; name: string; causeProbability: number | null },
		{ rejectWithValue }
	) => {
		try {
			const response = await causesApi.apiV1CausePost({
				hazopEntryId: id,
				name,
				causeProbability,
			})

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const editCause = createAsyncThunk(
	'hazopEntries/edit-cause',
	async ({ id, cause }: { id: string; cause: CauseDtoRequest }, { rejectWithValue }) => {
		try {
			const response = await causesApi.apiV1CausePut({ ...cause, hazopEntryId: id })

			return { hazopEntryId: id, cause: response.data }
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const removeCause = createAsyncThunk(
	'hazopEntries/remove-cause',
	async ({ id, hazopEntryId }: { id: string; hazopEntryId: string }, { rejectWithValue }) => {
		try {
			await causesApi.apiV1CauseDelete(id)

			return { id, hazopEntryId }
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const addConsequence = createAsyncThunk(
	'hazopEntries/add-consequence',
	async (
		{ id, name, type }: { id: string; name: string; type: ConsequenceDtoRequestRiskTypeEnum },
		{ rejectWithValue }
	) => {
		try {
			const response = await consequencesApi.apiV1ConsequencePost({
				hazopEntryId: id,
				riskType: type,
				name,
			})

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const editConsequence = createAsyncThunk(
	'hazopEntries/edit-consequence',
	async (
		{
			id,
			consequence,
			type,
		}: {
			id: string
			consequence: ConsequenceDtoRequest
			type: ConsequenceDtoRequestRiskTypeEnum
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await consequencesApi.apiV1ConsequencePut({
				...consequence,
				hazopEntryId: id,
				riskType: type,
			})

			return { hazopEntryId: id, consequence: response.data }
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const removeConsequence = createAsyncThunk(
	'hazopEntries/remove-consequence',
	async ({ id, hazopEntryId }: { id: string; hazopEntryId: string }, { rejectWithValue }) => {
		try {
			await consequencesApi.apiV1ConsequenceDelete(id)

			return { id, hazopEntryId }
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const addSafeGuard = createAsyncThunk(
	'hazopEntries/add-safe-guard',
	async (
		{
			id,
			name,
			type,
			enable,
		}: {
			id: string
			name: string
			type: SafeguardDtoRequestSafeguardTypeEnum
			enable: boolean
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await safeguardsApi.apiV1SafeguardPost({
				hazopEntryId: id,
				safeguardType: type,
				name,
				enable,
			})

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const editSafeGuard = createAsyncThunk(
	'hazopEntries/edit-safe-guard',
	async (
		{
			id,
			safeGuard,
			type,
		}: {
			id: string
			safeGuard: SafeguardDtoRequest
			type: SafeguardDtoRequestSafeguardTypeEnum
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await safeguardsApi.apiV1SafeguardPut({
				...safeGuard,
				hazopEntryId: id,
				safeguardType: type,
			})

			return { hazopEntryId: id, safeGuard: response.data }
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const removeSafeguards = createAsyncThunk(
	'hazopEntries/remove-safeguards',
	async ({ id, hazopEntryId }: { id: string; hazopEntryId: string }, { rejectWithValue }) => {
		try {
			await safeguardsApi.apiV1SafeguardDelete(id)

			return { id, hazopEntryId }
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const addRecommendation = createAsyncThunk(
	'hazopEntries/add-recommendation',
	async (
		{
			id,
			name,
			criticality,
		}: {
			id: string
			name: string
			criticality: RecommendationDtoRequestCriticalityEnum
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await recommendationsApi.apiV1RecommendationPost({
				hazopEntryId: id,
				name,
				criticality,
			})

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const editRecommendation = createAsyncThunk(
	'hazopEntries/edit-recommendation',
	async (
		{
			id,
			recommendation,
			criticality,
		}: {
			id: string
			recommendation: RecommendationDtoRequest
			criticality: RecommendationDtoRequestCriticalityEnum
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await recommendationsApi.apiV1RecommendationPut({
				...recommendation,
				hazopEntryId: id,
				criticality,
			})

			return { hazopEntryId: id, recommendation: response.data }
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const removeRecommendations = createAsyncThunk(
	'hazopEntries/remove-recommendations',
	async ({ id, hazopEntryId }: { id: string; hazopEntryId: string }, { rejectWithValue }) => {
		try {
			await recommendationsApi.apiV1RecommendationDelete(id)

			return { id, hazopEntryId }
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const generateParameters = createAsyncThunk(
	'hazopEntries/generate-parameters',
	async (nodeId: string, { rejectWithValue }) => {
		try {
			const response = await hazopEntriesApi.apiV1HazopEntryGenerateWithParametersPost(nodeId)

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)
