import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { LopaEntryDto } from '../../../generated/backend'
import { editLopaEntry } from '../../../redux/lopaEntries/lopaEntries.actions'
import { RootState, useAppDispatch } from '../../../redux/store'
import { Loader } from '../../UI/Loaders/Loader/Loader'
import { ILopaTableModal } from '../../types'
import styles from './LopaTable.module.css'
import LopaTableModal from './LopaTableModal'

type LopaTableProps = {}

const LopaTable: React.FC<LopaTableProps> = () => {
	const [showTableModal, setShowTableModal] = React.useState<ILopaTableModal>({
		open: false,
		type: '',
		id: '',
		hazopEntryIndex: null,
	})
	const lopaEntries = useSelector((state: RootState) => state.lopaEntries)
	const dispatch = useAppDispatch()

	const onCloseTableModal = () => {
		setShowTableModal({ open: false, type: '', id: '', hazopEntryIndex: null })
	}

	const onOpenTableModal =
		(type: string, id: string, numberOfNodeLine: number) =>
		(e: React.MouseEvent<HTMLDivElement>) => {
			setShowTableModal({ open: true, type, id, hazopEntryIndex })
		}

	const saveChanges = (values: { type: string | number }, id: string, numberOfNodeLine: number) => {
		const data = lopaEntries.items[numberOfNodeLine].find(item => item.id === id)

		const newData = {
			...data,
			...values,
			nodeLineId: data.nodeLine?.id,
			riskType: data.riskType?.id,
		}

		delete newData.nodeLine

		dispatch(editLopaEntry({ data: newData, hazopEntryId: numberOfNodeLine })).then(() =>
			onCloseTableModal()
		)
	}

	if (lopaEntries.loading) {
		return <Loader />
	}

	return (
		<>
			<LopaTableModal
				tableModalProps={showTableModal}
				onClose={onCloseTableModal}
				saveChanges={saveChanges}
			/>
			<div className={styles.wrap}>
				<div className={styles.container}>
					<div className={classNames(styles.cell, styles.columnHeading, styles.stickyCell)}>
						<span className={styles.heading}>Номер узла HAZOP</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Узел HAZOP</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Слово-параметр</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Управляющее слово</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Исходная причина</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Инициирующие событие</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Категория риска</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Группа риска HAZOP</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Приемлемая вероятность риска</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Вероятность реализации причины</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Проектирование</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Система управления (СУ)</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>
							Независимая от СУ сигнализация или действия оператора
						</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Предохранительные клапаны/диафрагма</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>СПАЗ</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>
							Действия оператора (реакция на показания приборов и сигнализацию)
						</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Другое</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Продолжительность риска</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Занятость персонала</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Вероятность возгорания</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Коэффициент уязвимости</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Вероятность промежуточного события</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Суммарная вероятность промежуточного события</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Вероятность отказа по запросу</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Коэффициент снижения риска</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>SIL</span>
					</div>
					<div className={classNames(styles.cell, styles.columnHeading)}>
						<span className={styles.heading}>Комментарий</span>
					</div>
					{/* <div className={classNames(styles.cell, styles.columnHeading)}>
                <span className={styles.heading}>СПАЗ2</span>
            </div> */}
					{/* {lopaEntries.items?.map((lopaEntry, index) => {
						return lopaEntry.map((item: LopaEntryDto) => (
							<React.Fragment key={item.id}>
								<div className={classNames(styles.stickyCell, styles.cell)}>
									{item?.nodeLine?.number}
								</div>
								<div className={styles.cell}>{item?.nodeLine?.node?.name}</div>
								<div className={styles.cell}>{item?.nodeLine?.nodeParameter?.name}</div>
								<div className={styles.cell}>{item?.nodeLine?.nodeGuideword?.name}</div>
								<div className={styles.cell}>{item?.nodeLine?.causes?.length}</div>
								<div className={styles.cell}>{}</div>
								<div className={styles.cell}>{item?.riskCategory}</div>
								<div className={styles.cell}>{item?.riskType?.name}</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('acceptableRiskProbability', item?.id, index)}
								>
									{item?.acceptableRiskProbability}
								</div>
								<div className={styles.cell}>{item?.causeProbability}</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('design', item?.id, index)}
								>
									{item?.design}
								</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('controlSystem', item?.id, index)}
								>
									{item?.controlSystem}
								</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('independentSignaling', item?.id, index)}
								>
									{item?.independentSignaling}
								</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('reliefValves', item?.id, index)}
								>
									{item?.reliefValves}
								</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('spaz', item?.id, index)}
								>
									{item?.spaz}
								</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('operatorActions', item?.id, index)}
								>
									{item?.operatorActions}
								</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('other', item?.id, index)}
								>
									{item?.other}
								</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('riskDuration', item?.id, index)}
								>
									{item?.riskDuration}
								</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('busyStaff', item?.id, index)}
								>
									{item?.busyStaff}
								</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('fireRisk', item?.id, index)}
								>
									{item?.fireRisk}
								</div>
								<div
									className={styles.cell}
									onClick={onOpenTableModal('vulnerabilityFactor', item?.id, index)}
								>
									{item?.vulnerabilityFactor}
								</div>
								<div className={styles.cell}>{item?.intermediateEventProbability}</div>
								<div className={styles.cell}>
									{item?.totalProbabilityOfIntermediateEvent}
								</div>
								<div className={styles.cell}>{item?.vonz}</div>
								<div className={styles.cell}>{item?.riskReductionFactor}</div>
								<div className={styles.cell}>{item?.tmel}</div>
								<div className={styles.cell}>{item?.comment}</div>
								<div className={styles.cell}>{item?.spaz2}</div>
							</React.Fragment>
						))
					})} */}
				</div>
			</div>
		</>
	)
}

export default LopaTable
