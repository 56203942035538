import { Modal } from 'antd'
import React from 'react'
import RiskMatrix from '../../../riskMatrix/RiskMatrix'

type TableModalProps = {
	showModal: {
		show: boolean
		type: string
	}
	onCancel: () => void
	addMatrixValue?: (typeOfMatrixValue: string, value: string) => void
}
export const TableModal: React.FC<TableModalProps> = ({ showModal, onCancel, addMatrixValue }) => {
	return (
		<Modal
			open={showModal.show}
			centered={true}
			width={'85vw'}
			onCancel={onCancel}
			footer={false}
		>
			<div style={{ padding: '10px 20px' }}>
				<RiskMatrix
					isModal={true}
					onCancel={onCancel}
					type={showModal.type}
					addMatrixValue={addMatrixValue}
				/>
			</div>
		</Modal>
	)
}
