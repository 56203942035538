import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { fetchUserInfo, login } from '../../redux/general/general.actions'
import { RootState, useAppDispatch } from '../../redux/store'
import Logo from './../../assets/img/logo-black.svg'
import styles from './styles.module.css'

export function Login() {
	const generalState = useSelector((state: RootState) => state.general)
	const { changeUsername, changePassword } = useActions()
	const dispatch = useAppDispatch()

	const navigate = useNavigate()

	React.useEffect(() => {
		if (generalState.token) {
			navigate('/')
		}
	}, [generalState.token])

	return (
		<div className={styles.container}>
			<Form className={styles.loginForm}>
				<div className={styles.logo}>
					<Logo />
				</div>
				<Form.Item rules={[{ required: true, message: 'Введите имя пользователя' }]}>
					<Input
						prefix={<UserOutlined rev={undefined} />}
						placeholder='Имя пользовотеля...'
						value={generalState.username}
						onChange={event => changeUsername(event.target.value)}
						name='username'
					/>
				</Form.Item>
				<Form.Item>
					<Input.Password
						prefix={<LockOutlined rev={undefined} />}
						placeholder={'Пароль...'}
						value={generalState.password}
						onChange={event => changePassword(event.target.value)}
						name='password'
					/>
				</Form.Item>
				<Form.Item>
					<Checkbox>Запомнить</Checkbox>
					<a href={''} className={styles.forgot}>
						Забыли пароль?
					</a>
				</Form.Item>
				<Form.Item>
					<Button
						htmlType={'submit'}
						type={'primary'}
						name='submit'
						className={styles.button}
						onClick={event => {
							event.preventDefault()

							dispatch(
								login({
									username: generalState.username,
									password: generalState.password,
								})
							).then(res => {
								if (typeof res.payload !== 'string')
									dispatch(fetchUserInfo(generalState.token))
							})
						}}
					>
						Войти
					</Button>
					Или <a href=''>Зарегистрироваться!</a>
				</Form.Item>
			</Form>
		</div>
	)
}
