import { Tree } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchNodes } from '../../redux/nodes/nodes.actions'
import { RootState, useAppDispatch } from '../../redux/store'

type LopaLayoutProps = {}

const LopaLayout: React.FC<LopaLayoutProps> = () => {
	const navigate = useNavigate()
	const { nodes } = useSelector((state: RootState) => state.nodes)
	const dispatch = useAppDispatch()

	React.useEffect(() => {
		if (nodes.length === 0) dispatch(fetchNodes())
	}, [])

	return (
		<>
			<Tree
				treeData={nodes?.map(node => ({
					...node,
					title: node.name,
					key: node.id,
				}))}
				onSelect={(_, info) => navigate(`/lopa/${info.node.id}`)}
			/>
		</>
	)
}

export default LopaLayout
