import { Button, Form, Input, InputRef, Modal, Select, Space, message } from 'antd'
import React from 'react'
import { IDescriptionValueModal } from '../types'

type DescriptionModalProps = {
	showProps: IDescriptionValueModal
	onCancel: () => void
	sendDescription: (
		x: number,
		y: number,
		title: string,
		isEdit: boolean,
		id: string
	) => Promise<void>
}

const options = [
	{ label: '1e-1', value: 1e-1 },
	{ label: '1e-2', value: 1e-2 },
	{ label: '1e-3', value: 1e-3 },
	{ label: '1e-4', value: 1e-4 },
	{ label: '1e-5', value: 1e-5 },
	{ label: '1e-6', value: 1e-6 },
	{ label: '1e-7', value: 1e-7 },
]

type FormState = {
	value: string | number
}

export const DescriptionModal: React.FC<DescriptionModalProps> = ({
	onCancel,
	showProps,
	sendDescription,
}) => {
	const { show, x, y, isEdit, id, title, isTMEL } = showProps

	const [form] = Form.useForm()
	const inputRef = React.useRef<InputRef>(null)

	React.useEffect(() => {
		if (!show) return
		form.setFieldValue('value', isNaN(+title) ? title : Number(title).toExponential())

		if (!isTMEL) {
			const timeout: NodeJS.Timeout = setTimeout(() => {
				inputRef.current!.focus({
					cursor: 'end',
				})
			}, 200)

			return () => clearTimeout(timeout)
		}
	}, [show])

	const onSubmit = async ({ value }: FormState) => {
		if (title === value) {
			onCancel()
			return
		}
		if (!value) value = ''

		await sendDescription(
			x,
			y,
			typeof value === 'string' ? value : value.toString(),
			isEdit,
			id
		)
		onCancel()
		form.resetFields()
	}

	return (
		<Modal
			title={'Добавить параметр'}
			open={show}
			onCancel={onCancel}
			centered={true}
			footer={false}
			forceRender
			destroyOnClose
		>
			<Form<FormState>
				form={form}
				layout='vertical'
				onFinish={onSubmit}
				onReset={() => form.resetFields()}
			>
				{isTMEL ? (
					<Form.Item name={'value'} label={'Уровень риска:'}>
						<Select
							placeholder='Выберите уровень риска'
							size='large'
							options={options}
							allowClear
						/>
					</Form.Item>
				) : (
					<Form.Item name={'value'} label={'Наименование:'}>
						<Input
							ref={inputRef}
							id={'label'}
							size='large'
							placeholder='Введите наименование'
						/>
					</Form.Item>
				)}
				<Form.Item>
					<Space>
						<Button name={'save'} htmlType='submit' type={'primary'}>
							Сохранить
						</Button>
						<Button htmlType='reset' onClick={onCancel}>
							Закрыть
						</Button>
					</Space>
				</Form.Item>
			</Form>
		</Modal>
	)
}
