import { Col, Row, Skeleton, Tree } from 'antd'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { dropDown } from '../../../utils/dropdown'
import { Entity } from '../../types'
import AddEntityModal from '../Modals/AddEntityModal/AddEntityModal'
import { AssetSystemDto, CauseProbabilityDto, NodeDto } from '../../../generated/backend'

type ContentLayoutProps = {
	onSave: (entity: Entity) => Promise<void>
	entity: Entity[]
	onDeleteEntity: (entity: Entity) => void
	path: string
	layoutKey: string
	loading: boolean
}

let treeHeight: number

const ContentLayout: React.FC<ContentLayoutProps> = ({
	onSave,
	entity,
	onDeleteEntity,
	path,
	layoutKey,
	loading,
}) => {
	const [selected, setSelected] = useLocalStorage<string | number>(layoutKey + '-selected', '')
	const navigate = useNavigate()
	const conditionForProbability = location.pathname.includes('probabilities')

	React.useEffect(() => {
		treeHeight = window.innerHeight - 170
	}, [])

	React.useEffect(() => {
		const path = location.pathname.split('/').filter(item => item !== 'settings')

		if (path.length <= 2) {
			setSelected('')
		}
	}, [location.pathname])

	return (
		<>
			<AddEntityModal onSave={onSave} setSelected={setSelected} />
			<Row
				style={{ marginTop: 12 }}
				justify={conditionForProbability ? 'start' : 'space-around'}
			>
				<Col span={6}>
					<Skeleton title={false} paragraph={{ rows: 25 }} active loading={loading}>
						<Tree
							treeData={entity?.map(item => ({
								...item,
								title: (
									<>
										{item.name}{' '}
										{layoutKey === 'nodes' || layoutKey === 'systems'
											? (item as NodeDto | AssetSystemDto)?.code
											: layoutKey === 'probabilities'
											? `- ${(
													item as CauseProbabilityDto
											  ).probability.toExponential()}`
											: null}
										<span onClick={e => e.stopPropagation()}>
											{dropDown(item, onDeleteEntity)}
										</span>
									</>
								),
								key: item.id,
								selectable: !conditionForProbability,
							}))}
							height={treeHeight}
							selectedKeys={[selected]}
							onSelect={(key, info) => {
								navigate(`${path}/${info.node.id}`)
								setSelected(info.node.key)
							}}
							defaultSelectedKeys={[selected]}
						/>
					</Skeleton>
				</Col>
				{conditionForProbability ? null : (
					<Col span={17}>
						<Outlet />
					</Col>
				)}
			</Row>
		</>
	)
}

export default ContentLayout
