import { Button, Modal, Space, Typography } from 'antd';
import React from 'react';

type DeleteAllModalProps = {
    open: boolean;
    loading: boolean;
    onCancel: () => void;
    clearAllData: () => void;
};

const DeleteAllModal: React.FC<DeleteAllModalProps> = ({ open, loading, onCancel, clearAllData }) => {
    return (
        <Modal open={open} centered footer={false} width={400} closeIcon={false} onCancel={onCancel} destroyOnClose>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                <Typography.Title level={3}>Вы действительно хотите удалить все данные?</Typography.Title>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                <Space>
                    <Button size='large' loading={loading} onClick={clearAllData} danger type='primary'>
                        Удалить
                    </Button>
                    <Button size='large' onClick={onCancel}>
                        Отмена
                    </Button>
                </Space>
            </div>
        </Modal>
    );
};

export default DeleteAllModal;
