import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HazopEntryDto } from '../../generated/backend'
import {
	addCause,
	addConsequence,
	addEmptyRow,
	addMatrixValue,
	addRecommendation,
	addRow,
	addSafeGuard,
	editCause,
	editConsequence,
	editRecommendation,
	editSafeGuard,
	fetchHazopEntries,
	fetchHazopEntryById,
	generateParameters,
	removeCause,
	removeConsequence,
	removeRecommendations,
	removeRow,
	removeSafeguards,
} from './hazopEntries.actions'

interface HazopEntriesState {
	items: HazopEntryDto[]
	item: HazopEntryDto | null
	loading: boolean
}

const initialState: HazopEntriesState = {
	items: [],
	item: null,
	loading: false,
}

export const hazopEntriesSlice = createSlice({
	name: 'hazopEntries',
	initialState,
	reducers: {
		editHazopEntry: (
			state,
			action: PayloadAction<{ id: string; field: string; value: unknown }>
		) => {
			const indexOfItem = state.items.findIndex(item => item.id === action.payload.id)

			state.items[indexOfItem][action.payload.field] = action.payload.value

			state.item = state.items[indexOfItem]
		},
	},
	extraReducers: builder => {
		builder.addCase(generateParameters.fulfilled, (state, action) => {
			state.items = action.payload.sort((a, b) => a.number - b.number)
		})

		builder.addCase(fetchHazopEntries.pending, state => {
			state.loading = true
		})
		builder.addCase(fetchHazopEntries.fulfilled, (state, action) => {
			state.loading = false
			state.items = action.payload.sort((a, b) => a.number - b.number)
		})
		builder.addCase(fetchHazopEntries.rejected, state => {
			state.loading = false
		})

		builder.addCase(fetchHazopEntryById.fulfilled, (state, action) => {
			state.item = action.payload
		})

		builder.addCase(addMatrixValue.fulfilled, (state, action) => {
			state.item = action.payload

			const indexOfItem = state.items.findIndex(item => item.id === action.payload.id)

			state.items[indexOfItem] = action.payload
		})

		builder.addCase(addEmptyRow.fulfilled, (state, action) => {
			state.loading = false

			state.items.push(action.payload)
			state.items.sort((a, b) => a.number - b.number)
		})

		builder.addCase(addRow.fulfilled, (state, action) => {
			state.items.push(action.payload)
			state.items.sort((a, b) => a.number - b.number)
		})

		builder.addCase(removeRow.fulfilled, (state, action) => {
			state.items = state.items.filter(item => item.id !== action.payload)
		})

		builder.addCase(addCause.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntry.id
			)

			if (state.items[indexOfItem].causes.length === 0) {
				state.items[indexOfItem].causes.push(action.payload)
			}

			state.items[indexOfItem].causes = state.items[indexOfItem].causes.map(cause => {
				if (!Object.hasOwn(cause, 'id')) {
					return action.payload
				}

				return cause
			})

			state.item = state.items[indexOfItem]
		})
		builder.addCase(editCause.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntryId
			)

			state.items[indexOfItem].causes = state.items[indexOfItem].causes.map(cause => {
				if (cause.id === action.payload.cause.id) {
					return action.payload.cause
				}
				return cause
			})
			console.log(action.payload)
			state.item = state.items[indexOfItem]
		})
		builder.addCase(removeCause.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntryId
			)

			state.items[indexOfItem].causes = state.items[indexOfItem].causes.filter(
				item => item.id !== action.payload.id
			)

			state.item = state.items[indexOfItem]
		})

		builder.addCase(addConsequence.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntry.id
			)

			if (
				state.items[indexOfItem].consequences.filter(
					item => item.riskType.id === action.payload.riskType.id
				).length === 0
			) {
				state.items[indexOfItem].consequences.push(action.payload)
			}

			state.items[indexOfItem].consequences = state.items[indexOfItem].consequences.map(
				consequence => {
					if (!Object.hasOwn(consequence, 'id')) {
						return action.payload
					}

					return consequence
				}
			)

			state.item = state.items[indexOfItem]
		})
		builder.addCase(editConsequence.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntryId
			)

			state.items[indexOfItem].consequences = state.items[indexOfItem].consequences.map(
				consequence => {
					if (consequence.id === action.payload.consequence.id) {
						return action.payload.consequence
					}
					return consequence
				}
			)

			state.item = state.items[indexOfItem]
		})
		builder.addCase(removeConsequence.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntryId
			)

			state.items[indexOfItem].consequences = state.items[indexOfItem].consequences.filter(
				item => item.id !== action.payload.id
			)

			state.item = state.items[indexOfItem]
		})

		builder.addCase(addSafeGuard.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntryId
			)

			if (
				state.items[indexOfItem].safeguards.filter(
					item => item.safeguardType?.id === action.payload.safeguardType?.id
				).length === 0
			) {
				state.items[indexOfItem].safeguards.push(action.payload)
			}

			state.items[indexOfItem].safeguards = state.items[indexOfItem].safeguards.map(
				safeguard => {
					if (!Object.hasOwn(safeguard, 'id')) {
						return action.payload
					}
					return safeguard
				}
			)

			state.item = state.items[indexOfItem]
		})
		builder.addCase(editSafeGuard.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntryId
			)

			state.items[indexOfItem].safeguards = state.items[indexOfItem].safeguards.map(
				safeguard => {
					if (safeguard.id === action.payload.safeGuard.id) {
						return action.payload.safeGuard
					}
					return safeguard
				}
			)
			state.item = state.items[indexOfItem]
		})
		builder.addCase(removeSafeguards.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntryId
			)

			state.items[indexOfItem].safeguards = state.items[indexOfItem].safeguards.filter(
				item => item.id !== action.payload.id
			)

			state.item = state.items[indexOfItem]
		})

		builder.addCase(addRecommendation.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntry.id
			)

			if (state.items[indexOfItem].recommendations.length === 0) {
				state.items[indexOfItem].recommendations.push(action.payload)
			}

			state.items[indexOfItem].recommendations = state.items[indexOfItem].recommendations.map(
				recommendation => {
					if (!Object.hasOwn(recommendation, 'id')) {
						return action.payload
					}
					return recommendation
				}
			)

			state.item = state.items[indexOfItem]
		})
		builder.addCase(editRecommendation.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntryId
			)

			state.items[indexOfItem].recommendations = state.items[indexOfItem].recommendations.map(
				recommendation => {
					if (recommendation.id === action.payload.recommendation.id) {
						return action.payload.recommendation
					}
					return recommendation
				}
			)
			state.item = state.items[indexOfItem]
		})
		builder.addCase(removeRecommendations.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.id === action.payload.hazopEntryId
			)

			state.items[indexOfItem].recommendations = state.items[
				indexOfItem
			].recommendations.filter(item => item.id !== action.payload.id)

			state.item = state.items[indexOfItem]
		})
	},
})

export const { editHazopEntry } = hazopEntriesSlice.actions

export default hazopEntriesSlice.reducer
