import {
	BookOutlined,
	CheckOutlined,
	DownloadOutlined,
	ExperimentOutlined,
	HeatMapOutlined,
	HomeOutlined,
	SettingOutlined,
	SubnodeOutlined,
	TableOutlined,
	UploadOutlined,
} from '@ant-design/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import styles from './Sider.module.css'
import { Tooltip } from 'antd'
import { useTypedSelector } from '../../../hooks/useTypedSelector'

interface INavigation {
	label: string
	key: string
	icon?: React.ReactNode
	children?: INavigation[]
	isActive?: boolean
}

const navigation: INavigation[] = [
	{
		label: 'Главная',
		key: '/',
		icon: <HomeOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: true,
	},
	{
		label: 'Справочники',
		key: 'dictionaries',
		icon: <BookOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: false,
		children: [
			{
				label: 'Системы',
				key: '/dictionaries/systems',
			},
			{
				label: 'Параметры',
				key: '/dictionaries/parameters',
			},
			{
				label: 'Оборудование',
				key: '/dictionaries/equipments',
			},
			{
				label: 'Вероятности',
				key: '/dictionaries/probabilities',
			},
			{
				label: 'Матрица Рисков',
				key: '/dictionaries/matrix',
			},
		],
	},
	{
		label: 'Узлы',
		key: '/nodes',
		icon: <SubnodeOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: false,
	},
	{
		label: 'Hazop',
		key: '/hazop',
		icon: <ExperimentOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: false,
	},
	{
		label: 'Lopa',
		key: '/lopa',
		icon: <HeatMapOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: false,
	},
	{
		label: 'Схемы',
		key: 'schemes',
		icon: <TableOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: false,
		children: [
			{
				label: 'Файлы PDF',
				key: '/pdf-files',
			},
			{
				label: 'Файлы JPG',
				key: '/jpg-files',
			},
			{
				label: 'Схемы',
				key: '/schemes',
			},
		],
	},
	{
		label: 'Импорт',
		key: '/import',
		icon: <UploadOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: false,
	},
	{
		label: 'Экспорт',
		key: '/export',
		icon: <DownloadOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: false,
	},
	{
		label: 'Настройки',
		key: 'settings',
		icon: <SettingOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: false,
		children: [
			{
				label: 'Основные',
				key: '/settings/main',
			},
			{
				label: 'Пользователи',
				key: '/settings/users',
			},
			{
				label: 'Роли',
				key: '/settings/roles',
			},
		],
	},
]

const Sider: React.FC = () => {
	const navigate = useNavigate()
	const [navigationMenu, setNavigationMenu] = React.useState<INavigation[]>(navigation)
	const [collapsed, setCollapsed] = React.useState<string>('')
	const roles: string[] = JSON.parse(localStorage.getItem('roles'))

	const { lopaEnable } = useTypedSelector(state => state.settings)

	const closeSubMenu = () => {
		setCollapsed('')
	}

	const subMenuRef = useOutsideClick<HTMLDivElement>(closeSubMenu)

	React.useEffect(() => {
		const changeNavigation = (key: string) => {
			setNavigationMenu(
				navigationMenu.map(itemNav => {
					const findChildren = itemNav.children?.filter(item => item.key === key)

					if (itemNav.key === key || findChildren?.length > 0) {
						itemNav.isActive = true
						itemNav?.children?.forEach(item => {
							if (item.key === key) {
								item.isActive = true
							} else {
								item.isActive = false
							}
						})
					} else {
						itemNav.isActive = false
						itemNav?.children?.forEach(item => {
							item.isActive = false
						})
					}
					return itemNav
				})
			)
		}

		changeNavigation(window.location.pathname)
	}, [window.location.pathname])

	const checkRole = () => {
		const filteredNavigationMenu = lopaEnable
			? navigationMenu
			: navigationMenu.filter(item => item.key !== '/lopa')

		if (!!roles?.find(item => item === 'admin')) {
			return filteredNavigationMenu
		}

		return filteredNavigationMenu.map(item => ({
			...item,
			children: item.children?.filter(
				child => child.key !== 'users' && child.key !== 'roles'
			),
		}))
	}

	return (
		<div className={styles.sider}>
			{checkRole().map(itemNav => {
				return (
					<div
						style={{ backgroundColor: itemNav.isActive ? '#38649f' : '' }}
						className={styles.item}
						key={itemNav.key}
						onClick={e => {
							e.stopPropagation()
							if (itemNav.children) {
								if (collapsed === itemNav.key) {
									closeSubMenu()
									return
								}
								setCollapsed(itemNav.key)
								return
							}

							navigate(itemNav.key)
						}}
					>
						{itemNav.icon}
						{itemNav.label.length > 9 ? (
							<Tooltip placement='right' title={itemNav.label}>
								<div className={styles.itemTitle}>
									{itemNav.label.substring(0, 7) + '...'}
								</div>
							</Tooltip>
						) : (
							<div className={styles.itemTitle}>{itemNav.label}</div>
						)}
						<div
							ref={subMenuRef}
							className={styles.subMenu}
							style={{
								opacity: collapsed === itemNav.key ? '1' : '',
								visibility: collapsed === itemNav.key ? 'visible' : 'hidden',
								transition: 'all 0.3s ease',
							}}
						>
							{itemNav.children?.map(item => (
								<div
									style={{ backgroundColor: item.isActive ? '#38649f' : '' }}
									key={item.key}
									className={styles.subMenuItem}
									onClick={e => {
										e.stopPropagation()
										navigate(item.key)
									}}
								>
									{item.label}
									{item.isActive && <CheckOutlined rev={undefined} />}
								</div>
							))}
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default Sider
