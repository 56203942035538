import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NodeParameterDto, NodeParameterDtoRequest } from '../../generated/backend'
import {
	addParameter,
	deleteParameter,
	fetchParameters,
} from '../../redux/parameters/parameters.actions'
import { RootState, useAppDispatch } from '../../redux/store'
import ContentLayout from '../UI/ContentLayout/ContentLayout'
import { message } from 'antd'

type ParametersLayoutProps = {}

const layoutKey = 'parameters'

export const ParametersLayout: React.FC<ParametersLayoutProps> = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { parameters, isLoading } = useSelector((state: RootState) => state.parameters)

	const [messageApi, contextHolder] = message.useMessage()

	React.useEffect(() => {
		const fetchParametersData = async () => {
			const response = await dispatch(fetchParameters())

			if (response.meta.requestStatus === 'rejected') {
				messageApi.error('Не удалось загрузить параметры!')
			}
		}

		fetchParametersData()
	}, [])

	const onSave = async (parameter: NodeParameterDtoRequest) => {
		const createdNode = await dispatch(addParameter(parameter))
		if (createdNode.meta.requestStatus === 'fulfilled') {
			navigate(`/dictionaries/parameters/${(createdNode.payload as NodeParameterDto).id}`)
			messageApi.success('Успешно добавлено!')
		} else {
			messageApi.error('Что-то пошло не так!')
		}
	}

	const onDeleteParameter = async (parameter: NodeParameterDto) => {
		navigate('/dictionaries/parameters')
		const response = await dispatch(deleteParameter(parameter.id))
		if (response.meta.requestStatus === 'fulfilled') {
			messageApi.success('Успешно удалено!')
		} else {
			messageApi.error('Что-то пошло не так!')
		}
	}

	return (
		<>
			{contextHolder}
			<ContentLayout
				onDeleteEntity={onDeleteParameter}
				onSave={onSave}
				entity={parameters}
				path={'/dictionaries/parameters'}
				loading={isLoading}
				layoutKey={layoutKey}
			/>
		</>
	)
}
