import { createAsyncThunk } from '@reduxjs/toolkit';
import { NodeParameterDtoRequest } from '../../generated/backend';
import { parametersApi } from '../../api/apis';

export const fetchParameters = createAsyncThunk('parameters/fetch-parameters', async (_, { rejectWithValue }) => {
    try {
        const response = await parametersApi.apiV1NodeParameterGet();

        return response.data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const fetchParameterById = createAsyncThunk(
    'parameters/parameter-by',
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await parametersApi.apiV1NodeParameterIdGet(id);

            return response.data;
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);

export const deleteParameter = createAsyncThunk(
    'parameters/deleteParameter',
    async (id: string, { rejectWithValue }) => {
        try {
            await parametersApi.apiV1NodeParameterDelete(id);

            return id;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const editParameter = createAsyncThunk(
    'parameters/edit-parameter',
    async (parameter: NodeParameterDtoRequest, { rejectWithValue }) => {
        try {
            const response = await parametersApi.apiV1NodeParameterPut(parameter);

            return response.data;
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);

export const addParameter = createAsyncThunk(
    'parameter/add-parameter',
    async (parameter: NodeParameterDtoRequest, { rejectWithValue }) => {
        try {
            const response = await parametersApi.apiV1NodeParameterPost(parameter);

            return response.data;
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);
