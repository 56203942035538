import { Button, Col, Modal, Row, message } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import { hazopEntriesApi } from '../../../../api/apis'
import { HazopEntryDto } from '../../../../generated/backend'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import {
	fetchHazopEntries,
	fetchHazopEntryById,
} from '../../../../redux/hazopEntries/hazopEntries.actions'
import { useAppDispatch } from '../../../../redux/store'

type CopyListAttributesModalProps = {
	show: boolean
	onCancel: () => void
	typeOfEntity: 'causes' | 'consequences' | 'safeguards' | 'recommendations'
}

type ListForCopy = { [key: string]: 'CAUSES' | 'CONSEQUENCES' | 'SAFEGUARDS' | 'RECOMMENDATIONS' }

const listForCopy: ListForCopy = {
	causes: 'CAUSES',
	consequences: 'CONSEQUENCES',
	safeguards: 'SAFEGUARDS',
	recommendations: 'RECOMMENDATIONS',
}

const CopyListAttributesModal: React.FC<CopyListAttributesModalProps> = ({
	show,
	typeOfEntity,
	onCancel,
}) => {
	const { id, hazopEntryId } = useParams()
	const dispatch = useAppDispatch()
	const hazopEntries = useTypedSelector(state => state.hazopEntries.items)

	const [activeHazopEntry, setActiveHazopEntry] = React.useState<HazopEntryDto>()

	const copyListAttributes = async () => {
		try {
			await hazopEntriesApi.apiV1HazopEntryCopyListAttributesPut(
				activeHazopEntry.id,
				hazopEntryId,
				listForCopy[typeOfEntity]
			)

			await dispatch(fetchHazopEntryById(hazopEntryId))
			await dispatch(fetchHazopEntries(id))

			message.success('Список атрибутов скопирован!')
			onCancel()
		} catch (e) {
			message.error('Не удалось скопировать атрибуты')
		}
	}

	return (
		<Modal
			open={show}
			onCancel={onCancel}
			destroyOnClose
			centered
			width={'50vw'}
			title={'Копировать список атрибутов'}
			footer={false}
		>
			<Row justify={'space-between'} style={{ padding: 10 }}>
				<Col span={14} style={{ maxHeight: 500, overflow: 'auto' }}>
					{hazopEntries
						.filter(hazopEntry => hazopEntry.id !== hazopEntryId)
						.map(hazopEntry => (
							<div
								style={{
									backgroundColor:
										activeHazopEntry?.id === hazopEntry.id
											? '#e6f4ff'
											: 'white',
									cursor: 'pointer',
								}}
								onClick={() => setActiveHazopEntry(hazopEntry)}
								key={hazopEntry.id}
							>
								{hazopEntry?.nodeParameter && !hazopEntry?.nodeGuideword
									? hazopEntry.nodeParameter.name
									: `${hazopEntry?.nodeParameter?.name} - ${hazopEntry?.nodeGuideword?.name}`}
							</div>
						))}
				</Col>

				<Col span={7} style={{ maxHeight: 500, overflow: 'auto' }}>
					{activeHazopEntry ? (
						<ul>
							{activeHazopEntry[typeOfEntity]?.map((entity, index) => (
								<li key={entity.id}>{entity?.name}</li>
							))}
						</ul>
					) : (
						'Выберите отклонение'
					)}
				</Col>
			</Row>
			<Button
				disabled={!activeHazopEntry?.id}
				type='dashed'
				block
				onClick={copyListAttributes}
			>
				Добавить
			</Button>
		</Modal>
	)
}

export default CopyListAttributesModal
