import { ConfigProvider } from 'antd'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './components/App'
import { store } from './redux/store'
import React from 'react'

const container = document.getElementById('root')
const root = createRoot(container!)

//todo env?
// if (process.env.NODE_ENV === 'development') {
//     startWorker();
// }

root.render(
	<Provider store={store}>
		<ConfigProvider
			theme={{
				token: {
					borderRadius: 3,
				},
			}}
		>
			<App />
		</ConfigProvider>
	</Provider>
)
