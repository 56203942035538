import React from 'react'

type CallApi = (value: string) => Promise<unknown>
type SetEntity = (entity: unknown) => void

export const useDebounceSearch = (
	callApi: CallApi,
	setEntity: SetEntity,
	searchValue: string,
	delay = 800
) => {
	const [isFetching, setIsFetching] = React.useState(false)

	React.useEffect(() => {
		if (!searchValue) {
			setIsFetching(false)
			return
		}

		setIsFetching(true)
		setEntity([])

		const timeout = setTimeout(async () => {
			try {
				const data = await callApi(searchValue)
				setEntity(data)
			} catch (e) {
				console.log(e)
			} finally {
				setIsFetching(false)
			}
		}, delay)

		return () => clearTimeout(timeout)
	}, [searchValue])

	return isFetching
}
