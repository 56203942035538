import { Button, Form, Input, Modal, Space, UploadProps } from 'antd';
import React from 'react';
import UploadBlockButton from '../../UI/Uploads/UploadBlockButton/UploadBlockButton';

type SchemeAddModalProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (scheme: any) => void;
};

const SchemeAddModal: React.FC<SchemeAddModalProps> = ({ open, onClose, onSubmit }) => {
    const [form] = Form.useForm();

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        accept: 'image/jpg, image/jpeg',
    };

    return (
        <Modal open={open} onCancel={onClose} centered footer={false} title={'Добавить схему'} destroyOnClose>
            <Form
                form={form}
                onFinish={values => {
                    onSubmit(values);
                    form.resetFields();
                }}
                layout='vertical'
            >
                <Form.Item label='Код' name={'code'}>
                    <Input size='large' />
                </Form.Item>
                <Form.Item label='Комментарий' name={'comment'}>
                    <Input size='large' />
                </Form.Item>
                <Form.Item label='Файлы'>
                    <UploadBlockButton uploadProps={uploadProps} title='Загрузить (JPG, JPEG)' />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type='primary' htmlType='submit'>
                            Добавить
                        </Button>
                        <Button
                            onClick={() => {
                                onClose();
                                form.resetFields();
                            }}
                        >
                            Отмена
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default SchemeAddModal;
