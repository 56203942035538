import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AssetSystemDto, AssetSystemDtoRequest } from '../../generated/backend'
import { RootState, useAppDispatch } from '../../redux/store'
import { addSystem, deleteSystem, fetchSystems } from '../../redux/systems/systems.actions'
import ContentLayout from '../UI/ContentLayout/ContentLayout'
import { message } from 'antd'

type SystemsLayoutProps = {}
const layoutKey = 'systems'

export const SystemsLayout: React.FC<SystemsLayoutProps> = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { systems, isLoading } = useSelector((state: RootState) => state.systems)

	const [messageApi, contextHolder] = message.useMessage()

	React.useEffect(() => {
		const fetchSystemsData = async () => {
			const response = await dispatch(fetchSystems())

			if (response.meta.requestStatus === 'rejected') {
				messageApi.error('Не удалось загрузить системы!')
			}
		}

		fetchSystemsData()
	}, [])

	const onSave = async (node: AssetSystemDtoRequest) => {
		const createdNode = await dispatch(addSystem(node))
		if (createdNode.meta.requestStatus === 'fulfilled') {
			navigate(`/dictionaries/systems/${(createdNode.payload as AssetSystemDto).id}`)
			messageApi.success('Успешно добавлено!')
		} else {
			messageApi.error('Не удалось добавить!')
		}
	}

	const onDeleteSystem = async (system: AssetSystemDto) => {
		navigate('/dictionaries/systems')
		const response = await dispatch(deleteSystem(system.id))
		if (response.meta.requestStatus === 'fulfilled') {
			messageApi.success('Удалено!')
		} else {
			messageApi.error('Не удалось удалить!')
		}
	}

	return (
		<>
			{contextHolder}
			<ContentLayout
				onDeleteEntity={onDeleteSystem}
				onSave={onSave}
				entity={systems}
				path={'/dictionaries/systems'}
				loading={isLoading}
				layoutKey={layoutKey}
			/>
		</>
	)
}
