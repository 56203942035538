import { Typography } from 'antd'
import React from 'react'
import LopaForm from '../lopaForm/LopaForm'
import MainForm from '../mainForm/MainForm'
import styles from './SettingsLayout.module.css'
import ClearAllDataButton from '../../UI/Buttons/ClearAllDataButton/ClearAllDataButton'

type SettingsLayoutProps = {}

export const SettingsLayout: React.FC<SettingsLayoutProps> = ({}) => {
	return (
		<div className={styles.container}>
			<Typography.Title style={{ margin: 0 }} level={5}>
				Основные
			</Typography.Title>
			<Typography.Title style={{ margin: 0 }} level={5}>
				Коэффициенты мер безопасности
			</Typography.Title>
			<Typography.Title style={{ margin: 0 }} level={5}>
				Данные
			</Typography.Title>
			<MainForm />
			<LopaForm />
			<ClearAllDataButton />
		</div>
	)
}
