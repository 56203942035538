import { Button, Checkbox, Input, Select, Switch, message } from 'antd'
import React from 'react'
import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { saveSettings } from '../../../redux/settings/settings.actions'
import { useAppDispatch } from '../../../redux/store'
import styles from './MainForm.module.css'
import { LopaSettingsDto } from '../../../generated/backend'

type MainFormProps = {}

const checkBoxGroupOptions = [
	{
		label: 'Меры достаточности',
		value: 'preLopaCalculation',
	},
	{
		label: 'Оборудование для отклонения',
		value: 'equipmentEnabled',
	},
	{
		label: 'Расчет LOPA',
		value: 'lopaEnable',
	},
]

const hazopTypeOptions = [
	{ label: '4', value: 'FOUR' },
	{ label: '8', value: 'EIGHT' },
]

const isLopaSettingsExist = (settings: LopaSettingsDto) => {
	return Object.values(settings).every(value => value)
}

const MainForm: React.FC<MainFormProps> = () => {
	const dispatch = useAppDispatch()
	const { changeProjectValues, changeProjectBoolValues, changeBackupSettings } = useActions()
	const { mainSettings, lopaSettings, lopaEnable, backupSettings } = useTypedSelector(
		state => state.settings
	)

	const [messageApi, contextHolder] = message.useMessage()

	React.useEffect(() => {
		if (!backupSettings.intervalMinutes) {
			changeBackupSettings({ key: 'intervalMinutes', value: 1 })
		}
	}, [backupSettings.intervalMinutes])

	const handleChangeProjectValue = (field: string, value: string) => {
		if (!value) {
			changeProjectValues({ field, value: null })
			return
		}

		changeProjectValues({ field, value })
	}

	const onChangeBackupSettings = (key: string, value: unknown) => {
		changeBackupSettings({ key, value })
	}

	const handleChangeComponents = (checkedValues: string[]) => {
		changeProjectBoolValues(checkedValues)
	}

	const handleSave = async () => {
		if (!isLopaSettingsExist(lopaSettings)) {
			messageApi.warning('Необходимо заполнить все коэффициенты LOPA')
			return
		}
		const response = await dispatch(
			saveSettings({ ...mainSettings, lopaSettings, lopaEnable, backupSettings })
		)
		if (response.meta.requestStatus === 'fulfilled') {
			messageApi.success('Настройки успешно сохранены!')
		} else {
			messageApi.error('Произошла ошибка при сохранении настроек')
		}
	}
	console.log(lopaSettings)
	return (
		<div className={styles.form}>
			{contextHolder}
			<p>Наименование проекта</p>
			<Input
				value={mainSettings.projectName}
				onChange={e => handleChangeProjectValue('projectName', e.target.value)}
				size='large'
				style={{ width: 255 }}
				name='name'
			/>
			<p>Этап проекта</p>
			<Input
				size='large'
				value={mainSettings.projectStep}
				onChange={e => handleChangeProjectValue('projectStep', e.target.value)}
				style={{ width: 255 }}
				name='step'
			/>
			<p>Тип Hazop</p>
			<Select
				style={{ width: 255 }}
				size='large'
				value={mainSettings.hazopType}
				options={hazopTypeOptions}
				onSelect={value => handleChangeProjectValue('hazopType', value)}
			/>
			<p>Модули</p>
			<Checkbox.Group
				onChange={handleChangeComponents}
				options={checkBoxGroupOptions}
				value={[
					mainSettings.equipmentEnabled && 'equipmentEnabled',
					mainSettings.preLopaCalculation && 'preLopaCalculation',
					lopaEnable && 'lopaEnable',
				]}
			/>
			<p>Бэкапы</p>
			<div className={styles.backup}>
				<Switch
					checked={backupSettings.status}
					checkedChildren='Вкл.'
					unCheckedChildren='Выкл.'
					style={{ width: '35%' }}
					onChange={value => onChangeBackupSettings('status', value)}
				/>
				<Input
					type='number'
					onChange={e => onChangeBackupSettings('intervalMinutes', +e.target.value)}
					addonAfter='мин'
					value={backupSettings.intervalMinutes}
					placeholder='Интервал'
					min={1}
				/>
			</div>
			<Button name='save' type={'primary'} onClick={handleSave}>
				Сохранить
			</Button>
		</div>
	)
}

export default MainForm
