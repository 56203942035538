import { Button, Space, Table, UploadProps, message } from 'antd'
import React from 'react'
import UploadBlockButton from '../UI/Uploads/UploadBlockButton/UploadBlockButton'
import { RcFile, UploadFile } from 'antd/es/upload'
import axios from 'axios'
import { ImageApi, ImageFileDto } from '../../generated/backend'
import { ColumnsType } from 'antd/es/table'
import { DeleteOutlined, EyeOutlined, PushpinOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import AttachToSchemeModal from '../UI/Modals/AttachToSchemeModal/AttachToSchemeModal'
import { imageApi } from '@rbiconcept/pandid/dist';

type JpgFilesTableProps = {}

const JpgFilesTable: React.FC<JpgFilesTableProps> = () => {
	const navigate = useNavigate()
	const [fileList, setFileList] = React.useState<RcFile[]>([])
	const [jpgFiles, setJpgFiles] = React.useState<ImageFileDto[]>([])
	const [openAttachToSchemeModal, setOpenAttachToSchemeModal] = React.useState({
		open: false,
		id: 0,
	})

	const onOpenAttachToSchemeModal = (id: number) => {
		setOpenAttachToSchemeModal({ open: true, id })
	}

	const onCloseAttachToSchemeModal = () => {
		setOpenAttachToSchemeModal({ open: false, id: 0 })
	}

	const fetchJpgFiles = async () => {
		const response = await imageApi.apiImageDrawingFileGet()

		setJpgFiles(response.data)
	}

	React.useEffect(() => {
		fetchJpgFiles()
	}, [])

	const deleteJpgFile = async (id: number) => {
		try {
			const { data } = await imageApi.apiImageDrawingFileDelete(id)

			if (data) {
				message.success('Файл удален')
				fetchJpgFiles()
			}
		} catch (e) {
			message.error('Не удалось удалить файл')
		}
	}

	const columns: ColumnsType<ImageFileDto> = [
		{
			title: 'Имя',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'PDF',
			key: 'pdf',
			dataIndex: ['parentPdfDrawingFile', 'filename'],
		},
		{
			title: 'Действия',
			dataIndex: 'actions',
			key: 'actions',
			width: '10%',
			render: (_, record) => {
				return (
					<Space size={0}>
						<Button
							type='link'
							icon={<EyeOutlined rev={undefined} />}
							href={`/jpg-files/view/${record.id}`}
							target='_blank'
						/>
						<Button
							onClick={() => onOpenAttachToSchemeModal(record.id)}
							type='link'
							icon={<PushpinOutlined rev={undefined} />}
						/>
						<Button
							onClick={() => deleteJpgFile(record.id)}
							type='link'
							icon={<DeleteOutlined rev={undefined} />}
						/>
					</Space>
				)
			},
		},
	]

	const uploadProps: UploadProps = {
		name: 'file',
		multiple: true,
		beforeUpload: file => {
			if (file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
				message.error('Файл должен быть jpg или jpeg')
				return false
			}

			setFileList([])
			return true
		},
		accept: 'image/jpg, image/jpeg',
		fileList: fileList,
		onChange: ({ file, fileList }: { file: UploadFile<RcFile>; fileList: RcFile[] }) => {
			if (file.type !== 'image/jpg' && file.type !== 'image/jpeg') return
			if (file.status === 'done') {
				const updatedFileList = fileList.filter(item => item.uid !== file.uid)
				setFileList(updatedFileList)

				message.success(`${file.name} успешно загружен`)
			} else if (file.status === 'error') {
				setFileList([])

				message.error(`${file.name} не удалось загрузить`)
			} else {
				setFileList(fileList)
			}
		},
		progress: {
			size: 'small',
			format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
		},
		customRequest: async info => {
			const { onSuccess, file, onProgress, onError } = info

			if (typeof file === 'string') return

			const formData = new FormData()

			if ('name' in file) {
				formData.append('file', file, file.name)
			}

			const config = {
				onUploadProgress: event => {
					onProgress({ percent: (event.loaded / event.total) * 100 })
				},
			}

			axios
				.post('/api/v1/image?dpi=100', formData, config)
				.then(res => {
					onSuccess('Ok')
					fetchJpgFiles()
				})
				.catch(err => {
					onError(err)
				})
		},
	}

	return (
		<>
			<Table
				size='small'
				columns={columns}
				dataSource={jpgFiles?.map(jpgFile => ({ ...jpgFile, key: jpgFile.id }))}
				style={{ marginBottom: '12px' }}
			/>
			<AttachToSchemeModal
				open={openAttachToSchemeModal.open}
				onCancel={onCloseAttachToSchemeModal}
			/>
			<UploadBlockButton uploadProps={uploadProps} title='Загрузить (JPG, JPEG)' />
		</>
	)
}

export default JpgFilesTable
