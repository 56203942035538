import { createAsyncThunk } from '@reduxjs/toolkit';
import { usersApi } from '../../api/apis';

export const fetchUserInfo = createAsyncThunk('general/fetchUserInfo', async (token: string, { rejectWithValue }) => {
    try {
        const response = await usersApi.apiV1UserGetMeGet({
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });

        return response.data.split(' ')[3];
    } catch (e) {
        return rejectWithValue(e.message);
    }
});

export const login = createAsyncThunk(
    'general/login',
    async (payload: { username: string; password: string }, { rejectWithValue }) => {
        const { username, password } = payload;
        try {
            const response = await usersApi.apiV1UserLoginPost({
                username,
                password,
            });

            return response.data;
        } catch (e) {
            return rejectWithValue('Неверный логин или пароль');
        }
    },
);
