import React, { FC } from 'react';
import DrawApp from '@rbiconcept/pandid/dist';

const DrawWrapper: FC = () => {

  return (
      <DrawApp applicationMode={'HAZOP_APP'} />
  );
};

export default DrawWrapper;
