import React from 'react'
import styles from './LoaderWithLogo.module.css'

const LoaderWithLogo: React.FC = () => {
  return (
    <div className={styles.loaderWrapper}>
      <div>
        <svg className={styles.imageWrapper} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.4913 0C19.6422 0 20.3639 1.19705 19.8097 2.16486L8.08127 21.6656H16.9187L13.8945 16.6375L15.2892 14.3186L19.793 21.8067C20.378 22.7793 19.6535 24 18.4913 24H6.50873C5.36427 24 4.64429 22.8164 5.18105 21.8515L15.8368 4.13493L15.836 4.1345L16.9187 2.33435H8.08127L11.1058 7.36303L9.71107 9.68194L5.207 2.19331C4.62201 1.22067 5.34648 0 6.50873 0H18.4913Z"
            fill="#215294"/>
        </svg>

      </div>
    </div>
  );
};

export default LoaderWithLogo;
