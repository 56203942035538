import { green, grey, red, yellow } from '@ant-design/colors'
import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { guideWordsApi, hazopEntriesApi, parametersApi } from '../../../api/apis'
import {
	CauseDto,
	ConsequenceDto,
	ConsequenceDtoRequest,
	ConsequenceDtoRequestRiskTypeEnum,
	HazopEntryDto,
	NodeGuidewordDto,
	NodeParameterDto,
	RecommendationDto,
	RecommendationDtoRequest,
	SafeguardDto,
	SafeguardDtoRequest,
	SafeguardDtoRequestSafeguardTypeEnum,
} from '../../../generated/backend'
import { useActions } from '../../../hooks/useActions'
import {
	addCause,
	addConsequence,
	addRecommendation,
	addSafeGuard,
	editCause,
	editConsequence,
	editRecommendation,
	editSafeGuard,
	removeCause,
	removeConsequence,
	removeRecommendations,
	removeSafeguards,
} from '../../../redux/hazopEntries/hazopEntries.actions'
import { RootState, useAppDispatch } from '../../../redux/store'
import { TableModal } from '../../UI/Modals/TableModal/TableModal'
import { isChanged } from '../../../utils/HazopTableUtils'
import { IEditHazopTable, IShowRiskTable, IValue, TypeOfHazopTableState } from '../../types'
import styles from './HazopTable.module.css'
import HazopTableSelectColumn from './HazopTableSelectColumn'
import HazopTableTextColumn from './HazopTableTextColumn'
import HazopTableDeleteModal from './Modals/HazopTableDeleteModal'
import HazopTableEditModal from './Modals/HazopTableEditModal'
import { message } from 'antd'

type TableForHazopFourProps = {
	hazopEntry: HazopEntryDto
}

const colors = {
	1: green[4],
	2: yellow[3],
	3: red[4],
	4: grey[4],
}

const TableForHazopFour: React.FC<TableForHazopFourProps> = ({ hazopEntry }) => {
	const [stateForTextColumns, setStateForTextColumns] = React.useState<TypeOfHazopTableState[]>(
		[]
	)
	const [stateForSelect, setStateForSelect] = React.useState<
		NodeGuidewordDto[] | NodeParameterDto[]
	>([])
	const [showEdit, setShowEdit] = React.useState<IEditHazopTable>({
		id: null,
		show: false,
		type: '',
		isSelect: false,
	})
	const [showTableModal, setShowTableModal] = React.useState<IShowRiskTable>({
		show: false,
		id: '',
		rowId: '',
	})
	const dispatch = useAppDispatch()
	const { editHazopEntry } = useActions()
	const { hazopType } = useSelector((state: RootState) => state.settings.mainSettings)
	const hazopEntries = useSelector((state: RootState) => state.hazopEntries.items)

	const fetchOptions = async (type: string) => {
		if (type === 'parameter') {
			const response = await parametersApi.apiV1NodeParameterGet()

			setStateForSelect(response.data as NodeParameterDto[])
			return
		}

		const response = await parametersApi.apiV1NodeParameterIdGet(hazopEntry.nodeParameter?.id)

		setStateForSelect(response.data.guidewords as NodeGuidewordDto[])
	}

	const deleteAllParameters = (hazopEntry: HazopEntryDto) => {
		try {
			hazopEntry.causes.map(cause =>
				dispatch(removeCause({ id: cause.id, hazopEntryId: hazopEntry.id }))
			)
			hazopEntry.consequences.map(consequence =>
				dispatch(removeConsequence({ id: consequence.id, hazopEntryId: hazopEntry.id }))
			)
			hazopEntry.safeguards.map(safeguard =>
				dispatch(removeSafeguards({ id: safeguard.id, hazopEntryId: hazopEntry.id }))
			)
			hazopEntry.recommendations.map(recommendation =>
				dispatch(
					removeRecommendations({ id: recommendation.id, hazopEntryId: hazopEntry.id })
				)
			)
			editHazopEntry({ field: 'nodeGuideword', value: null, id: hazopEntry.id })
			editHazopEntry({ field: 'humanMatrixValue', value: null, id: hazopEntry.id })
			editHazopEntry({ field: 'financeMatrixValue', value: null, id: hazopEntry.id })
			editHazopEntry({ field: 'ecologyMatrixValue', value: null, id: hazopEntry.id })
			editHazopEntry({ field: 'reputationMatrixValue', value: null, id: hazopEntry.id })
		} catch (error) {
			message.error('Что-то пошло не так')
		}
	}

	const setFieldValue = (field: string, value: IValue, id: string, isNodeParameter?: boolean) => {
		editHazopEntry({ field, value, id })

		if (isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id) {
			deleteAllParameters(hazopEntry)
		}

		const newItem = {
			nodeParameter: hazopEntry.nodeParameter?.id,
			nodeAdditionalParameter: hazopEntry.nodeAdditionalParameter?.id,
			nodeGuideword:
				isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id
					? null
					: hazopEntry.nodeGuideword?.id,
			humanMatrixValue:
				isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id
					? null
					: hazopEntry.humanMatrixValue?.id,
			financeMatrixValue:
				isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id
					? null
					: hazopEntry.financeMatrixValue?.id,
			ecologyMatrixValue:
				isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id
					? null
					: hazopEntry.ecologyMatrixValue?.id,
			reputationMatrixValue:
				isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id
					? null
					: hazopEntry.reputationMatrixValue?.id,
			nodeId: hazopEntry.node?.id,
			number: hazopEntry.number,
			id,
			[field]: value?.id,
		}

		hazopEntriesApi.apiV1HazopEntryPut(newItem)
	}

	const addColumnItem = async (
		entityType: string,
		hazopEntryId: string,
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => {
		let response

		switch (entityType) {
			case 'consequences':
				response = await dispatch(
					addConsequence({
						id: hazopEntryId,
						type: type as ConsequenceDtoRequestRiskTypeEnum,
					})
				)

				return response.payload as ConsequenceDto
			case 'safeguards':
				response = await dispatch(
					addSafeGuard({
						id: hazopEntryId,
						type:
							hazopType === 'EIGHT'
								? (type as SafeguardDtoRequestSafeguardTypeEnum)
								: null,
					})
				)
				return response.payload as SafeguardDto
			case 'recommendations':
				response = await dispatch(
					addRecommendation({
						id: hazopEntryId,
						type:
							hazopType === 'EIGHT'
								? (type as SafeguardDtoRequestSafeguardTypeEnum)
								: null,
					})
				)
				return response.payload as RecommendationDto
		}
	}

	const saveColumnItem = async (
		entityType: string,
		state: TypeOfHazopTableState[],
		hazopEntryId: string,
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => {
		const hazopEntry = hazopEntries.find(item => item.id === hazopEntryId)

		state.map(async (item: TypeOfHazopTableState, index: number) => {
			if (!isChanged(item, hazopEntry, entityType, index)) return
			const copyItem = { ...item }
			const filteredValue = item.name.replace(/\n{2,}/g, '\n')
			copyItem.name = filteredValue

			switch (entityType) {
				case 'causes':
					if (
						copyItem.id === hazopEntry.causes.find(item => item.id === copyItem.id)?.id
					) {
						delete (copyItem as CauseDto).hazopEntry
						dispatch(editCause({ id: hazopEntryId, cause: copyItem }))
					} else
						dispatch(
							addCause({
								id: hazopEntryId,
								name: copyItem.name,
								sort: null,
								causeProbability: null,
							})
						)
					return
				case 'consequences':
					delete (copyItem as ConsequenceDto).hazopEntry

					dispatch(
						editConsequence({
							id: hazopEntryId,
							consequence: copyItem as ConsequenceDtoRequest,
							type: type as ConsequenceDtoRequestRiskTypeEnum,
						})
					)
					return
				case 'safeguards':
					delete (copyItem as SafeguardDto).safeguardType
					delete (copyItem as SafeguardDto).hazopEntry

					dispatch(
						editSafeGuard({
							id: hazopEntryId,
							safeGuard: copyItem as SafeguardDtoRequest,
							type: null,
						})
					)
					return
				case 'recommendations':
					delete (copyItem as RecommendationDto).safeguardType
					delete (copyItem as RecommendationDto).criticality
					delete (copyItem as RecommendationDto).hazopEntry

					dispatch(
						editRecommendation({
							id: hazopEntryId,
							recommendation: copyItem as RecommendationDtoRequest,
							type: null,
							criticality: (item as RecommendationDto).criticality?.id,
						})
					)
					return
			}
		})
	}

	const deleteItem = (typeOfEntity: string, id: string) => {
		switch (typeOfEntity) {
			case 'causes':
				if (!hazopEntry.causes?.find(item => item.id === id)) {
					setStateForTextColumns(stateForTextColumns.filter(item => item.id !== id))
					return
				}
				dispatch(removeCause({ id, hazopEntryId: hazopEntry.id })).then(() =>
					setStateForTextColumns(stateForTextColumns.filter(item => item.id !== id))
				)
				break
			case 'consequences':
				dispatch(removeConsequence({ id, hazopEntryId: hazopEntry.id })).then(() =>
					setStateForTextColumns(stateForTextColumns.filter(item => item.id !== id))
				)
				break
			case 'safeguards':
				dispatch(removeSafeguards({ id, hazopEntryId: hazopEntry.id })).then(() =>
					setStateForTextColumns(stateForTextColumns.filter(item => item.id !== id))
				)
				break
			case 'recommendations':
				dispatch(removeRecommendations({ id, hazopEntryId: hazopEntry.id })).then(() =>
					setStateForTextColumns(stateForTextColumns.filter(item => item.id !== id))
				)
				break
		}
	}

	const onDoubleClick = (
		e: React.MouseEvent<HTMLElement>,
		id: string,
		type: string,
		riskType?: string,
		isRecommendation?: boolean,
		isSelect?: boolean
	) => {
		e.stopPropagation()
		setShowEdit({
			id,
			show: true,
			type,
			riskType: riskType || '',
			isRecommendation: isRecommendation || false,
			isSelect: isSelect || false,
		})
	}

	const onCancelTableModal = () => {
		setShowTableModal({
			show: false,
			id: '',
			rowId: null,
		})
	}

	const risk = [
		{
			title: 'Люди',
			id: 'HUMAN',
			MatrixValue: { ...hazopEntry.humanMatrixValue, type: 'humanMatrixValue' },
		},
		{
			title: 'Финансы',
			id: 'FINANCE',
			MatrixValue: { ...hazopEntry.financeMatrixValue, type: 'financeMatrixValue' },
		},
		{
			title: 'Экология',
			id: 'ECOLOGY',
			MatrixValue: { ...hazopEntry.ecologyMatrixValue, type: 'ecologyMatrixValue' },
		},
		{
			title: 'Репутация',
			id: 'REPUTATION',
			MatrixValue: { ...hazopEntry.reputationMatrixValue, type: 'reputationMatrixValue' },
		},
	]

	return (
		<>
			<HazopTableEditModal
				hazopEntry={hazopEntry}
				deleteItem={deleteItem}
				showEdit={showEdit}
				show={showEdit.isSelect ? false : showEdit.show}
				setShowEdit={setShowEdit}
				state={stateForTextColumns}
				setState={setStateForTextColumns}
				type={showEdit.type}
				addColumnItem={addColumnItem}
				saveColumnItem={saveColumnItem}
				isRecommendation={showEdit.isRecommendation}
			/>
			<TableModal
				setFieldValue={setFieldValue}
				showModal={showTableModal}
				onCancel={onCancelTableModal}
			/>

			<div className={styles.cell}>{hazopEntry.number}</div>
			<div
				className={styles.cell}
				onDoubleClick={e =>
					onDoubleClick(e, hazopEntry.id, 'nodeParameter', null, false, true)
				}
			>
				<HazopTableSelectColumn
					hazopEntry={hazopEntry}
					showSelect={showEdit}
					setShowSelect={setShowEdit}
					setFieldValue={setFieldValue}
					fetchOptions={() => fetchOptions('parameter')}
					options={stateForSelect}
					setOptions={setStateForSelect}
					type='nodeParameter'
				/>
			</div>
			<div
				className={styles.cell}
				onDoubleClick={e =>
					hazopEntry?.nodeParameter
						? onDoubleClick(e, hazopEntry.id, 'nodeGuideword', null, false, true)
						: message.warning('Выберите сначала слово параметр')
				}
			>
				<HazopTableSelectColumn
					hazopEntry={hazopEntry}
					showSelect={showEdit}
					setShowSelect={setShowEdit}
					setFieldValue={setFieldValue}
					fetchOptions={() => fetchOptions('nodeGuideword')}
					options={stateForSelect}
					setOptions={setStateForSelect}
					type='nodeGuideword'
				/>
			</div>
			<ol
				onDoubleClick={e => {
					onDoubleClick(e, hazopEntry.id, 'causes')
					setStateForTextColumns(hazopEntry.causes)
				}}
				className={classNames(styles.cell, styles.list)}
				start={1}
			>
				<HazopTableTextColumn state={hazopEntry?.causes} />
			</ol>
			<div className={classNames(styles.cellSpan)}>
				{risk.map((itemRisk, indexRisk) => {
					return (
						<React.Fragment key={indexRisk}>
							<ol
								onDoubleClick={e => {
									onDoubleClick(e, hazopEntry.id, 'consequences', itemRisk.id)
									setStateForTextColumns(
										hazopEntry?.consequences?.filter(
											item => item.riskType.id === itemRisk.id
										)
									)
								}}
								className={classNames(styles.cell, styles.list)}
								start={1}
							>
								<HazopTableTextColumn
									state={hazopEntry?.consequences}
									haveFilter={true}
									type={itemRisk.id}
									filterOption='riskType'
								/>
							</ol>
							<div className={classNames(styles.cell, styles.centeredCell)}>
								{itemRisk.title}
							</div>
							<div
								onDoubleClick={e => {
									e.preventDefault()

									setShowTableModal({
										show: true,
										id: itemRisk.MatrixValue.type,
										rowId: hazopEntry.id,
									})
								}}
								style={{
									background: colors[itemRisk.MatrixValue?.dangerValue?.id],
								}}
								className={classNames(styles.cell, styles.centeredCell)}
							>
								{itemRisk.MatrixValue.title}
							</div>
						</React.Fragment>
					)
				})}
			</div>
			<ol
				onDoubleClick={e => {
					onDoubleClick(e, hazopEntry.id, 'safeguards')
					setStateForTextColumns(hazopEntry.safeguards)
				}}
				className={classNames(styles.cell, styles.list)}
				start={1}
			>
				<HazopTableTextColumn state={hazopEntry?.safeguards} />
			</ol>
			<div
				className={styles.recommendationCellSpan}
				onDoubleClick={e => {
					onDoubleClick(e, hazopEntry.id, 'recommendations', undefined, true)
					setStateForTextColumns(hazopEntry.recommendations)
				}}
			>
				{hazopEntry?.recommendations?.length === 0 ? (
					<>
						<div className={styles.cell}></div>
						<div className={styles.cell}></div>
					</>
				) : (
					hazopEntry?.recommendations?.map((item, index) => {
						return (
							<React.Fragment key={index}>
								<div className={styles.cell}>
									<span
										style={{
											whiteSpace: item?.name?.includes('\n')
												? 'pre-wrap'
												: null,
										}}
									>
										{item?.name}
									</span>
								</div>
								<div className={classNames(styles.cell, styles.centeredCell)}>
									{item.criticality?.name}
								</div>
							</React.Fragment>
						)
					})
				)}
			</div>
			<div className={classNames(styles.cell, styles.centeredCell)}>
				<HazopTableDeleteModal hazopEntryId={hazopEntry.id} />
			</div>
		</>
	)
}

export default TableForHazopFour
