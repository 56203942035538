import {
	CopyOutlined,
	DeleteOutlined,
	ExpandAltOutlined,
	PlusOutlined,
	ShrinkOutlined,
} from '@ant-design/icons'
import { Button, Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import classNames from 'classnames'
import React from 'react'
import {
	ConsequenceDtoRequestRiskTypeEnum,
	SafeguardDto,
	SafeguardDtoRequestSafeguardTypeEnum,
	SafeguardTypeDtoIdEnum,
} from '../../../../generated/backend'
import { useModalActions } from '../../../../hooks/useModalActions'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import DebounceTextArea from '../../../UI/Inputs/DebounceTextArea/DebounceTextArea'
import { Loader } from '../../../UI/Loaders/Loader/Loader'
import CopyListAttributesModal from '../../../UI/Modals/CopyListAttributesModal/CopyListAttributesModal'
import { TypeOfHazopTableState } from '../../../types'
import styles from '../NewHazopForm.module.css'

const safeguards = [
	{ title: 'ПР.', id: SafeguardTypeDtoIdEnum.Design, lopaId: 'design' },
	{ title: 'СУ', id: SafeguardTypeDtoIdEnum.OperationalSystem, lopaId: 'controlSystem' },
	{ title: 'СНГ', id: SafeguardTypeDtoIdEnum.Alarm, lopaId: 'independentSignaling' },
	{ title: 'МЗ', id: SafeguardTypeDtoIdEnum.Mz, lopaId: 'reliefValves' },
	{ title: 'ПАЗ', id: SafeguardTypeDtoIdEnum.Paz, lopaId: 'spaz' },
	{ title: 'ДО', id: SafeguardTypeDtoIdEnum.OperatorAction, lopaId: 'operatorActions' },
	{ title: 'ДР1', id: SafeguardTypeDtoIdEnum.Other1, lopaId: 'other1' },
	{ title: 'ДР2', id: SafeguardTypeDtoIdEnum.Other2, lopaId: 'other2' },
]

type SafeguardsFormProps = {
	hazopType: string
	placeholder: string
	filterEntity: (
		entityType: 'causes' | 'consequences' | 'safeguards' | 'recommendations',
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => TypeOfHazopTableState[]
	saveItem: (
		entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
		item: TypeOfHazopTableState,
		hazopEntryId: string,
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => Promise<void>
	addItem: (
		entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
		riskType?: ConsequenceDtoRequestRiskTypeEnum,
		safeguardType?: SafeguardDtoRequestSafeguardTypeEnum
	) => void
	deleteItem: (typeOfEntity: string, id: string) => void
	mainForm: string
	onChangeView: (selectedForm: string, formType: string) => void
}

const SafeguardsForm: React.FC<SafeguardsFormProps> = ({
	hazopType,
	deleteItem,
	addItem,
	saveItem,
	filterEntity,
	placeholder,
	mainForm,
	onChangeView,
}) => {
	const { preLopaCalculation } = useTypedSelector(state => state.settings.mainSettings)
	const [showCopyModal, onOpenModal, onCloseModal] = useModalActions()

	const onCheckBoxValueChange = async (e: CheckboxChangeEvent, item: SafeguardDto) => {
		await saveItem(
			'safeguards',
			{ ...item, enable: e.target.checked },
			item.hazopEntryId,
			item.safeguardType?.id
		)
	}

	return (
		<>
			{!hazopType ? (
				<Loader />
			) : (
				<>
					<CopyListAttributesModal
						show={showCopyModal}
						onCancel={onCloseModal}
						typeOfEntity={'safeguards'}
					/>
					<div className={classNames(styles.title)}>
						Меры
						<div className={styles.actionButtons}>
							<Button
								type={'text'}
								icon={<CopyOutlined rev={undefined} />}
								onClick={onOpenModal}
							/>
							<Button
								type={'text'}
								icon={
									mainForm ? (
										<ShrinkOutlined rev={undefined} style={{ fontSize: 20 }} />
									) : (
										<ExpandAltOutlined rev={undefined} />
									)
								}
								onClick={() =>
									onChangeView(
										mainForm === 'safeguards' ? '' : 'safeguards',
										'safeguards'
									)
								}
							/>
						</div>
					</div>
					<div
						className={classNames(
							hazopType === 'EIGHT' && preLopaCalculation
								? styles.safeguardTable
								: hazopType === 'EIGHT' && !preLopaCalculation
								? styles.safeguardWithoutLopaTable
								: styles.table_1col
						)}
					>
						{hazopType === 'EIGHT' ? (
							<div className={classNames(styles.cellHead)}>Тип</div>
						) : null}
						<div className={classNames(styles.cellHead)}>
							Существующие меры безопасности
						</div>
						{hazopType === 'EIGHT' && preLopaCalculation ? (
							<div className={classNames(styles.cellHead)}>isEnabled</div>
						) : null}
					</div>
					{hazopType === 'EIGHT'
						? safeguards.map((safeguard, index) => (
								<div
									key={safeguard.id ?? index}
									className={classNames(styles.safeguardCells)}
								>
									<div className={classNames(styles.cell, styles.cellPad)}>
										{safeguard.title}
									</div>
									<div>
										{filterEntity('safeguards', safeguard.id).map(
											(item: SafeguardDto, idx) => (
												<div
													className={
														preLopaCalculation
															? styles.safeguardWithoutLopaCells
															: null
													}
													key={item.id ?? idx}
												>
													<div
														className={classNames(
															styles.cell,
															styles.cellPad
														)}
													>
														<div className={styles.cellWithNumber}>
															{item.number ?? idx + 1}.
														</div>
														<DebounceTextArea
															placeholder={placeholder}
															entityType={'safeguards'}
															variant={'borderless'}
															initialEntity={item}
															saveEntity={saveItem}
															type={safeguard.id}
															delay={500}
															onCreate={addItem}
															autoSize={{ minRows: mainForm ? 2 : 1 }}
														/>
														{item.name ? (
															<div className={styles.button}>
																<Button
																	size={'small'}
																	type={'text'}
																	icon={
																		<PlusOutlined
																			rev={undefined}
																			style={{
																				fontSize: mainForm
																					? '20px'
																					: null,
																			}}
																		/>
																	}
																	onClick={() =>
																		addItem(
																			'safeguards',
																			undefined,
																			safeguard.id
																		)
																	}
																/>
																<Button
																	size={'small'}
																	type={'text'}
																	icon={
																		<DeleteOutlined
																			rev={undefined}
																			style={{
																				fontSize: mainForm
																					? '20px'
																					: null,
																			}}
																		/>
																	}
																	onClick={() =>
																		deleteItem(
																			'safeguards',
																			item.id
																		)
																	}
																/>
															</div>
														) : null}
													</div>
													{preLopaCalculation ? (
														<div
															key={item.id ?? index}
															className={classNames(
																styles.cell,
																styles.cellPad
															)}
														>
															<Checkbox
																disabled={!item.name}
																defaultChecked={item.enable}
																onChange={e =>
																	onCheckBoxValueChange(e, item)
																}
															/>
														</div>
													) : null}
												</div>
											)
										)}
									</div>
								</div>
						  ))
						: filterEntity('safeguards').map((safeguard, index) => (
								<div
									key={safeguard.id ?? index}
									className={classNames(styles.cellList1Col)}
								>
									<div className={classNames(styles.cell, styles.cellPad)}>
										<div className={styles.cellWithNumber}>
											{safeguard.number ?? index + 1}.
										</div>
										<DebounceTextArea
											placeholder={placeholder}
											entityType={'safeguards'}
											variant={'borderless'}
											initialEntity={safeguard}
											saveEntity={saveItem}
											delay={500}
											onCreate={addItem}
											autoSize={{ minRows: mainForm ? 2 : 1 }}
											id={`safeguard-${index}`}
										/>
										{safeguard.name ? (
											<div className={styles.button}>
												<Button
													size={'small'}
													type={'text'}
													icon={
														<PlusOutlined
															rev={undefined}
															style={{
																fontSize: mainForm ? '20px' : null,
															}}
														/>
													}
													onClick={() => addItem('safeguards')}
													name={`safeguard-add-${index}`}
												/>
												<Button
													size={'small'}
													type={'text'}
													icon={
														<DeleteOutlined
															rev={undefined}
															style={{
																fontSize: mainForm ? '20px' : null,
															}}
														/>
													}
													onClick={() =>
														deleteItem('safeguards', safeguard.id)
													}
												/>
											</div>
										) : null}
									</div>
								</div>
						  ))}
				</>
			)}
		</>
	)
}

export default SafeguardsForm
