import { createAsyncThunk } from '@reduxjs/toolkit';
import { AssetSystemDtoRequest } from '../../generated/backend';
import { systemsApi } from '../../api/apis';

export const fetchSystems = createAsyncThunk('systems/fetch-systems', async (_, { rejectWithValue }) => {
    try {
        const response = await systemsApi.apiV1AssetSystemGet();

        return response.data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const fetchSystemById = createAsyncThunk('systems/system-by', async (id: string, { rejectWithValue }) => {
    try {
        const response = await systemsApi.apiV1AssetSystemIdGet(id);

        return response.data;
    } catch (e) {
        return rejectWithValue(e.message);
    }
});

export const deleteSystem = createAsyncThunk('systems/delete-system', async (id: string, { rejectWithValue }) => {
    try {
        await systemsApi.apiV1AssetSystemDelete(id);

        return id;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const editSystem = createAsyncThunk(
    'systems/edit-system',
    async (system: AssetSystemDtoRequest, { rejectWithValue }) => {
        try {
            const response = await systemsApi.apiV1AssetSystemPut(system);

            return response.data;
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);

export const addSystem = createAsyncThunk(
    'systems/add-system',
    async (system: AssetSystemDtoRequest, { rejectWithValue }) => {
        try {
            const response = await systemsApi.apiV1AssetSystemPost(system);

            return response.data;
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);
