import { DeleteOutlined } from '@ant-design/icons'
import { Button, Modal, Space, Typography, message } from 'antd'
import React from 'react'
import { useAppDispatch } from '../../../../redux/store'
import { removeRow } from '../../../../redux/hazopEntries/hazopEntries.actions'

type HazopTableDeleteModalProps = {
	hazopEntryId: string
}

const HazopTableDeleteModal: React.FC<HazopTableDeleteModalProps> = ({
	hazopEntryId,
}) => {
	const [loading, setLoading] = React.useState(false)
	const [showDeleteModal, setShowDeleteModal] = React.useState(false)
	const dispatch = useAppDispatch()

	const onCancel = () => {
		setShowDeleteModal(false)
	}

	const onOpen = () => {
		setShowDeleteModal(true)
	}

	return (
		<>
			<Button
				name='delete'
				type='text'
				icon={<DeleteOutlined rev={undefined} />}
				onClick={onOpen}
			/>
			<Modal
				open={showDeleteModal}
				centered
				footer={false}
				width={400}
				onCancel={onCancel}
				destroyOnClose
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
					}}
				>
					<Typography.Title level={3}>
						Вы уверены что хотите удалить строку?
					</Typography.Title>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
					}}
				>
					<Space>
						<Button
							danger
							name='submit'
							type='primary'
							size='large'
							loading={loading}
							onClick={() => {
								setLoading(true)
								dispatch(removeRow(hazopEntryId))
									.then(() => {
										setLoading(false)
										onCancel()
										message.success('Успешно удалено')
									})
									.catch(() => {
										setLoading(false)
										onCancel()
										message.error('Ошибка удаления')
									})
							}}
						>
							Удалить
						</Button>
						<Button size='large' onClick={onCancel}>
							Отмена
						</Button>
					</Space>
				</div>
			</Modal>
		</>
	)
}

export default HazopTableDeleteModal
