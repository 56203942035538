import { Button, Form, Input, Skeleton, Space, message } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { AssetSystemDto, AssetSystemDtoRequest } from '../../generated/backend'
import { RootState, useAppDispatch } from '../../redux/store'
import { editSystem, fetchSystemById } from '../../redux/systems/systems.actions'

type SystemFormProps = {}
export const SystemForm: React.FC<SystemFormProps> = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const [form] = Form.useForm()
	const { isLoading } = useSelector((state: RootState) => state.systems.selected)
	const dispatch = useAppDispatch()

	React.useEffect(() => {
		dispatch(fetchSystemById(id)).then(res => {
			const system = res.payload as AssetSystemDto

			form.setFieldsValue({
				code: system.code || '',
				name: system.name || '',
				id: system.id || '',
			})
		})
	}, [id])

	const onSubmit = async (data: AssetSystemDtoRequest) => {
		form.validateFields().then(() =>
			dispatch(editSystem(data)).then(() => message.success(<>Сохранено!</>))
		)
	}

	const onClose = () => {
		navigate('/dictionaries/systems')
		form.resetFields()
	}

	if (isLoading) {
		return (
			<>
				<Space style={{ marginBottom: '12px' }}>
					<Skeleton.Button active />
					<Skeleton.Button active />
				</Space>
				<Skeleton title={false} active paragraph={{ rows: 4 }} />
			</>
		)
	}

	return (
		<>
			<Form
				form={form}
				onFinish={onSubmit}
				labelCol={{ span: 3, offset: 0 }}
				labelAlign={'left'}
			>
				<Form.Item name={'id'}>
					<Space>
						<Button name={'save'} htmlType='submit' type={'primary'}>
							Сохранить
						</Button>
						<Button htmlType='button' onClick={onClose}>
							Закрыть
						</Button>
					</Space>
				</Form.Item>
				<Form.Item name={'code'} label={'Код'}>
					<Input id={'code'} />
				</Form.Item>
				<Form.Item
					rules={[{ required: true, message: 'Название не может быть пустым!' }]}
					name={'name'}
					label={'Название'}
				>
					<Input id={'name'} />
				</Form.Item>
			</Form>
		</>
	)
}
