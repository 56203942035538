import { Button, Modal, Select, Space } from 'antd';
import React from 'react';
import { SchemeDto } from '../../../../generated/backend';
import styles from './AttachToSchemeModal.module.css';
import { schemesApi } from '../../../../api/apis';

type AttachToSchemeModalProps = {
    open: boolean;
    onCancel: () => void;
};

const AttachToSchemeModal: React.FC<AttachToSchemeModalProps> = ({ open, onCancel }) => {
    const [schemes, setSchemes] = React.useState<SchemeDto[]>(null);

    const fetchSchemes = async () => {
        const response = await schemesApi.apiV1SchemeGet();
        setSchemes(response.data);
    };

    return (
        <Modal title={'Прикрепить к схеме'} footer={false} centered open={open} onCancel={onCancel} destroyOnClose>
            <div className={styles.container}>
                <Select
                    placeholder='Выберите схему'
                    options={schemes?.map(scheme => ({ label: scheme.name, value: scheme.id }))}
                    onFocus={fetchSchemes}
                />
                <Space>
                    <Button type='primary'>Сохранить</Button>
                    <Button onClick={onCancel}>Закрыть</Button>
                </Space>
            </div>
        </Modal>
    );
};

export default AttachToSchemeModal;
