import { Button, Space, message } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { addEmptyRow, fetchHazopEntries } from '../../../redux/hazopEntries/hazopEntries.actions'
import { RootState, useAppDispatch } from '../../../redux/store'
import { Loader } from '../../UI/Loaders/Loader/Loader'
import styles from './HazopTable.module.css'
import TableForHazopEight from './TableForHazopEight'
import TableForHazopFour from './TableForHazopFour'
import { HazopEntryDto } from '../../../generated/backend'

type HazopTableProps = {}

const HazopTable: React.FC<HazopTableProps> = () => {
	const { id } = useParams()
	const navigate = useNavigate()

	const { hazopType } = useSelector((state: RootState) => state.settings.mainSettings)
	const hazopEntries = useSelector((state: RootState) => state.hazopEntries)
	const dispatch = useAppDispatch()

	React.useEffect(() => {
		dispatch(fetchHazopEntries(id))
	}, [id])

	const onAddEmptyRow = async () => {
		dispatch(addEmptyRow(id)).then(res => {
			message.success(
				`Строка под номером ${(res.payload as HazopEntryDto).number} добавлена!`
			)
		})
	}

	const onClosePage = () => {
		navigate('/hazop')
	}

	if (hazopEntries.loading) return <Loader />

	return (
		<>
			<Space style={{ marginBottom: 12 }}>
				<Button type={'primary'} onClick={onAddEmptyRow}>
					Добавить строку
				</Button>
				<Button onClick={onClosePage}>Назад</Button>
			</Space>
			<div className={styles.wrap}>
				<div className={hazopType === 'FOUR' ? styles.tableFour : styles.tableEight}>
					<div
						className={classNames(
							styles.cell,
							styles.columnHeading,
							styles.stickyHeading
						)}
					>
						№
					</div>
					<div
						className={classNames(
							styles.cell,
							styles.columnHeading,
							styles.stickyHeading
						)}
					>
						Слово параметр
					</div>
					<div
						className={classNames(
							styles.cell,
							styles.columnHeading,
							styles.stickyHeading
						)}
					>
						Упр. слово
					</div>
					<div
						className={classNames(
							styles.cell,
							styles.columnHeading,
							styles.stickyHeading
						)}
					>
						Причины
					</div>

					{hazopType === 'FOUR' ? (
						<>
							<div
								className={classNames(styles.cellSpanHeading, styles.stickyHeading)}
							>
								<div className={classNames(styles.cell, styles.columnHeading)}>
									Последствия
								</div>
								<div className={classNames(styles.cell, styles.columnHeading)}>
									Категории ущерба
								</div>
								<div className={classNames(styles.cell, styles.columnHeading)}>
									Категории риска
								</div>
							</div>
							<div
								className={classNames(
									styles.cell,
									styles.columnHeading,
									styles.stickyHeading
								)}
							>
								Существующие меры безопасности
							</div>
							<div
								className={classNames(
									styles.recommendationCellSpan,
									styles.stickyHeading
								)}
							>
								<div className={classNames(styles.cell, styles.columnHeading)}>
									Рекомендации
								</div>
								<div className={classNames(styles.cell, styles.columnHeading)}>
									Крит
								</div>
							</div>
						</>
					) : (
						<div className={classNames(styles.cellSpanHeading7, styles.stickyHeading)}>
							<div className={classNames(styles.cell, styles.columnHeading)}>
								Последствия
							</div>
							<div className={classNames(styles.cell, styles.columnHeading)}>
								Категории ущерба
							</div>
							<div className={classNames(styles.cell, styles.columnHeading)}>
								Категории риска
							</div>
							<div className={styles.cellSpanHeading4}>
								<div className={classNames(styles.cell, styles.columnHeading)}>
									Тип
								</div>
								<div className={classNames(styles.cell, styles.columnHeading)}>
									Существующие меры безопасности
								</div>
								<div className={classNames(styles.cell, styles.columnHeading)}>
									Рекомендации
								</div>
								<div className={classNames(styles.cell, styles.columnHeading)}>
									Крит
								</div>
							</div>
						</div>
					)}
					<div
						className={classNames(
							styles.cell,
							styles.columnHeading,
							styles.stickyHeading
						)}
					>
						Действия
					</div>
					{hazopEntries?.items?.map((hazopEntry, index) => {
						return hazopType === 'FOUR' ? (
							<React.Fragment key={hazopEntry.id}>
								<TableForHazopFour hazopEntry={hazopEntry} />
							</React.Fragment>
						) : (
							<React.Fragment key={hazopEntry.id}>
								<TableForHazopEight hazopEntry={hazopEntry} />
							</React.Fragment>
						)
					})}
				</div>
			</div>
		</>
	)
}

export default HazopTable
