import React from 'react'
import classNames from 'classnames'
import styles from '../NewHazopForm.module.css'
import DebounceTextArea from '../../../UI/Inputs/DebounceTextArea/DebounceTextArea'
import ChooseProbabilityModal from '../../../UI/Modals/ChooseProbabilityModal/ChooseProbabilityModal'
import { Button } from 'antd'
import {
	CopyOutlined,
	DeleteOutlined,
	ExpandAltOutlined,
	PlusOutlined,
	ShrinkOutlined,
} from '@ant-design/icons'
import {
	ConsequenceDtoRequestRiskTypeEnum,
	SafeguardDtoRequestSafeguardTypeEnum,
} from '../../../../generated/backend'
import { TypeOfHazopTableState } from '../../../types'
import CopyListAttributesModal from '../../../UI/Modals/CopyListAttributesModal/CopyListAttributesModal'
import { useModalActions } from '../../../../hooks/useModalActions'

type CausesFormProps = {
	placeholder: string
	filterEntity: (
		entityType: 'causes' | 'consequences' | 'safeguards' | 'recommendations',
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => TypeOfHazopTableState[]
	saveItem: (
		entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
		item: TypeOfHazopTableState,
		hazopEntryId: string,
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => Promise<void>
	addItem: (
		entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
		riskType?: ConsequenceDtoRequestRiskTypeEnum,
		safeguardType?: SafeguardDtoRequestSafeguardTypeEnum
	) => void
	deleteItem: (typeOfEntity: string, id: string) => void
	mainForm: string
	onChangeView: (selectedForm: string, formType: string) => void
}
const CausesForm: React.FC<CausesFormProps> = ({
	placeholder,
	filterEntity,
	saveItem,
	addItem,
	deleteItem,
	mainForm,
	onChangeView,
}) => {
	const [showCopyModal, onOpenModal, onCloseModal] = useModalActions()

	return (
		<>
			<CopyListAttributesModal
				show={showCopyModal}
				onCancel={onCloseModal}
				typeOfEntity={'causes'}
			/>
			<div className={classNames(styles.title)}>
				Причины
				<div className={classNames(styles.actionButtons)}>
					<Button
						type={'text'}
						icon={<CopyOutlined rev={undefined} />}
						onClick={onOpenModal}
					/>
					<Button
						type={'text'}
						icon={
							mainForm ? (
								<ShrinkOutlined rev={undefined} style={{ fontSize: 20 }} />
							) : (
								<ExpandAltOutlined rev={undefined} />
							)
						}
						onClick={() =>
							onChangeView(mainForm === 'causes' ? '' : 'causes', 'causes')
						}
					/>
				</div>
			</div>
			<div className={classNames(styles.table)}>
				<div className={classNames(styles.cellHead)}>Причины</div>
				<div className={classNames(styles.cellHead)}>Вероятность</div>
				{filterEntity('causes').map((cause, index) => (
					<React.Fragment key={cause.id ?? index}>
						<div className={classNames(styles.cell, styles.cellPad)}>
							<div className={styles.cellWithNumber}>
								{cause.number ?? index + 1}.
							</div>
							<DebounceTextArea
								entityType={'causes'}
								placeholder={placeholder}
								variant={'borderless'}
								initialEntity={cause}
								saveEntity={saveItem}
								delay={500}
								onCreate={addItem}
								autoSize={{ minRows: mainForm ? 2 : 1 }}
								id={`cause-${index}`}
							/>
						</div>
						<div className={classNames(styles.cell, styles.cellWrap)}>
							<ChooseProbabilityModal
								saveItem={saveItem}
								item={cause}
								typeOfEntity={'causes'}
							/>
							{cause.name ? (
								<div className={styles.button}>
									<Button
										size={'small'}
										type={'text'}
										icon={
											<PlusOutlined
												rev={undefined}
												style={{ fontSize: mainForm ? '20px' : null }}
											/>
										}
										onClick={() => addItem('causes')}
										name={`cause-add-${index}`}
									/>
									<Button
										size={'small'}
										type={'text'}
										icon={
											<DeleteOutlined
												rev={undefined}
												style={{ fontSize: mainForm ? '20px' : null }}
											/>
										}
										onClick={() => deleteItem('causes', cause.id)}
									/>
								</div>
							) : null}
						</div>
					</React.Fragment>
				))}
			</div>
		</>
	)
}

export default CausesForm
