import { message } from 'antd'
import React from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	addCauseProbability,
	deleteCauseProbability,
	fetchCauseProbabilities,
} from '../../redux/causeProbabilities/causeProbabilities.actions'
import { useAppDispatch } from '../../redux/store'
import ContentLayout from '../UI/ContentLayout/ContentLayout'
import { CauseProbabilityDto } from '../../generated/backend'

const layoutKey = 'probabilities'

const ProbabilitiesLayout: React.FC = () => {
	const dispatch = useAppDispatch()
	const { probabilities, isLoading } = useTypedSelector(state => state.causeProbabilities)

	const [messageApi, contextHolder] = message.useMessage()

	React.useEffect(() => {
		const fetchProbabilitiesData = async () => {
			const response = await dispatch(fetchCauseProbabilities())
			if (response.meta.requestStatus === 'rejected') {
				messageApi.error('Не удалось загрузить вероятности!')
			}
		}

		fetchProbabilitiesData()
	}, [])

	const onSave = async (probability: CauseProbabilityDto) => {
		const response = await dispatch(addCauseProbability(probability))
		if (response.meta.requestStatus === 'fulfilled') {
			messageApi.success('Успешно добавлено!')
		} else {
			messageApi.error('Что-то пошло не так')
		}
	}

	const onDeleteProbability = async (probability: CauseProbabilityDto) => {
		const response = await dispatch(deleteCauseProbability(probability.id))
		if (response.meta.requestStatus === 'fulfilled') {
			messageApi.success('Успешно удалено!')
		} else {
			messageApi.error('Что-то пошло не так')
		}
	}

	return (
		<>
			{contextHolder}
			<ContentLayout
				onSave={onSave}
				onDeleteEntity={onDeleteProbability}
				entity={probabilities}
				path={'/dictionaries/probabilities'}
				loading={isLoading}
				layoutKey={layoutKey}
			/>
		</>
	)
}

export default ProbabilitiesLayout
