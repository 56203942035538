import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { fetchSettings, saveSettings } from './settings.actions'
import { BackupDataDto, LopaSettingsDto } from '../../generated/backend'

export interface SettingsState {
	mainSettings: {
		hazopType: string
		projectName: string
		projectStep: string
		preLopaCalculation: boolean
		equipmentEnabled: boolean
	}
	lopaSettings: LopaSettingsDto
	lopaEnable: boolean
	backupSettings: BackupDataDto
	isLoading: boolean
}

const initialState: SettingsState = {
	mainSettings: {
		hazopType: null,
		projectName: null,
		projectStep: null,
		preLopaCalculation: false,
		equipmentEnabled: false,
	},
	lopaSettings: {},
	lopaEnable: false,
	backupSettings: {},
	isLoading: true,
}

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		changeProjectValues: (state, action: PayloadAction<{ field: string; value: string }>) => {
			state.mainSettings[action.payload.field] = action.payload.value
		},
		changeProjectBoolValues: (state, action: PayloadAction<string[]>) => {
			state.mainSettings.equipmentEnabled = action.payload.includes('equipmentEnabled')
			state.mainSettings.preLopaCalculation = action.payload.includes('preLopaCalculation')
			state.lopaEnable = action.payload.includes('lopaEnable')
		},
		changeLopaSettings: (state, action: PayloadAction<{ key: string; value: number }>) => {
			state.lopaSettings[action.payload.key] = action.payload.value
		},
		changeBackupSettings: (state, action: PayloadAction<{ key: string; value: unknown }>) => {
			state.backupSettings = {
				...state.backupSettings,
				[action.payload.key]: action.payload.value,
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchSettings.pending, state => {
			state.isLoading = true
		})
		builder.addCase(fetchSettings.fulfilled, (state, action) => {
			state.mainSettings = action.payload.mainSettings
			state.lopaSettings = action.payload.lopaSettings
			state.lopaEnable = action.payload.lopaEnable
			state.backupSettings = action.payload.backupSettings
			state.isLoading = false
		})
		builder.addCase(fetchSettings.rejected, state => {
			state.isLoading = false
		})

		builder.addCase(saveSettings.fulfilled, (state, action) => {
			state.mainSettings.hazopType = action.payload.hazopType
			state.mainSettings.projectName = action.payload.projectName
			state.mainSettings.projectStep = action.payload.projectStep
			state.lopaSettings = action.payload.lopaSettings
			state.lopaEnable = action.payload.lopaEnable
			state.backupSettings = action.payload.backupSettings
		})
	},
})

// export const {} = settingsSlice.actions

export default settingsSlice.reducer
