import { createSlice } from '@reduxjs/toolkit'
import { CauseProbabilityDto } from '../../generated/backend'
import {
	addCauseProbability,
	deleteCauseProbability,
	editCauseProbability,
	fetchCauseProbabilities,
} from './causeProbabilities.actions'

interface ICauseProbabilitiesState {
	probabilities: CauseProbabilityDto[]
	isLoading: boolean
	selected: {
		probability: CauseProbabilityDto
		isLoading: boolean
	}
}

const initialState: ICauseProbabilitiesState = {
	probabilities: [],
	isLoading: true,
	selected: {
		probability: null,
		isLoading: true,
	},
}

const causeProbabilitiesSlice = createSlice({
	name: 'causeProbabilities',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchCauseProbabilities.pending, state => {
			state.isLoading = true
		})
		builder.addCase(fetchCauseProbabilities.fulfilled, (state, action) => {
			state.isLoading = false
			state.probabilities = action.payload
		})
		builder.addCase(fetchCauseProbabilities.rejected, state => {
			state.isLoading = false
		})

		builder.addCase(addCauseProbability.fulfilled, (state, action) => {
			state.probabilities.push(action.payload)
		})

		builder.addCase(editCauseProbability.fulfilled, (state, action) => {
			state.probabilities = state.probabilities.map((item: any) => {
				if (item.id === action.payload.id) {
					return action.payload
				}
				return item
			})
			state.selected.probability = action.payload
		})

		builder.addCase(deleteCauseProbability.fulfilled, (state, action) => {
			state.probabilities = state.probabilities.filter(item => item.id !== action.payload)
		})
	},
})

export const {} = causeProbabilitiesSlice.actions

export default causeProbabilitiesSlice.reducer
