import { createSlice } from '@reduxjs/toolkit'
import {
	addParameter,
	deleteParameter,
	editParameter,
	fetchParameterById,
	fetchParameters,
} from './parameters.actions'
import { NodeParameterDto } from '../../generated/backend'

interface ParametersState {
	parameters: NodeParameterDto[]
	isLoading: boolean
	selected: { parameter: NodeParameterDto; isLoading: boolean }
}

const initialState: ParametersState = {
	parameters: [],
	isLoading: true,
	selected: {
		parameter: {},
		isLoading: true,
	},
}

export const parametersSlice = createSlice({
	name: 'parameters',
	initialState,
	reducers: {
		addGuideWord: (state, action) => {
			state.selected.parameter.guidewords.push(action.payload)
		},
		removeGuideWord: (state, action) => {
			state.selected.parameter.guidewords = state.selected.parameter.guidewords.filter(
				item => item.id !== action.payload
			)
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchParameters.pending, state => {
			state.isLoading = true
		})
		builder.addCase(fetchParameters.fulfilled, (state, action) => {
			state.parameters = action.payload.sort((a, b) => a.sort - b.sort)
			state.isLoading = false
		})
		builder.addCase(fetchParameters.rejected, state => {
			state.isLoading = false
		})

		builder.addCase(deleteParameter.pending, state => {
			state.isLoading = true
		})
		builder.addCase(deleteParameter.fulfilled, (state, action) => {
			state.parameters = state.parameters.filter(item => item.id !== action.payload)
			state.isLoading = false
		})
		builder.addCase(deleteParameter.rejected, state => {
			state.isLoading = false
		})

		builder.addCase(fetchParameterById.pending, state => {
			state.selected.isLoading = true
		})
		builder.addCase(fetchParameterById.fulfilled, (state, action) => {
			state.selected.parameter = {
				...action.payload,
				guidewords: action.payload.guidewords.sort((a, b) => a.sort - b.sort),
			}
			state.selected.isLoading = false
		})
		builder.addCase(fetchParameterById.rejected, state => {
			state.selected.isLoading = false
		})

		builder.addCase(editParameter.pending, state => {
			state.selected.isLoading = true
		})
		builder.addCase(editParameter.fulfilled, (state, action) => {
			const editedIndex = state.parameters.findIndex(item => item.id === action.payload.id)

			state.parameters[editedIndex] = action.payload
			state.selected.parameter = {
				...action.payload,
				guidewords: action.payload.guidewords.sort((a, b) => a.sort - b.sort),
			}
			state.selected.isLoading = false
		})
		builder.addCase(editParameter.rejected, state => {
			state.selected.isLoading = false
		})

		builder.addCase(addParameter.fulfilled, (state, action) => {
			state.parameters.push({ ...action.payload, guidewords: [] })

			state.selected = {
				parameter: { ...action.payload, guidewords: [] },
				isLoading: false,
			}

			state.isLoading = false
			return state
		})
		builder.addCase(addParameter.rejected, state => {
			state.isLoading = false
		})
	},
})

export const {} = parametersSlice.actions

export default parametersSlice.reducer
