import { CloseOutlined } from '@ant-design/icons'
import { Button, Form, Input, List, Skeleton, Space, message } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { guideWordsApi } from '../../api/apis'
import { NodeGuidewordDto, NodeParameterDtoRequest } from '../../generated/backend'
import { useActions } from '../../hooks/useActions'
import { editParameter, fetchParameterById } from '../../redux/parameters/parameters.actions'
import { RootState, useAppDispatch } from '../../redux/store'

type ParameterFormProps = {}
export const ParameterForm: React.FC<ParameterFormProps> = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { id } = useParams()
	const { parameter, isLoading } = useSelector((state: RootState) => state.parameters.selected)
	const { addGuideWord, removeGuideWord } = useActions()

	const [messageApi, contextHolder] = message.useMessage()
	const [nameOfGuideWord, setNameOfGuideWord] = React.useState<string>('')
	const [form] = Form.useForm()

	React.useEffect(() => {
		dispatch(fetchParameterById(id)).then(res => {
			const parameter = res.payload as NodeParameterDtoRequest
			form.setFieldsValue({
				name: parameter?.name || '',
				id: parameter?.id || '',
			})
		})
	}, [id])

	const onSubmit = async (data: NodeParameterDtoRequest) => {
		form.validateFields().then(async () => {
			const response = await dispatch(editParameter(data))

			if (response.meta.requestStatus === 'fulfilled') {
				messageApi.success('Параметр обновлен!')
			} else {
				messageApi.error('Не удалось обновить параметр!')
			}
		})
	}

	const addGuideword = async () => {
		try {
			const response = await guideWordsApi.apiV1NodeGuidewordPost({
				name: nameOfGuideWord,
				nodeParameterId: parameter.id,
			})

			addGuideWord(response.data)
			messageApi.success('Управляющее слово добавлено!')
			setNameOfGuideWord('')
		} catch (error) {
			messageApi.error('Не удалось добавить управляющее слово!')
		}
	}

	const onDeleteGuideword = async (node: NodeGuidewordDto) => {
		try {
			await guideWordsApi.apiV1NodeGuidewordDelete(node.id)
			removeGuideWord(node.id)
			messageApi.success('Управляющее слово удалено!')
		} catch (error) {
			messageApi.error('Не удалось удалить управляющее слово!')
		}
	}

	const onClose = () => {
		navigate('/dictionaries/parameters')
	}

	if (isLoading) {
		return (
			<>
				<Space style={{ marginBottom: '12px' }}>
					<Skeleton.Button active />
					<Skeleton.Button active />
				</Space>
				<Skeleton title={false} active paragraph={{ rows: 4 }} />
			</>
		)
	}

	return (
		<>
			{contextHolder}
			<Form
				form={form}
				onFinish={onSubmit}
				labelCol={{ span: 3, offset: 0 }}
				labelAlign={'left'}
			>
				<Form.Item name={'id'}>
					<Space>
						<Button name={'save'} htmlType='submit' type={'primary'}>
							Сохранить
						</Button>
						<Button htmlType='button' onClick={onClose}>
							Закрыть
						</Button>
					</Space>
				</Form.Item>
				<Form.Item
					name={'name'}
					rules={[{ required: true, message: 'Название не может быть пустым!' }]}
					label={'Название'}
				>
					<Input id={'parameterName'} />
				</Form.Item>
				<Form.Item label={'Управляющие слова'}>
					<Space.Compact style={{ width: '100%' }}>
						<Input
							id={'guideWord'}
							value={nameOfGuideWord}
							onChange={e => setNameOfGuideWord(e.target.value)}
						/>
						<Button
							name={'addGuideWord'}
							type={'primary'}
							onClick={() => addGuideword()}
						>
							Добавить
						</Button>
					</Space.Compact>
					<List
						itemLayout={'horizontal'}
						dataSource={parameter.guidewords ?? []}
						renderItem={(item, index) => (
							<List.Item>
								<Space>
									{item?.name}
									<CloseOutlined
										style={{ cursor: 'pointer' }}
										rev={undefined}
										onClick={() => onDeleteGuideword(item)}
									/>
								</Space>
							</List.Item>
						)}
					/>
				</Form.Item>
			</Form>
		</>
	)
}
