import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Space } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { Entity } from '../../../types'

type AddEntityModalProps = {
	onSave: (entity: Entity) => Promise<void>
	setSelected: (id: string) => void
}

const pattern = new RegExp(/^[0-9]+((\.[0-9]+)?([eE][+-]?[0-9]+)?)$/)

const AddEntityModal: React.FC<AddEntityModalProps> = ({ onSave, setSelected }) => {
	const [form] = Form.useForm()
	const [isLoading, setIsLoading] = React.useState(false)
	const [showModal, setShowModal] = React.useState(false)
	const conditionForProbability = location.pathname.includes('probabilities')
	const conditionForNodes = location.pathname.includes('nodes')

	const { node } = useSelector((state: RootState) => state.nodes.selected)

	const onCloseModal = () => {
		setShowModal(false)
		form.resetFields()
	}

	const onOpenModal = () => {
		setShowModal(true)
	}

	const handleSave = (values: unknown) => {
		setIsLoading(true)

		onSave(values).then(() => {
			setIsLoading(false)
			onCloseModal()
			setSelected(node.id)
		})
	}

	return (
		<>
			<Space>
				<Button
					name={'add'}
					type={'text'}
					icon={<PlusOutlined rev={undefined} />}
					onClick={onOpenModal}
				>
					Добавить
				</Button>
			</Space>
			<Modal
				open={showModal}
				centered
				title={'Добавить элемент'}
				footer={false}
				onCancel={onCloseModal}
			>
				<Form onFinish={handleSave} layout='vertical' form={form}>
					{conditionForProbability ? (
						<>
							<Form.Item
								rules={[
									{ required: true, message: 'Название не может быть пустым!' },
								]}
								name={'name'}
								label={'Название'}
								hasFeedback
								validateDebounce={500}
							>
								<Input size='large' id={'name'} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'Вероятность не может быть пустая!',
									},
									{
										pattern,
										message: 'Вероятность должна быть в экспоненциальной форме',
									},
								]}
								name={'probability'}
								label={'Вероятность'}
								hasFeedback
								validateDebounce={500}
							>
								<Input size='large' id={'probability'} />
							</Form.Item>
						</>
					) : conditionForNodes ? (
						<>
							<Form.Item
								rules={[
									{ required: true, message: 'Название не может быть пустым!' },
								]}
								name={'name'}
								id={'name'}
								label={'Название'}
								hasFeedback
								validateDebounce={500}
							>
								<Input id='name' size='large' />
							</Form.Item>
							<Form.Item
								rules={[{ required: true, message: 'Код не может быть пустым!' }]}
								name={'code'}
								label={'Код'}
								hasFeedback
								validateDebounce={500}
							>
								<Input id='code' size='large' />
							</Form.Item>
						</>
					) : (
						<Form.Item
							rules={[{ required: true, message: 'Название не может быть пустым!' }]}
							name={'name'}
							id={'name'}
							label={'Название'}
							hasFeedback
							validateDebounce={500}
						>
							<Input size='large' />
						</Form.Item>
					)}
					<Space>
						<Button
							htmlType='submit'
							name={'submit'}
							loading={isLoading}
							data-test={'addItemButton'}
							type={'primary'}
						>
							Добавить
						</Button>
						<Button htmlType='button' onClick={onCloseModal}>
							Отмена
						</Button>
					</Space>
				</Form>
			</Modal>
		</>
	)
}

export default AddEntityModal
