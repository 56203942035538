import { ru } from 'date-fns/locale'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { fetchUserInfo } from '../redux/general/general.actions'
import { fetchSettings } from '../redux/settings/settings.actions'
import { useAppDispatch } from '../redux/store'
import './../assets/css/index.css'
import Dashboard from './Dashboard'
import JpgFilesLayout from './JpgFiles/JpgFilesLayout'
import PdfFilesLayout from './PdfFiles/PdfFilesLayout'
import { Loader } from './UI/Loaders/Loader/Loader'
import NotFoundRoute from './UI/NotFoundRoute/NotFoundRoute'
import DrawWrapper from './drawing/DrawWrapper'
import { EquipmentForm } from './equipment/EquipmentForm'
import { EquipmentLayout } from './equipment/EquipmentLayout'
import NewHazopForm from './hazop/HazopForm/NewHazopForm'
import { HazopItem } from './hazop/HazopItem/HazopItem'
import { HazopLayout } from './hazop/HazopLayout/HazopLayout'
import HazopList from './hazop/HazopList/HazopList'
import { Export } from './importAndExport/Export'
import { Import } from './importAndExport/Import'
import Layout from './layout/Layout'
import { Login } from './login/Login'
import LopaItem from './lopa/LopaItem/LopaItem'
import LopaLayout from './lopa/LopaLayout'
import { NodesForm } from './nodes/NodesForm'
import { NodesLayout } from './nodes/NodesLayout'
import { ParameterForm } from './parameters/ParameterForm'
import { ParametersLayout } from './parameters/ParametersLayout'
import ProbabilitiesLayout from './probabilities/ProbabilitiesLayout'
import RiskMatrix from './riskMatrix/RiskMatrix'
import { RoleForm } from './roles/RoleForm'
import { RolesLayout } from './roles/RolesLayout'
import SchemeForm from './schemes/SchemeForm'
import SchemesLayout from './schemes/SchemesLayout'
import { SettingsLayout } from './settings/layout/SettingsLayout'
import { SystemForm } from './systems/SystemForm'
import { SystemsLayout } from './systems/SystemsLayout'
import { UserForm } from './users/UserForm'
import { UsersList } from './users/UserList'

setDefaultOptions({ locale: ru })

//

const App: React.FC = () => {
	const generalState = useTypedSelector(state => state.general)
	const dispatch = useAppDispatch()
	const roles: string[] = JSON.parse(localStorage.getItem('roles'))

	const { isLoading, lopaEnable } = useTypedSelector(state => state.settings)

	const checkRole = () => {
		return !!roles?.find(item => item === 'admin')
	}

	React.useEffect(() => {
		dispatch(fetchUserInfo(generalState.token))
		dispatch(fetchSettings())
	}, [])

	if (generalState.initial || isLoading) {
		return <Loader isGeneral={true} />
	}

	return (
		<Router>
			<Routes>
				<Route path='/login' element={<Login />} />
				<Route path='/jpg-files/view/:id' element={<DrawWrapper />} />
				<Route path='/' element={<Layout />}>
					<Route path={'*'} element={<NotFoundRoute />} />
					<Route index={true} element={<Dashboard />} />

					<Route path={'hazop'} element={<HazopLayout />} />
					<Route path='hazop/list/:id' element={<HazopList />} />
					<Route path='hazop/list/:id/:hazopEntryId' element={<NewHazopForm />} />
					<Route path={'hazop/:id'} element={<HazopItem />} />

					{lopaEnable ? (
						<>
							<Route path={'/lopa'} element={<LopaLayout />} />
							<Route path={'/lopa/:id'} element={<LopaItem />} />
						</>
					) : null}

					<Route path='nodes' element={<NodesLayout />}>
						<Route path={':id'} element={<NodesForm />} />
					</Route>

					<Route path='pdf-files' element={<PdfFilesLayout />} />
					<Route path='jpg-files' element={<JpgFilesLayout />} />
					<Route path='schemes' element={<SchemesLayout />}>
						<Route path=':id' element={<SchemeForm />} />
					</Route>

					<Route path='dictionaries'>
						<Route path='probabilities' element={<ProbabilitiesLayout />}>
							{/* <Route path=':id' element={<ProbabilitiesForm />} /> */}
						</Route>
						<Route path={'equipments'} element={<EquipmentLayout />}>
							<Route path={':id'} element={<EquipmentForm />} />
						</Route>
						<Route path='systems' element={<SystemsLayout />}>
							<Route path={':id'} element={<SystemForm />} />
						</Route>
						<Route path='parameters' element={<ParametersLayout />}>
							<Route path={':id'} element={<ParameterForm />} />
						</Route>
						<Route path={'matrix'} element={<RiskMatrix isModal={false} />} />
					</Route>

					<Route path='settings'>
						<Route path={'main'} element={<SettingsLayout />} />
						{checkRole() ? (
							<>
								<Route path={'users'} element={<UsersList />} />
								<Route path={'users/:id'} element={<UserForm />} />
								<Route path={'roles'} element={<RolesLayout />} />
								<Route path={'roles/:id'} element={<RoleForm />} />
							</>
						) : null}
					</Route>

					<Route path={'import'} element={<Import />} />
					<Route path={'export'} element={<Export />} />
				</Route>
			</Routes>
		</Router>
	)
}

export default App
