import { PlusOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RoleDto } from '../../generated/backend'
import { RoleAddModal } from './Modals/RoleAddModal'
import { rolesApi } from '../../api/apis'

type RolesLayoutProps = {}
export const RolesLayout: React.FC<RolesLayoutProps> = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const [roles, setRoles] = React.useState<RoleDto[]>()
	const [showModal, setShowModal] = React.useState<boolean>(false)

	const fetchRoles = async () => {
		const response = await rolesApi.apiV1RoleGet()

		setRoles(
			response.data.map(role => ({
				...role,
				key: role.id,
			}))
		)
	}

	React.useEffect(() => {
		fetchRoles()
	}, [id])

	const columns: ColumnsType<RoleDto> = [
		{
			title: '№',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			render: (text, record) => (
				<span
					style={{ cursor: 'pointer' }}
					onClick={() => navigate(`/settings/roles/${record.id}`)}
				>
					{text}
				</span>
			),
		},
		{
			title: 'Описание',
			dataIndex: 'description',
			key: 'description',
		},
	]

	const onCreateRole = () => {
		setShowModal(true)
	}

	const onCancel = () => {
		setShowModal(false)
	}

	const createRole = async (name: string) => {
		await rolesApi.apiV1RoleCreatePost(name)

		await fetchRoles()
	}

	return (
		<>
			<Button
				type={'text'}
				icon={<PlusOutlined rev={undefined} />}
				onClick={onCreateRole}
			>
				Создать роль
			</Button>
			<Table columns={columns} dataSource={roles} />
			{showModal && (
				<RoleAddModal onCancel={onCancel} showModal={showModal} createRole={createRole} />
			)}
		</>
	)
}
