import axios from 'axios'
import { errorNotification } from '../components/UI/Notifications/ErrorNotification/ErrorNotification'
import {
	AssetSystemApiFactory,
	BackupApiFactory,
	CauseApiFactory,
	CauseProbabilitiesApiFactory,
	ConsequenceApiFactory,
	DbDeleteApiFactory,
	EnumApiFactory,
	EquipmentApiFactory,
	HazopEntryApiFactory,
	ImageApiFactory,
	InfoApiFactory,
	LopaEntryApiFactory,
	MatrixValueApiFactory,
	NodeApiFactory,
	NodeGuidewordApiFactory,
	NodeParameterApiFactory,
	PdfApiFactory,
	RecommendationApiFactory,
	RoleApiFactory,
	SafeguardApiFactory,
	SchemeApiFactory,
	SettingsApiFactory,
	UserApiFactory,
} from '../generated/backend/api'

axios.interceptors.request.use(
	config => {
		config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
		config.headers.tenantId = 'default' //todo env

		return config
	},
	error => Promise.reject(error)
)

axios.interceptors.response.use(
	function (response) {
		//Dosomethingwithresponsedata
		return response
	},
	function (error) {
		if (error.response?.status === 400) {
			errorNotification(error, 'Не удалось сохранить изменения')
		}
		if (error.response?.status === 404) {
			errorNotification(error, 'Ничего не найдено')
		}
		if (error.response?.status === 409) {
			errorNotification(error, 'Не удалось сохранить изменения')
		}
		if (error.response?.status === 401 && window.location.pathname !== '/login') {
			//redirect to auth page
			location.replace('/login')
		}
		if (error.response?.status >= 500) {
			errorNotification(error, 'Сервер не отвечает')
		}
		return Promise.reject(error)
	}
)

export const backupApi = BackupApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const equipmentsApi = EquipmentApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const usersApi = UserApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const rolesApi = RoleApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const lopaEntriesApi = LopaEntryApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const hazopEntriesApi = HazopEntryApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const causesApi = CauseApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const consequencesApi = ConsequenceApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const safeguardsApi = SafeguardApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const recommendationsApi = RecommendationApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const nodesApi = NodeApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const parametersApi = NodeParameterApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const schemesApi = SchemeApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const settingsApi = SettingsApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const systemsApi = AssetSystemApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const matrixApi = MatrixValueApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const enumsApi = EnumApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const pdfsApi = PdfApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const guideWordsApi = NodeGuidewordApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const jpgsApi = ImageApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const dbApi = DbDeleteApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

// export const presetsApi = PresetsApiFactory({
// 	isJsonMime: (type: string) => false,
// 	basePath: window.location.origin,
// })

// export const shapeApi = ShapeApiFactory({
// 	isJsonMime: (type: string) => false,
// 	basePath: window.location.origin,
// })

export const causeProbabilitiesApi = CauseProbabilitiesApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})

export const serverInfoApi = InfoApiFactory({
	isJsonMime: (type: string) => false,
	basePath: window.location.origin,
})
