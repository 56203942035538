import { Button, Form, Input, message, Space, Switch } from 'antd'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from '../../redux/store'
import { editScheme, fetchSchemeById } from '../../redux/schemes/schemes.actions'
import { SchemeDto } from '../../generated/backend'

type SchemeFormProps = {}

const SchemeForm: React.FC<SchemeFormProps> = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [form] = Form.useForm()
	const dispatch = useAppDispatch()

	React.useEffect(() => {
		dispatch(fetchSchemeById(id)).then(response => {
			if (response.meta.requestStatus === 'fulfilled') {
				form.setFieldsValue(response.payload)
			}
		})
	})

	const onClose = () => {
		navigate('/schemes')
	}

	const onSubmit = async (values: SchemeDto) => {
		dispatch(editScheme(values)).then(response => {
			if (response.meta.requestStatus === 'fulfilled') {
				message.success('Схема сохранена')
			}
		})
	}

	return (
		<Form<SchemeDto> form={form} labelCol={{ span: 3 }} labelAlign='left' onFinish={onSubmit}>
			<Form.Item name={'id'}>
				<Space>
					<Button type='primary' htmlType='submit'>
						Сохранить
					</Button>
					<Button onClick={onClose}>Закрыть</Button>
				</Space>
			</Form.Item>
			<Form.Item
				rules={[{ required: true, message: 'Обязательное поле' }]}
				label='Название'
				name={'name'}
				validateDebounce={500}
				hasFeedback
			>
				<Input />
			</Form.Item>
			<Form.Item label='Код' name={'code'}>
				<Input disabled />
			</Form.Item>
			<Form.Item label='Комментарий' name={'comment'}>
				<Input.TextArea disabled />
			</Form.Item>
			<Form.Item label='Подтверждено' name={'approved'} valuePropName='checked'>
				<Switch disabled />
			</Form.Item>
		</Form>
	)
}

export default SchemeForm
