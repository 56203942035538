import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { fetchMatrix } from './riskMatrix.actions'
import { MatrixValueRowDto } from '../../generated/backend'

interface IRiskMatrixState {
	item: MatrixValueRowDto
	loading: boolean
	initialMatrix: MatrixValueRowDto
}

const initialState: IRiskMatrixState = {
	item: {},
	loading: true,
	initialMatrix: {},
}

export const riskMatrixSlice = createSlice({
	name: 'riskMatrix',
	initialState,
	reducers: {
		setRiskMatrix: (state, action: PayloadAction<MatrixValueRowDto>) => {
			state.item = action.payload
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchMatrix.fulfilled, (state, action) => {
			state.item = action.payload.matrix

			if (action.payload.isInitial) {
				state.loading = false
			}
		})
		builder.addCase(fetchMatrix.rejected, state => {
			state.loading = false
		})
	},
})

export const {} = riskMatrixSlice.actions
export default riskMatrixSlice.reducer
