import { createAsyncThunk } from '@reduxjs/toolkit'
import { NodeDtoRequest } from '../../generated/backend'
import { equipmentsApi, nodesApi } from '../../api/apis'

export const fetchNodes = createAsyncThunk(
	'nodes/fetch-nodes',
	async (_, { rejectWithValue }) => {
		try {
			const response = await nodesApi.apiV1NodeGet()
			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const fetchNodeById = createAsyncThunk(
	'nodes/node-by',
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await nodesApi.apiV1NodeIdGet(id)
			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const addNode = createAsyncThunk(
	'nodes/add-node',
	async (node: NodeDtoRequest, { rejectWithValue }) => {
		try {
			const response = await nodesApi.apiV1NodePost(node)

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const deleteNode = createAsyncThunk(
	'nodes/delete-node',
	async (id: string, { rejectWithValue }) => {
		try {
			await nodesApi.apiV1NodeDelete(id)
			return id
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const changeNode = createAsyncThunk(
	'nodes/edit-node',
	async (node: NodeDtoRequest, { rejectWithValue }) => {
		try {
			const response = await nodesApi.apiV1NodePut(node)
			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const attachEquipmentToNode = createAsyncThunk(
	'nodes/attach-equipment',
	async (
		{ nodeId, equipmentId }: { nodeId: string; equipmentId: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await equipmentsApi.apiV1EquipmentAttachToNodePost(
				nodeId,
				equipmentId
			)
			return { nodeId, equipmentId }
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)
