import { createAsyncThunk } from '@reduxjs/toolkit'
import { causeProbabilitiesApi } from '../../api/apis'

export const fetchCauseProbabilities = createAsyncThunk(
	'causeProbabilities/fetch-all',
	async (_, { rejectWithValue }) => {
		try {
			const response = await causeProbabilitiesApi.apiV1CauseProbabilityGet()
			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

// export const fetchCauseProbabilitiesById = createAsyncThunk(
// 	'causeProbabilities/fetch-by-id',
// 	async (id: string, { rejectWithValue }) => {
// 		try {
// 			const response = await causeProbabilitiesApi.apiV1CauseProbabilityIdGet(id)
// 			return response.data
// 		} catch (e) {
// 			return rejectWithValue(e)
// 		}
// 	}
// )

export const addCauseProbability = createAsyncThunk(
	'causeProbabilities/add',
	async (data: any, { rejectWithValue }) => {
		try {
			const response = await causeProbabilitiesApi.apiV1CauseProbabilityPost(data)
			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

export const editCauseProbability = createAsyncThunk(
	'causeProbabilities/edit',
	async (data: any, { rejectWithValue }) => {
		try {
			const response = await causeProbabilitiesApi.apiV1CauseProbabilityPut(data)
			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

export const deleteCauseProbability = createAsyncThunk(
	'causeProbabilities/delete',
	async (id: number, { rejectWithValue }) => {
		try {
			const response = await causeProbabilitiesApi.apiV1CauseProbabilityDelete(id)
			return id
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)
