import { createSlice } from '@reduxjs/toolkit'
import { SchemeDto } from '../../generated/backend'
import {
	createScheme,
	deleteScheme,
	editScheme,
	fetchSchemeById,
	fetchSchemes,
} from './schemes.actions'

interface SchemesState {
	schemes: SchemeDto[]
	isLoading: boolean
	selected: { scheme: SchemeDto; isLoading: boolean }
}

const initialState: SchemesState = {
	schemes: [],
	isLoading: true,

	selected: {
		scheme: null,
		isLoading: true,
	},
}

export const schemesSlice = createSlice({
	name: 'schemes',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchSchemes.pending, state => {
			state.isLoading = true
		})
		builder.addCase(fetchSchemes.fulfilled, (state, action) => {
			state.isLoading = false
			state.schemes = action.payload
		})
		builder.addCase(fetchSchemes.rejected, state => {
			state.isLoading = false
		})

		builder.addCase(createScheme.fulfilled, (state, action) => {
			state.schemes.push(action.payload)
		})

		builder.addCase(fetchSchemeById.pending, state => {
			state.selected.isLoading = true
		})
		builder.addCase(fetchSchemeById.fulfilled, (state, action) => {
			state.selected.isLoading = false
			state.selected.scheme = action.payload
		})
		builder.addCase(fetchSchemeById.rejected, state => {
			state.selected.isLoading = false
		})

		builder.addCase(editScheme.fulfilled, (state, action) => {
			state.schemes = state.schemes.map(item => {
				if (item.id === action.payload.id) {
					return action.payload
				}
				return item
			})

			state.selected.scheme = action.payload
		})

		builder.addCase(deleteScheme.pending, state => {
			state.isLoading = true
		})
		builder.addCase(deleteScheme.fulfilled, (state, action) => {
			state.isLoading = false
			state.schemes = state.schemes.filter(item => item.id !== action.payload)
		})
		builder.addCase(deleteScheme.rejected, state => {
			state.isLoading = false
		})
	},
})

export const {} = schemesSlice.actions

export default schemesSlice.reducer
