import { Input } from 'antd'
import { InputRef, TextAreaProps } from 'antd/es/input'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
	ConsequenceDtoRequestRiskTypeEnum,
	SafeguardDtoRequestSafeguardTypeEnum,
} from '../../../../generated/backend'
import { TypeOfHazopTableState } from '../../../types'
import { InputFocusOptions } from 'antd/es/input/Input'

interface DebounceTextAreaProps extends TextAreaProps {
	initialEntity: TypeOfHazopTableState
	entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards'
	type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	saveEntity: (
		entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
		item: TypeOfHazopTableState,
		hazopEntryId: string,
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => Promise<void>
	delay?: number
	onCreate?: (
		entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
		riskType?: ConsequenceDtoRequestRiskTypeEnum,
		safeguardType?: SafeguardDtoRequestSafeguardTypeEnum
	) => void
}

const isEqual = (obj1: TypeOfHazopTableState, obj2: TypeOfHazopTableState) =>
	JSON.stringify(obj1) === JSON.stringify(obj2)

const DebounceTextArea: React.FC<DebounceTextAreaProps> = ({
	initialEntity,
	entityType,
	type,
	saveEntity,
	onCreate,
	delay = 800,
	...props
}) => {
	const { hazopEntryId } = useParams()
	const [value, setValue] = React.useState<TypeOfHazopTableState>(initialEntity)
	const [isLoading, setIsLoading] = React.useState(false)
	const inputRef = React.useRef<InputRef>(null)
	const focusTimeout = React.useRef<ReturnType<typeof setTimeout>>(null)
	const saveTimeout = React.useRef<ReturnType<typeof setTimeout>>(null)

	React.useEffect(() => {
		if (focusTimeout.current) clearTimeout(focusTimeout.current)
		if (saveTimeout.current) clearTimeout(saveTimeout.current)

		focusTimeout.current = setTimeout(() => {
			inputRef.current?.focus(isEqual(value, initialEntity) ? { cursor: 'end' } : undefined)
			return () => clearTimeout(focusTimeout.current)
		})

		if (!value || isEqual(value, initialEntity) || !value.name) return

		saveTimeout.current = setTimeout(async () => {
			setIsLoading(true)
			try {
				await saveEntity(entityType, value, hazopEntryId, type)
			} catch (e) {
				console.log(e)
			} finally {
				setIsLoading(false)
			}
		}, delay)

		return () => {
			clearTimeout(saveTimeout.current)
			clearTimeout(focusTimeout.current)
		}
	}, [value])

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue({ ...initialEntity, name: e.target.value })
	}

	return (
		<Input.TextArea
			ref={inputRef}
			onKeyPress={e => {
				if (e.key === 'Enter' && !e.shiftKey) e.preventDefault()
				if (e.key === 'Enter' && !e.shiftKey && !isLoading) {
					onCreate(
						entityType,
						entityType === 'consequences'
							? (type as ConsequenceDtoRequestRiskTypeEnum)
							: undefined,
						entityType === 'safeguards'
							? (type as SafeguardDtoRequestSafeguardTypeEnum)
							: undefined
					)
				}
			}}
			onChange={onChange}
			value={value?.name}
			{...props}
		/>
	)
}

export default React.memo(DebounceTextArea)
