import { createAsyncThunk } from '@reduxjs/toolkit'
import { schemesApi } from '../../api/apis'
import { SchemeDtoRequest } from '../../generated/backend'

export const fetchSchemes = createAsyncThunk(
	'schemes/fetchSchemes',
	async (_, { rejectWithValue }) => {
		try {
			const schemes = await schemesApi.apiV1SchemeGet()
			return schemes.data
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

export const deleteScheme = createAsyncThunk(
	'schemes/deleteScheme',
	async (id: string, { rejectWithValue }) => {
		try {
			await schemesApi.apiV1SchemeDelete(id)

			return id
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

export const createScheme = createAsyncThunk(
	'schemes/createScheme',
	async (scheme: SchemeDtoRequest, { rejectWithValue }) => {
		try {
			const response = await schemesApi.apiV1SchemePost(scheme)
			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

export const fetchSchemeById = createAsyncThunk(
	'schemes/fetchSchemeById',
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await schemesApi.apiV1SchemeIdGet(id)
			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

export const editScheme = createAsyncThunk(
	'schemes/editScheme',
	async (scheme: SchemeDtoRequest, { rejectWithValue }) => {
		try {
			const response = await schemesApi.apiV1SchemePut(scheme)
			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)
