import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, UploadProps } from 'antd';
import React from 'react';
import styles from './UploadBlockButton.module.css';

type UploadBlockButtonProps = {
    title: string;
    uploadProps: UploadProps;
};

const UploadBlockButton: React.FC<UploadBlockButtonProps> = ({ title, uploadProps }) => {
    return (
        <Upload className={styles.upload} {...uploadProps}>
            <Button size='large' block type='dashed' icon={<UploadOutlined rev={undefined} />}>
                {title}
            </Button>
        </Upload>
    );
};

export default UploadBlockButton;
