import React from 'react'

type NotFoundContentProps = {
	searchValue: string
	entity: string
	onOpenModal: () => void
}

const NotFoundContent: React.FC<NotFoundContentProps> = ({ searchValue, entity, onOpenModal }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div style={{ textAlign: 'center' }}>
				<p>Ничего не найдено.</p>
				<span
					style={{
						color: '#1890ff',
						cursor: 'pointer',
					}}
					onClick={onOpenModal}
				>
					Создать
				</span>{' '}
				{entity}
				{searchValue ? ` "${searchValue}"` : ''}?
			</div>
		</div>
	)
}

export default NotFoundContent
