import { WarningOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import Header from '../UI/Header/Header'
import Sider from '../UI/Sider/Sider'
import styles from './Layout.module.css'

const ErrorMsg = ({ error }) => {
	const navigate = useNavigate()

	const onBackButtonClick = () => {
		navigate('/')
		navigate(0)
	}

	return (
		<main className={styles.errorContainer}>
			<section className={styles.errorCard}>
				<WarningOutlined rev={undefined} style={{ fontSize: 50, color: 'red' }} />
				<h3>Что-то пошло не так!</h3>
				<p>Попробуйте обновить страницу или вернуться на главную.</p>
				<Button size='large' type='primary' onClick={onBackButtonClick}>
					Вернуться на главную
				</Button>
			</section>
		</main>
	)
}

const AppLayout: React.FC = () => {
	return (
		<>
			<Header />
			<main className={styles.main}>
				<Sider />
				<section className={styles.content}>
					<ErrorBoundary ErrorComponent={ErrorMsg}>
						<Outlet />
					</ErrorBoundary>
				</section>
			</main>
		</>
	)
}

export default AppLayout
