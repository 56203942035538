import { Select, Space } from 'antd'
import React from 'react'
import {
	HazopEntryDto,
	NodeGuidewordDto,
	NodeParameterDto,
} from '../../../generated/backend'
import { IValue } from '../../types'
import { useActions } from '../../../hooks/useActions'

type HazopTableSelectColumnProps = {
	hazopEntry: HazopEntryDto
	showSelect: {
		id: string
		show: boolean
		type: string
	}
	setShowSelect: (showSelect: { id: string; show: boolean; type: string }) => void
	fetchOptions: () => Promise<void>
	options: NodeParameterDto[] | NodeGuidewordDto[]
	setFieldValue: (
		field: string,
		value: IValue,
		hazopEntryId: string,
		isNodeParameter?: boolean
	) => void
	type: string
	setOptions: (options: NodeParameterDto[] | NodeGuidewordDto[]) => void
}

const HazopTableSelectColumn: React.FC<HazopTableSelectColumnProps> = ({
	hazopEntry,
	showSelect,
	setShowSelect,
	fetchOptions,
	options,
	setFieldValue,
	type,
	setOptions,
}) => {
	return (
		<>
			{hazopEntry.id === showSelect.id && showSelect.show && showSelect.type === type ? (
				<Space>
					<Select
						placeholder={'...'}
						onFocus={fetchOptions}
						options={options?.map(item => ({
							value: item.id,
							label: item.name,
						}))}
						value={hazopEntry[type]?.name}
						style={{
							width: 140,
							userSelect: 'none',
						}}
						onBlur={e => {
							e.stopPropagation()
							setShowSelect({
								id: null,
								show: false,
								type: '',
							})
							setOptions(null)
						}}
						onSelect={(value, option) => {
							setShowSelect({
								id: null,
								show: false,
								type: '',
							})
							setFieldValue(
								type,
								{
									name: option.label,
									id: option.value,
								},
								hazopEntry.id,
								type === 'nodeParameter'
							)

							setOptions(null)
						}}
					/>
				</Space>
			) : (
				hazopEntry[type]?.name
			)}
		</>
	)
}

export default HazopTableSelectColumn
