import { Button, Form, Input, Skeleton, Space, message, ColorPicker } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { EquipmentDto } from '../../generated/backend'
import { editEquipment, fetchEquipmentById } from '../../redux/equipments/equipments.actions'
import { RootState, useAppDispatch } from '../../redux/store'

type EquipmentFormProps = {}

export const EquipmentForm: React.FC<EquipmentFormProps> = ({}) => {
	const { id } = useParams()
	const navigate = useNavigate()
	const { isLoading } = useSelector((state: RootState) => state.equipments.selected)
	const dispatch = useAppDispatch()
	const [form] = Form.useForm()

	React.useEffect(() => {
		dispatch(fetchEquipmentById(id)).then(res => {
			const equipment = res.payload as EquipmentDto

			form.setFieldsValue({
				name: equipment?.name || '',
				id: equipment?.id || '',
			})
		})
	}, [id])

	const onSubmit = (equipment: EquipmentDto) => {
		dispatch(editEquipment(equipment)).then(response => {
			if (response.meta.requestStatus === 'fulfilled') message.success('Сохранено!')
			else message.error('Произошла ошибка')
		})
	}

	const onBack = () => {
		navigate('/dictionaries/equipments')
	}

	if (isLoading) {
		return (
			<>
				<Space style={{ marginBottom: '12px' }}>
					<Skeleton.Button active />
					<Skeleton.Button active />
				</Space>
				<Skeleton title={false} active paragraph={{ rows: 4 }} />
			</>
		)
	}

	return (
		<>
			<Form
				form={form}
				onFinish={onSubmit}
				labelCol={{ span: 3, offset: 0 }}
				labelAlign={'left'}
			>
				<Form.Item name={'id'}>
					<Space>
						<Button name={'save'} htmlType='submit' type={'primary'}>
							Сохранить
						</Button>
						<Button onClick={onBack}>Закрыть</Button>
					</Space>
				</Form.Item>
				<Form.Item
					name={'name'}
					rules={[{ required: true, message: 'Название не может быть пустым!' }]}
					label={'Название'}
				>
					<Input id={'name'} />
				</Form.Item>
				<Form.Item
					name={'color'}
					label={'Цвет'}
					rules={[{ required: true, message: 'Цвет обязателен!' }]}
					initialValue={'#1677ff'}
				>
					<ColorPicker
						size='large'
						format={'hex'}
						defaultFormat={'hex'}
						onChange={(value, hex) => form.setFieldValue('color', hex)}
						disabledAlpha
					/>
				</Form.Item>
			</Form>
		</>
	)
}
