import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { fetchNodes } from '../../../redux/nodes/nodes.actions'
import { useAppDispatch } from '../../../redux/store'

interface BreadcrumbsProps {}

const EXCLUDEDPATHS = ['settings', 'guidewords']

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({}) => {
	const navigate = useNavigate()
	const { hazopEntryId } = useParams()
	const location = useLocation()
	const paths = location.pathname.split('/').filter(path => path !== '')
	const dispatch = useAppDispatch()
	const { nodes } = useTypedSelector(state => state.nodes)
	const { schemes } = useTypedSelector(state => state.schemes)
	const { systems } = useTypedSelector(state => state.systems)
	const { parameters } = useTypedSelector(state => state.parameters)
	const { equipments } = useTypedSelector(state => state.equipments)
	const hazopEntry = useTypedSelector(state => state.hazopEntries.item)
	const causeProbability = useTypedSelector(state => state.causeProbabilities.item)

	React.useEffect(() => {
		if (nodes.length === 0) {
			dispatch(fetchNodes())
		}
	}, [])

	const getTitle = (str: string) => {
		switch (str) {
			case 'nodes':
				return 'Узлы'
			case 'equipments':
				return 'Оборудование'
			case 'systems':
				return 'Системы'
			case 'parameters':
				return 'Параметры'
			case 'settings':
				return 'Настройки'
			case 'guidewords':
				return 'Управляющие слова'
			case 'hazop':
				return 'Hazop'
			case 'lopa':
				return 'Lopa'
			case 'import':
				return 'Импорт файлов'
			case 'export':
				return 'Экспорт файлов'
			case 'main':
				return 'Основные настройки'
			case 'schemes':
				return 'Схемы'
			case 'pdf-files':
				return 'PDF файлы'
			case 'jpg-files':
				return 'JPG файлы'
			case 'matrix':
				return 'Матрица рисков'
			case 'roles':
				return 'Роли'
			case 'users':
				return 'Пользователи'
			case 'probabilities':
				return 'Вероятности'
			case 'dictionaries':
				return 'Справочники'
		}

		if (str === hazopEntryId) {
			const nodeParameter = hazopEntry?.nodeParameter?.name
			const nodeGuideword = hazopEntry?.nodeGuideword?.name
			
			if (!nodeParameter && !nodeGuideword) return 'Новое отклонение'
			if (nodeParameter && !nodeGuideword) return nodeParameter

			return `${nodeParameter} - ${nodeGuideword}`
		}

		const foundItem = [
			...nodes,
			...schemes,
			...systems,
			...parameters,
			...equipments,
			causeProbability,
		]?.find(item => item?.id === str)

		return foundItem?.name
	}

	return (
		<>
			{paths.map((path, index) => {
				if (path === 'list') return
				const routeTo = paths.slice(0, index + 1).join('/')
				const isLast = index === paths.length - 1
				return isLast ? (
					<li key={index}>
						{getTitle(path) ? getTitle(path) : <LoadingOutlined rev={undefined} />}
					</li>
				) : (
					<li
						onClick={() => {
							if (EXCLUDEDPATHS.includes(routeTo)) {
								return
							}

							navigate(`/${routeTo}`)
						}}
						key={index}
					>
						{getTitle(path) ? getTitle(path) : <LoadingOutlined rev={undefined} />}
					</li>
				)
			})}
		</>
	)
}
