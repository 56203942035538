import { Button, Form, Input, Modal, Space } from 'antd';
import React from 'react';

type RoleAddModalProps = {
    onCancel: () => void;
    showModal: boolean;
    createRole: (name: string) => void;
};
export const RoleAddModal: React.FC<RoleAddModalProps> = ({ onCancel, showModal, createRole }) => {
    const [role, setRole] = React.useState<string>('');

    const setFieldValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRole = e.target.value;

        setRole(newRole);
    };

    return (
        <div>
            <Modal centered={true} open={showModal} onCancel={onCancel} title={'Cоздание роли'} footer={false}>
                <Form layout='vertical'>
                    <Form.Item label={'Название роли'}>
                        <Input
                            size='large'
                            placeholder={'название...'}
                            name={'name'}
                            onChange={event => setFieldValue(event)}
                        />
                    </Form.Item>
                </Form>
                <Space>
                    <Button
                        type={'primary'}
                        onClick={() => {
                            createRole(role);
                            onCancel();
                        }}
                    >
                        Создать
                    </Button>
                    <Button onClick={onCancel}>Отмена</Button>
                </Space>
            </Modal>
        </div>
    );
};
