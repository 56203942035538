import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserDto } from '../../generated/backend';
import { UserAddModal } from './Modals/UserAddModal';
import { UserDeleteModal } from './Modals/UserDeleteModal';
import { usersApi } from '../../api/apis'

type UsersProps = {};

export const UsersList: React.FC<UsersProps> = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [users, setUsers] = React.useState<UserDto[]>([]);
    const [showDeleteModal, setShowDeleteModal] = React.useState<{
        show: boolean;
        id: number | null;
    }>({ show: false, id: null });

    const fetchUsers = async () => {
        const response = await usersApi.apiV1UserGet();

        setUsers(
            response.data.map(user => ({
                ...user,
                key: user.id,
            })),
        );
    };

    React.useEffect(() => {
        fetchUsers();
    }, [id]);

    const columns: ColumnsType<UserDto> = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'key',
        },
        {
            title: 'Имя',
            dataIndex: 'login',
            key: 'name',
            render: (text, record) => (
                <Space onClick={() => navigate(`/settings/users/${record.id}`)} style={{ cursor: 'pointer' }}>
                    <Avatar />
                    {text}
                </Space>
            ),
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => (
                <Button
                    type={'text'}
                    icon={<DeleteOutlined rev={undefined} onClick={() => prepareToDelete(record.id)} />}
                ></Button>
            ),
        },
    ];

    const prepareToDelete = (id: number) => {
        setShowDeleteModal({ show: true, id });
    };

    const onDeleteUser = async (id: number) => {
        await usersApi.apiV1UserIdDelete(id);

        await fetchUsers();
    };

    const onCloseDeleteModal = () => {
        setShowDeleteModal({ show: false, id: null });
    };

    const createUser = async (data: UserDto) => {
        await usersApi.apiV1UserCreatePost({
            username: data.login,
            password: data.password,
        });

        await fetchUsers();
    };

    const createRole = async () => {};

    const onCreateUser = () => {
        setShowModal(true);
    };

    const onCancel = () => {
        setShowModal(false);
    };

    return (
        <div style={{ padding: '0 12px' }}>
            {showDeleteModal.show && (
                <UserDeleteModal modalProps={showDeleteModal} onDelete={onDeleteUser} onClose={onCloseDeleteModal} />
            )}
            <Button type={'text'} icon={<PlusOutlined rev={undefined} />} onClick={onCreateUser}>
                Создать пользователя
            </Button>
            <Table dataSource={users} columns={columns} size={'small'} />
            {showModal && <UserAddModal onCancel={onCancel} showModal={showModal} createUser={createUser} />}
        </div>
    );
};
