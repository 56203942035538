import { configureStore } from '@reduxjs/toolkit'
import generalReducer from './general/generalSlice'
import nodesReducer from './nodes/nodesSlice'
import settingsReducer from './settings/settingsSlice'
import { useDispatch } from 'react-redux'
import schemesReducer from './schemes/schemesSlice'
import systemsReducer from './systems/systemsSlice'
import parametersReducer from './parameters/parametersSlice'
import equipmentReducer from './equipments/equipmentsSlice'
import hazopEntriesReducer from './hazopEntries/hazopEntriesSlice'
import riskMatrixReducer from './riskMatrix/riskMatrixSlice'
import lopaEntriesReducer from './lopaEntries/lopaEntriesSlice'
import causeProbabilitiesReducer from './causeProbabilities/causeProbabilitiesSlice'

export const store = configureStore({
	reducer: {
		general: generalReducer,
		nodes: nodesReducer,
		settings: settingsReducer,
		schemes: schemesReducer,
		systems: systemsReducer,
		parameters: parametersReducer,
		equipments: equipmentReducer,
		hazopEntries: hazopEntriesReducer,
		riskMatrix: riskMatrixReducer,
		lopaEntries: lopaEntriesReducer,
		causeProbabilities: causeProbabilitiesReducer,
	},
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
