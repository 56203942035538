import React from 'react'
import classNames from 'classnames'
import styles from '../NewHazopForm.module.css'
import DebounceTextArea from '../../../UI/Inputs/DebounceTextArea/DebounceTextArea'
import { Button, message } from 'antd'
import {
	CopyOutlined,
	DeleteOutlined,
	ExpandAltOutlined,
	PlusOutlined,
	ShrinkOutlined,
} from '@ant-design/icons'
import {
	ConsequenceDtoRequestRiskTypeEnum,
	MatrixValueDto,
	SafeguardDtoRequestSafeguardTypeEnum,
} from '../../../../generated/backend'
import { TypeOfHazopTableState } from '../../../types'
import { TableModal } from '../../../UI/Modals/TableModal/TableModal'
import { addMatrixValue } from '../../../../redux/hazopEntries/hazopEntries.actions'
import { useAppDispatch } from '../../../../redux/store'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { useModalActions } from '../../../../hooks/useModalActions'
import CopyListAttributesModal from '../../../UI/Modals/CopyListAttributesModal/CopyListAttributesModal'

interface MatrixValue extends MatrixValueDto {
	type: string
}

type ConsequencesFormProps = {
	placeholder: string
	risk: { title: string; id: ConsequenceDtoRequestRiskTypeEnum; matrixValue: MatrixValue }[]
	filterEntity: (
		entityType: 'causes' | 'consequences' | 'safeguards' | 'recommendations',
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => TypeOfHazopTableState[]
	saveItem: (
		entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
		item: TypeOfHazopTableState,
		hazopEntryId: string,
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => Promise<void>
	addItem: (
		entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
		riskType?: ConsequenceDtoRequestRiskTypeEnum,
		safeguardType?: SafeguardDtoRequestSafeguardTypeEnum
	) => void
	deleteItem: (typeOfEntity: string, id: string) => void
	mainForm: string
	onChangeView: (selectedForm: string, formType: string) => void
}

const colors = {
	1: 'spanGreen',
	2: 'spanYellow',
	3: 'spanRed',
	4: 'spanGrey',
}

const ConsequencesForm: React.FC<ConsequencesFormProps> = ({
	placeholder,
	risk,
	filterEntity,
	saveItem,
	addItem,
	deleteItem,
	mainForm,
	onChangeView,
}) => {
	const dispatch = useAppDispatch()
	const hazopEntry = useTypedSelector(state => state.hazopEntries.item)

	const [showModal, setShowModal] = React.useState<{ show: boolean; type: string }>({
		show: false,
		type: '',
	})
	const [showCopyModal, onOpenCopyModal, onCloseCopyModal] = useModalActions()

	const onCancel = () => {
		setShowModal({ show: false, type: '' })
	}

	const onOpenModal = (type: string) => {
		setShowModal({ show: true, type })
	}

	const onSaveMatrixValue = async (typeOfMatrixValue: string, value: string) => {
		const hazopEntryRequest = {
			...hazopEntry,
			nodeId: hazopEntry.node?.id,
			nodeParameter: hazopEntry.nodeParameter?.id,
			nodeGuideword: hazopEntry.nodeGuideword?.id,
			humanMatrixValue: hazopEntry.humanMatrixValue?.id,
			financeMatrixValue: hazopEntry.financeMatrixValue?.id,
			ecologyMatrixValue: hazopEntry.ecologyMatrixValue?.id,
			reputationMatrixValue: hazopEntry.reputationMatrixValue?.id,
			equipmentId: hazopEntry.equipment?.id,
			[typeOfMatrixValue]: value,
		}

		delete hazopEntryRequest.node
		delete hazopEntryRequest.causes
		delete hazopEntryRequest.consequences
		delete hazopEntryRequest.safeguards
		delete hazopEntryRequest.recommendations
		delete hazopEntryRequest.lopaRiskTotalDtos
		delete hazopEntryRequest.equipment

		const response = await dispatch(addMatrixValue(hazopEntryRequest))
		if (response.meta.requestStatus === 'fulfilled') {
			message.success('Значение сохранено!')
		} else {
			message.error('Не удалось сохранить значение')
		}
	}

	return (
		<>
			<TableModal
				showModal={showModal}
				onCancel={onCancel}
				addMatrixValue={onSaveMatrixValue}
			/>
			<CopyListAttributesModal
				show={showCopyModal}
				onCancel={onCloseCopyModal}
				typeOfEntity={'consequences'}
			/>
			<div className={classNames(styles.title)}>
				Последствия
				<div className={styles.actionButtons}>
					<Button
						type={'text'}
						icon={<CopyOutlined rev={undefined} />}
						onClick={onOpenCopyModal}
					/>
					<Button
						type={'text'}
						icon={
							mainForm ? (
								<ShrinkOutlined rev={undefined} style={{ fontSize: 20 }} />
							) : (
								<ExpandAltOutlined rev={undefined} />
							)
						}
						onClick={() =>
							onChangeView(
								mainForm === 'consequences' ? '' : 'consequences',
								'consequences'
							)
						}
					/>
				</div>
			</div>
			<div className={classNames(styles.table_3col)}>
				<div className={classNames(styles.cellHead)}>Последствия</div>
				<div className={classNames(styles.cellHead)}>Категория ущерба</div>
				<div className={classNames(styles.cellHead)}>Категория риска</div>
				{risk.map((riskType, idx) => (
					<div key={riskType.title} className={classNames(styles.cellList)}>
						<div>
							{filterEntity('consequences', riskType.id).map((consequence, index) => (
								<div
									key={consequence.id ?? index}
									className={classNames(styles.cell, styles.cellPad)}
								>
									<div className={styles.cellWithNumber}>
										{consequence.number ?? index + 1}.
									</div>
									<DebounceTextArea
										placeholder={placeholder}
										entityType={'consequences'}
										variant={'borderless'}
										initialEntity={consequence}
										saveEntity={saveItem}
										type={riskType.id}
										delay={500}
										onCreate={addItem}
										autoSize={{ minRows: mainForm ? 2 : 1 }}
										id={`consequence-${index}-${riskType.id}`}
									/>
									{consequence.name ? (
										<div className={styles.button}>
											<Button
												size={'small'}
												type={'text'}
												icon={
													<PlusOutlined
														rev={undefined}
														style={{
															fontSize: mainForm ? '20px' : null,
														}}
													/>
												}
												onClick={() => addItem('consequences', riskType.id)}
												name={`consequence-add-${index}-${riskType.id}`}
											/>
											<Button
												size={'small'}
												type={'text'}
												icon={
													<DeleteOutlined
														rev={undefined}
														style={{
															fontSize: mainForm ? '20px' : null,
														}}
													/>
												}
												onClick={() =>
													deleteItem('consequences', consequence.id)
												}
											/>
										</div>
									) : null}
								</div>
							))}
						</div>
						<div className={classNames(styles.cell, styles.cellPad)}>
							{riskType.title}
						</div>
						<div
							onClick={() => onOpenModal(riskType.matrixValue.type)}
							className={classNames(styles.cell, styles.cellPad)}
						>
							<span
								className={classNames(
									riskType.matrixValue.title
										? styles[colors[riskType.matrixValue.dangerValue?.id]]
										: styles.spanWithoutColor
								)}
							>
								{riskType.matrixValue.title ?? '?'}
							</span>
						</div>
					</div>
				))}
			</div>
		</>
	)
}

export default ConsequencesForm
