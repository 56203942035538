import { PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import {
	CauseDto,
	ConsequenceDto,
	ConsequenceDtoRequest,
	ConsequenceDtoRequestRiskTypeEnum,
	EquipmentDto,
	HazopEntryDto,
	RecommendationDto,
	RecommendationDtoRequest,
	SafeguardDto,
	SafeguardDtoRequest,
	SafeguardDtoRequestSafeguardTypeEnum,
} from '../../../generated/backend'
import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
	addCause,
	addConsequence,
	addRecommendation,
	addSafeGuard,
	editCause,
	editConsequence,
	editRecommendation,
	editSafeGuard,
	fetchHazopEntries,
	fetchHazopEntryById,
	removeCause,
	removeConsequence,
	removeRecommendations,
	removeSafeguards,
} from '../../../redux/hazopEntries/hazopEntries.actions'
import { useAppDispatch } from '../../../redux/store'
import { isChanged } from '../../../utils/HazopTableUtils'
import { wait } from '../../../utils/wait'
import { Loader } from '../../UI/Loaders/Loader/Loader'
import { TypeOfHazopTableState } from '../../types'
import CausesForm from './CausesForm/CausesForm'
import ConsequencesForm from './ConsequencesForm/ConsequencesForm'
import styles from './NewHazopForm.module.css'
import RecommendationsForm from './RecommendationsForm/RecommendationsForm'
import SafeguardsForm from './SafeguardsForm/SafeguardsForm'

const lopaTotalTitles = {
	HUMAN: 'Люди',
	FINANCE: 'Финансы',
	ECOLOGY: 'Экология',
}

const deleteFunctions = {
	causes: removeCause,
	consequences: removeConsequence,
	recommendations: removeRecommendations,
	safeguards: removeSafeguards,
}

const placeholder = 'Начните набирать текст здесь...'

const NewHazopForm: React.FC = () => {
	const { id, hazopEntryId } = useParams()
	const { editHazopEntry } = useActions()
	const dispatch = useAppDispatch()

	const hazopEntry = useTypedSelector(state => state.hazopEntries.item)
	const { hazopType, preLopaCalculation, equipmentEnabled } = useTypedSelector(
		state => state.settings.mainSettings
	)

	const causesRef = React.useRef<HTMLDivElement>(null)
	const consequencesRef = React.useRef<HTMLDivElement>(null)
	const safeguardsRef = React.useRef<HTMLDivElement>(null)
	const recommendationsRef = React.useRef<HTMLDivElement>(null)
	const equipmentRef = React.useRef<EquipmentDto[]>(null)

	const [mainForm, setMainForm] = useLocalStorage<string>('mainForm', '')
	const [messageApi, contextHolder] = message.useMessage()
	const [initialLoading, setInitialLoading] = React.useState(true)

	React.useEffect(() => {
		dispatch(fetchHazopEntryById(hazopEntryId)).then(response => {
			equipmentRef.current = (response.payload as HazopEntryDto).node.equipment
			setInitialLoading(false)
		})
	}, [hazopEntryId])

	React.useEffect(() => {
		dispatch(fetchHazopEntries(id))
	}, [])

	const saveItem = React.useCallback(
		async (
			entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
			item: TypeOfHazopTableState,
			hazopEntryId: string,
			type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
		) => {
			if (!isChanged(item, hazopEntry, entityType)) {
				messageApi.warning('Нельзя сохранить существующую запись')
				return
			}

			const copyItem = { ...item }
			copyItem.name = item.name.replace(/\n{2,}/g, '\n')
			delete copyItem.number

			let response: PayloadAction<
				unknown,
				string,
				{ requestStatus: 'fulfilled' | 'rejected' }
			>

			switch (entityType) {
				case 'causes':
					delete (copyItem as CauseDto).hazopEntry

					if (Object.hasOwn(copyItem, 'id')) {
						response = await dispatch(editCause({ id: hazopEntryId, cause: copyItem }))
					} else {
						response = await dispatch(
							addCause({
								id: hazopEntryId,
								name: copyItem.name,
								causeProbability: (copyItem as CauseDto).causeProbability,
							})
						)
					}

					break
				case 'consequences':
					delete (copyItem as ConsequenceDto).hazopEntry

					if (Object.hasOwn(copyItem, 'id')) {
						response = await dispatch(
							editConsequence({
								id: hazopEntryId,
								consequence: copyItem as ConsequenceDtoRequest,
								type: type as ConsequenceDtoRequestRiskTypeEnum,
							})
						)
					} else {
						response = await dispatch(
							addConsequence({
								id: hazopEntryId,
								name: copyItem.name,
								type: type as ConsequenceDtoRequestRiskTypeEnum,
							})
						)
					}

					break
				case 'safeguards':
					delete (copyItem as SafeguardDto).safeguardType
					delete (copyItem as SafeguardDto).hazopEntry

					if (Object.hasOwn(copyItem, 'id')) {
						response = await dispatch(
							editSafeGuard({
								id: hazopEntryId,
								safeGuard: copyItem as SafeguardDtoRequest,
								type:
									hazopType === 'EIGHT'
										? (type as SafeguardDtoRequestSafeguardTypeEnum)
										: null,
							})
						)
					} else {
						response = await dispatch(
							addSafeGuard({
								id: hazopEntryId,
								name: copyItem.name,
								type:
									hazopType === 'EIGHT'
										? (type as SafeguardDtoRequestSafeguardTypeEnum)
										: null,
								enable:
									hazopType === 'EIGHT'
										? (copyItem as SafeguardDto).enable
										: false,
							})
						)
					}

					break
				case 'recommendations':
					delete (copyItem as RecommendationDto).safeguardType
					delete (copyItem as RecommendationDto).criticality
					delete (copyItem as RecommendationDto).hazopEntry

					if (Object.hasOwn(copyItem, 'id')) {
						response = await dispatch(
							editRecommendation({
								id: hazopEntryId,
								recommendation: copyItem as RecommendationDtoRequest,
								criticality: (item as RecommendationDto).criticality?.id,
							})
						)
					} else {
						response = await dispatch(
							addRecommendation({
								id: hazopEntryId,
								name: copyItem.name,
								criticality: (item as RecommendationDto).criticality?.id,
							})
						)
					}

					break
			}

			if (response.meta.requestStatus === 'fulfilled') {
				messageApi.success('Сохранено!')
				preLopaCalculation && entityType !== 'recommendations'
					? await dispatch(fetchHazopEntryById(hazopEntryId))
					: null
			} else messageApi.error('Не удалось выполнить сохранение')
		},
		[hazopEntry, hazopType]
	)

	const addItem = (
		typeOfEntity: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
		riskType?: ConsequenceDtoRequestRiskTypeEnum,
		safeguardType?: SafeguardDtoRequestSafeguardTypeEnum
	) => {
		const itemWithoutId = riskType
			? hazopEntry[typeOfEntity as 'consequences'].find(
					item => !Object.hasOwn(item, 'id') && item.riskType?.id === riskType
			  )
			: safeguardType
			? hazopEntry[typeOfEntity as 'safeguards'].find(
					item => !Object.hasOwn(item, 'id') && item.safeguardType?.id === safeguardType
			  )
			: hazopEntry[typeOfEntity].find(item => !Object.hasOwn(item, 'id'))

		if (itemWithoutId) return

		const newItem = {
			name: '',
			hazopEntry: { id: hazopEntryId },
		}

		riskType ? ((newItem as ConsequenceDto).riskType = { id: riskType }) : null
		typeOfEntity === 'causes' ? ((newItem as CauseDto).causeProbability = null) : null
		if (safeguardType) {
			;(newItem as SafeguardDto).safeguardType = { id: safeguardType }
			;(newItem as SafeguardDto).enable = false
		}

		editHazopEntry({
			id: hazopEntryId,
			field: typeOfEntity,
			value: hazopEntry[typeOfEntity].concat(newItem),
		})
	}

	const deleteItem = async (typeOfEntity: string, id: string) => {
		const response = await dispatch(deleteFunctions[typeOfEntity]({ id, hazopEntryId }))

		if (response.meta.requestStatus === 'fulfilled') {
			messageApi.success('Успешно удалено!')
		} else messageApi.error('Что-то пошло не так!')

		await dispatch(fetchHazopEntryById(hazopEntryId))
	}

	const filterEntity = React.useCallback(
		(
			entityType: 'causes' | 'consequences' | 'safeguards' | 'recommendations',
			type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
		) => {
			if (entityType === 'consequences') {
				const filtered = hazopEntry[entityType].filter(item => item.riskType?.id === type)

				return filtered.length > 0
					? filtered.sort((a, b) => a.number - b.number)
					: [
							{
								hazopEntry: { id: hazopEntryId },
								riskType: { id: type as ConsequenceDtoRequestRiskTypeEnum },
							},
					  ]
			}
			if (entityType === 'safeguards' && type) {
				const filtered = hazopEntry[entityType].filter(
					item => item.safeguardType?.id === type
				)

				return filtered.length > 0
					? filtered.sort((a, b) => a.number - b.number)
					: [
							{
								hazopEntry: { id: hazopEntryId },
								safeguardType: { id: type as SafeguardDtoRequestSafeguardTypeEnum },
								enable: false,
							},
					  ]
			}

			return hazopEntry[entityType].length > 0
				? [...hazopEntry[entityType]].sort((a, b) => a.number - b.number)
				: [{ hazopEntry: { id: hazopEntryId } }]
		},
		[hazopEntry]
	)

	const risk = [
		{
			title: 'Люди',
			id: ConsequenceDtoRequestRiskTypeEnum.Human,
			matrixValue: { ...hazopEntry?.humanMatrixValue, type: 'humanMatrixValue' },
		},
		{
			title: 'Финансы',
			id: ConsequenceDtoRequestRiskTypeEnum.Finance,
			matrixValue: { ...hazopEntry?.financeMatrixValue, type: 'financeMatrixValue' },
		},
		{
			title: 'Экология',
			id: ConsequenceDtoRequestRiskTypeEnum.Ecology,
			matrixValue: { ...hazopEntry?.ecologyMatrixValue, type: 'ecologyMatrixValue' },
		},
		{
			title: 'Репутация',
			id: ConsequenceDtoRequestRiskTypeEnum.Reputation,
			matrixValue: { ...hazopEntry?.reputationMatrixValue, type: 'reputationMatrixValue' },
		},
	]

	const onChangeView = (selectedForm: string, formType: string) => {
		setMainForm(selectedForm)

		if (!selectedForm) {
			wait(500).then(() => {
				switch (formType) {
					case 'causes':
						causesRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
						break
					case 'consequences':
						consequencesRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
						})
						break
					case 'safeguards':
						safeguardsRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
						})
						break
					case 'recommendations':
						recommendationsRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
						})
						break
				}
			})
		}
	}

	if (initialLoading) return <Loader />

	return (
		<div className={classNames(!mainForm ? styles.content : styles.zoomContent)}>
			{contextHolder}
			{!mainForm ? (
				<>
					<div
						className={classNames(
							styles.block,
							preLopaCalculation ? styles.blockTop : styles.blockTopWithoutLopa
						)}
					>
						<div>
							<div className={styles.headItem}>Параметр</div>
							<div className={styles.item}>{hazopEntry.nodeParameter?.name}</div>
						</div>
						<div>
							<div className={styles.headItem}>Управляющее слово</div>
							<div className={styles.item}>{hazopEntry.nodeGuideword?.name}</div>
						</div>
						<div>
							<div className={styles.headItem}>Оборудование</div>
							<ol className={styles.listItem}>
								{equipmentRef.current?.map((equipment, index) => (
									<li key={equipment.id}>
										{++index}. {equipment.name}
									</li>
								))}
							</ol>
						</div>
						<div>
							<div className={styles.headItem}>Схемы</div>
							<ol className={styles.listItem}>
								{hazopEntry.node.schemesStr?.map((scheme, index) => {
									return (
										<li key={scheme.id}>
											{++index}. {scheme.name}
										</li>
									)
								})}
							</ol>
						</div>
						{preLopaCalculation ? (
							<div>
								<div className={styles.headItem}>Расчет достаточности</div>
								<ol className={styles.listItem}>
									{hazopEntry?.lopaRiskTotalDtos?.map(risk => {
										return (
											<li key={risk.id}>
												{lopaTotalTitles[risk.riskType]}:{' '}
												{risk.totalProbabilityOfIntermediateEvent?.toExponential(
													2
												)}
											</li>
										)
									})}
								</ol>
							</div>
						) : null}
						{equipmentEnabled ? (
							<div>
								<div className={styles.headItem}>Компонент</div>
								<div className={styles.item}>{hazopEntry.equipment?.name}</div>
							</div>
						) : null}
					</div>
					<div className={classNames(styles.blockWrap)}>
						<div ref={causesRef} className={classNames(styles.block)}>
							<CausesForm
								placeholder={placeholder}
								filterEntity={filterEntity}
								saveItem={saveItem}
								addItem={addItem}
								deleteItem={deleteItem}
								mainForm={mainForm}
								onChangeView={onChangeView}
							/>
						</div>
						<div ref={consequencesRef} className={classNames(styles.block)}>
							<ConsequencesForm
								placeholder={placeholder}
								risk={risk}
								filterEntity={filterEntity}
								saveItem={saveItem}
								addItem={addItem}
								deleteItem={deleteItem}
								mainForm={mainForm}
								onChangeView={onChangeView}
							/>
						</div>
					</div>
					<div className={classNames(styles.blockWrap)}>
						<div ref={safeguardsRef} className={classNames(styles.block)}>
							<SafeguardsForm
								hazopType={hazopType}
								placeholder={placeholder}
								filterEntity={filterEntity}
								saveItem={saveItem}
								addItem={addItem}
								deleteItem={deleteItem}
								mainForm={mainForm}
								onChangeView={onChangeView}
							/>
						</div>
						<div ref={recommendationsRef} className={classNames(styles.block)}>
							<RecommendationsForm
								placeholder={placeholder}
								filterEntity={filterEntity}
								saveItem={saveItem}
								addItem={addItem}
								deleteItem={deleteItem}
								mainForm={mainForm}
								onChangeView={onChangeView}
							/>
						</div>
					</div>
				</>
			) : mainForm === 'causes' ? (
				<div className={classNames(styles.blockZoom)}>
					<CausesForm
						placeholder={placeholder}
						filterEntity={filterEntity}
						saveItem={saveItem}
						addItem={addItem}
						deleteItem={deleteItem}
						mainForm={mainForm}
						onChangeView={onChangeView}
					/>
				</div>
			) : mainForm === 'consequences' ? (
				<div className={classNames(styles.blockZoom)}>
					<ConsequencesForm
						placeholder={placeholder}
						risk={risk}
						filterEntity={filterEntity}
						saveItem={saveItem}
						addItem={addItem}
						deleteItem={deleteItem}
						mainForm={mainForm}
						onChangeView={onChangeView}
					/>
				</div>
			) : mainForm === 'safeguards' ? (
				<div className={classNames(styles.blockZoom)}>
					<SafeguardsForm
						hazopType={hazopType}
						placeholder={placeholder}
						filterEntity={filterEntity}
						saveItem={saveItem}
						addItem={addItem}
						deleteItem={deleteItem}
						mainForm={mainForm}
						onChangeView={onChangeView}
					/>
				</div>
			) : (
				<div className={classNames(styles.blockZoom)}>
					<RecommendationsForm
						placeholder={placeholder}
						filterEntity={filterEntity}
						saveItem={saveItem}
						addItem={addItem}
						deleteItem={deleteItem}
						mainForm={mainForm}
						onChangeView={onChangeView}
					/>
				</div>
			)}
		</div>
	)
}

export default NewHazopForm
