import { Button, Space, Tree } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchNodes } from '../../../redux/nodes/nodes.actions'
import { RootState, useAppDispatch } from '../../../redux/store'
import styles from './HazopLayout.module.css'
import classNames from 'classnames'
import { Loader } from '../../UI/Loaders/Loader/Loader'

type HazopLayoutProps = {}

const headings = ['Наименование', 'Код', 'Оборудование', 'Риск', 'Действия']

export const HazopLayout: React.FC<HazopLayoutProps> = ({}) => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { nodes, isLoading } = useSelector((state: RootState) => state.nodes)

	React.useEffect(() => {
		dispatch(fetchNodes())
	}, [])

	if (isLoading) return <Loader />

	return (
		<div className={styles.container}>
			<div className={styles.table}>
				{headings.map((heading, index) => (
					<div key={index} className={classNames(styles.cell, styles.columnHeading)}>
						{heading}
					</div>
				))}
				{nodes?.map(node => (
					<React.Fragment key={node.id}>
						<div className={classNames(styles.cell)}>{node.name}</div>
						<div className={classNames(styles.cell)}>{node.code}</div>
						<div className={classNames(styles.cell)}>
							<ul className={styles.list}>
								{node?.equipment?.map(item => (
									<li key={item.id}>{item.name}</li>
								))}
							</ul>
						</div>
						<div className={classNames(styles.cell)}>Риск</div>
						<div className={classNames(styles.cell)}>
							<Space>
								{/* <Button type={'link'} onClick={() => navigate(`/hazop/${node.id}`)}>
									Таблица
								</Button> */}
								<Button
									type={'link'}
									name='list'
									onClick={() => navigate(`/hazop/list/${node.id}`)}
								>
									Список
								</Button>
							</Space>
						</div>
					</React.Fragment>
				))}
			</div>
		</div>
	)
}
