import React from 'react'
import PdfFilesTable from './PdfFilesTable'

type PdfFilesLayoutProps = {}

const PdfFilesLayout: React.FC<PdfFilesLayoutProps> = ({}) => {
	return (
		<>
			<PdfFilesTable />
		</>
	)
}

export default PdfFilesLayout
