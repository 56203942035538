import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { backupApi, settingsApi } from '../../api/apis'
import { BackupDataDto, LopaSettingsDto } from '../../generated/backend'

export const fetchSettings = createAsyncThunk(
	'settings/fetchSettings',
	async (_, { rejectWithValue }) => {
		try {
			const hazopType = await settingsApi.apiV1SettingsHazopTypeGet()
			const projectName = await settingsApi.apiV1SettingsProjectNameGet()
			const projectStep = await settingsApi.apiV1SettingsHazopProjectStepGet()
			const lopaSettings = await settingsApi.apiV1SettingsLopaSettingsGet()
			const preLopaCalculation = await settingsApi.apiV1SettingsPreLopaCalculationGet()
			const equipmentEnabled = await settingsApi.apiV1SettingsComponentEnableGet()
			const lopaEnable = await settingsApi.apiV1SettingsLopaEnableGet()
			const backupSettings = await backupApi.apiV1BackupStatusPost()

			return {
				mainSettings: {
					hazopType: hazopType.data,
					projectName: projectName.data,
					projectStep: projectStep.data,
					preLopaCalculation: preLopaCalculation.data,
					equipmentEnabled: equipmentEnabled.data,
				},
				lopaSettings: lopaSettings.data,
				lopaEnable: lopaEnable.data,
				backupSettings: backupSettings.data,
			}
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

export const saveSettings = createAsyncThunk(
	'settings/saveSettings',
	async (
		payload: {
			hazopType: string
			projectName: string
			projectStep: string
			preLopaCalculation: boolean
			equipmentEnabled: boolean
			lopaSettings: LopaSettingsDto
			lopaEnable: boolean
			backupSettings: BackupDataDto
		},
		{ rejectWithValue }
	) => {
		try {
			await settingsApi.apiV1SettingsHazopTypePost(payload.hazopType)
			await settingsApi.apiV1SettingsProjectNamePost(payload.projectName)
			await settingsApi.apiV1SettingsHazopProjectStepPost(payload.projectStep)
			await settingsApi.apiV1SettingsLopaSettingsPost(payload.lopaSettings)
			await settingsApi.apiV1SettingsPreLopaCalculationPost(payload.preLopaCalculation)
			await settingsApi.apiV1SettingsComponentEnablePost(payload.equipmentEnabled)
			await settingsApi.apiV1SettingsLopaEnablePost(payload.lopaEnable)

			let backupResponse: AxiosResponse<BackupDataDto>

			if (payload.backupSettings.status) {
				console.log(payload.backupSettings.intervalMinutes)
				backupResponse = await backupApi.apiV1BackupStartPost(
					payload.backupSettings.intervalMinutes
				)
			} else {
				backupResponse = await backupApi.apiV1BackupStopPost()
			}

			return { ...payload, backupSettings: backupResponse.data }
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)
