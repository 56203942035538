/* tslint:disable */
/* eslint-disable */
/**
 * hazop API
 * hazop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiV1CauseProbabilityIdGet200Response
 */
export interface ApiV1CauseProbabilityIdGet200Response {
    /**
     * 
     * @type {string}
     * @memberof ApiV1CauseProbabilityIdGet200Response
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1CauseProbabilityIdGet200Response
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiV1CauseProbabilityIdGet200Response
     */
    'probability'?: number;
}
/**
 * 
 * @export
 * @interface AssetSystemDto
 */
export interface AssetSystemDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSystemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSystemDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSystemDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AssetSystemDtoRequest
 */
export interface AssetSystemDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetSystemDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSystemDtoRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSystemDtoRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    'token'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthResponse
     */
    'roles'?: Array<string>;
}
/**
 * 
 * @export
 * @interface BackupDataDto
 */
export interface BackupDataDto {
    /**
     * 
     * @type {string}
     * @memberof BackupDataDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BackupDataDto
     */
    'status'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BackupDataDto
     */
    'intervalMinutes'?: number;
}
/**
 * 
 * @export
 * @interface CauseDto
 */
export interface CauseDto {
    /**
     * 
     * @type {string}
     * @memberof CauseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CauseDto
     */
    'name'?: string;
    /**
     * 
     * @type {HazopEntryDto}
     * @memberof CauseDto
     */
    'hazopEntry'?: HazopEntryDto;
    /**
     * 
     * @type {number}
     * @memberof CauseDto
     */
    'causeProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof CauseDto
     */
    'number'?: number;
}
/**
 * 
 * @export
 * @interface CauseDtoRequest
 */
export interface CauseDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof CauseDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CauseDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CauseDtoRequest
     */
    'hazopEntryId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CauseDtoRequest
     */
    'sort'?: number;
    /**
     * 
     * @type {number}
     * @memberof CauseDtoRequest
     */
    'causeProbability'?: number;
}
/**
 * 
 * @export
 * @interface CauseProbabilityDto
 */
export interface CauseProbabilityDto {
    /**
     * 
     * @type {number}
     * @memberof CauseProbabilityDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CauseProbabilityDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CauseProbabilityDto
     */
    'probability'?: number;
}
/**
 * 
 * @export
 * @interface CauseProbabilityDtoRequest
 */
export interface CauseProbabilityDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof CauseProbabilityDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CauseProbabilityDtoRequest
     */
    'probability'?: number;
}
/**
 * 
 * @export
 * @interface ConnectionDto
 */
export interface ConnectionDto {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'y'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConnectionDto
     */
    'color'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionDto
     */
    'shapesId'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ConnectionDtoRequest
 */
export interface ConnectionDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'y'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConnectionDtoRequest
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface ConsequenceDto
 */
export interface ConsequenceDto {
    /**
     * 
     * @type {string}
     * @memberof ConsequenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsequenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {HazopEntryDto}
     * @memberof ConsequenceDto
     */
    'hazopEntry'?: HazopEntryDto;
    /**
     * 
     * @type {RiskTypeDto}
     * @memberof ConsequenceDto
     */
    'riskType'?: RiskTypeDto;
    /**
     * 
     * @type {number}
     * @memberof ConsequenceDto
     */
    'number'?: number;
}
/**
 * 
 * @export
 * @interface ConsequenceDtoRequest
 */
export interface ConsequenceDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof ConsequenceDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsequenceDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsequenceDtoRequest
     */
    'hazopEntryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsequenceDtoRequest
     */
    'riskType'?: ConsequenceDtoRequestRiskTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ConsequenceDtoRequest
     */
    'sort'?: number;
}

export const ConsequenceDtoRequestRiskTypeEnum = {
    Human: 'HUMAN',
    Finance: 'FINANCE',
    Ecology: 'ECOLOGY',
    Reputation: 'REPUTATION'
} as const;

export type ConsequenceDtoRequestRiskTypeEnum = typeof ConsequenceDtoRequestRiskTypeEnum[keyof typeof ConsequenceDtoRequestRiskTypeEnum];

/**
 * 
 * @export
 * @interface CriticalityDto
 */
export interface CriticalityDto {
    /**
     * 
     * @type {string}
     * @memberof CriticalityDto
     */
    'id'?: CriticalityDtoIdEnum;
    /**
     * 
     * @type {string}
     * @memberof CriticalityDto
     */
    'name'?: string;
}

export const CriticalityDtoIdEnum = {
    Low: 'LOW',
    Medium: 'MEDIUM',
    High: 'HIGH'
} as const;

export type CriticalityDtoIdEnum = typeof CriticalityDtoIdEnum[keyof typeof CriticalityDtoIdEnum];

/**
 * 
 * @export
 * @interface EquipmentDto
 */
export interface EquipmentDto {
    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {EquipmentDto}
     * @memberof EquipmentDto
     */
    'parent'?: EquipmentDto;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface EquipmentDtoRequest
 */
export interface EquipmentDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDtoRequest
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDtoRequest
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'md5'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'filename'?: string;
}
/**
 * 
 * @export
 * @interface HazopEntryDto
 */
export interface HazopEntryDto {
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof HazopEntryDto
     */
    'number'?: number;
    /**
     * 
     * @type {NodeDto}
     * @memberof HazopEntryDto
     */
    'node'?: NodeDto;
    /**
     * 
     * @type {NodeParameterDto}
     * @memberof HazopEntryDto
     */
    'nodeParameter'?: NodeParameterDto;
    /**
     * 
     * @type {EquipmentDto}
     * @memberof HazopEntryDto
     */
    'equipment'?: EquipmentDto;
    /**
     * 
     * @type {NodeGuidewordDto}
     * @memberof HazopEntryDto
     */
    'nodeGuideword'?: NodeGuidewordDto;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof HazopEntryDto
     */
    'humanMatrixValue'?: MatrixValueDto;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof HazopEntryDto
     */
    'financeMatrixValue'?: MatrixValueDto;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof HazopEntryDto
     */
    'ecologyMatrixValue'?: MatrixValueDto;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof HazopEntryDto
     */
    'reputationMatrixValue'?: MatrixValueDto;
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDto
     */
    'fulfillment'?: string;
    /**
     * 
     * @type {Array<CauseDto>}
     * @memberof HazopEntryDto
     */
    'causes'?: Array<CauseDto>;
    /**
     * 
     * @type {Array<ConsequenceDto>}
     * @memberof HazopEntryDto
     */
    'consequences'?: Array<ConsequenceDto>;
    /**
     * 
     * @type {Array<SafeguardDto>}
     * @memberof HazopEntryDto
     */
    'safeguards'?: Array<SafeguardDto>;
    /**
     * 
     * @type {Array<RecommendationDto>}
     * @memberof HazopEntryDto
     */
    'recommendations'?: Array<RecommendationDto>;
    /**
     * 
     * @type {boolean}
     * @memberof HazopEntryDto
     */
    'noRecommendation'?: boolean;
    /**
     * 
     * @type {Array<LopaRiskTotalDto>}
     * @memberof HazopEntryDto
     */
    'lopaRiskTotalDtos'?: Array<LopaRiskTotalDto>;
}
/**
 * 
 * @export
 * @interface HazopEntryDtoRequest
 */
export interface HazopEntryDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof HazopEntryDtoRequest
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDtoRequest
     */
    'nodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDtoRequest
     */
    'nodeParameter'?: string;
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDtoRequest
     */
    'equipmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDtoRequest
     */
    'nodeGuideword'?: string;
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDtoRequest
     */
    'humanMatrixValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDtoRequest
     */
    'financeMatrixValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDtoRequest
     */
    'ecologyMatrixValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDtoRequest
     */
    'reputationMatrixValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof HazopEntryDtoRequest
     */
    'fulfillment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HazopEntryDtoRequest
     */
    'noRecommendation'?: boolean;
}
/**
 * 
 * @export
 * @interface ImageFileDto
 */
export interface ImageFileDto {
    /**
     * 
     * @type {string}
     * @memberof ImageFileDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageFileDto
     */
    'parentPdfId'?: string;
    /**
     * 
     * @type {PdfFileDto}
     * @memberof ImageFileDto
     */
    'parentPdf'?: PdfFileDto;
    /**
     * 
     * @type {FileDto}
     * @memberof ImageFileDto
     */
    'file'?: FileDto;
    /**
     * 
     * @type {number}
     * @memberof ImageFileDto
     */
    'dpi'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageFileDto
     */
    'imageNumber'?: number;
}
/**
 * 
 * @export
 * @interface ImportReportDto
 */
export interface ImportReportDto {
    /**
     * 
     * @type {string}
     * @memberof ImportReportDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportReportDto
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportReportDto
     */
    'consequences'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportReportDto
     */
    'type'?: ImportReportDtoTypeEnum;
}

export const ImportReportDtoTypeEnum = {
    Warning: 'WARNING',
    Error: 'ERROR'
} as const;

export type ImportReportDtoTypeEnum = typeof ImportReportDtoTypeEnum[keyof typeof ImportReportDtoTypeEnum];

/**
 * 
 * @export
 * @interface LopaEntryDto
 */
export interface LopaEntryDto {
    /**
     * 
     * @type {string}
     * @memberof LopaEntryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LopaEntryDto
     */
    'lopaRiskTotalId'?: string;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof LopaEntryDto
     */
    'riskCategory'?: MatrixValueDto;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'tmel'?: number;
    /**
     * 
     * @type {CauseDto}
     * @memberof LopaEntryDto
     */
    'cause'?: CauseDto;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDto
     */
    'enableDesign'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'design'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDto
     */
    'enableControlSystem'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'controlSystem'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDto
     */
    'enableIndependentSignaling'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'independentSignaling'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDto
     */
    'enableReliefValves'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'reliefValves'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDto
     */
    'enableSpaz'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'spaz'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDto
     */
    'enableOperatorActions'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'operatorActions'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDto
     */
    'enableOther1'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'other1'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDto
     */
    'enableOther2'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'other2'?: number;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'riskDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'busyStaff'?: number;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'fireRisk'?: number;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'vulnerabilityFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDto
     */
    'intermediateEventProbability'?: number;
}
/**
 * 
 * @export
 * @interface LopaEntryDtoRequest
 */
export interface LopaEntryDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof LopaEntryDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LopaEntryDtoRequest
     */
    'riskCategoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LopaEntryDtoRequest
     */
    'lopaRiskTotalId'?: string;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'tmel'?: number;
    /**
     * 
     * @type {string}
     * @memberof LopaEntryDtoRequest
     */
    'causeId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDtoRequest
     */
    'enableDesign'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'design'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDtoRequest
     */
    'enableControlSystem'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'controlSystem'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDtoRequest
     */
    'enableIndependentSignaling'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'independentSignaling'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDtoRequest
     */
    'enableReliefValves'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'reliefValves'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDtoRequest
     */
    'enableSpaz'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'spaz'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDtoRequest
     */
    'enableOperatorActions'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'operatorActions'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDtoRequest
     */
    'enableOther1'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'other1'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LopaEntryDtoRequest
     */
    'enableOther2'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'other2'?: number;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'riskDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'busyStaff'?: number;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'fireRisk'?: number;
    /**
     * 
     * @type {number}
     * @memberof LopaEntryDtoRequest
     */
    'vulnerabilityFactor'?: number;
}
/**
 * 
 * @export
 * @interface LopaRiskTotalDto
 */
export interface LopaRiskTotalDto {
    /**
     * 
     * @type {string}
     * @memberof LopaRiskTotalDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LopaRiskTotalDto
     */
    'riskType'?: LopaRiskTotalDtoRiskTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LopaRiskTotalDto
     */
    'hazopEntry'?: string;
    /**
     * 
     * @type {number}
     * @memberof LopaRiskTotalDto
     */
    'totalProbabilityOfIntermediateEvent'?: number;
    /**
     * 
     * @type {number}
     * @memberof LopaRiskTotalDto
     */
    'pfd'?: number;
    /**
     * 
     * @type {number}
     * @memberof LopaRiskTotalDto
     */
    'rrf'?: number;
    /**
     * 
     * @type {string}
     * @memberof LopaRiskTotalDto
     */
    'sil'?: LopaRiskTotalDtoSilEnum;
    /**
     * 
     * @type {Array<LopaEntryDto>}
     * @memberof LopaRiskTotalDto
     */
    'lopaEntries'?: Array<LopaEntryDto>;
}

export const LopaRiskTotalDtoRiskTypeEnum = {
    Human: 'HUMAN',
    Finance: 'FINANCE',
    Ecology: 'ECOLOGY',
    Reputation: 'REPUTATION'
} as const;

export type LopaRiskTotalDtoRiskTypeEnum = typeof LopaRiskTotalDtoRiskTypeEnum[keyof typeof LopaRiskTotalDtoRiskTypeEnum];
export const LopaRiskTotalDtoSilEnum = {
    Sila: 'SILa',
    Sil1: 'SIL1',
    Sil2: 'SIL2',
    Sil3: 'SIL3',
    Sil4: 'SIL4'
} as const;

export type LopaRiskTotalDtoSilEnum = typeof LopaRiskTotalDtoSilEnum[keyof typeof LopaRiskTotalDtoSilEnum];

/**
 * 
 * @export
 * @interface LopaSettingsDto
 */
export interface LopaSettingsDto {
    /**
     * Проектые защиты
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'design'?: number;
    /**
     * Система управления (РСУ)
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'controlSystem'?: number;
    /**
     * Независимая от РСУ сигнализация
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'independentSignaling'?: number;
    /**
     * Предохранительные клапаны/диафрагма
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'reliefValves'?: number;
    /**
     * Система ПАЗ
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'spaz'?: number;
    /**
     * Особые действия оператора
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'operatorActions'?: number;
    /**
     * Другое 1
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'other1'?: number;
    /**
     * Другие 2
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'other2'?: number;
    /**
     * Продолжительность риска
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'riskDuration'?: number;
    /**
     * Занятость персонала
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'busyStaff'?: number;
    /**
     * Вероятность возгорания
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'fireRisk'?: number;
    /**
     * Коэффициент уязвимости
     * @type {number}
     * @memberof LopaSettingsDto
     */
    'vulnerabilityFactor'?: number;
}
/**
 * 
 * @export
 * @interface MatrixValueColumnDto
 */
export interface MatrixValueColumnDto {
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof MatrixValueColumnDto
     */
    '0'?: MatrixValueDto;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof MatrixValueColumnDto
     */
    '1'?: MatrixValueDto;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof MatrixValueColumnDto
     */
    '2'?: MatrixValueDto;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof MatrixValueColumnDto
     */
    '3'?: MatrixValueDto;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof MatrixValueColumnDto
     */
    '4'?: MatrixValueDto;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof MatrixValueColumnDto
     */
    '5'?: MatrixValueDto;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof MatrixValueColumnDto
     */
    '-2'?: MatrixValueDto;
    /**
     * 
     * @type {MatrixValueDto}
     * @memberof MatrixValueColumnDto
     */
    '-1'?: MatrixValueDto;
}
/**
 * 
 * @export
 * @interface MatrixValueDto
 */
export interface MatrixValueDto {
    /**
     * 
     * @type {string}
     * @memberof MatrixValueDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MatrixValueDto
     */
    'xAxis'?: number;
    /**
     * 
     * @type {number}
     * @memberof MatrixValueDto
     */
    'yAxis'?: number;
    /**
     * 
     * @type {RiskDangerValueDto}
     * @memberof MatrixValueDto
     */
    'dangerValue'?: RiskDangerValueDto;
    /**
     * 
     * @type {string}
     * @memberof MatrixValueDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatrixValueDto
     */
    'dangerColor'?: string;
}
/**
 * 
 * @export
 * @interface MatrixValueDtoRequest
 */
export interface MatrixValueDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof MatrixValueDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MatrixValueDtoRequest
     */
    'xAxis'?: number;
    /**
     * 
     * @type {number}
     * @memberof MatrixValueDtoRequest
     */
    'yAxis'?: number;
    /**
     * 
     * @type {number}
     * @memberof MatrixValueDtoRequest
     */
    'dangerValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof MatrixValueDtoRequest
     */
    'dangerColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatrixValueDtoRequest
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface MatrixValueRowDto
 */
export interface MatrixValueRowDto {
    /**
     * 
     * @type {MatrixValueColumnDto}
     * @memberof MatrixValueRowDto
     */
    '0'?: MatrixValueColumnDto;
    /**
     * 
     * @type {MatrixValueColumnDto}
     * @memberof MatrixValueRowDto
     */
    '1'?: MatrixValueColumnDto;
    /**
     * 
     * @type {MatrixValueColumnDto}
     * @memberof MatrixValueRowDto
     */
    '2'?: MatrixValueColumnDto;
    /**
     * 
     * @type {MatrixValueColumnDto}
     * @memberof MatrixValueRowDto
     */
    '3'?: MatrixValueColumnDto;
    /**
     * 
     * @type {MatrixValueColumnDto}
     * @memberof MatrixValueRowDto
     */
    '4'?: MatrixValueColumnDto;
    /**
     * 
     * @type {MatrixValueColumnDto}
     * @memberof MatrixValueRowDto
     */
    '5'?: MatrixValueColumnDto;
    /**
     * 
     * @type {MatrixValueColumnDto}
     * @memberof MatrixValueRowDto
     */
    '-1'?: MatrixValueColumnDto;
}
/**
 * 
 * @export
 * @interface NodeAdditionalParameterDto
 */
export interface NodeAdditionalParameterDto {
    /**
     * 
     * @type {string}
     * @memberof NodeAdditionalParameterDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeAdditionalParameterDto
     */
    'name'?: string;
    /**
     * 
     * @type {NodeDto}
     * @memberof NodeAdditionalParameterDto
     */
    'nodeId'?: NodeDto;
}
/**
 * 
 * @export
 * @interface NodeAdditionalParameterDtoRequest
 */
export interface NodeAdditionalParameterDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof NodeAdditionalParameterDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeAdditionalParameterDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeAdditionalParameterDtoRequest
     */
    'nodeId'?: string;
}
/**
 * 
 * @export
 * @interface NodeDto
 */
export interface NodeDto {
    /**
     * 
     * @type {string}
     * @memberof NodeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeDto
     */
    'name'?: string;
    /**
     * 
     * @type {AssetSystemDto}
     * @memberof NodeDto
     */
    'system'?: AssetSystemDto;
    /**
     * 
     * @type {Array<SchemeDto>}
     * @memberof NodeDto
     */
    'schemesStr'?: Array<SchemeDto>;
    /**
     * 
     * @type {Array<EquipmentDto>}
     * @memberof NodeDto
     */
    'equipment'?: Array<EquipmentDto>;
}
/**
 * 
 * @export
 * @interface NodeDtoRequest
 */
export interface NodeDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof NodeDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeDtoRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeDtoRequest
     */
    'system'?: string;
}
/**
 * 
 * @export
 * @interface NodeGuidewordDto
 */
export interface NodeGuidewordDto {
    /**
     * 
     * @type {string}
     * @memberof NodeGuidewordDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeGuidewordDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeGuidewordDto
     */
    'nodeParameterId'?: string;
    /**
     * 
     * @type {number}
     * @memberof NodeGuidewordDto
     */
    'sort'?: number;
}
/**
 * 
 * @export
 * @interface NodeGuidewordDtoRequest
 */
export interface NodeGuidewordDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof NodeGuidewordDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeGuidewordDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeGuidewordDtoRequest
     */
    'nodeParameterId'?: string;
}
/**
 * 
 * @export
 * @interface NodeParameterDto
 */
export interface NodeParameterDto {
    /**
     * 
     * @type {string}
     * @memberof NodeParameterDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeParameterDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof NodeParameterDto
     */
    'sort'?: number;
    /**
     * 
     * @type {Array<NodeGuidewordDto>}
     * @memberof NodeParameterDto
     */
    'guidewords'?: Array<NodeGuidewordDto>;
}
/**
 * 
 * @export
 * @interface NodeParameterDtoRequest
 */
export interface NodeParameterDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof NodeParameterDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeParameterDtoRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PdfFileDto
 */
export interface PdfFileDto {
    /**
     * 
     * @type {string}
     * @memberof PdfFileDto
     */
    'id'?: string;
    /**
     * 
     * @type {FileDto}
     * @memberof PdfFileDto
     */
    'file'?: FileDto;
    /**
     * 
     * @type {boolean}
     * @memberof PdfFileDto
     */
    'isSplitting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PdfFileDto
     */
    'splitSuccess'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PdfFileDto
     */
    'pageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PdfFileDto
     */
    'imageCount'?: number;
}
/**
 * 
 * @export
 * @interface RecommendationDto
 */
export interface RecommendationDto {
    /**
     * 
     * @type {string}
     * @memberof RecommendationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendationDto
     */
    'name'?: string;
    /**
     * 
     * @type {HazopEntryDto}
     * @memberof RecommendationDto
     */
    'hazopEntry'?: HazopEntryDto;
    /**
     * 
     * @type {number}
     * @memberof RecommendationDto
     */
    'number'?: number;
    /**
     * 
     * @type {SafeguardTypeDto}
     * @memberof RecommendationDto
     */
    'safeguardType'?: SafeguardTypeDto;
    /**
     * 
     * @type {CriticalityDto}
     * @memberof RecommendationDto
     */
    'criticality'?: CriticalityDto;
}
/**
 * 
 * @export
 * @interface RecommendationDtoRequest
 */
export interface RecommendationDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof RecommendationDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendationDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendationDtoRequest
     */
    'hazopEntryId'?: string;
    /**
     * 
     * @type {number}
     * @memberof RecommendationDtoRequest
     */
    'sort'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecommendationDtoRequest
     */
    'safeguardType'?: RecommendationDtoRequestSafeguardTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RecommendationDtoRequest
     */
    'criticality'?: RecommendationDtoRequestCriticalityEnum;
}

export const RecommendationDtoRequestSafeguardTypeEnum = {
    StringName: 'stringName',
    Design: 'DESIGN',
    OperationalSystem: 'OPERATIONAL_SYSTEM',
    Alarm: 'ALARM',
    Mz: 'MZ',
    Paz: 'PAZ',
    OperatorAction: 'OPERATOR_ACTION',
    Other1: 'OTHER1',
    Other2: 'OTHER2'
} as const;

export type RecommendationDtoRequestSafeguardTypeEnum = typeof RecommendationDtoRequestSafeguardTypeEnum[keyof typeof RecommendationDtoRequestSafeguardTypeEnum];
export const RecommendationDtoRequestCriticalityEnum = {
    Low: 'LOW',
    Medium: 'MEDIUM',
    High: 'HIGH'
} as const;

export type RecommendationDtoRequestCriticalityEnum = typeof RecommendationDtoRequestCriticalityEnum[keyof typeof RecommendationDtoRequestCriticalityEnum];

/**
 * 
 * @export
 * @interface RiskDangerValueDto
 */
export interface RiskDangerValueDto {
    /**
     * 
     * @type {number}
     * @memberof RiskDangerValueDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RiskDangerValueDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskDangerValueDto
     */
    'dangerColor'?: string;
}
/**
 * 
 * @export
 * @interface RiskTypeDto
 */
export interface RiskTypeDto {
    /**
     * 
     * @type {string}
     * @memberof RiskTypeDto
     */
    'id'?: RiskTypeDtoIdEnum;
    /**
     * 
     * @type {string}
     * @memberof RiskTypeDto
     */
    'name'?: string;
}

export const RiskTypeDtoIdEnum = {
    Human: 'HUMAN',
    Finance: 'FINANCE',
    Ecology: 'ECOLOGY',
    Reputation: 'REPUTATION'
} as const;

export type RiskTypeDtoIdEnum = typeof RiskTypeDtoIdEnum[keyof typeof RiskTypeDtoIdEnum];

/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof RoleDto
     */
    'users'?: Array<UserDto>;
}
/**
 * 
 * @export
 * @interface SafeguardDto
 */
export interface SafeguardDto {
    /**
     * 
     * @type {string}
     * @memberof SafeguardDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeguardDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeguardDto
     */
    'hazopEntryId'?: string;
    /**
     * 
     * @type {HazopEntryDto}
     * @memberof SafeguardDto
     */
    'hazopEntry'?: HazopEntryDto;
    /**
     * 
     * @type {SafeguardTypeDto}
     * @memberof SafeguardDto
     */
    'safeguardType'?: SafeguardTypeDto;
    /**
     * 
     * @type {number}
     * @memberof SafeguardDto
     */
    'number'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SafeguardDto
     */
    'enable'?: boolean;
}
/**
 * 
 * @export
 * @interface SafeguardDtoRequest
 */
export interface SafeguardDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof SafeguardDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeguardDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeguardDtoRequest
     */
    'hazopEntryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeguardDtoRequest
     */
    'safeguardType'?: SafeguardDtoRequestSafeguardTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SafeguardDtoRequest
     */
    'sort'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SafeguardDtoRequest
     */
    'enable'?: boolean;
}

export const SafeguardDtoRequestSafeguardTypeEnum = {
    StringName: 'stringName',
    Design: 'DESIGN',
    OperationalSystem: 'OPERATIONAL_SYSTEM',
    Alarm: 'ALARM',
    Mz: 'MZ',
    Paz: 'PAZ',
    OperatorAction: 'OPERATOR_ACTION',
    Other1: 'OTHER1',
    Other2: 'OTHER2'
} as const;

export type SafeguardDtoRequestSafeguardTypeEnum = typeof SafeguardDtoRequestSafeguardTypeEnum[keyof typeof SafeguardDtoRequestSafeguardTypeEnum];

/**
 * 
 * @export
 * @interface SafeguardTypeDto
 */
export interface SafeguardTypeDto {
    /**
     * 
     * @type {string}
     * @memberof SafeguardTypeDto
     */
    'id'?: SafeguardTypeDtoIdEnum;
}

export const SafeguardTypeDtoIdEnum = {
    StringName: 'stringName',
    Design: 'DESIGN',
    OperationalSystem: 'OPERATIONAL_SYSTEM',
    Alarm: 'ALARM',
    Mz: 'MZ',
    Paz: 'PAZ',
    OperatorAction: 'OPERATOR_ACTION',
    Other1: 'OTHER1',
    Other2: 'OTHER2'
} as const;

export type SafeguardTypeDtoIdEnum = typeof SafeguardTypeDtoIdEnum[keyof typeof SafeguardTypeDtoIdEnum];

/**
 * 
 * @export
 * @interface SchemeDto
 */
export interface SchemeDto {
    /**
     * 
     * @type {string}
     * @memberof SchemeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemeDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SchemeDtoRequest
 */
export interface SchemeDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof SchemeDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemeDtoRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ServerInfoDto
 */
export interface ServerInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ServerInfoDto
     */
    'deployTag'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoDto
     */
    'deployTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoDto
     */
    'serverName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoDto
     */
    'backendUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoDto
     */
    'externalUrl'?: string;
}
/**
 * 
 * @export
 * @interface ShapeDto
 */
export interface ShapeDto {
    /**
     * 
     * @type {string}
     * @memberof ShapeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShapeDto
     */
    'shapeType'?: ShapeDtoShapeTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShapeDto
     */
    'points'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'rotate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'y'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShapeDto
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'imageDrawingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShapeDto
     */
    'equipmentId'?: string;
    /**
     * 
     * @type {EquipmentDto}
     * @memberof ShapeDto
     */
    'equipment'?: EquipmentDto;
    /**
     * 
     * @type {Array<ShapeTagDto>}
     * @memberof ShapeDto
     */
    'tags'?: Array<ShapeTagDto>;
}

export const ShapeDtoShapeTypeEnum = {
    Rectangle: 'RECTANGLE',
    Line: 'LINE',
    Polygon: 'POLYGON'
} as const;

export type ShapeDtoShapeTypeEnum = typeof ShapeDtoShapeTypeEnum[keyof typeof ShapeDtoShapeTypeEnum];

/**
 * 
 * @export
 * @interface ShapeDtoRequest
 */
export interface ShapeDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof ShapeDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShapeDtoRequest
     */
    'shapeType': ShapeDtoRequestShapeTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShapeDtoRequest
     */
    'points'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'rotate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'y'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShapeDtoRequest
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'imageDrawingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShapeDtoRequest
     */
    'equipmentId'?: string;
}

export const ShapeDtoRequestShapeTypeEnum = {
    Rectangle: 'RECTANGLE',
    Line: 'LINE',
    Polygon: 'POLYGON'
} as const;

export type ShapeDtoRequestShapeTypeEnum = typeof ShapeDtoRequestShapeTypeEnum[keyof typeof ShapeDtoRequestShapeTypeEnum];

/**
 * 
 * @export
 * @interface ShapeDtoResponse
 */
export interface ShapeDtoResponse {
    /**
     * 
     * @type {Array<ShapeDto>}
     * @memberof ShapeDtoResponse
     */
    'shape'?: Array<ShapeDto>;
    /**
     * 
     * @type {Array<ConnectionDto>}
     * @memberof ShapeDtoResponse
     */
    'connection'?: Array<ConnectionDto>;
}
/**
 * 
 * @export
 * @interface ShapeTagDto
 */
export interface ShapeTagDto {
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDto
     */
    'rotate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDto
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDto
     */
    'y'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'shapeId'?: string;
}
/**
 * 
 * @export
 * @interface ShapeTagDtoRequest
 */
export interface ShapeTagDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDtoRequest
     */
    'rotate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDtoRequest
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDtoRequest
     */
    'y'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDtoRequest
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDtoRequest
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDtoRequest
     */
    'shapeId'?: string;
}
/**
 * 
 * @export
 * @interface SilEntryDto
 */
export interface SilEntryDto {
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'id'?: string;
    /**
     * 
     * @type {SilTableDto}
     * @memberof SilEntryDto
     */
    'silTable'?: SilTableDto;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'assetSystemId'?: string;
    /**
     * 
     * @type {AssetSystemDto}
     * @memberof SilEntryDto
     */
    'assetSystem'?: AssetSystemDto;
    /**
     * 
     * @type {number}
     * @memberof SilEntryDto
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'initiator'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'device'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'finalElement'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'parameterPaz'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'descriptionFbPsb'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'pid'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'personnelC'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'personnelF'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'personnelP'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'personnelW'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'personnelSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'environmentC'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'environmentP'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'environmentW'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'environmentSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'productionC'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'productionP'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'productionW'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'productionSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'defenceLayerDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'riskReductionCoefficient'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'riskComment'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'rrfs'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'rrfsSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'rrfe'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'rrfeSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'rrfa'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'rrfaSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'definedSIL'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface SilEntryDtoRequest
 */
export interface SilEntryDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'silTable'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'assetSystemId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SilEntryDtoRequest
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'initiator'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'device'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'finalElement'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'parameterPaz'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'descriptionFbPsb'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'pid'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'personnelC'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'personnelF'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'personnelP'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'personnelW'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'personnelSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'environmentC'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'environmentP'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'environmentW'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'environmentSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'productionC'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'productionP'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'productionW'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'productionSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'defenceLayerDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'riskReductionCoefficient'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'riskComment'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'rrfs'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'rrfsSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'rrfe'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'rrfeSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'rrfa'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'rrfaSil'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'definedSIL'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilEntryDtoRequest
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface SilTableDto
 */
export interface SilTableDto {
    /**
     * 
     * @type {string}
     * @memberof SilTableDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilTableDto
     */
    'sheetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilTableDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SilTableDtoRequest
 */
export interface SilTableDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof SilTableDtoRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilTableDtoRequest
     */
    'sheetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SilTableDtoRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'password'?: string;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof UserDto
     */
    'roles'?: Array<RoleDto>;
}

/**
 * AssetSystemApi - axios parameter creator
 * @export
 */
export const AssetSystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * delete assetSystem
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/assetSystem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get all assetSystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/assetSystem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AssetSystemIdGet', 'id', id)
            const localVarPath = `/api/v1/assetSystem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add new assetSystem
         * @param {AssetSystemDtoRequest} assetSystemDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemPost: async (assetSystemDtoRequest: AssetSystemDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetSystemDtoRequest' is not null or undefined
            assertParamExists('apiV1AssetSystemPost', 'assetSystemDtoRequest', assetSystemDtoRequest)
            const localVarPath = `/api/v1/assetSystem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSystemDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify exist assetSystem
         * @param {AssetSystemDtoRequest} assetSystemDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemPut: async (assetSystemDtoRequest: AssetSystemDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetSystemDtoRequest' is not null or undefined
            assertParamExists('apiV1AssetSystemPut', 'assetSystemDtoRequest', assetSystemDtoRequest)
            const localVarPath = `/api/v1/assetSystem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSystemDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemSearchGet: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/assetSystem/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetSystemApi - functional programming interface
 * @export
 */
export const AssetSystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetSystemApiAxiosParamCreator(configuration)
    return {
        /**
         * delete assetSystem
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSystemDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSystemDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get all assetSystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSystemGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetSystemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSystemGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSystemIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSystemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSystemIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * add new assetSystem
         * @param {AssetSystemDtoRequest} assetSystemDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSystemPost(assetSystemDtoRequest: AssetSystemDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSystemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSystemPost(assetSystemDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * modify exist assetSystem
         * @param {AssetSystemDtoRequest} assetSystemDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSystemPut(assetSystemDtoRequest: AssetSystemDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSystemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSystemPut(assetSystemDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSystemSearchGet(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetSystemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSystemSearchGet(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetSystemApi - factory interface
 * @export
 */
export const AssetSystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetSystemApiFp(configuration)
    return {
        /**
         * delete assetSystem
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1AssetSystemDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * get all assetSystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemGet(options?: any): AxiosPromise<Array<AssetSystemDto>> {
            return localVarFp.apiV1AssetSystemGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemIdGet(id: string, options?: any): AxiosPromise<AssetSystemDto> {
            return localVarFp.apiV1AssetSystemIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * add new assetSystem
         * @param {AssetSystemDtoRequest} assetSystemDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemPost(assetSystemDtoRequest: AssetSystemDtoRequest, options?: any): AxiosPromise<AssetSystemDto> {
            return localVarFp.apiV1AssetSystemPost(assetSystemDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * modify exist assetSystem
         * @param {AssetSystemDtoRequest} assetSystemDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemPut(assetSystemDtoRequest: AssetSystemDtoRequest, options?: any): AxiosPromise<AssetSystemDto> {
            return localVarFp.apiV1AssetSystemPut(assetSystemDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSystemSearchGet(name?: string, options?: any): AxiosPromise<Array<AssetSystemDto>> {
            return localVarFp.apiV1AssetSystemSearchGet(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetSystemApi - object-oriented interface
 * @export
 * @class AssetSystemApi
 * @extends {BaseAPI}
 */
export class AssetSystemApi extends BaseAPI {
    /**
     * delete assetSystem
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSystemApi
     */
    public apiV1AssetSystemDelete(id?: string, options?: AxiosRequestConfig) {
        return AssetSystemApiFp(this.configuration).apiV1AssetSystemDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get all assetSystem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSystemApi
     */
    public apiV1AssetSystemGet(options?: AxiosRequestConfig) {
        return AssetSystemApiFp(this.configuration).apiV1AssetSystemGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSystemApi
     */
    public apiV1AssetSystemIdGet(id: string, options?: AxiosRequestConfig) {
        return AssetSystemApiFp(this.configuration).apiV1AssetSystemIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add new assetSystem
     * @param {AssetSystemDtoRequest} assetSystemDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSystemApi
     */
    public apiV1AssetSystemPost(assetSystemDtoRequest: AssetSystemDtoRequest, options?: AxiosRequestConfig) {
        return AssetSystemApiFp(this.configuration).apiV1AssetSystemPost(assetSystemDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * modify exist assetSystem
     * @param {AssetSystemDtoRequest} assetSystemDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSystemApi
     */
    public apiV1AssetSystemPut(assetSystemDtoRequest: AssetSystemDtoRequest, options?: AxiosRequestConfig) {
        return AssetSystemApiFp(this.configuration).apiV1AssetSystemPut(assetSystemDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSystemApi
     */
    public apiV1AssetSystemSearchGet(name?: string, options?: AxiosRequestConfig) {
        return AssetSystemApiFp(this.configuration).apiV1AssetSystemSearchGet(name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BackupApi - axios parameter creator
 * @export
 */
export const BackupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Start the backup process
         * @param {number} minutes The number of minutes after which the backup should start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BackupStartPost: async (minutes: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minutes' is not null or undefined
            assertParamExists('apiV1BackupStartPost', 'minutes', minutes)
            const localVarPath = `/api/v1/backup/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (minutes !== undefined && minutes !== null) {
                localVarHeaderParameter['minutes'] = String(JSON.stringify(minutes));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the backup status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BackupStatusPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/backup/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop the backup process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BackupStopPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/backup/stop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackupApi - functional programming interface
 * @export
 */
export const BackupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Start the backup process
         * @param {number} minutes The number of minutes after which the backup should start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BackupStartPost(minutes: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackupDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BackupStartPost(minutes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the backup status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BackupStatusPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackupDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BackupStatusPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop the backup process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BackupStopPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackupDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BackupStopPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BackupApi - factory interface
 * @export
 */
export const BackupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackupApiFp(configuration)
    return {
        /**
         * 
         * @summary Start the backup process
         * @param {number} minutes The number of minutes after which the backup should start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BackupStartPost(minutes: number, options?: any): AxiosPromise<BackupDataDto> {
            return localVarFp.apiV1BackupStartPost(minutes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the backup status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BackupStatusPost(options?: any): AxiosPromise<BackupDataDto> {
            return localVarFp.apiV1BackupStatusPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop the backup process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BackupStopPost(options?: any): AxiosPromise<BackupDataDto> {
            return localVarFp.apiV1BackupStopPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackupApi - object-oriented interface
 * @export
 * @class BackupApi
 * @extends {BaseAPI}
 */
export class BackupApi extends BaseAPI {
    /**
     * 
     * @summary Start the backup process
     * @param {number} minutes The number of minutes after which the backup should start
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public apiV1BackupStartPost(minutes: number, options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).apiV1BackupStartPost(minutes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the backup status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public apiV1BackupStatusPost(options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).apiV1BackupStatusPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop the backup process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public apiV1BackupStopPost(options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).apiV1BackupStopPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CauseApi - axios parameter creator
 * @export
 */
export const CauseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cause`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cause`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseHazopEntryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CauseHazopEntryIdGet', 'id', id)
            const localVarPath = `/api/v1/cause/hazopEntry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CauseIdGet', 'id', id)
            const localVarPath = `/api/v1/cause/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CauseDtoRequest} causeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CausePost: async (causeDtoRequest: CauseDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'causeDtoRequest' is not null or undefined
            assertParamExists('apiV1CausePost', 'causeDtoRequest', causeDtoRequest)
            const localVarPath = `/api/v1/cause`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(causeDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CauseDtoRequest} causeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CausePut: async (causeDtoRequest: CauseDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'causeDtoRequest' is not null or undefined
            assertParamExists('apiV1CausePut', 'causeDtoRequest', causeDtoRequest)
            const localVarPath = `/api/v1/cause`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(causeDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CauseApi - functional programming interface
 * @export
 */
export const CauseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CauseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CauseDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CauseDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CauseGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CauseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CauseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CauseHazopEntryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CauseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CauseHazopEntryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CauseIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CauseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CauseIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CauseDtoRequest} causeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CausePost(causeDtoRequest: CauseDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CauseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CausePost(causeDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CauseDtoRequest} causeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CausePut(causeDtoRequest: CauseDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CauseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CausePut(causeDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CauseApi - factory interface
 * @export
 */
export const CauseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CauseApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1CauseDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseGet(options?: any): AxiosPromise<Array<CauseDto>> {
            return localVarFp.apiV1CauseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseHazopEntryIdGet(id: string, options?: any): AxiosPromise<Array<CauseDto>> {
            return localVarFp.apiV1CauseHazopEntryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseIdGet(id: string, options?: any): AxiosPromise<CauseDto> {
            return localVarFp.apiV1CauseIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CauseDtoRequest} causeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CausePost(causeDtoRequest: CauseDtoRequest, options?: any): AxiosPromise<CauseDto> {
            return localVarFp.apiV1CausePost(causeDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CauseDtoRequest} causeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CausePut(causeDtoRequest: CauseDtoRequest, options?: any): AxiosPromise<CauseDto> {
            return localVarFp.apiV1CausePut(causeDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CauseApi - object-oriented interface
 * @export
 * @class CauseApi
 * @extends {BaseAPI}
 */
export class CauseApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseApi
     */
    public apiV1CauseDelete(id?: string, options?: AxiosRequestConfig) {
        return CauseApiFp(this.configuration).apiV1CauseDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseApi
     */
    public apiV1CauseGet(options?: AxiosRequestConfig) {
        return CauseApiFp(this.configuration).apiV1CauseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseApi
     */
    public apiV1CauseHazopEntryIdGet(id: string, options?: AxiosRequestConfig) {
        return CauseApiFp(this.configuration).apiV1CauseHazopEntryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseApi
     */
    public apiV1CauseIdGet(id: string, options?: AxiosRequestConfig) {
        return CauseApiFp(this.configuration).apiV1CauseIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CauseDtoRequest} causeDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseApi
     */
    public apiV1CausePost(causeDtoRequest: CauseDtoRequest, options?: AxiosRequestConfig) {
        return CauseApiFp(this.configuration).apiV1CausePost(causeDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CauseDtoRequest} causeDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseApi
     */
    public apiV1CausePut(causeDtoRequest: CauseDtoRequest, options?: AxiosRequestConfig) {
        return CauseApiFp(this.configuration).apiV1CausePut(causeDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CauseProbabilitiesApi - axios parameter creator
 * @export
 */
export const CauseProbabilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * delete causeProbability
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilityDelete: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/causeProbability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get all causeProbabilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilityGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/causeProbability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get one causeProbability
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilityIdGet: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/causeProbability/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a causeProbability
         * @param {CauseProbabilityDtoRequest} causeProbabilityDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilityPost: async (causeProbabilityDtoRequest: CauseProbabilityDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'causeProbabilityDtoRequest' is not null or undefined
            assertParamExists('apiV1CauseProbabilityPost', 'causeProbabilityDtoRequest', causeProbabilityDtoRequest)
            const localVarPath = `/api/v1/causeProbability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(causeProbabilityDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * put causeProbability
         * @param {CauseProbabilityDtoRequest} [causeProbabilityDtoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilityPut: async (causeProbabilityDtoRequest?: CauseProbabilityDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/causeProbability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(causeProbabilityDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilitySearchGet: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/causeProbability/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CauseProbabilitiesApi - functional programming interface
 * @export
 */
export const CauseProbabilitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CauseProbabilitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * delete causeProbability
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CauseProbabilityDelete(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CauseProbabilityDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get all causeProbabilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CauseProbabilityGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CauseProbabilityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CauseProbabilityGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get one causeProbability
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CauseProbabilityIdGet(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1CauseProbabilityIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CauseProbabilityIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a causeProbability
         * @param {CauseProbabilityDtoRequest} causeProbabilityDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CauseProbabilityPost(causeProbabilityDtoRequest: CauseProbabilityDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CauseProbabilityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CauseProbabilityPost(causeProbabilityDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * put causeProbability
         * @param {CauseProbabilityDtoRequest} [causeProbabilityDtoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CauseProbabilityPut(causeProbabilityDtoRequest?: CauseProbabilityDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CauseProbabilityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CauseProbabilityPut(causeProbabilityDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CauseProbabilitySearchGet(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CauseProbabilityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CauseProbabilitySearchGet(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CauseProbabilitiesApi - factory interface
 * @export
 */
export const CauseProbabilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CauseProbabilitiesApiFp(configuration)
    return {
        /**
         * delete causeProbability
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilityDelete(id?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1CauseProbabilityDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * get all causeProbabilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilityGet(options?: any): AxiosPromise<Array<CauseProbabilityDto>> {
            return localVarFp.apiV1CauseProbabilityGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get one causeProbability
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilityIdGet(id?: number, options?: any): AxiosPromise<ApiV1CauseProbabilityIdGet200Response> {
            return localVarFp.apiV1CauseProbabilityIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * create a causeProbability
         * @param {CauseProbabilityDtoRequest} causeProbabilityDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilityPost(causeProbabilityDtoRequest: CauseProbabilityDtoRequest, options?: any): AxiosPromise<CauseProbabilityDto> {
            return localVarFp.apiV1CauseProbabilityPost(causeProbabilityDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * put causeProbability
         * @param {CauseProbabilityDtoRequest} [causeProbabilityDtoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilityPut(causeProbabilityDtoRequest?: CauseProbabilityDtoRequest, options?: any): AxiosPromise<CauseProbabilityDto> {
            return localVarFp.apiV1CauseProbabilityPut(causeProbabilityDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CauseProbabilitySearchGet(name?: string, options?: any): AxiosPromise<Array<CauseProbabilityDto>> {
            return localVarFp.apiV1CauseProbabilitySearchGet(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CauseProbabilitiesApi - object-oriented interface
 * @export
 * @class CauseProbabilitiesApi
 * @extends {BaseAPI}
 */
export class CauseProbabilitiesApi extends BaseAPI {
    /**
     * delete causeProbability
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseProbabilitiesApi
     */
    public apiV1CauseProbabilityDelete(id?: number, options?: AxiosRequestConfig) {
        return CauseProbabilitiesApiFp(this.configuration).apiV1CauseProbabilityDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get all causeProbabilities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseProbabilitiesApi
     */
    public apiV1CauseProbabilityGet(options?: AxiosRequestConfig) {
        return CauseProbabilitiesApiFp(this.configuration).apiV1CauseProbabilityGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get one causeProbability
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseProbabilitiesApi
     */
    public apiV1CauseProbabilityIdGet(id?: number, options?: AxiosRequestConfig) {
        return CauseProbabilitiesApiFp(this.configuration).apiV1CauseProbabilityIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a causeProbability
     * @param {CauseProbabilityDtoRequest} causeProbabilityDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseProbabilitiesApi
     */
    public apiV1CauseProbabilityPost(causeProbabilityDtoRequest: CauseProbabilityDtoRequest, options?: AxiosRequestConfig) {
        return CauseProbabilitiesApiFp(this.configuration).apiV1CauseProbabilityPost(causeProbabilityDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * put causeProbability
     * @param {CauseProbabilityDtoRequest} [causeProbabilityDtoRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseProbabilitiesApi
     */
    public apiV1CauseProbabilityPut(causeProbabilityDtoRequest?: CauseProbabilityDtoRequest, options?: AxiosRequestConfig) {
        return CauseProbabilitiesApiFp(this.configuration).apiV1CauseProbabilityPut(causeProbabilityDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CauseProbabilitiesApi
     */
    public apiV1CauseProbabilitySearchGet(name?: string, options?: AxiosRequestConfig) {
        return CauseProbabilitiesApiFp(this.configuration).apiV1CauseProbabilitySearchGet(name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConnectionApi - axios parameter creator
 * @export
 */
export const ConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConnectionDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ConnectionDelete', 'id', id)
            const localVarPath = `/api/v1/connection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConnectionIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ConnectionIdGet', 'id', id)
            const localVarPath = `/api/v1/connection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConnectionDtoRequest} [connectionDtoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConnectionPost: async (connectionDtoRequest?: ConnectionDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/connection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConnectionPut: async (id: string, connectionDtoRequest: ConnectionDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ConnectionPut', 'id', id)
            // verify required parameter 'connectionDtoRequest' is not null or undefined
            assertParamExists('apiV1ConnectionPut', 'connectionDtoRequest', connectionDtoRequest)
            const localVarPath = `/api/v1/connection`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionApi - functional programming interface
 * @export
 */
export const ConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConnectionDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConnectionDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConnectionIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConnectionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConnectionDtoRequest} [connectionDtoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConnectionPost(connectionDtoRequest?: ConnectionDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConnectionPost(connectionDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConnectionPut(id: string, connectionDtoRequest: ConnectionDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConnectionPut(id, connectionDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConnectionApi - factory interface
 * @export
 */
export const ConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConnectionDelete(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1ConnectionDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConnectionIdGet(id: string, options?: any): AxiosPromise<ConnectionDto> {
            return localVarFp.apiV1ConnectionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConnectionDtoRequest} [connectionDtoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConnectionPost(connectionDtoRequest?: ConnectionDtoRequest, options?: any): AxiosPromise<ConnectionDto> {
            return localVarFp.apiV1ConnectionPost(connectionDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConnectionPut(id: string, connectionDtoRequest: ConnectionDtoRequest, options?: any): AxiosPromise<ConnectionDto> {
            return localVarFp.apiV1ConnectionPut(id, connectionDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectionApi - object-oriented interface
 * @export
 * @class ConnectionApi
 * @extends {BaseAPI}
 */
export class ConnectionApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public apiV1ConnectionDelete(id: string, options?: AxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).apiV1ConnectionDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public apiV1ConnectionIdGet(id: string, options?: AxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).apiV1ConnectionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConnectionDtoRequest} [connectionDtoRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public apiV1ConnectionPost(connectionDtoRequest?: ConnectionDtoRequest, options?: AxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).apiV1ConnectionPost(connectionDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ConnectionDtoRequest} connectionDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public apiV1ConnectionPut(id: string, connectionDtoRequest: ConnectionDtoRequest, options?: AxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).apiV1ConnectionPut(id, connectionDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConsequenceApi - axios parameter creator
 * @export
 */
export const ConsequenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequenceDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/consequence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequenceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/consequence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequenceHazopEntryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ConsequenceHazopEntryIdGet', 'id', id)
            const localVarPath = `/api/v1/consequence/hazopEntry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequenceIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ConsequenceIdGet', 'id', id)
            const localVarPath = `/api/v1/consequence/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConsequenceDtoRequest} consequenceDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequencePost: async (consequenceDtoRequest: ConsequenceDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consequenceDtoRequest' is not null or undefined
            assertParamExists('apiV1ConsequencePost', 'consequenceDtoRequest', consequenceDtoRequest)
            const localVarPath = `/api/v1/consequence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consequenceDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConsequenceDtoRequest} consequenceDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequencePut: async (consequenceDtoRequest: ConsequenceDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consequenceDtoRequest' is not null or undefined
            assertParamExists('apiV1ConsequencePut', 'consequenceDtoRequest', consequenceDtoRequest)
            const localVarPath = `/api/v1/consequence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consequenceDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsequenceApi - functional programming interface
 * @export
 */
export const ConsequenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsequenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsequenceDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsequenceDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsequenceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsequenceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsequenceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsequenceHazopEntryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsequenceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsequenceHazopEntryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsequenceIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsequenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsequenceIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConsequenceDtoRequest} consequenceDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsequencePost(consequenceDtoRequest: ConsequenceDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsequenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsequencePost(consequenceDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConsequenceDtoRequest} consequenceDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsequencePut(consequenceDtoRequest: ConsequenceDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsequenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsequencePut(consequenceDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsequenceApi - factory interface
 * @export
 */
export const ConsequenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsequenceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequenceDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1ConsequenceDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequenceGet(options?: any): AxiosPromise<Array<ConsequenceDto>> {
            return localVarFp.apiV1ConsequenceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequenceHazopEntryIdGet(id: string, options?: any): AxiosPromise<Array<ConsequenceDto>> {
            return localVarFp.apiV1ConsequenceHazopEntryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequenceIdGet(id: string, options?: any): AxiosPromise<ConsequenceDto> {
            return localVarFp.apiV1ConsequenceIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConsequenceDtoRequest} consequenceDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequencePost(consequenceDtoRequest: ConsequenceDtoRequest, options?: any): AxiosPromise<ConsequenceDto> {
            return localVarFp.apiV1ConsequencePost(consequenceDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConsequenceDtoRequest} consequenceDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsequencePut(consequenceDtoRequest: ConsequenceDtoRequest, options?: any): AxiosPromise<ConsequenceDto> {
            return localVarFp.apiV1ConsequencePut(consequenceDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsequenceApi - object-oriented interface
 * @export
 * @class ConsequenceApi
 * @extends {BaseAPI}
 */
export class ConsequenceApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsequenceApi
     */
    public apiV1ConsequenceDelete(id?: string, options?: AxiosRequestConfig) {
        return ConsequenceApiFp(this.configuration).apiV1ConsequenceDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsequenceApi
     */
    public apiV1ConsequenceGet(options?: AxiosRequestConfig) {
        return ConsequenceApiFp(this.configuration).apiV1ConsequenceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsequenceApi
     */
    public apiV1ConsequenceHazopEntryIdGet(id: string, options?: AxiosRequestConfig) {
        return ConsequenceApiFp(this.configuration).apiV1ConsequenceHazopEntryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsequenceApi
     */
    public apiV1ConsequenceIdGet(id: string, options?: AxiosRequestConfig) {
        return ConsequenceApiFp(this.configuration).apiV1ConsequenceIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConsequenceDtoRequest} consequenceDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsequenceApi
     */
    public apiV1ConsequencePost(consequenceDtoRequest: ConsequenceDtoRequest, options?: AxiosRequestConfig) {
        return ConsequenceApiFp(this.configuration).apiV1ConsequencePost(consequenceDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConsequenceDtoRequest} consequenceDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsequenceApi
     */
    public apiV1ConsequencePut(consequenceDtoRequest: ConsequenceDtoRequest, options?: AxiosRequestConfig) {
        return ConsequenceApiFp(this.configuration).apiV1ConsequencePut(consequenceDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DbDeleteApi - axios parameter creator
 * @export
 */
export const DbDeleteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DbDeleteDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/db-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DbDeleteApi - functional programming interface
 * @export
 */
export const DbDeleteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DbDeleteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DbDeleteDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DbDeleteDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DbDeleteApi - factory interface
 * @export
 */
export const DbDeleteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DbDeleteApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DbDeleteDelete(options?: any): AxiosPromise<string> {
            return localVarFp.apiV1DbDeleteDelete(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DbDeleteApi - object-oriented interface
 * @export
 * @class DbDeleteApi
 * @extends {BaseAPI}
 */
export class DbDeleteApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DbDeleteApi
     */
    public apiV1DbDeleteDelete(options?: AxiosRequestConfig) {
        return DbDeleteApiFp(this.configuration).apiV1DbDeleteDelete(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnumApi - axios parameter creator
 * @export
 */
export const EnumApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumDangerValuesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/enum/danger-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumRecommendationCriticalityValuesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/enum/recommendation-criticality-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnumApi - functional programming interface
 * @export
 */
export const EnumApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnumApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumDangerValuesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RiskDangerValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumDangerValuesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumRecommendationCriticalityValuesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CriticalityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumRecommendationCriticalityValuesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnumApi - factory interface
 * @export
 */
export const EnumApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnumApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumDangerValuesGet(options?: any): AxiosPromise<Array<RiskDangerValueDto>> {
            return localVarFp.apiV1EnumDangerValuesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumRecommendationCriticalityValuesGet(options?: any): AxiosPromise<Array<CriticalityDto>> {
            return localVarFp.apiV1EnumRecommendationCriticalityValuesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnumApi - object-oriented interface
 * @export
 * @class EnumApi
 * @extends {BaseAPI}
 */
export class EnumApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public apiV1EnumDangerValuesGet(options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).apiV1EnumDangerValuesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public apiV1EnumRecommendationCriticalityValuesGet(options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).apiV1EnumRecommendationCriticalityValuesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EquipmentApi - axios parameter creator
 * @export
 */
export const EquipmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [nodeId] 
         * @param {string} [equipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentAttachToNodePost: async (nodeId?: string, equipmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/equipment/attach-to-node`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }

            if (equipmentId !== undefined) {
                localVarQueryParameter['equipmentId'] = equipmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentByNodeGet: async (nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('apiV1EquipmentByNodeGet', 'nodeId', nodeId)
            const localVarPath = `/api/v1/equipment/by-node`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1EquipmentIdGet', 'id', id)
            const localVarPath = `/api/v1/equipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EquipmentDtoRequest} equipmentDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentPost: async (equipmentDtoRequest: EquipmentDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentDtoRequest' is not null or undefined
            assertParamExists('apiV1EquipmentPost', 'equipmentDtoRequest', equipmentDtoRequest)
            const localVarPath = `/api/v1/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EquipmentDtoRequest} equipmentDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentPut: async (equipmentDtoRequest: EquipmentDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentDtoRequest' is not null or undefined
            assertParamExists('apiV1EquipmentPut', 'equipmentDtoRequest', equipmentDtoRequest)
            const localVarPath = `/api/v1/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentSearchGet: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/equipment/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentApi - functional programming interface
 * @export
 */
export const EquipmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [nodeId] 
         * @param {string} [equipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EquipmentAttachToNodePost(nodeId?: string, equipmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EquipmentAttachToNodePost(nodeId, equipmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EquipmentByNodeGet(nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EquipmentByNodeGet(nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EquipmentDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EquipmentDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EquipmentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EquipmentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EquipmentIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EquipmentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EquipmentDtoRequest} equipmentDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EquipmentPost(equipmentDtoRequest: EquipmentDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EquipmentPost(equipmentDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EquipmentDtoRequest} equipmentDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EquipmentPut(equipmentDtoRequest: EquipmentDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EquipmentPut(equipmentDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EquipmentSearchGet(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EquipmentSearchGet(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquipmentApi - factory interface
 * @export
 */
export const EquipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [nodeId] 
         * @param {string} [equipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentAttachToNodePost(nodeId?: string, equipmentId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1EquipmentAttachToNodePost(nodeId, equipmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentByNodeGet(nodeId: string, options?: any): AxiosPromise<Array<EquipmentDto>> {
            return localVarFp.apiV1EquipmentByNodeGet(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1EquipmentDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentGet(options?: any): AxiosPromise<Array<EquipmentDto>> {
            return localVarFp.apiV1EquipmentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentIdGet(id: string, options?: any): AxiosPromise<EquipmentDto> {
            return localVarFp.apiV1EquipmentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EquipmentDtoRequest} equipmentDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentPost(equipmentDtoRequest: EquipmentDtoRequest, options?: any): AxiosPromise<EquipmentDto> {
            return localVarFp.apiV1EquipmentPost(equipmentDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EquipmentDtoRequest} equipmentDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentPut(equipmentDtoRequest: EquipmentDtoRequest, options?: any): AxiosPromise<EquipmentDto> {
            return localVarFp.apiV1EquipmentPut(equipmentDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EquipmentSearchGet(name?: string, options?: any): AxiosPromise<Array<EquipmentDto>> {
            return localVarFp.apiV1EquipmentSearchGet(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EquipmentApi - object-oriented interface
 * @export
 * @class EquipmentApi
 * @extends {BaseAPI}
 */
export class EquipmentApi extends BaseAPI {
    /**
     * 
     * @param {string} [nodeId] 
     * @param {string} [equipmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiV1EquipmentAttachToNodePost(nodeId?: string, equipmentId?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiV1EquipmentAttachToNodePost(nodeId, equipmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiV1EquipmentByNodeGet(nodeId: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiV1EquipmentByNodeGet(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiV1EquipmentDelete(id?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiV1EquipmentDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiV1EquipmentGet(options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiV1EquipmentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiV1EquipmentIdGet(id: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiV1EquipmentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentDtoRequest} equipmentDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiV1EquipmentPost(equipmentDtoRequest: EquipmentDtoRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiV1EquipmentPost(equipmentDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentDtoRequest} equipmentDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiV1EquipmentPut(equipmentDtoRequest: EquipmentDtoRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiV1EquipmentPut(equipmentDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public apiV1EquipmentSearchGet(name?: string, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).apiV1EquipmentSearchGet(name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExportHazopGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/export/hazop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExportHazopLopaGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/export/hazop-lopa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExportHazopRecommendationsListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/export/hazop-recommendations-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [eightLines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExportHazopWorkbookGet: async (eightLines?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/export/hazop-workbook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eightLines !== undefined) {
                localVarQueryParameter['eight-lines'] = eightLines;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ExportHazopGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ExportHazopGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ExportHazopLopaGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ExportHazopLopaGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ExportHazopRecommendationsListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ExportHazopRecommendationsListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [eightLines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ExportHazopWorkbookGet(eightLines?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ExportHazopWorkbookGet(eightLines, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExportHazopGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1ExportHazopGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExportHazopLopaGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1ExportHazopLopaGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExportHazopRecommendationsListGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1ExportHazopRecommendationsListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [eightLines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExportHazopWorkbookGet(eightLines?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1ExportHazopWorkbookGet(eightLines, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public apiV1ExportHazopGet(options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).apiV1ExportHazopGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public apiV1ExportHazopLopaGet(options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).apiV1ExportHazopLopaGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public apiV1ExportHazopRecommendationsListGet(options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).apiV1ExportHazopRecommendationsListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [eightLines] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public apiV1ExportHazopWorkbookGet(eightLines?: string, options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).apiV1ExportHazopWorkbookGet(eightLines, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HazopEntryApi - axios parameter creator
 * @export
 */
export const HazopEntryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [nodeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryByNodeGet: async (nodeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/hazopEntry/by-node`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fromId 
         * @param {string} toId 
         * @param {'SAFEGUARDS' | 'RECOMMENDATIONS' | 'CONSEQUENCES' | 'CAUSES'} list 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryCopyListAttributesPut: async (fromId: string, toId: string, list: 'SAFEGUARDS' | 'RECOMMENDATIONS' | 'CONSEQUENCES' | 'CAUSES', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromId' is not null or undefined
            assertParamExists('apiV1HazopEntryCopyListAttributesPut', 'fromId', fromId)
            // verify required parameter 'toId' is not null or undefined
            assertParamExists('apiV1HazopEntryCopyListAttributesPut', 'toId', toId)
            // verify required parameter 'list' is not null or undefined
            assertParamExists('apiV1HazopEntryCopyListAttributesPut', 'list', list)
            const localVarPath = `/api/v1/hazopEntry/copy-list-attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromId !== undefined) {
                localVarQueryParameter['fromId'] = fromId;
            }

            if (toId !== undefined) {
                localVarQueryParameter['toId'] = toId;
            }

            if (list !== undefined) {
                localVarQueryParameter['list'] = list;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [nodeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryCreateEmptyPost: async (nodeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/hazopEntry/create-empty`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/hazopEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Для выбранного узла генерирет все возможные отклонения из имеющися параметров и управляющихся слов.
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryGenerateWithParametersPost: async (nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('apiV1HazopEntryGenerateWithParametersPost', 'nodeId', nodeId)
            const localVarPath = `/api/v1/hazopEntry/generate-with-parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/hazopEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1HazopEntryIdGet', 'id', id)
            const localVarPath = `/api/v1/hazopEntry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HazopEntryDtoRequest} hazopEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryPost: async (hazopEntryDtoRequest: HazopEntryDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hazopEntryDtoRequest' is not null or undefined
            assertParamExists('apiV1HazopEntryPost', 'hazopEntryDtoRequest', hazopEntryDtoRequest)
            const localVarPath = `/api/v1/hazopEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hazopEntryDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HazopEntryDtoRequest} hazopEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryPut: async (hazopEntryDtoRequest: HazopEntryDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hazopEntryDtoRequest' is not null or undefined
            assertParamExists('apiV1HazopEntryPut', 'hazopEntryDtoRequest', hazopEntryDtoRequest)
            const localVarPath = `/api/v1/hazopEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hazopEntryDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HazopEntryApi - functional programming interface
 * @export
 */
export const HazopEntryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HazopEntryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [nodeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HazopEntryByNodeGet(nodeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HazopEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HazopEntryByNodeGet(nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fromId 
         * @param {string} toId 
         * @param {'SAFEGUARDS' | 'RECOMMENDATIONS' | 'CONSEQUENCES' | 'CAUSES'} list 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HazopEntryCopyListAttributesPut(fromId: string, toId: string, list: 'SAFEGUARDS' | 'RECOMMENDATIONS' | 'CONSEQUENCES' | 'CAUSES', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HazopEntryCopyListAttributesPut(fromId, toId, list, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [nodeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HazopEntryCreateEmptyPost(nodeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HazopEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HazopEntryCreateEmptyPost(nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HazopEntryDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HazopEntryDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Для выбранного узла генерирет все возможные отклонения из имеющися параметров и управляющихся слов.
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HazopEntryGenerateWithParametersPost(nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HazopEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HazopEntryGenerateWithParametersPost(nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HazopEntryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HazopEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HazopEntryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HazopEntryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HazopEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HazopEntryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {HazopEntryDtoRequest} hazopEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HazopEntryPost(hazopEntryDtoRequest: HazopEntryDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HazopEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HazopEntryPost(hazopEntryDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {HazopEntryDtoRequest} hazopEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HazopEntryPut(hazopEntryDtoRequest: HazopEntryDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HazopEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HazopEntryPut(hazopEntryDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HazopEntryApi - factory interface
 * @export
 */
export const HazopEntryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HazopEntryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [nodeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryByNodeGet(nodeId?: string, options?: any): AxiosPromise<Array<HazopEntryDto>> {
            return localVarFp.apiV1HazopEntryByNodeGet(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fromId 
         * @param {string} toId 
         * @param {'SAFEGUARDS' | 'RECOMMENDATIONS' | 'CONSEQUENCES' | 'CAUSES'} list 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryCopyListAttributesPut(fromId: string, toId: string, list: 'SAFEGUARDS' | 'RECOMMENDATIONS' | 'CONSEQUENCES' | 'CAUSES', options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1HazopEntryCopyListAttributesPut(fromId, toId, list, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [nodeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryCreateEmptyPost(nodeId?: string, options?: any): AxiosPromise<HazopEntryDto> {
            return localVarFp.apiV1HazopEntryCreateEmptyPost(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1HazopEntryDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Для выбранного узла генерирет все возможные отклонения из имеющися параметров и управляющихся слов.
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryGenerateWithParametersPost(nodeId: string, options?: any): AxiosPromise<Array<HazopEntryDto>> {
            return localVarFp.apiV1HazopEntryGenerateWithParametersPost(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryGet(options?: any): AxiosPromise<Array<HazopEntryDto>> {
            return localVarFp.apiV1HazopEntryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryIdGet(id: string, options?: any): AxiosPromise<HazopEntryDto> {
            return localVarFp.apiV1HazopEntryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HazopEntryDtoRequest} hazopEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryPost(hazopEntryDtoRequest: HazopEntryDtoRequest, options?: any): AxiosPromise<HazopEntryDto> {
            return localVarFp.apiV1HazopEntryPost(hazopEntryDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HazopEntryDtoRequest} hazopEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HazopEntryPut(hazopEntryDtoRequest: HazopEntryDtoRequest, options?: any): AxiosPromise<HazopEntryDto> {
            return localVarFp.apiV1HazopEntryPut(hazopEntryDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HazopEntryApi - object-oriented interface
 * @export
 * @class HazopEntryApi
 * @extends {BaseAPI}
 */
export class HazopEntryApi extends BaseAPI {
    /**
     * 
     * @param {string} [nodeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HazopEntryApi
     */
    public apiV1HazopEntryByNodeGet(nodeId?: string, options?: AxiosRequestConfig) {
        return HazopEntryApiFp(this.configuration).apiV1HazopEntryByNodeGet(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fromId 
     * @param {string} toId 
     * @param {'SAFEGUARDS' | 'RECOMMENDATIONS' | 'CONSEQUENCES' | 'CAUSES'} list 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HazopEntryApi
     */
    public apiV1HazopEntryCopyListAttributesPut(fromId: string, toId: string, list: 'SAFEGUARDS' | 'RECOMMENDATIONS' | 'CONSEQUENCES' | 'CAUSES', options?: AxiosRequestConfig) {
        return HazopEntryApiFp(this.configuration).apiV1HazopEntryCopyListAttributesPut(fromId, toId, list, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [nodeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HazopEntryApi
     */
    public apiV1HazopEntryCreateEmptyPost(nodeId?: string, options?: AxiosRequestConfig) {
        return HazopEntryApiFp(this.configuration).apiV1HazopEntryCreateEmptyPost(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HazopEntryApi
     */
    public apiV1HazopEntryDelete(id?: string, options?: AxiosRequestConfig) {
        return HazopEntryApiFp(this.configuration).apiV1HazopEntryDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Для выбранного узла генерирет все возможные отклонения из имеющися параметров и управляющихся слов.
     * @param {string} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HazopEntryApi
     */
    public apiV1HazopEntryGenerateWithParametersPost(nodeId: string, options?: AxiosRequestConfig) {
        return HazopEntryApiFp(this.configuration).apiV1HazopEntryGenerateWithParametersPost(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HazopEntryApi
     */
    public apiV1HazopEntryGet(options?: AxiosRequestConfig) {
        return HazopEntryApiFp(this.configuration).apiV1HazopEntryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HazopEntryApi
     */
    public apiV1HazopEntryIdGet(id: string, options?: AxiosRequestConfig) {
        return HazopEntryApiFp(this.configuration).apiV1HazopEntryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HazopEntryDtoRequest} hazopEntryDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HazopEntryApi
     */
    public apiV1HazopEntryPost(hazopEntryDtoRequest: HazopEntryDtoRequest, options?: AxiosRequestConfig) {
        return HazopEntryApiFp(this.configuration).apiV1HazopEntryPost(hazopEntryDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HazopEntryDtoRequest} hazopEntryDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HazopEntryApi
     */
    public apiV1HazopEntryPut(hazopEntryDtoRequest: HazopEntryDtoRequest, options?: AxiosRequestConfig) {
        return HazopEntryApiFp(this.configuration).apiV1HazopEntryPut(hazopEntryDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} pdfId 
         * @param {number} imageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageByPdfAndNumberGet: async (pdfId: string, imageNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfId' is not null or undefined
            assertParamExists('apiV1ImageByPdfAndNumberGet', 'pdfId', pdfId)
            // verify required parameter 'imageNumber' is not null or undefined
            assertParamExists('apiV1ImageByPdfAndNumberGet', 'imageNumber', imageNumber)
            const localVarPath = `/api/v1/image/by-pdf-and-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pdfId !== undefined) {
                localVarQueryParameter['pdf-id'] = pdfId;
            }

            if (imageNumber !== undefined) {
                localVarQueryParameter['imageNumber'] = imageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageByPdfGet: async (pdfId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfId' is not null or undefined
            assertParamExists('apiV1ImageByPdfGet', 'pdfId', pdfId)
            const localVarPath = `/api/v1/image/by-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pdfId !== undefined) {
                localVarQueryParameter['pdf-id'] = pdfId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageContentGet: async (imageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('apiV1ImageContentGet', 'imageId', imageId)
            const localVarPath = `/api/v1/image/content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (imageId !== undefined) {
                localVarQueryParameter['image-id'] = imageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageImageIdGet: async (imageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('apiV1ImageImageIdGet', 'imageId', imageId)
            const localVarPath = `/api/v1/image/{image-id}`
                .replace(`{${"image-id"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [dpi] 
         * @param {number} [imageNumber] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImagePost: async (dpi?: number, imageNumber?: number, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dpi !== undefined) {
                localVarQueryParameter['dpi'] = dpi;
            }

            if (imageNumber !== undefined) {
                localVarQueryParameter['imageNumber'] = imageNumber;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} pdfId 
         * @param {number} imageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageByPdfAndNumberGet(pdfId: string, imageNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageByPdfAndNumberGet(pdfId, imageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} pdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageByPdfGet(pdfId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageByPdfGet(pdfId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageContentGet(imageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageContentGet(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageImageIdGet(imageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageImageIdGet(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [dpi] 
         * @param {number} [imageNumber] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImagePost(dpi?: number, imageNumber?: number, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImagePost(dpi, imageNumber, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} pdfId 
         * @param {number} imageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageByPdfAndNumberGet(pdfId: string, imageNumber: number, options?: any): AxiosPromise<Array<ImageFileDto>> {
            return localVarFp.apiV1ImageByPdfAndNumberGet(pdfId, imageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageByPdfGet(pdfId: string, options?: any): AxiosPromise<Array<ImageFileDto>> {
            return localVarFp.apiV1ImageByPdfGet(pdfId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageContentGet(imageId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1ImageContentGet(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1ImageDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageGet(options?: any): AxiosPromise<Array<ImageFileDto>> {
            return localVarFp.apiV1ImageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageImageIdGet(imageId: string, options?: any): AxiosPromise<ImageFileDto> {
            return localVarFp.apiV1ImageImageIdGet(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [dpi] 
         * @param {number} [imageNumber] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImagePost(dpi?: number, imageNumber?: number, file?: any, options?: any): AxiosPromise<ImageFileDto> {
            return localVarFp.apiV1ImagePost(dpi, imageNumber, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
    /**
     * 
     * @param {string} pdfId 
     * @param {number} imageNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiV1ImageByPdfAndNumberGet(pdfId: string, imageNumber: number, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiV1ImageByPdfAndNumberGet(pdfId, imageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pdfId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiV1ImageByPdfGet(pdfId: string, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiV1ImageByPdfGet(pdfId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} imageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiV1ImageContentGet(imageId: string, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiV1ImageContentGet(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiV1ImageDelete(id?: string, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiV1ImageDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiV1ImageGet(options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiV1ImageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} imageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiV1ImageImageIdGet(imageId: string, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiV1ImageImageIdGet(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [dpi] 
     * @param {number} [imageNumber] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiV1ImagePost(dpi?: number, imageNumber?: number, file?: any, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiV1ImagePost(dpi, imageNumber, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImportApi - axios parameter creator
 * @export
 */
export const ImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImportCauseProbabilityPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/import/causeProbability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImportNodePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/import/node`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [eightLines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImportPost: async (eightLines?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eightLines !== undefined) {
                localVarQueryParameter['eight-lines'] = eightLines;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImportSilV3Post: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/import/sil-v3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImportApi - functional programming interface
 * @export
 */
export const ImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImportCauseProbabilityPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImportCauseProbabilityPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImportNodePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImportNodePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [eightLines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImportPost(eightLines?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImportPost(eightLines, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImportSilV3Post(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImportSilV3Post(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImportApi - factory interface
 * @export
 */
export const ImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImportApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImportCauseProbabilityPost(options?: any): AxiosPromise<Array<ImportReportDto>> {
            return localVarFp.apiV1ImportCauseProbabilityPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImportNodePost(options?: any): AxiosPromise<Array<ImportReportDto>> {
            return localVarFp.apiV1ImportNodePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [eightLines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImportPost(eightLines?: string, options?: any): AxiosPromise<Array<ImportReportDto>> {
            return localVarFp.apiV1ImportPost(eightLines, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImportSilV3Post(options?: any): AxiosPromise<Array<ImportReportDto>> {
            return localVarFp.apiV1ImportSilV3Post(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImportApi - object-oriented interface
 * @export
 * @class ImportApi
 * @extends {BaseAPI}
 */
export class ImportApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public apiV1ImportCauseProbabilityPost(options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).apiV1ImportCauseProbabilityPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public apiV1ImportNodePost(options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).apiV1ImportNodePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [eightLines] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public apiV1ImportPost(eightLines?: string, options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).apiV1ImportPost(eightLines, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public apiV1ImportSilV3Post(options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).apiV1ImportSilV3Post(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InfoApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InfoGet(options?: any): AxiosPromise<ServerInfoDto> {
            return localVarFp.apiV1InfoGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public apiV1InfoGet(options?: AxiosRequestConfig) {
        return InfoApiFp(this.configuration).apiV1InfoGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LopaEntryApi - axios parameter creator
 * @export
 */
export const LopaEntryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} hazopEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryByHazopEntryGet: async (hazopEntryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hazopEntryId' is not null or undefined
            assertParamExists('apiV1LopaEntryByHazopEntryGet', 'hazopEntryId', hazopEntryId)
            const localVarPath = `/api/v1/lopaEntry/by-hazopEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hazopEntryId !== undefined) {
                localVarQueryParameter['hazopEntry-id'] = hazopEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/lopaEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryGenerateEmptyPost: async (nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('apiV1LopaEntryGenerateEmptyPost', 'nodeId', nodeId)
            const localVarPath = `/api/v1/lopaEntry/generate-empty`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['node-id'] = nodeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1LopaEntryIdGet', 'id', id)
            const localVarPath = `/api/v1/lopaEntry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LopaEntryDtoRequest} lopaEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryPost: async (lopaEntryDtoRequest: LopaEntryDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lopaEntryDtoRequest' is not null or undefined
            assertParamExists('apiV1LopaEntryPost', 'lopaEntryDtoRequest', lopaEntryDtoRequest)
            const localVarPath = `/api/v1/lopaEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lopaEntryDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LopaEntryDtoRequest} lopaEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryPut: async (lopaEntryDtoRequest: LopaEntryDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lopaEntryDtoRequest' is not null or undefined
            assertParamExists('apiV1LopaEntryPut', 'lopaEntryDtoRequest', lopaEntryDtoRequest)
            const localVarPath = `/api/v1/lopaEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lopaEntryDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LopaEntryApi - functional programming interface
 * @export
 */
export const LopaEntryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LopaEntryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} hazopEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LopaEntryByHazopEntryGet(hazopEntryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LopaRiskTotalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LopaEntryByHazopEntryGet(hazopEntryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LopaEntryDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LopaEntryDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LopaEntryGenerateEmptyPost(nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HazopEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LopaEntryGenerateEmptyPost(nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LopaEntryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LopaEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LopaEntryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LopaEntryDtoRequest} lopaEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LopaEntryPost(lopaEntryDtoRequest: LopaEntryDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LopaEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LopaEntryPost(lopaEntryDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LopaEntryDtoRequest} lopaEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LopaEntryPut(lopaEntryDtoRequest: LopaEntryDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LopaEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LopaEntryPut(lopaEntryDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LopaEntryApi - factory interface
 * @export
 */
export const LopaEntryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LopaEntryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} hazopEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryByHazopEntryGet(hazopEntryId: string, options?: any): AxiosPromise<Array<LopaRiskTotalDto>> {
            return localVarFp.apiV1LopaEntryByHazopEntryGet(hazopEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1LopaEntryDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryGenerateEmptyPost(nodeId: string, options?: any): AxiosPromise<Array<HazopEntryDto>> {
            return localVarFp.apiV1LopaEntryGenerateEmptyPost(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryIdGet(id: string, options?: any): AxiosPromise<LopaEntryDto> {
            return localVarFp.apiV1LopaEntryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LopaEntryDtoRequest} lopaEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryPost(lopaEntryDtoRequest: LopaEntryDtoRequest, options?: any): AxiosPromise<LopaEntryDto> {
            return localVarFp.apiV1LopaEntryPost(lopaEntryDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LopaEntryDtoRequest} lopaEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LopaEntryPut(lopaEntryDtoRequest: LopaEntryDtoRequest, options?: any): AxiosPromise<LopaEntryDto> {
            return localVarFp.apiV1LopaEntryPut(lopaEntryDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LopaEntryApi - object-oriented interface
 * @export
 * @class LopaEntryApi
 * @extends {BaseAPI}
 */
export class LopaEntryApi extends BaseAPI {
    /**
     * 
     * @param {string} hazopEntryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LopaEntryApi
     */
    public apiV1LopaEntryByHazopEntryGet(hazopEntryId: string, options?: AxiosRequestConfig) {
        return LopaEntryApiFp(this.configuration).apiV1LopaEntryByHazopEntryGet(hazopEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LopaEntryApi
     */
    public apiV1LopaEntryDelete(id?: string, options?: AxiosRequestConfig) {
        return LopaEntryApiFp(this.configuration).apiV1LopaEntryDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LopaEntryApi
     */
    public apiV1LopaEntryGenerateEmptyPost(nodeId: string, options?: AxiosRequestConfig) {
        return LopaEntryApiFp(this.configuration).apiV1LopaEntryGenerateEmptyPost(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LopaEntryApi
     */
    public apiV1LopaEntryIdGet(id: string, options?: AxiosRequestConfig) {
        return LopaEntryApiFp(this.configuration).apiV1LopaEntryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LopaEntryDtoRequest} lopaEntryDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LopaEntryApi
     */
    public apiV1LopaEntryPost(lopaEntryDtoRequest: LopaEntryDtoRequest, options?: AxiosRequestConfig) {
        return LopaEntryApiFp(this.configuration).apiV1LopaEntryPost(lopaEntryDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LopaEntryDtoRequest} lopaEntryDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LopaEntryApi
     */
    public apiV1LopaEntryPut(lopaEntryDtoRequest: LopaEntryDtoRequest, options?: AxiosRequestConfig) {
        return LopaEntryApiFp(this.configuration).apiV1LopaEntryPut(lopaEntryDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MatrixValueApi - axios parameter creator
 * @export
 */
export const MatrixValueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueAllDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/matrix-value/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/matrix-value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/matrix-value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Возвращает коды доступных шаблонов матрицы рисков
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueGetTemplatesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/matrix-value/get-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MatrixValueIdGet', 'id', id)
            const localVarPath = `/api/v1/matrix-value/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueMatrixGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/matrix-value/matrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueMatrixPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/matrix-value/matrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MatrixValueDtoRequest} matrixValueDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValuePost: async (matrixValueDtoRequest: MatrixValueDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matrixValueDtoRequest' is not null or undefined
            assertParamExists('apiV1MatrixValuePost', 'matrixValueDtoRequest', matrixValueDtoRequest)
            const localVarPath = `/api/v1/matrix-value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(matrixValueDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MatrixValueDtoRequest} matrixValueDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValuePut: async (matrixValueDtoRequest: MatrixValueDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matrixValueDtoRequest' is not null or undefined
            assertParamExists('apiV1MatrixValuePut', 'matrixValueDtoRequest', matrixValueDtoRequest)
            const localVarPath = `/api/v1/matrix-value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(matrixValueDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueSaveAsTemplatePost: async (templateCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateCode' is not null or undefined
            assertParamExists('apiV1MatrixValueSaveAsTemplatePost', 'templateCode', templateCode)
            const localVarPath = `/api/v1/matrix-value/save-as-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (templateCode !== undefined && templateCode !== null) {
                localVarHeaderParameter['templateCode'] = String(templateCode);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueSelectTemplatePost: async (templateCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateCode' is not null or undefined
            assertParamExists('apiV1MatrixValueSelectTemplatePost', 'templateCode', templateCode)
            const localVarPath = `/api/v1/matrix-value/select-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (templateCode !== undefined && templateCode !== null) {
                localVarHeaderParameter['templateCode'] = String(templateCode);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MatrixValueApi - functional programming interface
 * @export
 */
export const MatrixValueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MatrixValueApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MatrixValueAllDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MatrixValueAllDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MatrixValueDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MatrixValueDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MatrixValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MatrixValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MatrixValueGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Возвращает коды доступных шаблонов матрицы рисков
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MatrixValueGetTemplatesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MatrixValueGetTemplatesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MatrixValueIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixValueDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MatrixValueIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MatrixValueMatrixGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixValueRowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MatrixValueMatrixGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MatrixValueMatrixPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MatrixValueMatrixPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MatrixValueDtoRequest} matrixValueDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MatrixValuePost(matrixValueDtoRequest: MatrixValueDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixValueDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MatrixValuePost(matrixValueDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MatrixValueDtoRequest} matrixValueDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MatrixValuePut(matrixValueDtoRequest: MatrixValueDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixValueDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MatrixValuePut(matrixValueDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MatrixValueSaveAsTemplatePost(templateCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixValueDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MatrixValueSaveAsTemplatePost(templateCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MatrixValueSelectTemplatePost(templateCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixValueRowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MatrixValueSelectTemplatePost(templateCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MatrixValueApi - factory interface
 * @export
 */
export const MatrixValueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MatrixValueApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueAllDelete(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1MatrixValueAllDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1MatrixValueDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueGet(options?: any): AxiosPromise<Array<MatrixValueDto>> {
            return localVarFp.apiV1MatrixValueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Возвращает коды доступных шаблонов матрицы рисков
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueGetTemplatesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1MatrixValueGetTemplatesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueIdGet(id: string, options?: any): AxiosPromise<MatrixValueDto> {
            return localVarFp.apiV1MatrixValueIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueMatrixGet(options?: any): AxiosPromise<MatrixValueRowDto> {
            return localVarFp.apiV1MatrixValueMatrixGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueMatrixPost(options?: any): AxiosPromise<object> {
            return localVarFp.apiV1MatrixValueMatrixPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MatrixValueDtoRequest} matrixValueDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValuePost(matrixValueDtoRequest: MatrixValueDtoRequest, options?: any): AxiosPromise<MatrixValueDto> {
            return localVarFp.apiV1MatrixValuePost(matrixValueDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MatrixValueDtoRequest} matrixValueDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValuePut(matrixValueDtoRequest: MatrixValueDtoRequest, options?: any): AxiosPromise<MatrixValueDto> {
            return localVarFp.apiV1MatrixValuePut(matrixValueDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueSaveAsTemplatePost(templateCode: string, options?: any): AxiosPromise<MatrixValueDto> {
            return localVarFp.apiV1MatrixValueSaveAsTemplatePost(templateCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MatrixValueSelectTemplatePost(templateCode: string, options?: any): AxiosPromise<MatrixValueRowDto> {
            return localVarFp.apiV1MatrixValueSelectTemplatePost(templateCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MatrixValueApi - object-oriented interface
 * @export
 * @class MatrixValueApi
 * @extends {BaseAPI}
 */
export class MatrixValueApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatrixValueApi
     */
    public apiV1MatrixValueAllDelete(options?: AxiosRequestConfig) {
        return MatrixValueApiFp(this.configuration).apiV1MatrixValueAllDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatrixValueApi
     */
    public apiV1MatrixValueDelete(id?: string, options?: AxiosRequestConfig) {
        return MatrixValueApiFp(this.configuration).apiV1MatrixValueDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatrixValueApi
     */
    public apiV1MatrixValueGet(options?: AxiosRequestConfig) {
        return MatrixValueApiFp(this.configuration).apiV1MatrixValueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Возвращает коды доступных шаблонов матрицы рисков
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatrixValueApi
     */
    public apiV1MatrixValueGetTemplatesGet(options?: AxiosRequestConfig) {
        return MatrixValueApiFp(this.configuration).apiV1MatrixValueGetTemplatesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatrixValueApi
     */
    public apiV1MatrixValueIdGet(id: string, options?: AxiosRequestConfig) {
        return MatrixValueApiFp(this.configuration).apiV1MatrixValueIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatrixValueApi
     */
    public apiV1MatrixValueMatrixGet(options?: AxiosRequestConfig) {
        return MatrixValueApiFp(this.configuration).apiV1MatrixValueMatrixGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatrixValueApi
     */
    public apiV1MatrixValueMatrixPost(options?: AxiosRequestConfig) {
        return MatrixValueApiFp(this.configuration).apiV1MatrixValueMatrixPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MatrixValueDtoRequest} matrixValueDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatrixValueApi
     */
    public apiV1MatrixValuePost(matrixValueDtoRequest: MatrixValueDtoRequest, options?: AxiosRequestConfig) {
        return MatrixValueApiFp(this.configuration).apiV1MatrixValuePost(matrixValueDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MatrixValueDtoRequest} matrixValueDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatrixValueApi
     */
    public apiV1MatrixValuePut(matrixValueDtoRequest: MatrixValueDtoRequest, options?: AxiosRequestConfig) {
        return MatrixValueApiFp(this.configuration).apiV1MatrixValuePut(matrixValueDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatrixValueApi
     */
    public apiV1MatrixValueSaveAsTemplatePost(templateCode: string, options?: AxiosRequestConfig) {
        return MatrixValueApiFp(this.configuration).apiV1MatrixValueSaveAsTemplatePost(templateCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatrixValueApi
     */
    public apiV1MatrixValueSelectTemplatePost(templateCode: string, options?: AxiosRequestConfig) {
        return MatrixValueApiFp(this.configuration).apiV1MatrixValueSelectTemplatePost(templateCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodeApi - axios parameter creator
 * @export
 */
export const NodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/node`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/node`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1NodeIdGet', 'id', id)
            const localVarPath = `/api/v1/node/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NodeDtoRequest} nodeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodePost: async (nodeDtoRequest: NodeDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeDtoRequest' is not null or undefined
            assertParamExists('apiV1NodePost', 'nodeDtoRequest', nodeDtoRequest)
            const localVarPath = `/api/v1/node`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NodeDtoRequest} nodeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodePut: async (nodeDtoRequest: NodeDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeDtoRequest' is not null or undefined
            assertParamExists('apiV1NodePut', 'nodeDtoRequest', nodeDtoRequest)
            const localVarPath = `/api/v1/node`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [nodeId] 
         * @param {string} [equipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeRemoveLinkEquipmentPut: async (nodeId?: string, equipmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/node/remove-link-equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['node-id'] = nodeId;
            }

            if (equipmentId !== undefined) {
                localVarQueryParameter['equipment-id'] = equipmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeApi - functional programming interface
 * @export
 */
export const NodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NodeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NodeDtoRequest} nodeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodePost(nodeDtoRequest: NodeDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodePost(nodeDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NodeDtoRequest} nodeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodePut(nodeDtoRequest: NodeDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodePut(nodeDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [nodeId] 
         * @param {string} [equipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeRemoveLinkEquipmentPut(nodeId?: string, equipmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeRemoveLinkEquipmentPut(nodeId, equipmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodeApi - factory interface
 * @export
 */
export const NodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1NodeDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGet(options?: any): AxiosPromise<Array<NodeDto>> {
            return localVarFp.apiV1NodeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeIdGet(id: string, options?: any): AxiosPromise<NodeDto> {
            return localVarFp.apiV1NodeIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NodeDtoRequest} nodeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodePost(nodeDtoRequest: NodeDtoRequest, options?: any): AxiosPromise<NodeDto> {
            return localVarFp.apiV1NodePost(nodeDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NodeDtoRequest} nodeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodePut(nodeDtoRequest: NodeDtoRequest, options?: any): AxiosPromise<NodeDto> {
            return localVarFp.apiV1NodePut(nodeDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [nodeId] 
         * @param {string} [equipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeRemoveLinkEquipmentPut(nodeId?: string, equipmentId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1NodeRemoveLinkEquipmentPut(nodeId, equipmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeApi - object-oriented interface
 * @export
 * @class NodeApi
 * @extends {BaseAPI}
 */
export class NodeApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public apiV1NodeDelete(id?: string, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).apiV1NodeDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public apiV1NodeGet(options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).apiV1NodeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public apiV1NodeIdGet(id: string, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).apiV1NodeIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NodeDtoRequest} nodeDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public apiV1NodePost(nodeDtoRequest: NodeDtoRequest, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).apiV1NodePost(nodeDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NodeDtoRequest} nodeDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public apiV1NodePut(nodeDtoRequest: NodeDtoRequest, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).apiV1NodePut(nodeDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [nodeId] 
     * @param {string} [equipmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    public apiV1NodeRemoveLinkEquipmentPut(nodeId?: string, equipmentId?: string, options?: AxiosRequestConfig) {
        return NodeApiFp(this.configuration).apiV1NodeRemoveLinkEquipmentPut(nodeId, equipmentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodeAdditionalParameterApi - axios parameter creator
 * @export
 */
export const NodeAdditionalParameterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeAdditionalParameterDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/nodeAdditionalParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeAdditionalParameterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/nodeAdditionalParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeAdditionalParameterIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1NodeAdditionalParameterIdGet', 'id', id)
            const localVarPath = `/api/v1/nodeAdditionalParameter/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NodeAdditionalParameterDtoRequest} nodeAdditionalParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeAdditionalParameterPost: async (nodeAdditionalParameterDtoRequest: NodeAdditionalParameterDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeAdditionalParameterDtoRequest' is not null or undefined
            assertParamExists('apiV1NodeAdditionalParameterPost', 'nodeAdditionalParameterDtoRequest', nodeAdditionalParameterDtoRequest)
            const localVarPath = `/api/v1/nodeAdditionalParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeAdditionalParameterDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NodeAdditionalParameterDtoRequest} nodeAdditionalParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeAdditionalParameterPut: async (nodeAdditionalParameterDtoRequest: NodeAdditionalParameterDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeAdditionalParameterDtoRequest' is not null or undefined
            assertParamExists('apiV1NodeAdditionalParameterPut', 'nodeAdditionalParameterDtoRequest', nodeAdditionalParameterDtoRequest)
            const localVarPath = `/api/v1/nodeAdditionalParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeAdditionalParameterDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeAdditionalParameterApi - functional programming interface
 * @export
 */
export const NodeAdditionalParameterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeAdditionalParameterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeAdditionalParameterDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeAdditionalParameterDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeAdditionalParameterGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NodeAdditionalParameterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeAdditionalParameterGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeAdditionalParameterIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeAdditionalParameterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeAdditionalParameterIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NodeAdditionalParameterDtoRequest} nodeAdditionalParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeAdditionalParameterPost(nodeAdditionalParameterDtoRequest: NodeAdditionalParameterDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeAdditionalParameterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeAdditionalParameterPost(nodeAdditionalParameterDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NodeAdditionalParameterDtoRequest} nodeAdditionalParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeAdditionalParameterPut(nodeAdditionalParameterDtoRequest: NodeAdditionalParameterDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeAdditionalParameterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeAdditionalParameterPut(nodeAdditionalParameterDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodeAdditionalParameterApi - factory interface
 * @export
 */
export const NodeAdditionalParameterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeAdditionalParameterApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeAdditionalParameterDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1NodeAdditionalParameterDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeAdditionalParameterGet(options?: any): AxiosPromise<Array<NodeAdditionalParameterDto>> {
            return localVarFp.apiV1NodeAdditionalParameterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeAdditionalParameterIdGet(id: string, options?: any): AxiosPromise<NodeAdditionalParameterDto> {
            return localVarFp.apiV1NodeAdditionalParameterIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NodeAdditionalParameterDtoRequest} nodeAdditionalParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeAdditionalParameterPost(nodeAdditionalParameterDtoRequest: NodeAdditionalParameterDtoRequest, options?: any): AxiosPromise<NodeAdditionalParameterDto> {
            return localVarFp.apiV1NodeAdditionalParameterPost(nodeAdditionalParameterDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NodeAdditionalParameterDtoRequest} nodeAdditionalParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeAdditionalParameterPut(nodeAdditionalParameterDtoRequest: NodeAdditionalParameterDtoRequest, options?: any): AxiosPromise<NodeAdditionalParameterDto> {
            return localVarFp.apiV1NodeAdditionalParameterPut(nodeAdditionalParameterDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeAdditionalParameterApi - object-oriented interface
 * @export
 * @class NodeAdditionalParameterApi
 * @extends {BaseAPI}
 */
export class NodeAdditionalParameterApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeAdditionalParameterApi
     */
    public apiV1NodeAdditionalParameterDelete(id?: string, options?: AxiosRequestConfig) {
        return NodeAdditionalParameterApiFp(this.configuration).apiV1NodeAdditionalParameterDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeAdditionalParameterApi
     */
    public apiV1NodeAdditionalParameterGet(options?: AxiosRequestConfig) {
        return NodeAdditionalParameterApiFp(this.configuration).apiV1NodeAdditionalParameterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeAdditionalParameterApi
     */
    public apiV1NodeAdditionalParameterIdGet(id: string, options?: AxiosRequestConfig) {
        return NodeAdditionalParameterApiFp(this.configuration).apiV1NodeAdditionalParameterIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NodeAdditionalParameterDtoRequest} nodeAdditionalParameterDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeAdditionalParameterApi
     */
    public apiV1NodeAdditionalParameterPost(nodeAdditionalParameterDtoRequest: NodeAdditionalParameterDtoRequest, options?: AxiosRequestConfig) {
        return NodeAdditionalParameterApiFp(this.configuration).apiV1NodeAdditionalParameterPost(nodeAdditionalParameterDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NodeAdditionalParameterDtoRequest} nodeAdditionalParameterDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeAdditionalParameterApi
     */
    public apiV1NodeAdditionalParameterPut(nodeAdditionalParameterDtoRequest: NodeAdditionalParameterDtoRequest, options?: AxiosRequestConfig) {
        return NodeAdditionalParameterApiFp(this.configuration).apiV1NodeAdditionalParameterPut(nodeAdditionalParameterDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodeGuidewordApi - axios parameter creator
 * @export
 */
export const NodeGuidewordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGuidewordDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/nodeGuideword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGuidewordGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/nodeGuideword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGuidewordIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1NodeGuidewordIdGet', 'id', id)
            const localVarPath = `/api/v1/nodeGuideword/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NodeGuidewordDtoRequest} nodeGuidewordDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGuidewordPost: async (nodeGuidewordDtoRequest: NodeGuidewordDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeGuidewordDtoRequest' is not null or undefined
            assertParamExists('apiV1NodeGuidewordPost', 'nodeGuidewordDtoRequest', nodeGuidewordDtoRequest)
            const localVarPath = `/api/v1/nodeGuideword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeGuidewordDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NodeGuidewordDtoRequest} nodeGuidewordDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGuidewordPut: async (nodeGuidewordDtoRequest: NodeGuidewordDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeGuidewordDtoRequest' is not null or undefined
            assertParamExists('apiV1NodeGuidewordPut', 'nodeGuidewordDtoRequest', nodeGuidewordDtoRequest)
            const localVarPath = `/api/v1/nodeGuideword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeGuidewordDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeGuidewordApi - functional programming interface
 * @export
 */
export const NodeGuidewordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeGuidewordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeGuidewordDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeGuidewordDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeGuidewordGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NodeGuidewordDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeGuidewordGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeGuidewordIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeGuidewordDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeGuidewordIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NodeGuidewordDtoRequest} nodeGuidewordDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeGuidewordPost(nodeGuidewordDtoRequest: NodeGuidewordDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeGuidewordDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeGuidewordPost(nodeGuidewordDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NodeGuidewordDtoRequest} nodeGuidewordDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeGuidewordPut(nodeGuidewordDtoRequest: NodeGuidewordDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeGuidewordDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeGuidewordPut(nodeGuidewordDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodeGuidewordApi - factory interface
 * @export
 */
export const NodeGuidewordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeGuidewordApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGuidewordDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1NodeGuidewordDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGuidewordGet(options?: any): AxiosPromise<Array<NodeGuidewordDto>> {
            return localVarFp.apiV1NodeGuidewordGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGuidewordIdGet(id: string, options?: any): AxiosPromise<NodeGuidewordDto> {
            return localVarFp.apiV1NodeGuidewordIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NodeGuidewordDtoRequest} nodeGuidewordDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGuidewordPost(nodeGuidewordDtoRequest: NodeGuidewordDtoRequest, options?: any): AxiosPromise<NodeGuidewordDto> {
            return localVarFp.apiV1NodeGuidewordPost(nodeGuidewordDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NodeGuidewordDtoRequest} nodeGuidewordDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeGuidewordPut(nodeGuidewordDtoRequest: NodeGuidewordDtoRequest, options?: any): AxiosPromise<NodeGuidewordDto> {
            return localVarFp.apiV1NodeGuidewordPut(nodeGuidewordDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeGuidewordApi - object-oriented interface
 * @export
 * @class NodeGuidewordApi
 * @extends {BaseAPI}
 */
export class NodeGuidewordApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeGuidewordApi
     */
    public apiV1NodeGuidewordDelete(id?: string, options?: AxiosRequestConfig) {
        return NodeGuidewordApiFp(this.configuration).apiV1NodeGuidewordDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeGuidewordApi
     */
    public apiV1NodeGuidewordGet(options?: AxiosRequestConfig) {
        return NodeGuidewordApiFp(this.configuration).apiV1NodeGuidewordGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeGuidewordApi
     */
    public apiV1NodeGuidewordIdGet(id: string, options?: AxiosRequestConfig) {
        return NodeGuidewordApiFp(this.configuration).apiV1NodeGuidewordIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NodeGuidewordDtoRequest} nodeGuidewordDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeGuidewordApi
     */
    public apiV1NodeGuidewordPost(nodeGuidewordDtoRequest: NodeGuidewordDtoRequest, options?: AxiosRequestConfig) {
        return NodeGuidewordApiFp(this.configuration).apiV1NodeGuidewordPost(nodeGuidewordDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NodeGuidewordDtoRequest} nodeGuidewordDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeGuidewordApi
     */
    public apiV1NodeGuidewordPut(nodeGuidewordDtoRequest: NodeGuidewordDtoRequest, options?: AxiosRequestConfig) {
        return NodeGuidewordApiFp(this.configuration).apiV1NodeGuidewordPut(nodeGuidewordDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodeParameterApi - axios parameter creator
 * @export
 */
export const NodeParameterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeParameterDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/nodeParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeParameterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/nodeParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeParameterIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1NodeParameterIdGet', 'id', id)
            const localVarPath = `/api/v1/nodeParameter/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NodeParameterDtoRequest} nodeParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeParameterPost: async (nodeParameterDtoRequest: NodeParameterDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeParameterDtoRequest' is not null or undefined
            assertParamExists('apiV1NodeParameterPost', 'nodeParameterDtoRequest', nodeParameterDtoRequest)
            const localVarPath = `/api/v1/nodeParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeParameterDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NodeParameterDtoRequest} nodeParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeParameterPut: async (nodeParameterDtoRequest: NodeParameterDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeParameterDtoRequest' is not null or undefined
            assertParamExists('apiV1NodeParameterPut', 'nodeParameterDtoRequest', nodeParameterDtoRequest)
            const localVarPath = `/api/v1/nodeParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeParameterDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeParameterApi - functional programming interface
 * @export
 */
export const NodeParameterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeParameterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeParameterDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeParameterDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeParameterGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NodeParameterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeParameterGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeParameterIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeParameterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeParameterIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NodeParameterDtoRequest} nodeParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeParameterPost(nodeParameterDtoRequest: NodeParameterDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeParameterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeParameterPost(nodeParameterDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NodeParameterDtoRequest} nodeParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NodeParameterPut(nodeParameterDtoRequest: NodeParameterDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeParameterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NodeParameterPut(nodeParameterDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodeParameterApi - factory interface
 * @export
 */
export const NodeParameterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeParameterApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeParameterDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1NodeParameterDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeParameterGet(options?: any): AxiosPromise<Array<NodeParameterDto>> {
            return localVarFp.apiV1NodeParameterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeParameterIdGet(id: string, options?: any): AxiosPromise<NodeParameterDto> {
            return localVarFp.apiV1NodeParameterIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NodeParameterDtoRequest} nodeParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeParameterPost(nodeParameterDtoRequest: NodeParameterDtoRequest, options?: any): AxiosPromise<NodeParameterDto> {
            return localVarFp.apiV1NodeParameterPost(nodeParameterDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NodeParameterDtoRequest} nodeParameterDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NodeParameterPut(nodeParameterDtoRequest: NodeParameterDtoRequest, options?: any): AxiosPromise<NodeParameterDto> {
            return localVarFp.apiV1NodeParameterPut(nodeParameterDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeParameterApi - object-oriented interface
 * @export
 * @class NodeParameterApi
 * @extends {BaseAPI}
 */
export class NodeParameterApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeParameterApi
     */
    public apiV1NodeParameterDelete(id?: string, options?: AxiosRequestConfig) {
        return NodeParameterApiFp(this.configuration).apiV1NodeParameterDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeParameterApi
     */
    public apiV1NodeParameterGet(options?: AxiosRequestConfig) {
        return NodeParameterApiFp(this.configuration).apiV1NodeParameterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeParameterApi
     */
    public apiV1NodeParameterIdGet(id: string, options?: AxiosRequestConfig) {
        return NodeParameterApiFp(this.configuration).apiV1NodeParameterIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NodeParameterDtoRequest} nodeParameterDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeParameterApi
     */
    public apiV1NodeParameterPost(nodeParameterDtoRequest: NodeParameterDtoRequest, options?: AxiosRequestConfig) {
        return NodeParameterApiFp(this.configuration).apiV1NodeParameterPost(nodeParameterDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NodeParameterDtoRequest} nodeParameterDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeParameterApi
     */
    public apiV1NodeParameterPut(nodeParameterDtoRequest: NodeParameterDtoRequest, options?: AxiosRequestConfig) {
        return NodeParameterApiFp(this.configuration).apiV1NodeParameterPut(nodeParameterDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PdfApi - axios parameter creator
 * @export
 */
export const PdfApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} pdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PdfContentGet: async (pdfId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfId' is not null or undefined
            assertParamExists('apiV1PdfContentGet', 'pdfId', pdfId)
            const localVarPath = `/api/v1/pdf/content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pdfId !== undefined) {
                localVarQueryParameter['pdf-id'] = pdfId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PdfDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PdfGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PdfPdfIdGet: async (pdfId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfId' is not null or undefined
            assertParamExists('apiV1PdfPdfIdGet', 'pdfId', pdfId)
            const localVarPath = `/api/v1/pdf/{pdf-id}`
                .replace(`{${"pdf-id"}}`, encodeURIComponent(String(pdfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PdfUploadPost: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pdf/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PdfApi - functional programming interface
 * @export
 */
export const PdfApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PdfApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} pdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PdfContentGet(pdfId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PdfContentGet(pdfId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PdfDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PdfDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PdfGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PdfFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PdfGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} pdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PdfPdfIdGet(pdfId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PdfPdfIdGet(pdfId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PdfUploadPost(file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PdfUploadPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PdfApi - factory interface
 * @export
 */
export const PdfApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PdfApiFp(configuration)
    return {
        /**
         * 
         * @param {string} pdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PdfContentGet(pdfId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1PdfContentGet(pdfId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PdfDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1PdfDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PdfGet(options?: any): AxiosPromise<Array<PdfFileDto>> {
            return localVarFp.apiV1PdfGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pdfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PdfPdfIdGet(pdfId: string, options?: any): AxiosPromise<PdfFileDto> {
            return localVarFp.apiV1PdfPdfIdGet(pdfId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PdfUploadPost(file?: any, options?: any): AxiosPromise<PdfFileDto> {
            return localVarFp.apiV1PdfUploadPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PdfApi - object-oriented interface
 * @export
 * @class PdfApi
 * @extends {BaseAPI}
 */
export class PdfApi extends BaseAPI {
    /**
     * 
     * @param {string} pdfId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfApi
     */
    public apiV1PdfContentGet(pdfId: string, options?: AxiosRequestConfig) {
        return PdfApiFp(this.configuration).apiV1PdfContentGet(pdfId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfApi
     */
    public apiV1PdfDelete(id?: string, options?: AxiosRequestConfig) {
        return PdfApiFp(this.configuration).apiV1PdfDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfApi
     */
    public apiV1PdfGet(options?: AxiosRequestConfig) {
        return PdfApiFp(this.configuration).apiV1PdfGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pdfId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfApi
     */
    public apiV1PdfPdfIdGet(pdfId: string, options?: AxiosRequestConfig) {
        return PdfApiFp(this.configuration).apiV1PdfPdfIdGet(pdfId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfApi
     */
    public apiV1PdfUploadPost(file?: any, options?: AxiosRequestConfig) {
        return PdfApiFp(this.configuration).apiV1PdfUploadPost(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecommendationApi - axios parameter creator
 * @export
 */
export const RecommendationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/recommendation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/recommendation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationHazopEntryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RecommendationHazopEntryIdGet', 'id', id)
            const localVarPath = `/api/v1/recommendation/hazopEntry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RecommendationIdGet', 'id', id)
            const localVarPath = `/api/v1/recommendation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RecommendationDtoRequest} recommendationDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationPost: async (recommendationDtoRequest: RecommendationDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recommendationDtoRequest' is not null or undefined
            assertParamExists('apiV1RecommendationPost', 'recommendationDtoRequest', recommendationDtoRequest)
            const localVarPath = `/api/v1/recommendation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recommendationDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RecommendationDtoRequest} recommendationDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationPut: async (recommendationDtoRequest: RecommendationDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recommendationDtoRequest' is not null or undefined
            assertParamExists('apiV1RecommendationPut', 'recommendationDtoRequest', recommendationDtoRequest)
            const localVarPath = `/api/v1/recommendation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recommendationDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecommendationApi - functional programming interface
 * @export
 */
export const RecommendationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecommendationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RecommendationDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RecommendationDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RecommendationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecommendationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RecommendationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RecommendationHazopEntryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecommendationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RecommendationHazopEntryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RecommendationIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecommendationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RecommendationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RecommendationDtoRequest} recommendationDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RecommendationPost(recommendationDtoRequest: RecommendationDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecommendationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RecommendationPost(recommendationDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RecommendationDtoRequest} recommendationDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RecommendationPut(recommendationDtoRequest: RecommendationDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecommendationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RecommendationPut(recommendationDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecommendationApi - factory interface
 * @export
 */
export const RecommendationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecommendationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1RecommendationDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationGet(options?: any): AxiosPromise<Array<RecommendationDto>> {
            return localVarFp.apiV1RecommendationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationHazopEntryIdGet(id: string, options?: any): AxiosPromise<Array<RecommendationDto>> {
            return localVarFp.apiV1RecommendationHazopEntryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationIdGet(id: string, options?: any): AxiosPromise<RecommendationDto> {
            return localVarFp.apiV1RecommendationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RecommendationDtoRequest} recommendationDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationPost(recommendationDtoRequest: RecommendationDtoRequest, options?: any): AxiosPromise<RecommendationDto> {
            return localVarFp.apiV1RecommendationPost(recommendationDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RecommendationDtoRequest} recommendationDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecommendationPut(recommendationDtoRequest: RecommendationDtoRequest, options?: any): AxiosPromise<RecommendationDto> {
            return localVarFp.apiV1RecommendationPut(recommendationDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecommendationApi - object-oriented interface
 * @export
 * @class RecommendationApi
 * @extends {BaseAPI}
 */
export class RecommendationApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationApi
     */
    public apiV1RecommendationDelete(id?: string, options?: AxiosRequestConfig) {
        return RecommendationApiFp(this.configuration).apiV1RecommendationDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationApi
     */
    public apiV1RecommendationGet(options?: AxiosRequestConfig) {
        return RecommendationApiFp(this.configuration).apiV1RecommendationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationApi
     */
    public apiV1RecommendationHazopEntryIdGet(id: string, options?: AxiosRequestConfig) {
        return RecommendationApiFp(this.configuration).apiV1RecommendationHazopEntryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationApi
     */
    public apiV1RecommendationIdGet(id: string, options?: AxiosRequestConfig) {
        return RecommendationApiFp(this.configuration).apiV1RecommendationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RecommendationDtoRequest} recommendationDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationApi
     */
    public apiV1RecommendationPost(recommendationDtoRequest: RecommendationDtoRequest, options?: AxiosRequestConfig) {
        return RecommendationApiFp(this.configuration).apiV1RecommendationPost(recommendationDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RecommendationDtoRequest} recommendationDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationApi
     */
    public apiV1RecommendationPut(recommendationDtoRequest: RecommendationDtoRequest, options?: AxiosRequestConfig) {
        return RecommendationApiFp(this.configuration).apiV1RecommendationPut(recommendationDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleCreatePost: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/role/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RoleIdGet', 'id', id)
            const localVarPath = `/api/v1/role/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RoleCreatePost(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RoleCreatePost(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RoleGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RoleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RoleIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RoleIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleCreatePost(name?: string, options?: any): AxiosPromise<RoleDto> {
            return localVarFp.apiV1RoleCreatePost(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleGet(options?: any): AxiosPromise<Array<RoleDto>> {
            return localVarFp.apiV1RoleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleIdGet(id: number, options?: any): AxiosPromise<RoleDto> {
            return localVarFp.apiV1RoleIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiV1RoleCreatePost(name?: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiV1RoleCreatePost(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiV1RoleGet(options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiV1RoleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiV1RoleIdGet(id: number, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiV1RoleIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SafeguardApi - axios parameter creator
 * @export
 */
export const SafeguardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/safeguard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/safeguard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardHazopEntryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1SafeguardHazopEntryIdGet', 'id', id)
            const localVarPath = `/api/v1/safeguard/hazopEntry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1SafeguardIdGet', 'id', id)
            const localVarPath = `/api/v1/safeguard/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SafeguardDtoRequest} safeguardDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardPost: async (safeguardDtoRequest: SafeguardDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'safeguardDtoRequest' is not null or undefined
            assertParamExists('apiV1SafeguardPost', 'safeguardDtoRequest', safeguardDtoRequest)
            const localVarPath = `/api/v1/safeguard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(safeguardDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SafeguardDtoRequest} safeguardDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardPut: async (safeguardDtoRequest: SafeguardDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'safeguardDtoRequest' is not null or undefined
            assertParamExists('apiV1SafeguardPut', 'safeguardDtoRequest', safeguardDtoRequest)
            const localVarPath = `/api/v1/safeguard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(safeguardDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SafeguardApi - functional programming interface
 * @export
 */
export const SafeguardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SafeguardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SafeguardDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SafeguardDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SafeguardGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SafeguardDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SafeguardGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SafeguardHazopEntryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SafeguardDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SafeguardHazopEntryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SafeguardIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SafeguardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SafeguardIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SafeguardDtoRequest} safeguardDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SafeguardPost(safeguardDtoRequest: SafeguardDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SafeguardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SafeguardPost(safeguardDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SafeguardDtoRequest} safeguardDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SafeguardPut(safeguardDtoRequest: SafeguardDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SafeguardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SafeguardPut(safeguardDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SafeguardApi - factory interface
 * @export
 */
export const SafeguardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SafeguardApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SafeguardDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardGet(options?: any): AxiosPromise<Array<SafeguardDto>> {
            return localVarFp.apiV1SafeguardGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardHazopEntryIdGet(id: string, options?: any): AxiosPromise<Array<SafeguardDto>> {
            return localVarFp.apiV1SafeguardHazopEntryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardIdGet(id: string, options?: any): AxiosPromise<SafeguardDto> {
            return localVarFp.apiV1SafeguardIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SafeguardDtoRequest} safeguardDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardPost(safeguardDtoRequest: SafeguardDtoRequest, options?: any): AxiosPromise<SafeguardDto> {
            return localVarFp.apiV1SafeguardPost(safeguardDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SafeguardDtoRequest} safeguardDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SafeguardPut(safeguardDtoRequest: SafeguardDtoRequest, options?: any): AxiosPromise<SafeguardDto> {
            return localVarFp.apiV1SafeguardPut(safeguardDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SafeguardApi - object-oriented interface
 * @export
 * @class SafeguardApi
 * @extends {BaseAPI}
 */
export class SafeguardApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafeguardApi
     */
    public apiV1SafeguardDelete(id?: string, options?: AxiosRequestConfig) {
        return SafeguardApiFp(this.configuration).apiV1SafeguardDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafeguardApi
     */
    public apiV1SafeguardGet(options?: AxiosRequestConfig) {
        return SafeguardApiFp(this.configuration).apiV1SafeguardGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafeguardApi
     */
    public apiV1SafeguardHazopEntryIdGet(id: string, options?: AxiosRequestConfig) {
        return SafeguardApiFp(this.configuration).apiV1SafeguardHazopEntryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafeguardApi
     */
    public apiV1SafeguardIdGet(id: string, options?: AxiosRequestConfig) {
        return SafeguardApiFp(this.configuration).apiV1SafeguardIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SafeguardDtoRequest} safeguardDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafeguardApi
     */
    public apiV1SafeguardPost(safeguardDtoRequest: SafeguardDtoRequest, options?: AxiosRequestConfig) {
        return SafeguardApiFp(this.configuration).apiV1SafeguardPost(safeguardDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SafeguardDtoRequest} safeguardDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafeguardApi
     */
    public apiV1SafeguardPut(safeguardDtoRequest: SafeguardDtoRequest, options?: AxiosRequestConfig) {
        return SafeguardApiFp(this.configuration).apiV1SafeguardPut(safeguardDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SchemeApi - axios parameter creator
 * @export
 */
export const SchemeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [nodeId] 
         * @param {string} [schemeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemeAttachToNodePost: async (nodeId?: string, schemeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/scheme/attach-to-node`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }

            if (schemeId !== undefined) {
                localVarQueryParameter['schemeId'] = schemeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemeDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/scheme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/scheme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemeIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1SchemeIdGet', 'id', id)
            const localVarPath = `/api/v1/scheme/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SchemeDtoRequest} schemeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemePost: async (schemeDtoRequest: SchemeDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schemeDtoRequest' is not null or undefined
            assertParamExists('apiV1SchemePost', 'schemeDtoRequest', schemeDtoRequest)
            const localVarPath = `/api/v1/scheme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schemeDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SchemeDtoRequest} schemeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemePut: async (schemeDtoRequest: SchemeDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schemeDtoRequest' is not null or undefined
            assertParamExists('apiV1SchemePut', 'schemeDtoRequest', schemeDtoRequest)
            const localVarPath = `/api/v1/scheme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schemeDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemeSearchGet: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/scheme/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchemeApi - functional programming interface
 * @export
 */
export const SchemeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchemeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [nodeId] 
         * @param {string} [schemeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SchemeAttachToNodePost(nodeId?: string, schemeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SchemeAttachToNodePost(nodeId, schemeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SchemeDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SchemeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SchemeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SchemeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SchemeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SchemeIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchemeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SchemeIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SchemeDtoRequest} schemeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SchemePost(schemeDtoRequest: SchemeDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchemeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SchemePost(schemeDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SchemeDtoRequest} schemeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SchemePut(schemeDtoRequest: SchemeDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchemeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SchemePut(schemeDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SchemeSearchGet(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SchemeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SchemeSearchGet(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchemeApi - factory interface
 * @export
 */
export const SchemeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchemeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [nodeId] 
         * @param {string} [schemeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemeAttachToNodePost(nodeId?: string, schemeId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SchemeAttachToNodePost(nodeId, schemeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemeDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SchemeDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemeGet(options?: any): AxiosPromise<Array<SchemeDto>> {
            return localVarFp.apiV1SchemeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemeIdGet(id: string, options?: any): AxiosPromise<SchemeDto> {
            return localVarFp.apiV1SchemeIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchemeDtoRequest} schemeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemePost(schemeDtoRequest: SchemeDtoRequest, options?: any): AxiosPromise<SchemeDto> {
            return localVarFp.apiV1SchemePost(schemeDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchemeDtoRequest} schemeDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemePut(schemeDtoRequest: SchemeDtoRequest, options?: any): AxiosPromise<SchemeDto> {
            return localVarFp.apiV1SchemePut(schemeDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SchemeSearchGet(name?: string, options?: any): AxiosPromise<Array<SchemeDto>> {
            return localVarFp.apiV1SchemeSearchGet(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchemeApi - object-oriented interface
 * @export
 * @class SchemeApi
 * @extends {BaseAPI}
 */
export class SchemeApi extends BaseAPI {
    /**
     * 
     * @param {string} [nodeId] 
     * @param {string} [schemeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemeApi
     */
    public apiV1SchemeAttachToNodePost(nodeId?: string, schemeId?: string, options?: AxiosRequestConfig) {
        return SchemeApiFp(this.configuration).apiV1SchemeAttachToNodePost(nodeId, schemeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemeApi
     */
    public apiV1SchemeDelete(id?: string, options?: AxiosRequestConfig) {
        return SchemeApiFp(this.configuration).apiV1SchemeDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemeApi
     */
    public apiV1SchemeGet(options?: AxiosRequestConfig) {
        return SchemeApiFp(this.configuration).apiV1SchemeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemeApi
     */
    public apiV1SchemeIdGet(id: string, options?: AxiosRequestConfig) {
        return SchemeApiFp(this.configuration).apiV1SchemeIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchemeDtoRequest} schemeDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemeApi
     */
    public apiV1SchemePost(schemeDtoRequest: SchemeDtoRequest, options?: AxiosRequestConfig) {
        return SchemeApiFp(this.configuration).apiV1SchemePost(schemeDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchemeDtoRequest} schemeDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemeApi
     */
    public apiV1SchemePut(schemeDtoRequest: SchemeDtoRequest, options?: AxiosRequestConfig) {
        return SchemeApiFp(this.configuration).apiV1SchemePut(schemeDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemeApi
     */
    public apiV1SchemeSearchGet(name?: string, options?: AxiosRequestConfig) {
        return SchemeApiFp(this.configuration).apiV1SchemeSearchGet(name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsComponentEnableGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/component-enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} componentEnable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsComponentEnablePost: async (componentEnable: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentEnable' is not null or undefined
            assertParamExists('apiV1SettingsComponentEnablePost', 'componentEnable', componentEnable)
            const localVarPath = `/api/v1/settings/component-enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (componentEnable !== undefined) {
                localVarQueryParameter['componentEnable'] = componentEnable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsHazopProjectStepGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/hazop-project-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [hazopProjectStep] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsHazopProjectStepPost: async (hazopProjectStep?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/hazop-project-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hazopProjectStep !== undefined) {
                localVarQueryParameter['hazopProjectStep'] = hazopProjectStep;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsHazopTypeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/hazop-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [hazopType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsHazopTypePost: async (hazopType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/hazop-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hazopType !== undefined) {
                localVarQueryParameter['hazopType'] = hazopType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsLopaEnableGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/lopa-enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} lopaEnable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsLopaEnablePost: async (lopaEnable: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lopaEnable' is not null or undefined
            assertParamExists('apiV1SettingsLopaEnablePost', 'lopaEnable', lopaEnable)
            const localVarPath = `/api/v1/settings/lopa-enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lopaEnable !== undefined) {
                localVarQueryParameter['lopaEnable'] = lopaEnable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsLopaSettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/lopa-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LopaSettingsDto} [lopaSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsLopaSettingsPost: async (lopaSettingsDto?: LopaSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/lopa-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lopaSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsMatrixHeightGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/matrix-height`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [matrixHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsMatrixHeightPost: async (matrixHeight?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/matrix-height`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (matrixHeight !== undefined) {
                localVarQueryParameter['matrixHeight'] = matrixHeight;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsPreLopaCalculationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/preLopa-calculation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} preLopaCalculation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsPreLopaCalculationPost: async (preLopaCalculation: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preLopaCalculation' is not null or undefined
            assertParamExists('apiV1SettingsPreLopaCalculationPost', 'preLopaCalculation', preLopaCalculation)
            const localVarPath = `/api/v1/settings/preLopa-calculation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (preLopaCalculation !== undefined) {
                localVarQueryParameter['preLopaCalculation'] = preLopaCalculation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsProjectNameGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/project-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsProjectNamePost: async (projectName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/project-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectName !== undefined) {
                localVarQueryParameter['projectName'] = projectName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsXAxisLabelGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/x-axis-label`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsXAxisLabelPost: async (value?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/x-axis-label`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsYAxisLabelGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/y-axis-label`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsYAxisLabelPost: async (value?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/y-axis-label`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsComponentEnableGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsComponentEnableGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} componentEnable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsComponentEnablePost(componentEnable: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsComponentEnablePost(componentEnable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsHazopProjectStepGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsHazopProjectStepGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [hazopProjectStep] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsHazopProjectStepPost(hazopProjectStep?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsHazopProjectStepPost(hazopProjectStep, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsHazopTypeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsHazopTypeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [hazopType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsHazopTypePost(hazopType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsHazopTypePost(hazopType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsLopaEnableGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsLopaEnableGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} lopaEnable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsLopaEnablePost(lopaEnable: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsLopaEnablePost(lopaEnable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsLopaSettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LopaSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsLopaSettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LopaSettingsDto} [lopaSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsLopaSettingsPost(lopaSettingsDto?: LopaSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LopaSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsLopaSettingsPost(lopaSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsMatrixHeightGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsMatrixHeightGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [matrixHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsMatrixHeightPost(matrixHeight?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsMatrixHeightPost(matrixHeight, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsPreLopaCalculationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsPreLopaCalculationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} preLopaCalculation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsPreLopaCalculationPost(preLopaCalculation: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsPreLopaCalculationPost(preLopaCalculation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsProjectNameGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsProjectNameGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [projectName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsProjectNamePost(projectName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsProjectNamePost(projectName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsXAxisLabelGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsXAxisLabelGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsXAxisLabelPost(value?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsXAxisLabelPost(value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsYAxisLabelGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsYAxisLabelGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SettingsYAxisLabelPost(value?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SettingsYAxisLabelPost(value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsComponentEnableGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SettingsComponentEnableGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} componentEnable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsComponentEnablePost(componentEnable: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SettingsComponentEnablePost(componentEnable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsHazopProjectStepGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiV1SettingsHazopProjectStepGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [hazopProjectStep] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsHazopProjectStepPost(hazopProjectStep?: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiV1SettingsHazopProjectStepPost(hazopProjectStep, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsHazopTypeGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiV1SettingsHazopTypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [hazopType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsHazopTypePost(hazopType?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SettingsHazopTypePost(hazopType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsLopaEnableGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SettingsLopaEnableGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} lopaEnable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsLopaEnablePost(lopaEnable: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SettingsLopaEnablePost(lopaEnable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsLopaSettingsGet(options?: any): AxiosPromise<LopaSettingsDto> {
            return localVarFp.apiV1SettingsLopaSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LopaSettingsDto} [lopaSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsLopaSettingsPost(lopaSettingsDto?: LopaSettingsDto, options?: any): AxiosPromise<LopaSettingsDto> {
            return localVarFp.apiV1SettingsLopaSettingsPost(lopaSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsMatrixHeightGet(options?: any): AxiosPromise<number> {
            return localVarFp.apiV1SettingsMatrixHeightGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [matrixHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsMatrixHeightPost(matrixHeight?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SettingsMatrixHeightPost(matrixHeight, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsPreLopaCalculationGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SettingsPreLopaCalculationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} preLopaCalculation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsPreLopaCalculationPost(preLopaCalculation: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SettingsPreLopaCalculationPost(preLopaCalculation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsProjectNameGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiV1SettingsProjectNameGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsProjectNamePost(projectName?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SettingsProjectNamePost(projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsXAxisLabelGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiV1SettingsXAxisLabelGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsXAxisLabelPost(value?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SettingsXAxisLabelPost(value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsYAxisLabelGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiV1SettingsYAxisLabelGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SettingsYAxisLabelPost(value?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SettingsYAxisLabelPost(value, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsComponentEnableGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsComponentEnableGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} componentEnable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsComponentEnablePost(componentEnable: boolean, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsComponentEnablePost(componentEnable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsHazopProjectStepGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsHazopProjectStepGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [hazopProjectStep] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsHazopProjectStepPost(hazopProjectStep?: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsHazopProjectStepPost(hazopProjectStep, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsHazopTypeGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsHazopTypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [hazopType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsHazopTypePost(hazopType?: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsHazopTypePost(hazopType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsLopaEnableGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsLopaEnableGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} lopaEnable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsLopaEnablePost(lopaEnable: boolean, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsLopaEnablePost(lopaEnable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsLopaSettingsGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsLopaSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LopaSettingsDto} [lopaSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsLopaSettingsPost(lopaSettingsDto?: LopaSettingsDto, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsLopaSettingsPost(lopaSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsMatrixHeightGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsMatrixHeightGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [matrixHeight] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsMatrixHeightPost(matrixHeight?: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsMatrixHeightPost(matrixHeight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsPreLopaCalculationGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsPreLopaCalculationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} preLopaCalculation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsPreLopaCalculationPost(preLopaCalculation: boolean, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsPreLopaCalculationPost(preLopaCalculation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsProjectNameGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsProjectNameGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsProjectNamePost(projectName?: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsProjectNamePost(projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsXAxisLabelGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsXAxisLabelGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsXAxisLabelPost(value?: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsXAxisLabelPost(value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsYAxisLabelGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsYAxisLabelGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiV1SettingsYAxisLabelPost(value?: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiV1SettingsYAxisLabelPost(value, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SilEntryApi - axios parameter creator
 * @export
 */
export const SilEntryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILEntryDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sILEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILEntryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sILEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILEntryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1SILEntryIdGet', 'id', id)
            const localVarPath = `/api/v1/sILEntry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SilEntryDtoRequest} silEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILEntryPost: async (silEntryDtoRequest: SilEntryDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'silEntryDtoRequest' is not null or undefined
            assertParamExists('apiV1SILEntryPost', 'silEntryDtoRequest', silEntryDtoRequest)
            const localVarPath = `/api/v1/sILEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(silEntryDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SilEntryDtoRequest} silEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILEntryPut: async (silEntryDtoRequest: SilEntryDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'silEntryDtoRequest' is not null or undefined
            assertParamExists('apiV1SILEntryPut', 'silEntryDtoRequest', silEntryDtoRequest)
            const localVarPath = `/api/v1/sILEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(silEntryDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SilEntryApi - functional programming interface
 * @export
 */
export const SilEntryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SilEntryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SILEntryDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SILEntryDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SILEntryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SilEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SILEntryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SILEntryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SilEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SILEntryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SilEntryDtoRequest} silEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SILEntryPost(silEntryDtoRequest: SilEntryDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SilEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SILEntryPost(silEntryDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SilEntryDtoRequest} silEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SILEntryPut(silEntryDtoRequest: SilEntryDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SilEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SILEntryPut(silEntryDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SilEntryApi - factory interface
 * @export
 */
export const SilEntryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SilEntryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILEntryDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SILEntryDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILEntryGet(options?: any): AxiosPromise<Array<SilEntryDto>> {
            return localVarFp.apiV1SILEntryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILEntryIdGet(id: string, options?: any): AxiosPromise<SilEntryDto> {
            return localVarFp.apiV1SILEntryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilEntryDtoRequest} silEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILEntryPost(silEntryDtoRequest: SilEntryDtoRequest, options?: any): AxiosPromise<SilEntryDto> {
            return localVarFp.apiV1SILEntryPost(silEntryDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilEntryDtoRequest} silEntryDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILEntryPut(silEntryDtoRequest: SilEntryDtoRequest, options?: any): AxiosPromise<SilEntryDto> {
            return localVarFp.apiV1SILEntryPut(silEntryDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SilEntryApi - object-oriented interface
 * @export
 * @class SilEntryApi
 * @extends {BaseAPI}
 */
export class SilEntryApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilEntryApi
     */
    public apiV1SILEntryDelete(id?: string, options?: AxiosRequestConfig) {
        return SilEntryApiFp(this.configuration).apiV1SILEntryDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilEntryApi
     */
    public apiV1SILEntryGet(options?: AxiosRequestConfig) {
        return SilEntryApiFp(this.configuration).apiV1SILEntryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilEntryApi
     */
    public apiV1SILEntryIdGet(id: string, options?: AxiosRequestConfig) {
        return SilEntryApiFp(this.configuration).apiV1SILEntryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilEntryDtoRequest} silEntryDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilEntryApi
     */
    public apiV1SILEntryPost(silEntryDtoRequest: SilEntryDtoRequest, options?: AxiosRequestConfig) {
        return SilEntryApiFp(this.configuration).apiV1SILEntryPost(silEntryDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilEntryDtoRequest} silEntryDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilEntryApi
     */
    public apiV1SILEntryPut(silEntryDtoRequest: SilEntryDtoRequest, options?: AxiosRequestConfig) {
        return SilEntryApiFp(this.configuration).apiV1SILEntryPut(silEntryDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SilTableApi - axios parameter creator
 * @export
 */
export const SilTableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILTableDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sILTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILTableGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sILTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILTableIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1SILTableIdGet', 'id', id)
            const localVarPath = `/api/v1/sILTable/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SilTableDtoRequest} silTableDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILTablePost: async (silTableDtoRequest: SilTableDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'silTableDtoRequest' is not null or undefined
            assertParamExists('apiV1SILTablePost', 'silTableDtoRequest', silTableDtoRequest)
            const localVarPath = `/api/v1/sILTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(silTableDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SilTableDtoRequest} silTableDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILTablePut: async (silTableDtoRequest: SilTableDtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'silTableDtoRequest' is not null or undefined
            assertParamExists('apiV1SILTablePut', 'silTableDtoRequest', silTableDtoRequest)
            const localVarPath = `/api/v1/sILTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(silTableDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SilTableApi - functional programming interface
 * @export
 */
export const SilTableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SilTableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SILTableDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SILTableDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SILTableGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SilTableDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SILTableGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SILTableIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SilTableDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SILTableIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SilTableDtoRequest} silTableDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SILTablePost(silTableDtoRequest: SilTableDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SilTableDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SILTablePost(silTableDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SilTableDtoRequest} silTableDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SILTablePut(silTableDtoRequest: SilTableDtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SilTableDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SILTablePut(silTableDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SilTableApi - factory interface
 * @export
 */
export const SilTableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SilTableApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILTableDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SILTableDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILTableGet(options?: any): AxiosPromise<Array<SilTableDto>> {
            return localVarFp.apiV1SILTableGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILTableIdGet(id: string, options?: any): AxiosPromise<SilTableDto> {
            return localVarFp.apiV1SILTableIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilTableDtoRequest} silTableDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILTablePost(silTableDtoRequest: SilTableDtoRequest, options?: any): AxiosPromise<SilTableDto> {
            return localVarFp.apiV1SILTablePost(silTableDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilTableDtoRequest} silTableDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SILTablePut(silTableDtoRequest: SilTableDtoRequest, options?: any): AxiosPromise<SilTableDto> {
            return localVarFp.apiV1SILTablePut(silTableDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SilTableApi - object-oriented interface
 * @export
 * @class SilTableApi
 * @extends {BaseAPI}
 */
export class SilTableApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilTableApi
     */
    public apiV1SILTableDelete(id?: string, options?: AxiosRequestConfig) {
        return SilTableApiFp(this.configuration).apiV1SILTableDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilTableApi
     */
    public apiV1SILTableGet(options?: AxiosRequestConfig) {
        return SilTableApiFp(this.configuration).apiV1SILTableGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilTableApi
     */
    public apiV1SILTableIdGet(id: string, options?: AxiosRequestConfig) {
        return SilTableApiFp(this.configuration).apiV1SILTableIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilTableDtoRequest} silTableDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilTableApi
     */
    public apiV1SILTablePost(silTableDtoRequest: SilTableDtoRequest, options?: AxiosRequestConfig) {
        return SilTableApiFp(this.configuration).apiV1SILTablePost(silTableDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilTableDtoRequest} silTableDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilTableApi
     */
    public apiV1SILTablePut(silTableDtoRequest: SilTableDtoRequest, options?: AxiosRequestConfig) {
        return SilTableApiFp(this.configuration).apiV1SILTablePut(silTableDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplatesApi - axios parameter creator
 * @export
 */
export const TemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [eightLines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemplatesHazopGet: async (eightLines?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/templates/hazop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eightLines !== undefined) {
                localVarQueryParameter['eight-lines'] = eightLines;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemplatesLopaGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/templates/lopa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemplatesLopaV2Get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/templates/lopa-v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemplatesSilTemplateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/templates/sil-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplatesApi - functional programming interface
 * @export
 */
export const TemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [eightLines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TemplatesHazopGet(eightLines?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TemplatesHazopGet(eightLines, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TemplatesLopaGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TemplatesLopaGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TemplatesLopaV2Get(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TemplatesLopaV2Get(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TemplatesSilTemplateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TemplatesSilTemplateGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplatesApi - factory interface
 * @export
 */
export const TemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplatesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [eightLines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemplatesHazopGet(eightLines?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1TemplatesHazopGet(eightLines, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemplatesLopaGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1TemplatesLopaGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemplatesLopaV2Get(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1TemplatesLopaV2Get(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemplatesSilTemplateGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1TemplatesSilTemplateGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplatesApi - object-oriented interface
 * @export
 * @class TemplatesApi
 * @extends {BaseAPI}
 */
export class TemplatesApi extends BaseAPI {
    /**
     * 
     * @param {string} [eightLines] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public apiV1TemplatesHazopGet(eightLines?: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).apiV1TemplatesHazopGet(eightLines, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public apiV1TemplatesLopaGet(options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).apiV1TemplatesLopaGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public apiV1TemplatesLopaV2Get(options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).apiV1TemplatesLopaV2Get(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public apiV1TemplatesSilTemplateGet(options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).apiV1TemplatesSilTemplateGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [userId] 
         * @param {number} [roleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserAddToRolePost: async (userId?: number, roleId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/add-to-role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserCreatePost: async (authRequest: AuthRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authRequest' is not null or undefined
            assertParamExists('apiV1UserCreatePost', 'authRequest', authRequest)
            const localVarPath = `/api/v1/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserGetMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/get-me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UserIdDelete', 'id', id)
            const localVarPath = `/api/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UserIdGet', 'id', id)
            const localVarPath = `/api/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserLoginPost: async (authRequest: AuthRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authRequest' is not null or undefined
            assertParamExists('apiV1UserLoginPost', 'authRequest', authRequest)
            const localVarPath = `/api/v1/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userLogin] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUpdateTokenPost: async (userLogin?: string, refreshToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/updateToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userLogin !== undefined) {
                localVarQueryParameter['userLogin'] = userLogin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [userId] 
         * @param {number} [roleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserAddToRolePost(userId?: number, roleId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserAddToRolePost(userId, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserCreatePost(authRequest: AuthRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserCreatePost(authRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserGetMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserGetMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserLoginPost(authRequest: AuthRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserLoginPost(authRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userLogin] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserUpdateTokenPost(userLogin?: string, refreshToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserUpdateTokenPost(userLogin, refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [userId] 
         * @param {number} [roleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserAddToRolePost(userId?: number, roleId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.apiV1UserAddToRolePost(userId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserCreatePost(authRequest: AuthRequest, options?: any): AxiosPromise<UserDto> {
            return localVarFp.apiV1UserCreatePost(authRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserGet(options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.apiV1UserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserGetMeGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiV1UserGetMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserIdDelete(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.apiV1UserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserIdGet(id: number, options?: any): AxiosPromise<UserDto> {
            return localVarFp.apiV1UserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserLoginPost(authRequest: AuthRequest, options?: any): AxiosPromise<AuthResponse> {
            return localVarFp.apiV1UserLoginPost(authRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userLogin] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUpdateTokenPost(userLogin?: string, refreshToken?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1UserUpdateTokenPost(userLogin, refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {number} [userId] 
     * @param {number} [roleId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserAddToRolePost(userId?: number, roleId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserAddToRolePost(userId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthRequest} authRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserCreatePost(authRequest: AuthRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserCreatePost(authRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserGetMeGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserGetMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserIdDelete(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserIdGet(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthRequest} authRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserLoginPost(authRequest: AuthRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserLoginPost(authRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userLogin] 
     * @param {string} [refreshToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserUpdateTokenPost(userLogin?: string, refreshToken?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserUpdateTokenPost(userLogin, refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}


