import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RoleDto, UserDto } from '../../generated/backend'
import { setFieldValue } from '../../utils/helpers'
import { Loader } from '../UI/Loaders/Loader/Loader'
import { rolesApi, usersApi } from '../../api/apis'

type RoleFormProps = {}
export const RoleForm: React.FC<RoleFormProps> = () => {
	const navigate = useNavigate()
	const { id } = useParams()

	const [data, setData] = React.useState<RoleDto>()
	const [users, setUsers] = React.useState<UserDto[]>([])

	const fetchRole = async () => {
		const response = await rolesApi.apiV1RoleIdGet(+id)

		setData(response.data)
	}

	const getUsers = async () => {
		const response = await usersApi.apiV1UserGet()

		setUsers(response.data)
	}

	React.useEffect(() => {
		fetchRole()
	}, [id])

	const onSubmit = async () => {
		//Сохранение роли
	}

	const addUser = async (userId: number) => {
		await usersApi.apiV1UserAddToRolePost(userId, data.id)

		await fetchRole()
	}

	if (!data) {
		return <Loader />
	}

	return (
		<div style={{ margin: '0 12px' }}>
			<Space style={{ marginBottom: '12px' }}>
				<Button type={'primary'}>Сохранить</Button>
				<Button onClick={() => navigate('/settings/roles')}>Назад</Button>
			</Space>
			<Row>
				<Col span={8}>
					<Form labelAlign={'left'} labelCol={{ span: 6 }}>
						<Form.Item label={'Название'}>
							<Input
								placeholder={'Название'}
								name={'name'}
								value={data.name}
								onChange={event =>
									setData(
										setFieldValue(event.target.name, event.target.value, data)
									)
								}
							/>
						</Form.Item>
						<Form.Item label={'Описание'}>
							<Input
								placeholder={'Описание'}
								name={'description'}
								value={data.description}
								onChange={event =>
									setData(
										setFieldValue(event.target.name, event.target.value, data)
									)
								}
							/>
						</Form.Item>
						<Form.Item label={'Пользователи'}>
							<Select
								placeholder={'Пользователи'}
								options={users.map(user => {
									const role = user.roles?.find(item => item.name === data.name)

									if (role) return

									return {
										value: user.id,
										label: user.login,
									}
								})}
								mode={'multiple'}
								value={data.users.map(role => role.login)}
								showSearch
								filterOption={(input, option: { label: string; value: number }) =>
									(option?.label ?? '').includes(input)
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								onSelect={(value, option: { label: string; value: number }) => {
									const selectedUser = users.find(
										item => item.id === option.value
									)

									const filteredUsers = users.filter(
										item => item.id !== selectedUser.id
									)

									setUsers(filteredUsers)

									addUser(selectedUser.id)
								}}
								onFocus={getUsers}
							/>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</div>
	)
}
