import { Button, Form, Input, Modal, Space } from 'antd'
import React from 'react'

type AddFormValueModalProps = {
	searchValue: string
	open: boolean
	field: string
	onClose: () => void
	onSubmit: (field: string, name: string) => Promise<void>
}

const titles = {
	equipment: 'оборудование',
	system: 'систему',
}

const AddFormValueModal: React.FC<AddFormValueModalProps> = ({
	open,
	searchValue,
	field,
	onClose,
	onSubmit,
}) => {
	const [form] = Form.useForm()
	const [isLoading, setIsLoading] = React.useState(false)

	React.useEffect(() => {
		form.setFieldsValue({ name: searchValue })
	}, [open])

	return (
		<Modal
			title={`Добавить ${titles[field]}`}
			open={open}
			onCancel={onClose}
			centered
			footer={false}
			destroyOnClose
			zIndex={9999}
		>
			<Form<{ name: string }>
				onFinish={({ name }) => {
					setIsLoading(true)
					onSubmit(field, name)
						.then(onClose)
						.finally(() => setIsLoading(false))
				}}
				form={form}
				layout='vertical'
			>
				<Form.Item
					rules={[{ required: true, message: 'Обязательное поле!' }]}
					name={'name'}
					label={'Наименование'}
				>
					<Input size='large' />
				</Form.Item>
				<Space>
					<Button htmlType='submit' loading={isLoading} type={'primary'}>
						Добавить
					</Button>
					<Button onClick={onClose}>Отмена</Button>
				</Space>
			</Form>
		</Modal>
	)
}

export default AddFormValueModal
