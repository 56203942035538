import axios from 'axios'
import { IDownload } from '../components/types'
import { message } from 'antd'
type IDownloadReports = (props: IDownload) => void

export const downloadReports: IDownloadReports = ({ isLopa, setIsLoading }) => {
	axios
		.get(`/api/v1/export/${isLopa ? 'hazop-lopa' : `hazop`}`, {
			responseType: 'blob',
		})
		.then(response => {
			const href = URL.createObjectURL(response.data)
			const fileName = response.headers['content-disposition'].split('=')[1].replace(/"/g, '')

			const link = document.createElement('a')
			link.href = href
			link.setAttribute('download', fileName)
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			URL.revokeObjectURL(href)
			setIsLoading({ loading: false, id: '' })
			message.success('Файл успешно экспортирован')
		})
		.catch(() => {
			setIsLoading({ loading: false, id: '' })
		})
}
