import { Button, Form, Input, message, Skeleton, Space } from 'antd'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { equipmentsApi, nodesApi, systemsApi } from '../../api/apis'
import { AssetSystemDto, EquipmentDto, NodeDto } from '../../generated/backend'
import { useActions } from '../../hooks/useActions'
import { addEquipment, fetchEquipments } from '../../redux/equipments/equipments.actions'
import { changeNode, fetchNodeById } from '../../redux/nodes/nodes.actions'
import { useAppDispatch } from '../../redux/store'
import { addSystem, fetchSystems } from '../../redux/systems/systems.actions'
import AddFormValueModal from '../UI/Modals/AddFormValueModal/AddFormValueModal'
import DebounceSelect from '../UI/Selects/DebounceSelect/DebounceSelect'
import { useTypedSelector } from '../../hooks/useTypedSelector'

type NodesFormProps = {}

// const columns = [
// 	{
// 		title: 'Код',
// 		dataIndex: 'code',
// 		key: 'code',
// 	},
// 	{
// 		title: 'Комментарий',
// 		dataIndex: 'comment',
// 		key: 'comment',
// 	},
// 	{
// 		title: 'Название файла',
// 		dataIndex: 'name',
// 		key: 'name',
// 	},
// ]

export const NodesForm: React.FC<NodesFormProps> = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const dispatch = useAppDispatch()
	const [form] = Form.useForm()
	const { setEquipments, setSystems, editSelectedNode } = useActions()

	const [showModal, setShowModal] = React.useState(false)
	const [searchValueForEquipment, setSearchValueForEquipment] = React.useState<string>('')
	const [searchValueForSystem, setSearchValueForSystem] = React.useState<string>('')
	const [field, setField] = React.useState<'system' | 'equipment' | null>(null)

	const { node, isLoading } = useTypedSelector(state => state.nodes.selected)
	const { systems } = useTypedSelector(state => state.systems)
	const { equipments } = useTypedSelector(state => state.equipments)

	React.useEffect(() => {
		dispatch(fetchNodeById(id)).then(res => {
			const node = res.payload as NodeDto

			form.setFieldsValue({
				name: node.name || '',
				code: node.code || '',
				id: node.id || '',
				system: node.system ? [`${node?.system?.name}`, `${node?.system?.id}`] : [],
			})
		})
	}, [id])

	const fetchOptionsForSearchEquipment = async (value: string) => {
		const response = await equipmentsApi.apiV1EquipmentSearchGet(value)

		return response.data
	}

	const fetchOptionsForSearchSystem = async (value: string) => {
		const response = await systemsApi.apiV1AssetSystemSearchGet(value)

		return response.data
	}

	const onSubmit = async (data: NodeDto) => {
		const copyData = { ...data }
		let system: string

		if (typeof copyData.system === 'string') {
			system = copyData?.system as string
		} else system = copyData?.system[1]

		delete copyData.equipment
		delete copyData.schemesStr

		dispatch(changeNode({ ...copyData, system })).then(res => {
			if (typeof res.payload === 'string') message.error(res.payload)
			else message.success('Сохранено!')
		})
	}

	const fetchOptionsForSystems = async () => {
		dispatch(fetchSystems())
	}

	const fetchOptionsForEquipment = async () => {
		dispatch(fetchEquipments())
	}

	const onClose = () => {
		setSearchValueForEquipment('')
		setSearchValueForSystem('')
		setShowModal(false)
	}

	const onSubmitEquipment = async (name: string) => {
		dispatch(addEquipment({ name })).then(async response => {
			if (response.meta.requestStatus === 'fulfilled') {
				setField(null)

				await equipmentsApi.apiV1EquipmentAttachToNodePost(
					node.id,
					(response.payload as EquipmentDto).id
				)
				await fetchOptionsForEquipment()

				editSelectedNode({
					field: 'equipment',
					value: [...node.equipment, response.payload],
				})

				message.success('Оборудование добавлено')
			} else message.error('Не удалось добавить оборудование')
		})
	}

	const onSubmitSystem = async (name: string) => {
		dispatch(addSystem({ name })).then(response => {
			if (response.meta.requestStatus === 'fulfilled') {
				setField(null)
				form.setFieldValue('system', [name, (response.payload as AssetSystemDto).id])
				message.success('Система добавлена')
			}
		})
	}

	const onModalFormSubmit = async (field: string, name: string) => {
		switch (field) {
			case 'equipment': {
				await onSubmitEquipment(name)
				break
			}
			case 'system': {
				await onSubmitSystem(name)
				break
			}
		}
	}

	const attachEquipment = async (nodeId: string, equipment: { label: string; value: string }) => {
		try {
			await equipmentsApi.apiV1EquipmentAttachToNodePost(nodeId, equipment.value)

			editSelectedNode({
				field: 'equipment',
				value: [...node.equipment, { ...equipment, id: equipment.value }],
			})

			message.success('Оборудование добавлено')
		} catch (e) {
			message.error('Не удалось добавить оборудование')
		}
	}

	const deleteFromNode = async (nodeId: string, equipmentId: string) => {
		try {
			await nodesApi.apiV1NodeRemoveLinkEquipmentPut(nodeId, equipmentId)

			editSelectedNode({
				field: 'equipment',
				value: node.equipment?.filter(e => e.id !== equipmentId),
			})

			message.success('Оборудование удалено')
		} catch (e) {
			message.error('Не удалось удалить оборудование')
		}
	}

	const onBack = () => {
		navigate('/nodes')
	}

	const onOpenModal = () => {
		setShowModal(true)
	}

	const filteredEquipments = React.useMemo(
		() =>
			equipments
				?.filter(item => !node.equipment?.find(e => e.id === item.id))
				?.map(item => ({
					label: item.name,
					value: item.id,
				})),
		[equipments]
	)

	const onClearSystem = () => {
		form.setFieldValue('system', null)
	}

	if (isLoading) {
		return (
			<>
				<Space style={{ marginBottom: '12px' }}>
					<Skeleton.Button active />
					<Skeleton.Button active />
				</Space>
				<Skeleton title={false} active paragraph={{ rows: 4 }} />
			</>
		)
	}

	return (
		<>
			<AddFormValueModal
				onSubmit={onModalFormSubmit}
				searchValue={field === 'system' ? searchValueForSystem : searchValueForEquipment}
				open={showModal}
				onClose={onClose}
				field={field}
			/>
			<Form
				form={form}
				onFinish={onSubmit}
				labelCol={{ span: 3, offset: 0 }}
				labelAlign={'left'}
			>
				<Form.Item name={'id'}>
					<Space>
						<Button name='save' htmlType='submit' type={'primary'}>
							Сохранить
						</Button>
						<Button htmlType='button' onClick={onBack}>
							Закрыть
						</Button>
					</Space>
				</Form.Item>
				<Form.Item
					rules={[{ required: true, message: 'Код не может быть пустым!' }]}
					name={'code'}
					label={'Код'}
				>
					<Input id='code' />
				</Form.Item>
				<Form.Item
					name={'name'}
					rules={[{ required: true, message: 'Название не может быть пустым!' }]}
					label={'Название'}
				>
					<Input id='name' />
				</Form.Item>
				<Form.Item name={'system'} label={'Система'}>
					<DebounceSelect
						setEntity={setSystems}
						fetchOptions={fetchOptionsForSearchSystem}
						onOpenModal={onOpenModal}
						searchValue={searchValueForSystem}
						autoClearSearchValue
						entity='systems'
						delay={500}
						onSearch={(value: string) => {
							if (!value) fetchOptionsForSystems()
							setSearchValueForSystem(value)
						}}
						onFocus={() => {
							fetchOptionsForSystems()
							setField('system')
						}}
						options={systems?.map(item => ({
							value: item.id,
							label: item.name,
						}))}
						allowClear
						onClear={onClearSystem}
						data-test='systemsSelect'
					/>
				</Form.Item>
				<Form.Item label={'Оборудование'}>
					<DebounceSelect
						setEntity={setEquipments}
						fetchOptions={fetchOptionsForSearchEquipment}
						onOpenModal={onOpenModal}
						entity='equipments'
						searchValue={searchValueForEquipment}
						autoClearSearchValue
						delay={500}
						mode='multiple'
						value={node?.equipment?.map(item => ({
							label: item.name,
							value: item.id,
						}))}
						onSearch={(value: string) => {
							if (!value) fetchOptionsForEquipment()
							setSearchValueForEquipment(value)
						}}
						onFocus={() => {
							fetchOptionsForEquipment()
							setField('equipment')
						}}
						onSelect={(_, option: { label: string; value: string }) => {
							attachEquipment(node.id, option)
							setSearchValueForEquipment('')
						}}
						onDeselect={(value: string) => deleteFromNode(node.id, value)}
						options={filteredEquipments}
						data-test='equipmentsSelect'
					/>
				</Form.Item>
				{/* Схемы:
				<Table
					bordered
					columns={columns}
					size='small'
					title={() => <Input.Search />}
					// footer={() => <Button onClick={() => setShowModal({ equipment: false, schemes: true })}>Создать схему</Button>}
				/> */}
				{/* <SchemeAddModal open={showModal.schemes} onClose={onClose} onSubmit={() => {}} /> */}
			</Form>
		</>
	)
}
