import { Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../redux/store'
import styles from './HazopItem.module.css'
import HazopTable from '../HazopTable/HazopTable'

type HazopItemProps = {}

export const HazopItem: React.FC<HazopItemProps> = ({}) => {
	return (
		<div className={styles.container}>
			<HazopTable />
		</div>
	)
}
