import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { NodeDto } from '../../generated/backend'
import { addNode, changeNode, deleteNode, fetchNodeById, fetchNodes } from './nodes.actions'

export interface NodeState {
	nodes: NodeDto[]
	isLoading: boolean
	selected: { node: NodeDto; isLoading: boolean }
}

const initialState: NodeState = {
	nodes: [],
	isLoading: true,
	selected: {
		node: {},
		isLoading: true,
	},
}

export const nodesSlice = createSlice({
	name: 'nodes',
	initialState,
	reducers: {
		editSelectedNode: (state, action: PayloadAction<{ field: string; value: unknown }>) => {
			state.selected.node[action.payload.field] = action.payload.value
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchNodes.pending, state => {
			state.isLoading = true
		})
		builder.addCase(fetchNodes.fulfilled, (state, action) => {
			state.isLoading = false
			state.nodes = action.payload
		})
		builder.addCase(fetchNodes.rejected, state => {
			state.isLoading = false
		})

		builder.addCase(addNode.fulfilled, (state, action) => {
			state.nodes.push(action.payload)

			state.selected = {
				node: action.payload,
				isLoading: false,
			}
		})

		builder.addCase(deleteNode.fulfilled, (state, action) => {
			state.nodes = state.nodes.filter(item => item.id !== action.payload)
		})

		builder.addCase(changeNode.fulfilled, (state, action) => {
			state.nodes = state.nodes.map(item => (item.id === action.payload.id ? action.payload : item))
			state.selected.node = action.payload
		})

		builder.addCase(fetchNodeById.pending, state => {
			state.selected.isLoading = true
		})
		builder.addCase(fetchNodeById.fulfilled, (state, action) => {
			state.selected.isLoading = false
			state.selected.node = action.payload
		})
		builder.addCase(fetchNodeById.rejected, state => {
			state.selected.isLoading = false
		})
	},
})

// Action creators are generated for each case reducer function
export const { editSelectedNode } = nodesSlice.actions

export default nodesSlice.reducer
