import { green, grey, red, yellow } from '@ant-design/colors'
import { Col, Row } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { matrixApi } from '../../api/apis'
import { fetchMatrix } from '../../redux/riskMatrix/riskMatrix.actions'
import { RootState, useAppDispatch } from '../../redux/store'
import { Loader } from '../UI/Loaders/Loader/Loader'
import CreatePresetModal from '../UI/Modals/CreatePresetModal/CreatePresetModal'
import PresetsModal from '../UI/Modals/PresetsModal/PresetsModal'
import { DangerValueRequest, IDangerValueModal, IDescriptionValueModal } from '../types'
import DangerCell from './DangerCell'
import { DangerValueModal } from './DangerValueModal'
import { DescriptionModal } from './DescriptionModal'
import styles from './RiskMatrix.module.css'
import { SettingsModal } from './SettingsModal'

const colors = {
	1: green[4],
	2: yellow[3],
	3: red[4],
	4: grey[4],
}

type TableShortProps = {
	isModal?: boolean
	onCancel?: () => void
	addMatrixValue?: (typeOfMatrixValue: string, value: string) => void
	type?: string
}

const RiskMatrix: React.FC<TableShortProps> = ({
	onCancel,
	addMatrixValue,
	type,
	isModal = false,
}) => {
	const riskMatrix = useSelector((state: RootState) => state.riskMatrix)
	const dispatch = useAppDispatch()
	const [showDescriptionModal, setShowDescriptionModal] = React.useState<IDescriptionValueModal>({
		show: false,
		x: null,
		y: null,
		isEdit: false,
		isTMEL: false,
		title: '',
		id: '',
	})
	const [showDangerValueModal, setShowDangerValueModal] = React.useState<IDangerValueModal>({
		show: false,
		x: null,
		y: null,
		isEdit: false,
		value: null,
		title: '',
		id: '',
	})

	React.useEffect(() => {
		dispatch(fetchMatrix({ isInitial: true }))
	}, [])

	const onCloseDescriptionModal = () => {
		setShowDescriptionModal({
			show: false,
			x: null,
			y: null,
			isEdit: false,
			isTMEL: false,
			title: '',
			id: '',
		})
	}

	const onOpenDescriptionModal = (
		x: number,
		y: number,
		isEdit: boolean,
		isTMEL: boolean,
		title: string,
		id: string
	) => {
		setShowDescriptionModal({
			show: true,
			x,
			y,
			isEdit,
			isTMEL,
			title,
			id,
		})
	}

	const sendDescription = async (
		x: number,
		y: number,
		title: string,
		isEdit: boolean,
		id: string
	) => {
		if (isEdit) {
			await matrixApi.apiV1MatrixValuePut({
				xAxis: x,
				yAxis: y,
				title,
				id,
			})
		} else {
			await matrixApi.apiV1MatrixValuePost({
				xAxis: x,
				yAxis: y,
				title,
			})
		}

		dispatch(fetchMatrix({ isInitial: false }))
	}

	const onCloseDangerValueModal = () => {
		setShowDangerValueModal({
			show: false,
			x: null,
			y: null,
			isEdit: false,
			value: null,
			title: '',
			id: '',
		})
	}

	const onOpenDangerValueModal = (dangerValueModalProps: Omit<IDangerValueModal, 'show'>) => {
		const { x, y, isEdit, value, title, id } = dangerValueModalProps
		setShowDangerValueModal({
			show: true,
			x: x,
			y: y,
			isEdit,
			value,
			title,
			id,
		})
	}

	const sendDangerValue = async (dangerValueRequest: DangerValueRequest) => {
		const { x, y, isEdit, value, title, id, dangerColor } = dangerValueRequest
		if (isEdit) {
			await matrixApi.apiV1MatrixValuePut({
				xAxis: x,
				yAxis: y,
				dangerValue: value,
				dangerColor,
				title,
				id,
			})
		} else {
			await matrixApi.apiV1MatrixValuePost({
				xAxis: x,
				yAxis: y,
				dangerValue: value,
				dangerColor,
				title,
			})
		}

		dispatch(fetchMatrix({ isInitial: false }))
	}

	if (riskMatrix.loading) {
		return <Loader />
	}

	return (
		<div className={classNames(styles.wrap)}>
			{!isModal ? (
				<>
					<DangerValueModal
						showProps={showDangerValueModal}
						onCancel={onCloseDangerValueModal}
						sendDangerValue={sendDangerValue}
					/>

					<DescriptionModal
						onCancel={onCloseDescriptionModal}
						showProps={showDescriptionModal}
						sendDescription={sendDescription}
					/>
					<div className={classNames(styles.buttonContainer)}>
						<SettingsModal />
						<PresetsModal />
					</div>
				</>
			) : null}
			<div
				className={classNames(styles.tableContainer)}
				style={{ borderBottom: `2px solid ${grey[7]}` }}
			>
				<Row
					style={{
						borderRight: `2px solid ${grey[7]}`,
						borderLeft: `2px solid ${grey[7]}`,
						borderTop: `2px solid ${grey[7]}`,
					}}
					className={classNames(styles.row)}
				>
					<Col
						style={{ backgroundColor: green[8] }}
						className={classNames(styles.cellTitle)}
						xs={24}
					>
						<div style={{ color: '#fff' }}>
							<i style={{ fontSize: '14px' }}>
								Матрица рисков для анализа опасности и работоспособности HAZOP
							</i>
						</div>
					</Col>
				</Row>

				{riskMatrix.item &&
					Object.values(riskMatrix.item)
						.reverse()
						.map((row, index) => {
							if (index === 0 || index === Object.values(riskMatrix.item).length - 1)
								return

							return (
								<Row
									key={index}
									style={{
										borderRight: `2px solid ${grey[7]}`,
									}}
									className={classNames(styles.row)}
								>
									<Col
										xs={2}
										style={{
											borderLeft: `2px solid ${grey[5]}`,
											borderTop: `1px solid ${grey[5]}`,
											borderBottom: `1px solid ${grey[5]}`,
										}}
										className={classNames(styles.cell, 'risk-value')}
										onClick={() =>
											!isModal
												? onOpenDescriptionModal(
														-2,
														Object.values(riskMatrix.item).length -
															1 -
															index,
														typeof row[-2]?.title === 'string',
														true,
														row[-2]?.title,
														row[-2]?.id
												  )
												: null
										}
									>
										<div>
											{row[-2]?.title
												? Number(row[-2]?.title).toExponential()
												: null}
										</div>
									</Col>
									<Col
										style={{
											borderLeft: `2px solid ${grey[5]}`,
											borderTop: `1px solid ${grey[5]}`,
											borderBottom: `1px solid ${grey[5]}`,
										}}
										xs={2}
										className={classNames(
											styles.cell,
											styles.textSm,
											'description-value'
										)}
										onClick={() =>
											!isModal
												? onOpenDescriptionModal(
														-1,
														Object.values(riskMatrix.item).length -
															1 -
															index,
														typeof row[-1]?.title === 'string',
														false,
														row[-1]?.title,
														row[-1]?.id
												  )
												: null
										}
									>
										<div>{row[-1]?.title}</div>
									</Col>
									<Col
										style={{
											borderLeft: `1px solid ${grey[7]}`,
											borderTop: `1px solid ${grey[7]}`,
											borderBottom: `1px solid ${grey[7]}`,
										}}
										xs={1}
										className={classNames(styles.cell, styles.textSm)}
										onClick={() =>
											!isModal
												? onOpenDescriptionModal(
														0,
														Object.values(riskMatrix.item).length -
															1 -
															index,
														typeof row[0]?.title === 'string',
														false,
														row[0]?.title,
														row[0]?.id
												  )
												: null
										}
									>
										<div>{row[0]?.title}</div>
									</Col>
									<Col xs={19}>
										<Row style={{ height: '100%' }}>
											{Array.from({ length: 5 }).map((_, i) => (
												<DangerCell
													backgroundColor={
														colors[row[i + 1]?.dangerValue?.id]
													}
													title={row[i + 1]?.title}
													onClickCell={() => {
														if (!isModal) {
															onOpenDangerValueModal({
																x: i + 1,
																value: row[i + 1]?.dangerValue?.id,
																id: row[i + 1]?.id,
																title: row[i + 1]?.title,
																y:
																	Object.values(riskMatrix.item)
																		.length -
																	1 -
																	index,
																isEdit:
																	typeof row[i + 1]?.title ===
																		'string' ||
																	row[i + 1]?.dangerValue?.id !==
																		undefined,
															})
															return
														}

														addMatrixValue(type, row[i + 1].id)
														onCancel()
													}}
												/>
											))}
										</Row>
									</Col>
								</Row>
							)
						})}

				<Row
					style={{
						borderRight: `1px solid ${grey[7]}`,
					}}
					className={classNames(styles.row)}
				>
					<Col
						style={{
							borderLeft: `2px solid ${grey[7]}`,
							borderTop: `2px solid ${grey[7]}`,
							borderBottom: `1px solid ${grey[7]}`,
						}}
						className={classNames(styles.cellTitle)}
						xs={5}
					>
						<div>Степень тяжести воздействия</div>
					</Col>
					<Col xs={19}>
						<Row
							style={{
								borderRight: `1px solid ${grey[7]}`,
								height: '50%',
							}}
						>
							{riskMatrix.item &&
								Object.values(riskMatrix.item)
									.filter(
										(item, index) =>
											index < Object.values(riskMatrix.item).length - 1
									)
									.map((row, index) => {
										if (index === 0) {
											return Object.values(row).map(
												(item: { title: string; id: string }, index) => {
													if (index < 1 || index > 5) return null
													return (
														<Col
															key={index}
															style={{
																borderLeft: `1px solid ${grey[7]}`,
																borderTop: `2px solid ${grey[7]}`,
																borderBottom: `1px solid ${grey[7]}`,
															}}
															className={classNames(styles.cellTitle)}
															flex='5'
															onClick={() =>
																!isModal
																	? onOpenDescriptionModal(
																			index,
																			0,
																			typeof item?.title ===
																				'string',
																			false,
																			item?.title,
																			item?.id
																	  )
																	: null
															}
														>
															<div>{item?.title}</div>
														</Col>
													)
												}
											)
										}
									})}
						</Row>
						<Row
							style={{
								borderRight: `1px solid ${grey[7]}`,
								height: '50%',
							}}
						>
							{riskMatrix.item &&
								Object.values(riskMatrix.item).map((row, index) => {
									if (index === Object.values(riskMatrix.item).length - 1) {
										return Object.values(row).map(
											(item: { title: string; id: string }, index) => {
												if (index < 1 || index > 5) return null
												return (
													<Col
														key={index}
														style={{
															borderLeft: `1px solid ${grey[7]}`,
															borderTop: `2px solid ${grey[7]}`,
															borderBottom: `1px solid ${grey[7]}`,
														}}
														className={classNames(styles.cellTitle)}
														flex='5'
														onClick={() =>
															!isModal
																? onOpenDescriptionModal(
																		index,
																		-1,
																		typeof item?.title ===
																			'string',
																		false,
																		item?.title,
																		item?.id
																  )
																: null
														}
													>
														<div>{item?.title}</div>
													</Col>
												)
											}
										)
									}
								})}
						</Row>
					</Col>
				</Row>
			</div>
			{!isModal ? (
				<div className={styles.buttonContainer}>
					<CreatePresetModal />
				</div>
			) : null}
		</div>
	)
}

export default RiskMatrix
