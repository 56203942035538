import { EditOutlined } from '@ant-design/icons'
import { Button, Modal, Table, TableProps, message } from 'antd'
import React from 'react'
import { matrixApi } from '../../../../api/apis'
import { useActions } from '../../../../hooks/useActions'
import { useModalActions } from '../../../../hooks/useModalActions'
import styles from './PresetsModal.module.css'

type PresetsModalProps = {}

type Preset = {
	name: string
}

const PresetsModal: React.FC<PresetsModalProps> = ({}) => {
	const { setRiskMatrix } = useActions()

	const [isOpen, onOpenModal, onCloseModal] = useModalActions()
	const [presets, setPresets] = React.useState(null)
	const [messageApi, contextHolder] = message.useMessage()

	const onDownloadButtonClick = async (templateCode: string) => {
		try {
			const response = await matrixApi.apiV1MatrixValueSelectTemplatePost(templateCode)
			setRiskMatrix(response.data)
			onCloseModal()
			messageApi.success('Пресет успешно загружен!')
		} catch (error) {
			messageApi.error('Не удалось загрузить пресет')
		}
	}

	React.useEffect(() => {
		if (!isOpen) return
		const fetchPresets = async () => {
			try {
				const response = await matrixApi.apiV1MatrixValueGetTemplatesGet()
				setPresets(response?.data)
			} catch (error) {
				messageApi.error('Не удалось загрузить пресеты')
			}
		}

		fetchPresets()
	}, [isOpen])

	const columns: TableProps<Preset>['columns'] = [
		{
			title: 'Название',
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: '',
			dataIndex: 'action',
			key: 'action',
			width: '15%',
			render: (_, record) => (
				<>
					<Button
						name='choose'
						onClick={() => onDownloadButtonClick(record.name)}
						type='link'
					>
						Загрузить
					</Button>
				</>
			),
		},
	]

	return (
		<>
			{contextHolder}
			<Button name='preset' onClick={onOpenModal} icon={<EditOutlined rev={undefined} />}>
				Выбрать пресет
			</Button>
			<Modal
				width={'40vw'}
				className={styles.modal}
				open={isOpen}
				onCancel={onCloseModal}
				destroyOnClose
				centered
				footer={false}
				title={'Пресеты'}
			>
				<div className={styles.tableContainer}>
					<Table
						columns={columns}
						dataSource={presets?.map(preset => ({ name: preset }))}
						size='small'
						pagination={false}
						rowKey={record => record.name}
					/>
				</div>
			</Modal>
		</>
	)
}

export default PresetsModal
