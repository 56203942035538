import { PlusOutlined } from '@ant-design/icons'
import { Button, message, Skeleton, Tree } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SchemeDto, SchemeDtoRequest } from '../../generated/backend'
import { dropDown } from '../../utils/dropdown'
import { createScheme, deleteScheme, fetchSchemes } from '../../redux/schemes/schemes.actions'
import { RootState, useAppDispatch } from '../../redux/store'
import SchemeAddModal from './SchemeAddModal/SchemeAddModal'

type SchemasListProps = {}

const SchemasList: React.FC<SchemasListProps> = ({}) => {
	const navigate = useNavigate()
	const [showModal, setShowModal] = React.useState(false)
	const { schemes, isLoading } = useSelector((state: RootState) => state.schemes)
	const dispatch = useAppDispatch()

	const onDeleteScheme = async (scheme: SchemeDto) => {
		dispatch(deleteScheme(scheme.id)).then(response => {
			if (response.meta.requestStatus === 'fulfilled') {
				navigate('/schemes')
				message.success('Схема удалена')
			}
		})
	}

	React.useEffect(() => {
		dispatch(fetchSchemes())
	}, [])

	const onSubmit = (values: SchemeDtoRequest) => {
		dispatch(createScheme(values)).then(response => {
			if (response.meta.requestStatus === 'fulfilled') {
				message.success('Схема создана')
				setShowModal(false)
			}
		})
	}

	const onOpenModal = () => {
		setShowModal(true)
	}

	const onClose = () => {
		setShowModal(false)
	}

	return (
		<>
			<SchemeAddModal open={showModal} onClose={onClose} onSubmit={onSubmit} />
			<Button type='text' icon={<PlusOutlined rev={undefined} />} onClick={onOpenModal}>
				Добавить
			</Button>
			<Skeleton loading={isLoading} active>
				<Tree
					treeData={schemes?.map(scheme => ({
						...scheme,
						title: (
							<>
								{scheme.name}{' '}
								<span onClick={e => e.stopPropagation()}>
									{dropDown(scheme, onDeleteScheme)}
								</span>
							</>
						),
						key: scheme.id,
					}))}
					onSelect={(key, info) => navigate(`/schemes/${info.node.id}`)}
				/>
			</Skeleton>
		</>
	)
}

export default SchemasList
