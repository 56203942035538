import React from 'react'

interface Props {
	ErrorComponent: React.FC<{ error: string }>
	children: React.ReactNode
}

interface State {
	error: Error
}

export default class ErrorBoundary extends React.Component<Props, State> {
	state = {
		error: null,
	}
	static getDerivedStateFromError(error: Error) {
		return { error }
	}
	render() {
		const { error } = this.state

		if (error) {
			return <this.props.ErrorComponent error={error} />
		}
		return this.props.children
	}
}
