import React from 'react'

export const useModalActions = () => {
	const [isOpen, setIsOpen] = React.useState(false)

	const onOpenModal = () => {
		setIsOpen(true)
	}

	const onCloseModal = () => {
		setIsOpen(false)
	}

	return [isOpen, onOpenModal, onCloseModal] as const
}
