export const setFieldValue = <TypeOfValue, TypeOfData>(
	field: string,
	value: TypeOfValue,
	data: TypeOfData
) => {
	return { ...data, [field]: value }
}

export const exponentialValue = (value: number) => {
	return value.toExponential(3)
}
