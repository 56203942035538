import { Button, Select, Space, message } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import { equipmentsApi, parametersApi } from '../../../api/apis'
import {
	EquipmentDto,
	HazopEntryDto,
	NodeGuidewordDto,
	NodeParameterDto,
} from '../../../generated/backend'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { addEmptyRow, addRow } from '../../../redux/hazopEntries/hazopEntries.actions'
import { useAppDispatch } from '../../../redux/store'

type Entity = NodeParameterDto | NodeGuidewordDto | EquipmentDto

type StatesForSelect = {
	parameters: NodeParameterDto[]
	guidewords: NodeGuidewordDto[]
	equipments: EquipmentDto[]
}

const prepareOptions = (entity: Entity[]) => {
	return entity?.map(item => ({ value: item.id, label: item.name }))
}

export const contentForPopover = ({ lastNumber }) => {
	const { id } = useParams()
	const { equipmentEnabled } = useTypedSelector(state => state.settings.mainSettings)
	const dispatch = useAppDispatch()

	const [loading, setLoading] = React.useState('')
	const [hazopEntry, setHazopEntry] = React.useState<{
		nodeParameter: string
		nodeGuideword: string
		equipmentId?: string
	}>(null)
	const [statesForSelect, setStatesForSelect] = React.useState<StatesForSelect>({
		parameters: [],
		guidewords: [],
		equipments: [],
	})

	const { parameters, guidewords, equipments } = statesForSelect

	const fetchOptions = async (isParameter: boolean) => {
		setLoading(isParameter ? 'parameter' : 'guideword')
		isParameter
			? setStatesForSelect({ ...statesForSelect, parameters: [] })
			: setStatesForSelect({ ...statesForSelect, guidewords: [] })

		if (isParameter) {
			const response = await parametersApi.apiV1NodeParameterGet()

			setStatesForSelect({
				...statesForSelect,
				parameters: [...response.data].sort((a, b) => a.sort - b.sort),
			})
			setLoading('')
			return
		}

		const response = await parametersApi.apiV1NodeParameterIdGet(hazopEntry.nodeParameter)

		setStatesForSelect({
			...statesForSelect,
			guidewords: [...response.data.guidewords].sort((a, b) => a.sort - b.sort),
		})
		setLoading('')
	}

	const fetchEquipmentsForSelect = async () => {
		setLoading('equipment')
		setStatesForSelect({ ...statesForSelect, equipments: [] })
		try {
			const response = await equipmentsApi.apiV1EquipmentByNodeGet(id)
			setStatesForSelect({ ...statesForSelect, equipments: response.data })
		} catch (error) {
			message.error('Произошла ошибка при загрузке оборудования')
		} finally {
			setLoading('')
		}
	}

	const onAddButtonClick = async () => {
		const response = !hazopEntry
			? await dispatch(addEmptyRow(id))
			: await dispatch(addRow({ ...hazopEntry, nodeId: id, number: lastNumber + 1 }))

		if (response.meta.requestStatus === 'fulfilled') {
			message.success(
				`Отклонение под номером ${(response.payload as HazopEntryDto).number} добавлено!`
			)
		} else {
			message.error('Произошла ошибка при добавлении отклонения')
		}
	}

	const onChange = (field: string, value: unknown) => {
		setHazopEntry({ ...hazopEntry, [field]: value })
	}

	return (
		<Space align='end'>
			{/* <AddFormValueModal
				searchValue={searchValue}
				open={showModal}
				field={'equipment'}
				onClose={onCloseModal}
				onSubmit={onSubmitEquipment}
			/> */}
			<Space style={{ width: '250px' }} direction='vertical'>
				Слово параметр
				<Select
					style={{ width: '100%' }}
					options={prepareOptions(parameters)}
					allowClear
					onClear={() => setHazopEntry(null)}
					onSelect={value => onChange('nodeParameter', value)}
					onFocus={() => fetchOptions(true)}
					placement='topLeft'
					loading={loading === 'parameter'}
					data-test='parameter-select'
				/>
			</Space>
			<Space style={{ width: '250px' }} direction='vertical'>
				Управляющее слово
				<Select
					style={{ width: '100%' }}
					options={prepareOptions(guidewords)}
					allowClear
					disabled={!hazopEntry?.nodeParameter}
					onClear={() => setHazopEntry({ ...hazopEntry, nodeGuideword: null })}
					onSelect={value => onChange('nodeGuideword', value)}
					onFocus={() => fetchOptions(false)}
					placement='topLeft'
					loading={loading === 'guideword'}
					data-test='guideWord-select'
				/>
			</Space>
			{equipmentEnabled ? (
				<Space style={{ width: '250px' }} direction='vertical'>
					Компонент
					<Select
						onClear={() => setHazopEntry({ ...hazopEntry, equipmentId: null })}
						onSelect={value => onChange('equipmentId', value)}
						onFocus={fetchEquipmentsForSelect}
						options={prepareOptions(equipments)}
						style={{ width: '100%' }}
						loading={loading === 'equipment'}
						placement='topLeft'
						data-test='equipment-select'
						allowClear
					/>
					{/* <DebounceSelect
						onOpenModal={onOpenModal}
						style={{ width: '100%' }}
						fetchOptions={searchEquipments}
						showSearch
						setEntity={setEquipments}
						entity={'equipments'}
						searchValue={searchValue}
						onFocus={fetchEquipmentsForSelect}
						value={hazopEntry?.equipment}
						autoClearSearchValue
						delay={500}
						onSearch={(value: string) => {
							if (!value) fetchEquipmentsForSelect()
							setSearchValue(value)
						}}
						placement={'topLeft'}
						options={prepareOptions(equipments)}
					/> */}
				</Space>
			) : null}
			<Button name='save' type='primary' onClick={onAddButtonClick}>
				Создать {!hazopEntry ? 'пустое отклонение' : null}
			</Button>
		</Space>
	)
}
