import { Button, Form, Input, Modal, Space, message } from 'antd';
import React from 'react';
import { UserDto } from '../../../generated/backend';

type UserAddModalProps = {
    onCancel: () => void;
    showModal: boolean;
    createUser: (data: UserDto) => void;
};
export const UserAddModal: React.FC<UserAddModalProps> = ({ onCancel, showModal, createUser }) => {
    const [user, setUser] = React.useState<UserDto>({
        login: '',
        password: '',
    });
    const [password, setPassword] = React.useState('');

    const setFieldValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUser = { ...user, [e.target.name]: e.target.value };

        setUser(newUser);
    };

    return (
        <div>
            <Modal centered={true} open={showModal} onCancel={onCancel} title={'Cоздание пользователя'} footer={false}>
                <Form layout='vertical'>
                    <Form.Item label={'Логин'}>
                        <Input
                            size='large'
                            placeholder={'example'}
                            name={'login'}
                            onChange={event => setFieldValue(event)}
                        />
                    </Form.Item>
                    <Form.Item label={'Пароль'}>
                        <Input
                            size='large'
                            placeholder={'*****'}
                            type={'password'}
                            name={'password'}
                            onChange={event => setFieldValue(event)}
                        />
                    </Form.Item>
                    <Form.Item label={'Повторите пароль'}>
                        <Input
                            size='large'
                            placeholder={'*****'}
                            name={'password'}
                            type={'password'}
                            onChange={event => {
                                setPassword(event.target.value);
                            }}
                        />
                    </Form.Item>
                </Form>
                <Space>
                    <Button
                        type={'primary'}
                        onClick={() => {
                            const correct = password === user.password;

                            if (correct) {
                                createUser(user);
                                onCancel();
                            } else message.error('Пароли не совпадают');
                        }}
                    >
                        Создать
                    </Button>
                    <Button onClick={onCancel}>Отмена</Button>
                </Space>
            </Modal>
        </div>
    );
};
