import React from 'react'
import { useNavigate } from 'react-router-dom'
import CheckCircle from '../../../assets/img/check-circle.svg'
import RbiLogoWhiteMin from '../../../assets/img/logo-white-min.svg'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import styles from './Header.module.css'
import { Avatar, Dropdown, MenuProps } from 'antd'
import { logout } from '../../../redux/general/generalSlice'
import { useAppDispatch } from '../../../redux/store'
import { LogoutOutlined } from '@ant-design/icons'
import { LoadingOutlined } from '@ant-design/icons'
import { ServerInfoDto } from '../../../generated/backend'
import { serverInfoApi } from '../../../api/apis'

const Header: React.FC = () => {
	const navigate = useNavigate()
	const [serverInfo, setServerInfo] = React.useState<ServerInfoDto>(null)
	const dispatch = useAppDispatch()

	React.useEffect(() => {
		serverInfoApi.apiV1InfoGet().then(res => setServerInfo(res.data))
	}, [])

	const items: MenuProps['items'] = [
		{
			key: '1',
			label: (
				<span
					onClick={() => {
						dispatch(logout())
					}}
				>
					Выход
				</span>
			),
			icon: <LogoutOutlined rev={undefined} />,
			danger: true,
		},
	]

	return (
		<header className={styles.header}>
			<div className={styles.logo} onClick={() => navigate('/')}>
				<RbiLogoWhiteMin />
				<div className={styles.logoTitle}>HazOps</div>
			</div>
			<div className={styles.content}>
				<div className={styles.info}>
					<div className={styles.text}>
						{!serverInfo ? (
							<>
								<LoadingOutlined rev={undefined} />
								Обновления данных
							</>
						) : (
							<>
								<CheckCircle />
								<div>
									Версия:{' '}
									<span className={styles.tags}>{serverInfo.deployTag}</span>.
									Время:{' '}
									<span className={styles.tags}>
										{new Date(serverInfo.deployTime).toLocaleString()}
									</span>
									.
								</div>
							</>
						)}
					</div>
					<ul className={styles.breadCrumbs}>
						<Breadcrumbs />
					</ul>
				</div>
				<Dropdown trigger={['click', 'hover']} menu={{ items }}>
					<Avatar style={{ cursor: 'pointer' }}>RBI</Avatar>
				</Dropdown>
			</div>
		</header>
	)
}
export default Header
