import { Button, Col, Divider, Row, Space, Typography } from 'antd'
import React from 'react'
import { downloadBooks } from '../../utils/downloadBooks'
import { downloadTemplates } from '../../utils/downloadTemplates'
import { downloadRecommendations } from '../../utils/downloadRecommendations'
import { downloadReports } from '../../utils/downloadReports'
import { useTypedSelector } from '../../hooks/useTypedSelector'

type ExportProps = {}
export const Export: React.FC<ExportProps> = ({}) => {
	const { hazopType } = useTypedSelector(state => state.settings.mainSettings)
	const [isLoading, setIsLoading] = React.useState<{ loading: boolean; id: string }>({
		loading: false,
		id: '',
	})

	const exportTemplate = async (type: string) => {
		setIsLoading({ loading: true, id: type })

		if (type.includes('lopa')) {
			downloadTemplates({
				isLopa: true,
				setIsLoading,
				type: type.split('-').slice(1).join('-'),
			})
			return
		}

		downloadTemplates({ isLopa: false, setIsLoading, type: type.split('-')[1] })
	}

	const exportBooks = (type: string) => {
		setIsLoading({ loading: true, id: type })

		if (type === 'book-lopa') {
			return
		}

		downloadBooks(false, setIsLoading, type.split('-')[1])
	}

	const exportReports = (type: string) => {
		setIsLoading({ loading: true, id: type })

		if (type === 'hazop-lopa') {
			downloadReports({ isLopa: true, setIsLoading })
			return
		}

		downloadReports({ isLopa: false, setIsLoading })
	}

	const exportRecommendations = () => {
		setIsLoading({ loading: true, id: 'report-recommendations' })

		downloadRecommendations({ setIsLoading })
	}

	return (
		<>
			<Row justify={'space-around'}>
				<Col span={7}>
					<Typography.Title level={4}>Шаблоны</Typography.Title>
					<Space wrap align={'center'} style={{ width: '100%' }}>
						<Button
							loading={isLoading.id === 'templateEight-false' && isLoading.loading}
							onClick={() =>
								exportTemplate(
									`templateEight-${hazopType === 'EIGHT' ? 'true' : 'false'}`
								)
							}
						>
							Hazop
						</Button>
						<Button
							loading={isLoading.id === 'template-lopa' && isLoading.loading}
							onClick={() => exportTemplate('template-lopa')}
						>
							Lopa
						</Button>
					</Space>
				</Col>
				<Col span={1}>
					<Divider type={'vertical'} style={{ height: '100px' }} />
				</Col>
				<Col span={7}>
					<Typography.Title level={4}>Рабочие книги</Typography.Title>
					<Space wrap align={'center'} style={{ width: '100%' }}>
						<Button
							loading={isLoading.id === 'book-false' && isLoading.loading}
							onClick={() =>
								exportBooks(`book-${hazopType === 'EIGHT' ? 'true' : 'false'}`)
							}
						>
							Hazop
						</Button>
						<Button
							disabled
							loading={isLoading.id === 'book-lopa' && isLoading.loading}
							onClick={() => exportBooks('book-lopa')}
						>
							Lopa
						</Button>
					</Space>
				</Col>
				<Col span={1}>
					<Divider type={'vertical'} style={{ height: '100px' }} />
				</Col>
				<Col span={7}>
					<Typography.Title level={4}>Отчеты</Typography.Title>
					<Space wrap align={'center'} style={{ width: '100%' }}>
						<Button
							loading={isLoading.id === 'hazop' && isLoading.loading}
							onClick={() => exportReports('hazop')}
						>
							Hazop
						</Button>
						<Button
							loading={isLoading.id === 'hazop-lopa' && isLoading.loading}
							onClick={() => exportReports('hazop-lopa')}
						>
							Lopa
						</Button>
						<Button
							loading={isLoading.id === 'report-recommendation' && isLoading.loading}
							onClick={exportRecommendations}
						>
							Отчет по рекомендациям
						</Button>
					</Space>
				</Col>
			</Row>
		</>
	)
}
