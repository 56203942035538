import { Col, Row } from 'antd'
import React from 'react'
import { Outlet } from 'react-router-dom'
import SchemasList from './SchemasList'

type Props = {};

const SchemesLayout: React.FC = (props: Props) => {
    return (
        <>
            <Row justify={'space-between'}>
                <Col span={6}>
                    <SchemasList />
                </Col>
                <Col span={16}>
                    <Outlet />
                </Col>
            </Row>
        </>
    );
};

export default SchemesLayout;
