import { createAsyncThunk } from '@reduxjs/toolkit'
import { LopaEntryDtoRequest } from '../../generated/backend'
import { lopaEntriesApi } from '../../api/apis'

export const importLopaEntries = createAsyncThunk(
	'lopaEntries/import',
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await lopaEntriesApi.apiV1LopaEntryGenerateEmptyPost(id)

			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

export const fetchLopaEntries = createAsyncThunk(
	'lopaEntries/fetch',
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await lopaEntriesApi.apiV1LopaEntryByNodeGet(id)

			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

export const editLopaEntry = createAsyncThunk(
	'lopaEntries/edit',
	async (
		{ data, hazopEntryId }: { data: LopaEntryDtoRequest; hazopEntryId: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await lopaEntriesApi.apiV1LopaEntryPut(data)

			return { hazopEntryId, lopaEntry: response.data }
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)
