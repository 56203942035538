import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Select, Space } from 'antd'
import React from 'react'
import {
	CauseDto,
	ConsequenceDto,
	ConsequenceDtoRequestRiskTypeEnum,
	HazopEntryDto,
	RecommendationDto,
	SafeguardDto,
	SafeguardDtoRequestSafeguardTypeEnum,
} from '../../../../generated/backend'
import { TypeOfHazopTableState } from '../../../types'
import { enumsApi } from '../../../../api/apis'

type HazopTableEditModalProps = {
	hazopEntry: HazopEntryDto
	show: boolean
	showEdit: {
		id: string
		show: boolean
		type: string
		riskType?: string
	}
	setShowEdit: (showSelect: {
		id: string
		show: boolean
		type: string
		riskType?: string
	}) => void
	state: TypeOfHazopTableState[]
	setState: (state: TypeOfHazopTableState[]) => void
	type: string
	addColumnItem: (
		entityType: string,
		hazopEntryId: string,
		type?:
			| ConsequenceDtoRequestRiskTypeEnum
			| SafeguardDtoRequestSafeguardTypeEnum
			| string
	) => Promise<TypeOfHazopTableState>
	saveColumnItem: (
		entityType: string,
		state: CauseDto[] | ConsequenceDto[] | SafeguardDto[] | RecommendationDto[],
		hazopEntryId: string,
		type?:
			| ConsequenceDtoRequestRiskTypeEnum
			| SafeguardDtoRequestSafeguardTypeEnum
			| string
	) => Promise<void>
	deleteItem: (typeOfEntity: string, id: string) => void
	isRecommendation?: boolean
}

enum TypeOfState {
	causes = 'причину',
	consequences = 'последствие',
	safeguards = 'меру защиты',
	recommendations = 'рекомендацию',
}

const HazopTableEditModal: React.FC<HazopTableEditModalProps> = ({
	hazopEntry,
	showEdit,
	state,
	type,
	isRecommendation,
	show,
	setShowEdit,
	setState,
	addColumnItem,
	saveColumnItem,
	deleteItem,
}) => {
	const [options, setOptions] = React.useState([])
	const [loading, setLoading] = React.useState(false)

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>, ind: number) => {
		setState(
			state.map((item, index) => {
				if (index === ind) {
					if (e.target.value === '' && isRecommendation) {
						return { ...item, name: e.target.value, criticality: null }
					}
					return {
						...item,
						name: e.target.value,
					}
				}
				return item
			})
		)
	}

	const onSelect = ({ value, label }, ind: number) => {
		setState(
			state.map((item, index) => {
				if (index === ind) {
					return {
						...item,
						criticality: { id: value, name: label },
					}
				}
				return item
			})
		)
	}

	const fetchOptions = async () => {
		const response = await enumsApi.apiV1EnumRecommendationCriticalityValuesGet()

		setOptions(response.data)
	}

	return (
		<Modal
			open={show}
			destroyOnClose
			centered
			footer={false}
			title='Редактирование'
			onCancel={() => setShowEdit({ id: null, show: false, type: '', riskType: '' })}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 5,
					userSelect: 'none',
				}}
			>
				{state?.map((item, ind: number) => {
					let count = ind + 1
					return (
						<div
							key={ind}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								gap: 5,
							}}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									width: '100%',
								}}
							>
								<span style={{ width: 24 }}>{count}.</span>
								<Input.TextArea
									value={item?.name ? item.name : undefined}
									autoSize={{ minRows: 2 }}
									onChange={event => onChange(event, ind)}
								/>
								{!isRecommendation ? (
									<Button
										icon={<DeleteOutlined rev={undefined} />}
										type='link'
										onClick={() => deleteItem(type, item.id)}
									/>
								) : null}
							</div>
							{isRecommendation ? (
								<>
									<Select
										options={options.map(item => ({ value: item.id, label: item.name }))}
										onFocus={fetchOptions}
										disabled={!item?.name}
										value={(item as RecommendationDto)?.criticality?.name}
										onSelect={(value, option) => onSelect(option, ind)}
										style={{ width: 60 }}
									/>
									<Button
										icon={<DeleteOutlined rev={undefined} />}
										type='link'
										onClick={() => deleteItem(type, item.id)}
									/>
								</>
							) : null}
						</div>
					)
				})}

				<Button
					type='dashed'
					loading={loading}
					disabled={loading}
					block
					onClick={async e => {
						e.stopPropagation()
						setLoading(true)

						const newItem =
							type === 'causes'
								? { hazopEntry: { id: hazopEntry.id }, id: `${state.length}` }
								: await addColumnItem(type, hazopEntry.id, showEdit.riskType)

						state ? setState([...state, newItem]) : setState([newItem])

						setLoading(false)
					}}
					icon={<PlusOutlined rev={undefined} />}
					style={{ marginBottom: 10 }}
				>
					Добавить {TypeOfState[type]}
				</Button>
			</div>
			<Space>
				<Button
					type='primary'
					onClick={e => {
						e.stopPropagation()

						type === 'causes'
							? saveColumnItem(type, state, hazopEntry.id)
							: saveColumnItem(type, state, hazopEntry.id, showEdit.riskType)

						setShowEdit({
							id: null,
							show: false,
							type: '',
							riskType: '',
						})
					}}
				>
					Сохранить
				</Button>
				<Button
					onClick={() =>
						setShowEdit({
							id: '',
							show: false,
							type: '',
							riskType: '',
						})
					}
				>
					Отмена
				</Button>
			</Space>
		</Modal>
	)
}

export default HazopTableEditModal
