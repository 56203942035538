import { Avatar, Button, Col, Form, Input, Row, Select, Space } from 'antd'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RoleDto, UserDto } from '../../generated/backend'
import { setFieldValue } from '../../utils/helpers'
import { Loader } from '../UI/Loaders/Loader/Loader'
import { rolesApi, usersApi } from '../../api/apis'

type UserFormProps = {}

export const UserForm: React.FC<UserFormProps> = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const [data, setData] = React.useState<UserDto>()
	const [roles, setRoles] = React.useState<RoleDto[]>([])

	const fetchUser = async () => {
		const response = await usersApi.apiV1UserIdGet(+id)

		setData(response.data)
	}

	const getRoles = async () => {
		const response = await rolesApi.apiV1RoleGet()

		setRoles(response.data)
	}

	React.useEffect(() => {
		fetchUser()
	}, [id])

	const onSubmit = async () => {
		//Сохранение пользователя
	}

	const addToRole = async (userId: number, roleId: number) => {
		await usersApi.apiV1UserAddToRolePost(userId, roleId)

		await fetchUser()
	}

	if (!data) {
		return <Loader />
	}

	return (
		<div style={{ padding: '0 12px' }}>
			<Space style={{ marginBottom: '12px' }}>
				<Button type={'primary'}>Сохранить</Button>
				<Button onClick={() => navigate('/settings/users')}>Назад</Button>
			</Space>
			<Row>
				<Col span={3}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<Avatar size={120} />
					</div>
				</Col>
				<Col span={12}>
					<Form labelCol={{ span: 3 }} labelAlign={'left'}>
						<Form.Item label={'Логин'}>
							<Input placeholder={'Имя'} value={data.login} disabled={true} />
						</Form.Item>
						<Form.Item label={'Пароль'}>
							<Input
								placeholder={'Пароль'}
								value={''}
								name={'password'}
								onChange={event =>
									setData(
										setFieldValue(event.target.name, event.target.value, data)
									)
								}
							/>
						</Form.Item>
						<Form.Item label={'Роль'}>
							<Select
								placeholder={'Роль'}
								options={roles.map(role => ({
									value: role.id,
									label: role.name,
								}))}
								mode={'multiple'}
								value={data.roles.map(role => role.name)}
								showSearch
								filterOption={(input, option: { label: string; value: number }) =>
									(option?.label ?? '').includes(input)
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								onSelect={async (
									value,
									option: { label: string; value: number }
								) => {
									const selectedRole = roles.find(
										item => item.id === option.value
									)

									const filteredRoles = roles.filter(
										item => item.id !== selectedRole.id
									)

									setRoles(filteredRoles)

									await addToRole(data.id, selectedRole.id)
								}}
								onFocus={getRoles}
							/>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</div>
	)
}
