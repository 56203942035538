import React from 'react'
import styles from './NotFoundRoute.module.css'
import { Button, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

const NotFoundRoute: React.FC = () => {
	const navigate = useNavigate()

	const onBackButtonClick = () => {
		navigate('/')
	}

	return (
		<main className={styles.container}>
			<section>
				<Typography.Title level={3}>Страница не найдена</Typography.Title>
				<div className={styles.text}>
					Кажется такой страницы не существует или она была удалена.
				</div>
				<Button onClick={onBackButtonClick} type={'primary'} size={'large'}>
					Вернуться на главную
				</Button>
			</section>
		</main>
	)
}

export default NotFoundRoute
