import React from 'react'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
import { fetchUserInfo, login } from './general.actions'

export interface GeneralState {
	value: number
	initial: boolean
	token: string
	username: string
	password: string
	roles: string[]
	name: string
}

const initialState: GeneralState = {
	value: 0,
	username: '',
	password: '',
	name: '',
	initial: true,
	roles: JSON.parse(localStorage.getItem('roles')),
	token: localStorage.getItem('token'),
}

export const generalSlice = createSlice({
	name: 'counter',
	initialState,
	reducers: {
		changeUsername: (state, action: PayloadAction<string>) => {
			state.username = action.payload
		},
		changePassword: (state, action: PayloadAction<string>) => {
			state.password = action.payload
		},
		logout: state => {
			state.token = undefined
			localStorage.setItem('token', undefined)
			localStorage.setItem('roles', JSON.stringify([]))
			localStorage.setItem('isInitial', 'true')
			location.href = '/login'
		},
	},
	extraReducers: builder => {
		// Add reducers for additional action types here, and handle loading state as needed
		builder
			.addCase(fetchUserInfo.fulfilled, (state, action) => {
				if (localStorage.getItem('isInitial') === 'true') {
					message.info(<>Добро пожаловать, {action.payload}!</>)
				}

				localStorage.setItem('isInitial', 'false')

				state.name = action.payload
				state.initial = false
			})
			.addCase(fetchUserInfo.rejected, state => {
				// Add user to the state array
				//state.entities.push(action.payload)
				state.initial = false
				state.token = undefined

				message.info(
					<>
						Вы разлогинились, <a href={'/login'}>залогиньтесь</a>
					</>
				)
			})
			.addCase(login.fulfilled, (state, action) => {
				// Add user to the state array
				state.token = action.payload.token

				//store token
				localStorage.setItem('token', state.token)
				//store Roles
				localStorage.setItem('roles', JSON.stringify(action.payload.roles ?? []))
			})
			.addCase(login.rejected, (state, action) => {
				message.error(`${action.payload}`)
			})
		//todo add cases (errors etc)
	},
})

// Action creators are generated for each case reducer function
export const { changeUsername, changePassword, logout } = generalSlice.actions

export default generalSlice.reducer
