import { Button, Form, Input, Modal, Space, message } from 'antd'
import React from 'react'
import { matrixApi } from '../../../../api/apis'
import { useModalActions } from '../../../../hooks/useModalActions'

type CreatePresetModalProps = {}

const pattern = new RegExp(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/)

const CreatePresetModal: React.FC<CreatePresetModalProps> = ({}) => {
	const [messageApi, contextHolder] = message.useMessage()
	const [form] = Form.useForm()
	const [isOpen, onOpenModal, onCloseModal] = useModalActions()

	const onFinish = async ({ templateCode }: { templateCode: string }) => {
		try {
			await matrixApi.apiV1MatrixValueSaveAsTemplatePost(templateCode)
			onCloseModal()
			messageApi.success('Пресет успешно создан!')
			form.resetFields()
		} catch (error) {
			messageApi.error('Не удалось создать пресет')
		}
	}

	return (
		<>
			{contextHolder}
			<Button name='saveAsPreset' onClick={onOpenModal} type='dashed'>
				Сохранить как пресет
			</Button>
			<Modal
				open={isOpen}
				onCancel={onCloseModal}
				destroyOnClose
				centered
				footer={false}
				title={'Создать пресет'}
			>
				<Form onFinish={onFinish} form={form} layout='vertical'>
					<Form.Item
						hasFeedback
						validateDebounce={500}
						rules={[
							{
								pattern,
								message:
									'Код пресета должен содержать только латинские буквы и цифры!',
							},
							{ required: true, message: 'Код пресета не может быть пустым!' },
						]}
						name={'templateCode'}
						label={'Код пресета'}
					>
						<Input id='code' placeholder='Введите код' size='large' />
					</Form.Item>
					<Form.Item>
						<Space>
							<Button name='save' htmlType='submit' type='primary'>
								Сохранить
							</Button>
							<Button onClick={onCloseModal}>Закрыть</Button>
						</Space>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

export default CreatePresetModal
