import { Button, Modal, Space } from 'antd';
import React from 'react';

type UserDeleteModalProps = {
    modalProps: { show: boolean; id: number | null };
    onDelete: (id: number) => void;
    onClose: () => void;
};
export const UserDeleteModal: React.FC<UserDeleteModalProps> = ({ modalProps, onDelete, onClose }) => {
    return (
        <Modal
            centered={true}
            title={'Вы действительно хотите удалить пользователя?'}
            open={modalProps.show}
            onCancel={onClose}
            footer={false}
        >
            <Space>
                <Button
                    danger={true}
                    onClick={() => {
                        onDelete(modalProps.id);
                        onClose();
                    }}
                >
                    Удалить
                </Button>
                <Button onClick={onClose}>Отмена</Button>
            </Space>
        </Modal>
    );
};
