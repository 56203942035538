import { Button, Popover } from 'antd'
import { RenderFunction } from 'antd/es/_util/getRenderPropValue'
import React, { PropsWithChildren } from 'react'

interface CustomPopoverProps extends PropsWithChildren {
	type?: 'link' | 'text' | 'default' | 'primary' | 'dashed'
	title?: string
	content?: React.ReactNode | RenderFunction
}

const CustomPopover: React.FC<CustomPopoverProps> = ({ children, type, title, content }) => {
	const [open, setOpen] = React.useState(false)

	const onOpenChange = (open: boolean) => {
		setOpen(open)
	}

	return (
		<Popover
			content={content}
			onOpenChange={onOpenChange}
			trigger={'click'}
			open={open}
			placement='topLeft'
			title={title}
		>
			<Button name='add' type={type}>
				{children}
			</Button>
		</Popover>
	)
}

export default CustomPopover
