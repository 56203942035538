import {
	CopyOutlined,
	DeleteOutlined,
	ExpandAltOutlined,
	PlusOutlined,
	ShrinkOutlined,
} from '@ant-design/icons'
import { Button, Select, message } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useParams } from 'react-router-dom'
import { enumsApi } from '../../../../api/apis'
import {
	ConsequenceDtoRequestRiskTypeEnum,
	CriticalityDto,
	RecommendationDto,
	SafeguardDtoRequestSafeguardTypeEnum,
} from '../../../../generated/backend'
import { useModalActions } from '../../../../hooks/useModalActions'
import DebounceTextArea from '../../../UI/Inputs/DebounceTextArea/DebounceTextArea'
import CopyListAttributesModal from '../../../UI/Modals/CopyListAttributesModal/CopyListAttributesModal'
import { TypeOfHazopTableState } from '../../../types'
import styles from '../NewHazopForm.module.css'

type RecommendationsFormProps = {
	placeholder: string
	filterEntity: (
		entityType: 'causes' | 'consequences' | 'safeguards' | 'recommendations',
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => TypeOfHazopTableState[]
	saveItem: (
		entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
		item: TypeOfHazopTableState,
		hazopEntryId: string,
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => Promise<void>
	addItem: (
		entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards',
		riskType?: ConsequenceDtoRequestRiskTypeEnum,
		safeguardType?: SafeguardDtoRequestSafeguardTypeEnum
	) => void
	deleteItem: (typeOfEntity: string, id: string) => void
	mainForm: string
	onChangeView: (selectedForm: string, formType: string) => void
}

const RecommendationsForm: React.FC<RecommendationsFormProps> = ({
	placeholder,
	deleteItem,
	addItem,
	saveItem,
	filterEntity,
	mainForm,
	onChangeView,
}) => {
	const { hazopEntryId } = useParams()

	const [criticality, setCriticality] = React.useState<CriticalityDto[]>([])
	const [showCopyModal, onOpenModal, onCloseModal] = useModalActions()

	React.useEffect(() => {
		const fetchOptionsForCriticality = async () => {
			try {
				const response = await enumsApi.apiV1EnumRecommendationCriticalityValuesGet()

				setCriticality(response.data)
			} catch (error) {
				message.error('Не удалось загрузить критичность')
			}
		}

		fetchOptionsForCriticality()
	}, [])

	return (
		<>
			<CopyListAttributesModal
				show={showCopyModal}
				onCancel={onCloseModal}
				typeOfEntity={'recommendations'}
			/>
			<div className={classNames(styles.title)}>
				Рекомендации
				<div className={styles.actionButtons}>
					<Button
						type={'text'}
						icon={<CopyOutlined rev={undefined} />}
						onClick={onOpenModal}
					/>
					<Button
						type={'text'}
						icon={
							mainForm ? (
								<ShrinkOutlined rev={undefined} style={{ fontSize: 20 }} />
							) : (
								<ExpandAltOutlined rev={undefined} />
							)
						}
						onClick={() =>
							onChangeView(
								mainForm === 'recommendations' ? '' : 'recommendations',
								'recommendations'
							)
						}
					/>
				</div>
			</div>
			<div className={classNames(styles.table)}>
				<div className={classNames(styles.cellHead)}>Причины</div>
				<div className={classNames(styles.cellHead)}>Критичность</div>
				{filterEntity('recommendations').map((recommendation, index) => (
					<React.Fragment key={recommendation.id ?? index}>
						<div className={classNames(styles.cell, styles.cellPad)}>
							<div className={styles.cellWithNumber}>
								{recommendation.number ?? index + 1}.
							</div>
							<DebounceTextArea
								placeholder={placeholder}
								entityType={'recommendations'}
								variant={'borderless'}
								initialEntity={recommendation}
								saveEntity={saveItem}
								delay={500}
								onCreate={addItem}
								autoSize={{ minRows: mainForm ? 2 : 1 }}
								id={`recommendation-${index}`}
							/>
						</div>
						<div className={classNames(styles.cellWithSelect)}>
							<Select
								disabled={!recommendation.name}
								variant={'borderless'}
								value={(recommendation as RecommendationDto).criticality?.name}
								placeholder={'Выбрать'}
								size={mainForm ? 'large' : 'middle'}
								options={criticality.map(item => ({
									...item,
									value: item.id,
									label: item.name,
								}))}
								onSelect={(value, option) => {
									saveItem(
										'recommendations',
										{ ...recommendation, criticality: option },
										hazopEntryId
									)
								}}
								data-test={`recommendation-criticality-${index}`}
							/>
							{recommendation.name ? (
								<div className={styles.button}>
									<Button
										size={'small'}
										type={'text'}
										icon={
											<PlusOutlined
												rev={undefined}
												style={{
													fontSize: mainForm ? '20px' : null,
												}}
											/>
										}
										onClick={() => addItem('recommendations')}
										name={`recommendation-add-${index}`}
									/>
									<Button
										size={'small'}
										type={'text'}
										icon={
											<DeleteOutlined
												rev={undefined}
												style={{
													fontSize: mainForm ? '20px' : null,
												}}
											/>
										}
										onClick={() =>
											deleteItem('recommendations', recommendation.id)
										}
									/>
								</div>
							) : null}
						</div>
					</React.Fragment>
				))}
			</div>
		</>
	)
}

export default RecommendationsForm
