import React from 'react'
import styles from './LopaForm.module.css'
import { InputNumber } from 'antd'
import classNames from 'classnames'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { useActions } from '../../../hooks/useActions'

type LopaFormProps = {}

const safeguards = [
	{ title: 'ПР.', id: 'design' },
	{ title: 'СУ', id: 'controlSystem' },
	{ title: 'СНГ', id: 'independentSignaling' },
	{ title: 'МЗ', id: 'reliefValves' },
	{ title: 'ПАЗ', id: 'spaz' },
	{ title: 'ДО', id: 'operatorActions' },
	{ title: 'ДР1', id: 'other1' },
	{ title: 'ДР2', id: 'other2' },
]

const lopaSettingsList = [
	{ title: 'riskDuration', id: 'riskDuration' },
	{ title: 'busyStaff', id: 'busyStaff' },
	{ title: 'fireRisk', id: 'fireRisk' },
	{ title: 'vulnerabilityFactor', id: 'vulnerabilityFactor' },
]

const LopaForm: React.FC<LopaFormProps> = () => {
	const { lopaSettings } = useTypedSelector(state => state.settings)
	const { changeLopaSettings } = useActions()

	const handleChangeLopaSettings = (key: string, value: number) => {
		if (!value) {
			changeLopaSettings({ key, value: null })
			return
		}
		changeLopaSettings({ key, value })
	}
	return (
		<div className={styles.safeguardSettings}>
			<div>
				<div className={styles.safeguardTypeContainer}>
					<div className={styles.cellHead}>Тип</div>
					<div className={styles.cellHead}>Коэффициент</div>
					{safeguards.map(safeguard => (
						<React.Fragment key={safeguard.id}>
							<div className={classNames(styles.cell, styles.cellPad)}>
								{safeguard.title}
							</div>
							<div className={classNames(styles.cell, styles.cellPad)}>
								<InputNumber
									onChange={value => {
										handleChangeLopaSettings(safeguard.id, value)
									}}
									value={lopaSettings[safeguard.id]}
									name={safeguard.id}
								/>
							</div>
						</React.Fragment>
					))}
				</div>
			</div>
			<div className={styles.safeguardTypeContainer}>
				<div className={styles.cellHead}>Тип</div>
				<div className={styles.cellHead}>Коэффициент</div>
				{lopaSettingsList.map(lopa => (
					<React.Fragment key={lopa.id}>
						<div className={classNames(styles.cell, styles.cellPad)}>{lopa.title}</div>
						<div className={classNames(styles.cell, styles.cellPad)}>
							<InputNumber
								onChange={value => handleChangeLopaSettings(lopa.id, value)}
								value={lopaSettings[lopa.id]}
								name={lopa.id}
							/>
						</div>
					</React.Fragment>
				))}
			</div>
		</div>
	)
}

export default LopaForm
