import { Button, Form, Input, Modal, Select, Space } from 'antd';
import React from 'react';
import { ILopaTableModal } from '../../types';

type LopaTableModalProps = {
    tableModalProps: ILopaTableModal;
    onClose: () => void;
    saveChanges: (values: { type: string | number }, id: string, numberOfNodeLine: number) => void;
};

const LopaTableModal: React.FC<LopaTableModalProps> = ({ tableModalProps, onClose, saveChanges }) => {
    const [form] = Form.useForm();

    return (
        <Modal
            open={tableModalProps.open}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
            footer={[]}
            centered
            title='Редактирование'
            destroyOnClose
        >
            <Form
                layout='vertical'
                onFinish={values => {
                    saveChanges(values, tableModalProps.id, tableModalProps.numberOfNodeLine);
                    form.resetFields();
                }}
                form={form}
            >
                {tableModalProps.type === 'comment' ? (
                    <Form.Item name={tableModalProps.type} label={'Комментарий'}>
                        <Input.TextArea />
                    </Form.Item>
                ) : (
                    <>
                        <Form.Item name={tableModalProps.type} label={'Число'}>
                            <Input type='number' size='large' />
                        </Form.Item>
                        <Form.Item label={'Степень числа'}>
                            <Select size='large' />
                        </Form.Item>
                    </>
                )}
                <Form.Item>
                    <Space>
                        <Button htmlType='submit' type='primary'>
                            Сохранить
                        </Button>
                        <Button
                            onClick={() => {
                                onClose();
                                form.resetFields();
                            }}
                            htmlType='reset'
                        >
                            Закрыть
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default LopaTableModal;
