import { Button, Space, message } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { hazopEntriesApi, parametersApi } from '../../../api/apis'
import { HazopEntryDto, NodeGuidewordDto, NodeParameterDto } from '../../../generated/backend'
import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
	fetchHazopEntries,
	generateParameters,
	removeCause,
	removeConsequence,
	removeRecommendations,
	removeRow,
	removeSafeguards,
} from '../../../redux/hazopEntries/hazopEntries.actions'
import { useAppDispatch } from '../../../redux/store'
import { Loader } from '../../UI/Loaders/Loader/Loader'
import CustomPopover from '../../UI/Popover/CustomPopover'
import { IEditHazopTable, IValue } from '../../types'
import HazopTableSelectColumn from '../HazopTable/HazopTableSelectColumn'
import { contentForPopover } from './ContentForPopover'
import styles from './HazopList.module.css'

type HazopListProps = {}

const headings = ['№', 'Слово параметр', 'Управляющее слово', 'Действия']

const HazopList: React.FC<HazopListProps> = ({}) => {
	const { id } = useParams()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const hazopEntries = useTypedSelector(state => state.hazopEntries)
	const { editHazopEntry } = useActions()

	const [messageApi, contextHolder] = message.useMessage()
	const [stateForSelect, setStateForSelect] = React.useState<
		NodeGuidewordDto[] | NodeParameterDto[]
	>([])
	const [showEdit, setShowEdit] = React.useState<IEditHazopTable>({
		id: null,
		show: false,
		type: '',
	})

	React.useEffect(() => {
		dispatch(fetchHazopEntries(id))
	}, [id])

	const deleteAllParameters = (hazopEntry: HazopEntryDto) => {
		try {
			hazopEntry.causes.map(cause =>
				dispatch(removeCause({ id: cause.id, hazopEntryId: hazopEntry.id }))
			)
			hazopEntry.consequences.map(consequence =>
				dispatch(removeConsequence({ id: consequence.id, hazopEntryId: hazopEntry.id }))
			)
			hazopEntry.safeguards.map(safeguard =>
				dispatch(removeSafeguards({ id: safeguard.id, hazopEntryId: hazopEntry.id }))
			)
			hazopEntry.recommendations.map(recommendation =>
				dispatch(
					removeRecommendations({ id: recommendation.id, hazopEntryId: hazopEntry.id })
				)
			)
			editHazopEntry({ field: 'nodeGuideword', value: null, id: hazopEntry.id })
			editHazopEntry({ field: 'humanMatrixValue', value: null, id: hazopEntry.id })
			editHazopEntry({ field: 'financeMatrixValue', value: null, id: hazopEntry.id })
			editHazopEntry({ field: 'ecologyMatrixValue', value: null, id: hazopEntry.id })
			editHazopEntry({ field: 'reputationMatrixValue', value: null, id: hazopEntry.id })
		} catch (error) {}
	}

	const setFieldValue = (field: string, value: IValue, id: string, isNodeParameter?: boolean) => {
		editHazopEntry({ field, value, id })

		const hazopEntry = hazopEntries.items.find(item => item.id === id)

		if (isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id) {
			deleteAllParameters(hazopEntry)
		}

		const newItem = {
			nodeParameter: hazopEntry.nodeParameter?.id,
			// nodeAdditionalParameter: hazopEntry.nodeAdditionalParameter?.id,
			nodeGuideword:
				isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id
					? null
					: hazopEntry.nodeGuideword?.id,
			humanMatrixValue:
				isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id
					? null
					: hazopEntry.humanMatrixValue?.id,
			financeMatrixValue:
				isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id
					? null
					: hazopEntry.financeMatrixValue?.id,
			ecologyMatrixValue:
				isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id
					? null
					: hazopEntry.ecologyMatrixValue?.id,
			reputationMatrixValue:
				isNodeParameter && value?.id !== hazopEntry.nodeParameter?.id
					? null
					: hazopEntry.reputationMatrixValue?.id,
			nodeId: hazopEntry.node?.id,
			number: hazopEntry.number,
			id,
			[field]: value?.id,
		}

		hazopEntriesApi.apiV1HazopEntryPut(newItem)
	}

	const onBackButtonClick = () => {
		navigate('/hazop')
	}

	const onMoreButtonClick = (hazopEntryId: string) => {
		navigate(`/hazop/list/${id}/${hazopEntryId}`)
	}

	const onDeleteButtonClick = (hazopEntryId: string) => {
		dispatch(removeRow(hazopEntryId)).then(response => {
			if (response.meta.requestStatus === 'fulfilled') {
				messageApi.success('Успешно удалено')
			}
		})
	}

	const fetchOptions = async (type: 'parameter' | 'nodeGuideword', hazopEntry: HazopEntryDto) => {
		switch (type) {
			case 'parameter': {
				const response = await parametersApi.apiV1NodeParameterGet()
				setStateForSelect(response.data as NodeParameterDto[])

				break
			}
			case 'nodeGuideword':
				const response = await parametersApi.apiV1NodeParameterIdGet(
					hazopEntry.nodeParameter?.id
				)
				setStateForSelect(response.data.guidewords as NodeGuidewordDto[])

				break
		}
	}

	const onDoubleClick = (e: React.MouseEvent<HTMLElement>, id: string, type: string) => {
		e.stopPropagation()
		setShowEdit({
			id,
			show: true,
			type,
		})
	}

	const generateHazopEntries = async () => {
		const response = await dispatch(generateParameters(id))

		if (response.meta.requestStatus === 'fulfilled') {
			messageApi.success('Генерация выполнена успешно')
		} else {
			messageApi.error('Произошла ошибка при генерации')
		}
	}

	if (hazopEntries.loading) return <Loader />

	return (
		<div className={styles.container}>
			{contextHolder}
			<Button
				name='generate'
				onClick={generateHazopEntries}
				className={styles.generateButton}
			>
				Генерация отклонений
			</Button>
			<div className={styles.wrap}>
				<div className={styles.table}>
					{headings.map((heading, index) => (
						<div
							key={index}
							className={classNames(
								styles.cell,
								styles.columnHeading,
								styles.centeredCell,
								styles.stickyHeading
							)}
						>
							{heading}
						</div>
					))}
					{hazopEntries?.items?.map((hazopEntry, index) => (
						<React.Fragment key={hazopEntry.id}>
							<div className={classNames(styles.cell, styles.centeredCell)}>
								{hazopEntry.number}
							</div>
							<div
								className={classNames(styles.cell, styles.centeredCell)}
								onDoubleClick={e =>
									onDoubleClick(e, hazopEntry.id, 'nodeParameter')
								}
							>
								<HazopTableSelectColumn
									hazopEntry={hazopEntry}
									showSelect={showEdit}
									setShowSelect={setShowEdit}
									setFieldValue={setFieldValue}
									fetchOptions={() => fetchOptions('parameter', hazopEntry)}
									options={stateForSelect}
									setOptions={setStateForSelect}
									type='nodeParameter'
								/>
							</div>
							<div
								className={classNames(styles.cell, styles.centeredCell)}
								onDoubleClick={e =>
									hazopEntry?.nodeParameter
										? onDoubleClick(e, hazopEntry.id, 'nodeGuideword')
										: messageApi.warning('Выберите сначала слово параметр')
								}
							>
								<HazopTableSelectColumn
									hazopEntry={hazopEntry}
									showSelect={showEdit}
									setShowSelect={setShowEdit}
									setFieldValue={setFieldValue}
									fetchOptions={() => fetchOptions('nodeGuideword', hazopEntry)}
									options={stateForSelect}
									setOptions={setStateForSelect}
									type='nodeGuideword'
								/>
							</div>
							<div className={classNames(styles.cell, styles.centeredCell)}>
								<Button
									type='link'
									onClick={() => onMoreButtonClick(hazopEntry.id)}
									name='more'
								>
									Подробнее
								</Button>
								<Button
									name='delete'
									onClick={() => onDeleteButtonClick(hazopEntry.id)}
									type='link'
								>
									Удалить
								</Button>
							</div>
						</React.Fragment>
					))}
				</div>
			</div>
			<Space className={styles.buttons}>
				<CustomPopover
					content={() => contentForPopover({ lastNumber: hazopEntries.items.length })}
					type='primary'
					title='Добавить отклонение'
				>
					Добавить
				</CustomPopover>
				<Button onClick={onBackButtonClick}>Назад</Button>
			</Space>
		</div>
	)
}

export default HazopList
