import { createAsyncThunk } from '@reduxjs/toolkit';
import { matrixApi } from '../../api/apis';

export const fetchMatrix = createAsyncThunk('riskMatrix/fetch-matrix', async ({isInitial}: {isInitial: boolean}, { rejectWithValue }) => {
    try {
        const response = await matrixApi.apiV1MatrixValueMatrixGet();

        return {matrix: response.data, isInitial};
    } catch (e) {
        return rejectWithValue(e);
    }
});
