import { createSlice } from '@reduxjs/toolkit'
import { editLopaEntry, fetchLopaEntries, importLopaEntries } from './lopaEntries.actions'
import { LopaEntry } from '../../generated/backend'

interface LopaEntriesState {
	items: LopaEntry[]
	loading: boolean
}

const initialState: LopaEntriesState = {
	items: [],
	loading: false,
}

export const lopaEntriesSlice = createSlice({
	name: 'lopaEntries',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchLopaEntries.pending, state => {
			state.loading = true
		})
		builder.addCase(fetchLopaEntries.fulfilled, (state, action) => {
			state.loading = false
			state.items = action.payload
		})
		builder.addCase(fetchLopaEntries.rejected, state => {
			state.loading = false
		})

		builder.addCase(importLopaEntries.pending, state => {
			state.loading = true
		})
		builder.addCase(importLopaEntries.fulfilled, (state, action) => {
			state.loading = false
			state.items = action.payload
		})
		builder.addCase(importLopaEntries.rejected, state => {
			state.loading = false
		})

		builder.addCase(editLopaEntry.fulfilled, (state, action) => {
			const indexOfItem = state.items.findIndex(
				item => item.hazopEntry.id === action.payload.hazopEntryId
			)

			state.items[indexOfItem] = action.payload.lopaEntry
		})
	},
})

export const {} = lopaEntriesSlice.actions

export default lopaEntriesSlice.reducer
