import { message } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { EquipmentDto, EquipmentDtoRequest } from '../../generated/backend'
import {
	addEquipment,
	deleteEquipment,
	fetchEquipments,
} from '../../redux/equipments/equipments.actions'
import { RootState, useAppDispatch } from '../../redux/store'
import ContentLayout from '../UI/ContentLayout/ContentLayout'

type EquipmentLayoutProps = {}

const layoutKey = 'equipments'

export const EquipmentLayout: React.FC<EquipmentLayoutProps> = ({}) => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { equipments, isLoading } = useSelector((state: RootState) => state.equipments)

	const [messageApi, contextHolder] = message.useMessage()

	React.useEffect(() => {
		const fetchEquipmentsData = async () => {
			const response = await dispatch(fetchEquipments())

			if (response.meta.requestStatus === 'rejected') {
				messageApi.error('Не удалось загрузить оборудование!')
			}
		}

		fetchEquipmentsData()
	}, [])

	const onDeleteEquipment = async (equipment: EquipmentDto) => {
		navigate('/dictionaries/equipments/')
		const response = await dispatch(deleteEquipment(equipment.id))
		if (response.meta.requestStatus === 'fulfilled') {
			messageApi.success('Удалено')
		} else {
			messageApi.error('Что-то пошло не так!')
		}
	}

	const onSave = async (equipment: EquipmentDtoRequest) => {
		const createdEquipment = await dispatch(addEquipment({ ...equipment, parentId: null }))
		if (createdEquipment.meta.requestStatus === 'fulfilled') {
			navigate(`/dictionaries/equipments/${(createdEquipment.payload as EquipmentDto)?.id}`)
			messageApi.success('Успешно создано!')
		} else {
			messageApi.error('Что-то пошло не так!')
		}
	}

	return (
		<>
			{contextHolder}
			<ContentLayout
				onDeleteEntity={onDeleteEquipment}
				onSave={onSave}
				entity={equipments}
				path={'/dictionaries/equipments'}
				loading={isLoading}
				layoutKey={layoutKey}
			/>
		</>
	)
}
