import { createSlice } from '@reduxjs/toolkit'
import { EquipmentDto } from '../../generated/backend'
import {
	addEquipment,
	deleteEquipment,
	editEquipment,
	fetchEquipmentById,
	fetchEquipments,
} from './equipments.actions'

interface EquipmentsState {
	equipments: EquipmentDto[]
	isLoading: boolean
	selected: { equipment: EquipmentDto; isLoading: boolean }
}

const initialState: EquipmentsState = {
	equipments: [],
	isLoading: true,
	selected: {
		equipment: {},
		isLoading: true,
	},
}

export const equipmentSlice = createSlice({
	name: 'equipments',
	initialState,
	reducers: {
		setEquipments: (state, action) => {
			state.equipments = action.payload
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchEquipments.pending, state => {
			state.isLoading = true
		})
		builder.addCase(fetchEquipments.fulfilled, (state, action) => {
			state.isLoading = false
			state.equipments = action.payload
		})
		builder.addCase(fetchEquipments.rejected, state => {
			state.isLoading = false
		})

		builder.addCase(fetchEquipmentById.pending, state => {
			state.selected.isLoading = true
		})
		builder.addCase(fetchEquipmentById.fulfilled, (state, action) => {
			state.selected.isLoading = false
			state.selected.equipment = action.payload
		})
		builder.addCase(fetchEquipmentById.rejected, state => {
			state.selected.isLoading = false
		})

		builder.addCase(addEquipment.pending, state => {
			state.isLoading = true
		})
		builder.addCase(addEquipment.fulfilled, (state, action) => {
			state.isLoading = false
			state.equipments.push(action.payload)

			state.selected = {
				equipment: action.payload,
				isLoading: false,
			}

			return state
		})
		builder.addCase(addEquipment.rejected, state => {
			state.isLoading = false
		})

		builder.addCase(editEquipment.pending, state => {
			// state.loading = true;
		})
		builder.addCase(editEquipment.fulfilled, (state, action) => {
			state.selected.isLoading = false
			const editedIndex = state.equipments.findIndex(item => item.id === action.payload.id)

			state.equipments[editedIndex] = action.payload
			state.selected.equipment = action.payload
		})
		builder.addCase(editEquipment.rejected, state => {
			state.isLoading = false
		})

		builder.addCase(deleteEquipment.pending, state => {
			state.isLoading = true
		})
		builder.addCase(deleteEquipment.fulfilled, (state, action) => {
			state.isLoading = false
			state.equipments = state.equipments.filter(item => item.id !== action.payload)
		})
		builder.addCase(deleteEquipment.rejected, state => {
			state.isLoading = false
		})
	},
})

export const { setEquipments } = equipmentSlice.actions

export default equipmentSlice.reducer
