import { notification } from 'antd'
import { errorModal } from '../../Modals/ErrorModal/ErrorModal'
import React from 'react'
import { AxiosError } from 'axios'

const errorType = (error: AxiosError) => {
	switch (error.response.status) {
		case 400:
			return (
				<>
					Пожалуйста, проверьте правильность заполнения полей.
					<div
						onClick={() => errorModal(error.response.data)}
						style={{ cursor: 'pointer', color: '#1677ff' }}
					>
						Подробнее...
					</div>
				</>
			)
		case 404:
			return <></>
		case 409:
			return <>{error.response.data}</>
		case 500:
			return (
				<>
					Пожалуйста, попробуйте позже.
					<div
						onClick={() => errorModal(error.response.data)}
						style={{ cursor: 'pointer', color: '#1677ff' }}
					>
						Подробнее...
					</div>
				</>
			)
	}
}

export const errorNotification = (error: AxiosError, title: string) => {
	notification.error({
		message: title,
		description: errorType(error),
		duration: 3,
	})
}
