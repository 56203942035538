import { Button, Divider, Form, Input, message, Modal, Space } from 'antd'
import React from 'react'
import styles from './ChooseProbability.module.css'
import classNames from 'classnames'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { useAppDispatch } from '../../../../redux/store'
import { fetchCauseProbabilities } from '../../../../redux/causeProbabilities/causeProbabilities.actions'
import { TypeOfHazopTableState } from '../../../types'
import {
	CauseDto,
	ConsequenceDtoRequestRiskTypeEnum,
	SafeguardDtoRequestSafeguardTypeEnum,
} from '../../../../generated/backend'
import { useParams } from 'react-router-dom'
import { useModalActions } from '../../../../hooks/useModalActions'

type ChooseProbabilityModalProps = {
	saveItem: (
		entityType: string,
		item: TypeOfHazopTableState,
		hazopEntryId: string,
		type?: ConsequenceDtoRequestRiskTypeEnum | SafeguardDtoRequestSafeguardTypeEnum
	) => Promise<void>
	item: CauseDto
	typeOfEntity: 'causes'
}

const pattern = new RegExp(/^[0-9]+((\.[0-9]+)?([eE][+-]?[0-9]+)?)$/)

const ChooseProbabilityModal: React.FC<ChooseProbabilityModalProps> = ({
	item,
	typeOfEntity,
	saveItem,
}) => {
	const [form] = Form.useForm<{ probability: number }>()
	const dispatch = useAppDispatch()
	const { hazopEntryId } = useParams()
	const { probabilities } = useTypedSelector(state => state.causeProbabilities)

	const [open, onOpenModal, onCloseModal] = useModalActions()

	const fetchCauseProbability = async () => {
		try {
			await dispatch(fetchCauseProbabilities())
		} catch (e) {
			message.error('Не удалось загрузить вероятности')
		}
	}

	const onChooseProbability = async (chosenProbability: number) => {
		try {
			await saveItem(
				typeOfEntity,
				{ ...item, causeProbability: chosenProbability },
				hazopEntryId
			)

			onCloseModal()
		} catch (e) {
			console.log(e)
		}
	}

	const mutateProbability = (value: string) => {
		const number = value.split('-')[0]
		const pow = value.split('e')[1]

		return (
			<span>
				{number} <sup>{pow}</sup>
			</span>
		)
	}

	return (
		<>
			{item.causeProbability ? (
				<div
					onClick={async () => {
						await fetchCauseProbability()
						onOpenModal()
					}}
					className={classNames(styles.cellTop)}
				>
					{mutateProbability(item.causeProbability.toExponential())}
				</div>
			) : (
				<div
					onClick={
						item.name
							? async () => {
									await fetchCauseProbability()
									onOpenModal()
							  }
							: null
					}
					className={classNames(styles.cellTop)}
					data-test='empty-probability'
				>
					<span
						className={styles.spanWithoutColor}
						style={{
							cursor: item.name ? 'pointer' : 'default',
						}}
					>
						?
					</span>
				</div>
			)}
			<Modal
				open={open}
				centered
				title={'Справочник вероятностей'}
				footer={false}
				onCancel={onCloseModal}
				width={'50vw'}
			>
				<main className={styles.wrap}>
					<div className={styles.table}>
						{probabilities?.map((causeProbability, index) => (
							<React.Fragment key={index}>
								<div className={classNames(styles.cell, styles.centeredCell)}>
									{++index}
								</div>
								<div className={styles.cell}>{causeProbability.name}</div>
								<div className={styles.cell}>
									{causeProbability.probability.toExponential()}
								</div>
								<div className={classNames(styles.cell, styles.centeredCell)}>
									<Button
										onClick={() =>
											onChooseProbability(causeProbability.probability)
										}
										name={`pick-${index}`}
										type='link'
									>
										Выбрать
									</Button>
								</div>
							</React.Fragment>
						))}
					</div>
				</main>
				<Divider plain>или</Divider>
				<Form form={form} onFinish={({ probability }) => onChooseProbability(probability)}>
					<Form.Item
						name='probability'
						rules={[
							{
								pattern,
								message: 'Вероятность должна быть в экспоненциальной форме!',
							},
							{ required: true, message: 'Вероятность не может быть пустой!' },
						]}
						hasFeedback
						validateDebounce={500}
					>
						<Space.Compact block>
							<Input placeholder='Введите вероятность в экспоненциальной форме' />
							<Button htmlType='submit' type='primary'>
								Выбрать
							</Button>
						</Space.Compact>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

export default ChooseProbabilityModal
