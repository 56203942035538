import { CloseCircleFilled, InfoCircleFilled, WarningFilled } from '@ant-design/icons'
import { Button, List, Space, Typography, Upload, UploadFile, UploadProps, message } from 'antd'
import type { RcFile } from 'antd/es/upload/interface'
import axios, { AxiosResponse } from 'axios'
import React from 'react'
import { dbApi, settingsApi } from '../../api/apis'
import { useActions } from '../../hooks/useActions'
import DeleteAllModal from './Modals/DeleteAllModal'
import ImportModal from './Modals/ImportModal'

const iconForNotification = {
	ERROR: <CloseCircleFilled style={{ color: 'red', fontSize: '30px' }} rev={undefined} />,
	WARNING: <WarningFilled style={{ color: 'orange', fontSize: '30px' }} rev={undefined} />,
	INFO: <InfoCircleFilled style={{ color: 'blue', fontSize: '30px' }} rev={undefined} />,
}

export const Import: React.FC = () => {
	const { changeProjectValues } = useActions()

	const [showModal, setShowModal] = React.useState<string>('')
	const [loading, setLoading] = React.useState<boolean>(false)
	const [file, setFile] = React.useState<UploadFile>(undefined)
	const [type, setType] = React.useState<string>('')
	const [serverInfo, setServerInfo] =
		React.useState<{ name: string; value: string; consequences: string; type: string }[]>(null)

	const props: UploadProps = {
		name: 'file',
		action: '',
		accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
		beforeUpload: file => {
			setShowModal('import')
			setFile(file)
			return false
		},
		showUploadList: false,
	}

	const handleUpload = async () => {
		setLoading(true)
		const formData = new FormData()
		formData.append('file', file as RcFile)

		try {
			let response: AxiosResponse
			if (type === 'node') {
				response = await axios.post(`/api/v1/import/node`, formData)
			} else {
				response = await axios.post(`/api/v1/import?eight-lines=${type}`, formData)
				await settingsApi.apiV1SettingsHazopTypePost(type === 'True' ? 'EIGHT' : 'FOUR')
				changeProjectValues({
					field: 'hazopType',
					value: type === 'True' ? 'EIGHT' : 'FOUR',
				})
			}

			setFile(undefined)
			setServerInfo(response.data)
			message.success('Загрузка завершена')
		} catch (e) {
			message.error('Загрузка прервана')
		} finally {
			setLoading(false)
			setShowModal('')
		}
	}

	const onClose = () => {
		setFile(undefined)
		setLoading(false)
		setShowModal('')
	}

	return (
		<>
			<ImportModal
				isUploading={loading}
				open={showModal === 'import'}
				onCancel={onClose}
				handleUpload={handleUpload}
			/>

			<Space direction={'vertical'} size={20}>
				<div>
					<Typography.Title level={5}>Импорт Hazop 8</Typography.Title>
					<Upload {...props}>
						<Button onClick={() => setType('True')}>Загрузить</Button>
					</Upload>
				</div>
				<div>
					<Typography.Title level={5}>Импорт Hazop 4</Typography.Title>
					<Upload {...props}>
						<Button onClick={() => setType('False')}>Загрузить</Button>
					</Upload>
				</div>
				<div>
					<Typography.Title level={5}>Импорт Lopa</Typography.Title>
					<Upload {...props}>
						<Button onClick={() => setType('LOPA')}>Загрузить</Button>
					</Upload>
				</div>
				<div>
					<Typography.Title level={5}>Импорт узлов и систем</Typography.Title>
					<Upload {...props}>
						<Button onClick={() => setType('node')}>Загрузить</Button>
					</Upload>
				</div>
			</Space>
			{serverInfo?.length > 0 && (
				<div style={{ marginTop: '20px' }}>
					<Typography.Title level={5}>Информация</Typography.Title>
					<div style={{ height: '48vh', overflow: 'auto' }}>
						<List
							dataSource={serverInfo}
							renderItem={item => {
								const icon = iconForNotification[item.type]

								return (
									<List.Item>
										<List.Item.Meta
											avatar={icon}
											title={item.name}
											description={
												<div>
													{item.value} <br /> {item.consequences}
												</div>
											}
										/>
									</List.Item>
								)
							}}
						/>
					</div>
				</div>
			)}
		</>
	)
}
