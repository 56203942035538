import React from 'react'
import { CauseDto, RecommendationDto, SafeguardDto } from '../../../generated/backend'
import styles from './HazopTable.module.css'

type HazopTableTextColumnProps = {
	state: CauseDto[] | RecommendationDto[] | SafeguardDto[]
	haveFilter?: boolean
	type?: string
	filterOption?: string
}

const HazopTableTextColumn: React.FC<HazopTableTextColumnProps> = ({
	state,
	haveFilter,
	type,
	filterOption,
}) => {
	if (haveFilter) {
		return (
			<>
				{state
					?.filter(item => item[filterOption]?.id === type)
					.map((item, index) => {
						return (
							<li key={index} className={styles.largePadding}>
								<span
									style={{ whiteSpace: item?.name?.includes('\n') ? 'pre-wrap' : null }}
								>
									{item?.name}
								</span>
							</li>
						)
					})}
			</>
		)
	}

	return (
		<>
			{state?.map((item, index) => {
				return (
					<li key={index} className={styles.largePadding}>
						<span style={{ whiteSpace: item?.name?.includes('\n') ? 'pre-wrap' : null }}>
							{item?.name}
						</span>
					</li>
				)
			})}
		</>
	)
}

export default HazopTableTextColumn
