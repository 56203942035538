import { EquipmentDto } from './../../generated/backend/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EquipmentDtoRequest } from '../../generated/backend'
import { equipmentsApi } from '../../api/apis'

export const fetchEquipments = createAsyncThunk(
	'equipments/fetchEquipments',
	async (_, { rejectWithValue }) => {
		try {
			const response = await equipmentsApi.apiV1EquipmentGet()

			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

export const fetchEquipmentById = createAsyncThunk(
	'equipments/equipment-by',
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await equipmentsApi.apiV1EquipmentIdGet(id)

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const deleteEquipment = createAsyncThunk(
	'equipments/deleteEquipment',
	async (id: string, { rejectWithValue }) => {
		try {
			await equipmentsApi.apiV1EquipmentDelete(id)

			return id
		} catch (e) {
			return rejectWithValue(e)
		}
	}
)

export const editEquipment = createAsyncThunk(
	'equipments/edit-equipment',
	async (parameter: EquipmentDtoRequest, { rejectWithValue }) => {
		try {
			const response = await equipmentsApi.apiV1EquipmentPut(parameter)

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const addEquipment = createAsyncThunk(
	'equipments/add-equipments',
	async (parameter: EquipmentDtoRequest, { rejectWithValue }) => {
		try {
			const response = await equipmentsApi.apiV1EquipmentPost(parameter)

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const searchEquipment = createAsyncThunk(
	'equipments/search-equipment',
	async (
		{ searchValue, debounceTime }: { searchValue: string; debounceTime: number },
		{ rejectWithValue }
	) => {
		try {
			await new Promise(resolve => {
				const timeout = setTimeout(() => resolve(null), debounceTime)

				return () => clearTimeout(timeout)
			})

			const response = await equipmentsApi.apiV1EquipmentSearchGet(searchValue)

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)
