import { Modal } from 'antd'
import React from 'react'

export const errorModal = async error => {
	let text = error

	if (typeof error === 'object') {
		text = await error.text()
	}

	Modal.error({
		title: 'Ошибка',
		content: (
			<div style={{ overflow: 'auto', height: 'auto', maxHeight: 'calc(100vh - 300px)' }}>
				{text}
			</div>
		),
		width: 1000,
		onOk() {
			Modal.destroyAll()
		},
	})
}
