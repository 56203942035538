import { createSlice } from '@reduxjs/toolkit'
import { AssetSystemDto } from '../../generated/backend'
import {
	addSystem,
	deleteSystem,
	editSystem,
	fetchSystemById,
	fetchSystems,
} from './systems.actions'

interface SystemsState {
	systems: AssetSystemDto[]
	isLoading: boolean
	selected: { system: AssetSystemDto; isLoading: boolean }
}

const initialState: SystemsState = {
	systems: [],
	isLoading: true,

	selected: {
		system: {},
		isLoading: true,
	},
}

export const systemsSlice = createSlice({
	name: 'systems',
	initialState,
	reducers: {
		setSystems: (state, action) => {
			state.systems = action.payload
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchSystems.pending, state => {
			state.isLoading = true
		})
		builder.addCase(fetchSystems.fulfilled, (state, action) => {
			state.isLoading = false
			state.systems = action.payload
		})
		builder.addCase(fetchSystems.rejected, state => {
			state.isLoading = false
		})

		builder.addCase(deleteSystem.pending, state => {
			state.isLoading = true
		})
		builder.addCase(deleteSystem.fulfilled, (state, action) => {
			state.isLoading = false
			state.systems = state.systems.filter(item => item.id !== action.payload)
		})
		builder.addCase(deleteSystem.rejected, state => {
			state.isLoading = false
		})

		builder.addCase(fetchSystemById.pending, state => {
			state.selected.isLoading = true
		})
		builder.addCase(fetchSystemById.fulfilled, (state, action) => {
			state.selected.isLoading = false
			state.selected.system = action.payload
		})
		builder.addCase(fetchSystemById.rejected, state => {
			state.selected.isLoading = false
		})

		builder.addCase(editSystem.pending, state => {
			state.selected.isLoading = true
		})
		builder.addCase(editSystem.fulfilled, (state, action) => {
			state.selected.isLoading = false
			const editedIndex = state.systems.findIndex(item => item.id === action.payload.id)

			state.systems[editedIndex] = action.payload
			state.selected.system = action.payload
		})
		builder.addCase(editSystem.rejected, state => {
			state.selected.isLoading = false
		})

		builder.addCase(addSystem.fulfilled, (state, action) => {
			state.isLoading = false

			state.systems.push(action.payload)

			state.selected = {
				system: action.payload,
				isLoading: false,
			}

			return state
		})
		builder.addCase(addSystem.rejected, state => {
			state.isLoading = false
		})
	},
})

export const { setSystems } = systemsSlice.actions

export default systemsSlice.reducer
