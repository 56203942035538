import { Button, Form, Input, InputRef, Modal, Select, Space, message } from 'antd'
import React from 'react'
import { RiskDangerValueDto } from '../../generated/backend'
import { DangerValueRequest, IDangerValueModal } from '../types'
import { enumsApi } from '../../api/apis'

type DangerValueModalProps = {
	showProps: IDangerValueModal
	onCancel: () => void
	sendDangerValue: (dangerValueRequest: DangerValueRequest) => Promise<void>
}

type dangerValueOption = RiskDangerValueDto & { value: number; label: string }

export const DangerValueModal: React.FC<DangerValueModalProps> = ({
	onCancel,
	showProps,
	sendDangerValue,
}) => {
	const { show, x, y, isEdit, id, value, title } = showProps
	const inputRef = React.useRef<InputRef>(null)

	const [state, setState] = React.useState<{
		value: number
		label: string
		dangerColor?: string
	}>()
	const [dangerValues, setDangerValues] = React.useState<RiskDangerValueDto[]>([])
	const [form] = Form.useForm()

	React.useEffect(() => {
		if (!show) return

		setState({ value, label: title })
		const timeout = setTimeout(() => {
			inputRef.current!.focus({
				cursor: 'end',
			})
		}, 200)

		return () => clearTimeout(timeout)
	}, [show])

	React.useEffect(() => {
		fetchDangerValues()
	}, [])

	const fetchDangerValues = async () => {
		const response = await enumsApi.apiV1EnumDangerValuesGet()

		setDangerValues(response.data)
	}

	const onSubmit = async () => {
		let clearedState = { ...state }
		if (!clearedState.label) clearedState.label = ''
		if (!clearedState.value) clearedState.value = 0

		await sendDangerValue({
			x,
			y,
			isEdit,
			id,
			value: clearedState.value,
			dangerColor: clearedState.dangerColor,
			title: clearedState.label,
		})
		onCancel()
	}

	const onSelectDangerValue = (value: number, { dangerColor }: dangerValueOption) => {
		setState({ ...state, value, dangerColor })
	}

	const onClearSelect = () => {
		setState({ ...state, value: 0 })
	}

	const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, label: e.target.value })
	}

	return (
		<Modal
			title={'Добавить параметр'}
			open={show}
			onCancel={onCancel}
			centered={true}
			footer={false}
			destroyOnClose
		>
			<Form form={form} layout='vertical'>
				<Form.Item label='Уровень риска:'>
					<Select
						id={'danger-value-select'}
						size='large'
						value={state?.value}
						placeholder='Выберите уровень риска'
						options={dangerValues.map(dangerValue => ({
							...dangerValue,
							value: dangerValue.id,
							label: dangerValue.name,
						}))}
						onSelect={onSelectDangerValue}
						allowClear
						onClear={onClearSelect}
					/>
				</Form.Item>
				<Form.Item label='Значение:'>
					<Input
						id={'danger-value-input'}
						name='label'
						ref={inputRef}
						size='large'
						placeholder='Введите значение'
						value={state?.label}
						onChange={onChangeValue}
					/>
				</Form.Item>
				<Form.Item>
					<Space>
						<Button name='save' htmlType='submit' type={'primary'} onClick={onSubmit}>
							Сохранить
						</Button>
						<Button htmlType='reset' onClick={onCancel}>
							Закрыть
						</Button>
					</Space>
				</Form.Item>
			</Form>
		</Modal>
	)
}
