import { Col } from 'antd'
import classNames from 'classnames'
import React from 'react'
import styles from './RiskMatrix.module.css'
import { green, grey, red, yellow } from '@ant-design/colors'

type DangerCellProps = {
	backgroundColor: string
	title: string
	onClickCell: () => void
}

const DangerCell: React.FC<DangerCellProps> = ({ backgroundColor, title, onClickCell }) => {
	return (
		<Col
			style={{
				borderLeft: `1px solid ${grey[7]}`,
				borderTop: `1px solid ${grey[7]}`,
				borderBottom: `1px solid ${grey[7]}`,
				backgroundColor,
			}}
			className={classNames(styles.cell, styles.textSm, 'danger-value')}
			flex={'5 0 5px'}
			onClick={onClickCell}
		>
			<div>{title}</div>
		</Col>
	)
}

export default DangerCell
