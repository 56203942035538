import { CauseDto, HazopEntryDto, RecommendationDto } from '../generated/backend'
import { TypeOfHazopTableState } from '../components/types'

export const isChanged = (
	item: TypeOfHazopTableState,
	hazopEntry: HazopEntryDto,
	entityType: 'causes' | 'consequences' | 'recommendations' | 'safeguards'
) => {
	if (entityType !== 'recommendations' && entityType !== 'causes') {
		const sourceItem = hazopEntry[entityType]?.find(i => i.name === item.name)
		if (entityType === 'safeguards') {
			return true
		}
		if (sourceItem) return false
	} else if (entityType === 'recommendations') {
		const sourceItem = hazopEntry[entityType]?.find(
			i => i.name === item.name && i.criticality === (item as RecommendationDto).criticality
		)

		if (sourceItem) return false
	} else if (entityType === 'causes') {
		const sourceItem = hazopEntry[entityType]?.find(
			i => i.name === item.name && i.causeProbability === (item as CauseDto).causeProbability
		)

		if (sourceItem) return false
	}

	return true
}
