import { Button, Modal, Space, Typography } from 'antd';
import React from 'react';

type ImportModalProps = {
    open: boolean;
    onCancel: () => void;
    handleUpload: () => void;
    isUploading: boolean;
};

const ImportModal: React.FC<ImportModalProps> = ({ open, onCancel, handleUpload, isUploading }) => {
    return (
        <Modal open={open} onCancel={onCancel} centered footer={false} width={400} destroyOnClose>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                <Typography.Title level={3}>Вы действительно хотите загрузить файл?</Typography.Title>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                <Space>
                    <Button loading={isUploading} danger type='primary' onClick={handleUpload}>
                        Загрузить
                    </Button>
                    <Button onClick={onCancel}>Отмена</Button>
                </Space>
            </div>
        </Modal>
    );
};

export default ImportModal;
