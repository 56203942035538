import axios from 'axios'
import { IDownloadBooks } from '../components/types'
import { message } from 'antd'

export const downloadBooks: IDownloadBooks = (isLopa, callback, type): void => {
	axios
		.get(`/api/v1/export/hazop-workbook${isLopa ? 'lopa' : `?eight-lines=${type}`}`, {
			responseType: 'blob',
		})
		.then(response => {
			const href = URL.createObjectURL(response.data)
			const fileName = response.headers['content-disposition'].split('=')[1].replace(/"/g, '')

			const link = document.createElement('a')
			link.href = href
			link.setAttribute('download', fileName)
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			URL.revokeObjectURL(href)
			callback({ loading: false, id: '' })
			message.success('Файл успешно экспортирован')
		})
		.catch(() => {
			callback({ loading: false, id: '' })
		})
}
