import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NodeDto, NodeDtoRequest } from '../../generated/backend'
import { addNode, deleteNode, fetchNodes } from '../../redux/nodes/nodes.actions'
import { RootState, useAppDispatch } from '../../redux/store'
import ContentLayout from '../UI/ContentLayout/ContentLayout'
import { message } from 'antd'

type NodesLayoutProps = {}
const layoutKey = 'nodes'

export const NodesLayout: React.FC<NodesLayoutProps> = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { nodes, isLoading } = useSelector((state: RootState) => state.nodes)

	const [messageApi, contextHolder] = message.useMessage()

	React.useEffect(() => {
		const fetchNodesData = async () => {
			const response = await dispatch(fetchNodes())

			if (response.meta.requestStatus === 'rejected') {
				messageApi.error('Не удалось загрузить узлы!')
			}
		}

		fetchNodesData()
	}, [])

	const onDeleteNode = async (node: NodeDto) => {
		navigate('/nodes')
		const response = await dispatch(deleteNode(node.id))
		if (response.meta.requestStatus === 'fulfilled') {
			messageApi.success('Узел успешно удален!')
		} else {
			messageApi.error('Что-то пошло не так!')
		}
	}

	const onSave = async (node: NodeDtoRequest) => {
		const createdNode = await dispatch(addNode(node))
		if (createdNode.meta.requestStatus === 'fulfilled') {
			navigate(`/nodes/${(createdNode.payload as NodeDto)?.id}`)
			messageApi.success('Узел успешно создан!')
		} else {
			messageApi.error('Что-то пошло не так!')
		}
	}

	return (
		<>
			{contextHolder}
			<ContentLayout
				onSave={onSave}
				onDeleteEntity={onDeleteNode}
				entity={nodes}
				path={'/nodes'}
				loading={isLoading}
				layoutKey={layoutKey}
			/>
		</>
	)
}
