import { riskMatrixSlice } from './../redux/riskMatrix/riskMatrixSlice'
import { hazopEntriesSlice } from '../redux/hazopEntries/hazopEntriesSlice'
import { nodesSlice } from './../redux/nodes/nodesSlice'
import { equipmentSlice } from './../redux/equipments/equipmentsSlice'
import { parametersSlice } from './../redux/parameters/parametersSlice'
import { bindActionCreators } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../redux/store'
import { generalSlice } from './../redux/general/generalSlice'
import { settingsSlice } from './../redux/settings/settingsSlice'
import { systemsSlice } from './../redux/systems/systemsSlice'

const allActions = {
	...settingsSlice.actions,
	...generalSlice.actions,
	...parametersSlice.actions,
	...equipmentSlice.actions,
	...nodesSlice.actions,
	...hazopEntriesSlice.actions,
	...riskMatrixSlice.actions,
	...systemsSlice.actions,
}

export const useActions = () => {
	const dispatch = useDispatch<AppDispatch>()

	return useMemo(() => bindActionCreators(allActions, dispatch), [dispatch])
}
