import { Button, message } from 'antd'
import React from 'react'
import DeleteAllModal from '../../../importAndExport/Modals/DeleteAllModal'
import { useModalActions } from '../../../../hooks/useModalActions'
import { dbApi } from '../../../../api/apis'

type ClearAllDataButtonProps = {}

const ClearAllDataButton: React.FC<ClearAllDataButtonProps> = () => {
	const [isOpen, onOpenModal, onCloseModal] = useModalActions()
	const [isLoading, setIsLoading] = React.useState(false)
	const [messageApi, contextHolder] = message.useMessage()

	const clearAllData = async () => {
		setIsLoading(true)

		try {
			await dbApi.apiV1DbDeleteDelete()
			const timeoutId = setTimeout(() => {
				onCloseModal()
				setIsLoading(false)

				messageApi.success('Все данные успешно удалены!')
				clearTimeout(timeoutId)
			}, 1000)

			return () => clearTimeout(timeoutId)
		} catch (error) {
			messageApi.error('Произошла ошибка при удалении данных!')
		}
	}

	return (
		<>
			{contextHolder}
			<DeleteAllModal
				open={isOpen}
				loading={isLoading}
				onCancel={onCloseModal}
				clearAllData={clearAllData}
			/>
			<Button onClick={onOpenModal} danger>
				Удалить все данные
			</Button>
		</>
	)
}

export default ClearAllDataButton
