import React from 'react';
import JpgFilesTable from './JpgFilesTable';

type JpgFilesLayoutProps = {};

const JpgFilesLayout: React.FC<JpgFilesLayoutProps> = () => {
    return (
        <>
            <JpgFilesTable />
        </>
    );
};

export default JpgFilesLayout;
